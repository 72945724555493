import React, { useCallback, useEffect, useMemo, useState } from "react";
import { HeaderTitle } from "../../components/header/Header";
import Container from "../../components/layout/Container";
import { mock_data_head_table, mock_data_table } from "../../utils/useMockData";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_WHITE_TABLE_RETIRE,
} from "../../utils/useStyle";
import { styleTable, styleTableBody } from "./styles/TableStyle";
import { useRetireStore } from "../../_store/retireStore";
import numeral from "numeral";
import { MTButton } from "../../components/button/MTButton";
import { ICON_DL_PDF } from "../../utils/useIcons";
import NavigateGoBack from "../../functions/Navigate.Goback";
import { useLocation, useNavigate } from "react-router-dom";
import { avgWeight } from "./functions/functionMain";
import {
  dataAge,
  dataAgeAF,
  getYear,
  getYearAF,
  loopAge,
  loopIncome,
  loopIncomeAF,
  loopInvestmentAssets,
  loopInvestmentAssetsAF,
  loopOutcome,
  loopOutcomeAF,
  loopRemain,
  loopRemainAF,
  loopReturnInvestment,
  loopReturnInvestmentAF,
  loopYear,
} from "./functions/functionRetriementPlan";
import {
  dataAgeAFSensitive,
  loopIncomeAFSensitive,
  loopInvestmentAssetsAFSensitive,
  loopOutcomeAFSensitive,
  loopRemainAFSensitive,
  loopReturnInvestmentAFSensitive,
} from "./functions/functionRetrementSensitivePlan";
import {
  DebtRetire,
  InvestingPropertyCal,
  InvestmentReturnCal,
  OutcomeReliableCal,
  OutcomeUreliableCal,
} from "../../functions/retirement/retirementFunction";
import { useBalanceStore } from "../../_store/balanceStore";
import { useFuturePlanStore } from "../../_store/futurePlanStore";
import { CalcAll_Expense } from "../../functions/futurePlan/futurePlanFunction";
import { key_under_education } from "../education/functions/options";
import { useEducationStore } from "../../_store/educationStore";
import { calDebtCreditCard } from "../../utils/useFunctions";
import {
  InvestmentReturnCalAfter,
  OutcomeReliableCalAfter,
  OutcomeUreliableCalAfter,
} from "../../functions/retirement/retirementFunctionAfter";
import { useUserStore } from "../../_store/userStore";

const TABLE_HEADER_STYLE =
  " px-[16px] pt-[14px] pb-[6px] break-keep whitespace-nowrap text-[#18488A] text-base font-semibold font-IBMPlexSansThai";

let merge_keys = [
  "debt_short_list",
  "debt_creditcard",
  "debt_shortterm",
  "debt_informal_shortterm",
  "debt_shortterm_other",
  "debt_car",
  "debt_place",
  "debt_long_other",
];

const merge_label_key = {
  expenese_home_items: "บ้าน",
  expenese_car_items: "ซื้อรถ",
  expenese_children_items: "ลูก",
  expenese_travel_items: "ท่องเที่ยว",
  expenese_collection_items: "ของสะสม",
  expenese_other_items: "รายจ่ายอื่นๆ",
  income_legacy_items: "รายได้จากมรดก",
  income_business_items: "ขายธุรกิจ",
  income_property_items: "รายได้จากอสังหาริม",
  income_other_items: "รายได้อื่นๆ",
  income_peryear: "รายได้",
  remain_accumulate: "สินทรัพย์ลงทุน",
  outcome_peryear: "จ่ายผันแปร",
  out_come_stable: "จ่ายคงที่ (ไม่รวมหนี้)",
  outcome_total: "รวมรายจ่ายต่อปี ",
  pension_peryear: "บำนาญต่อปี",
  remain: "เงินคงเหลือ",
  income_investment: "ผลตอบแทนการลงทุน ",
  debt_shortterm_other: "หนี้ดอกลอย",
  debt_informal_shortterm: "หนี้นอกระบบ",
  debt_shortterm: "หนี้ระยะสั้น",
  debt_creditcard: "หนี้บัตรเครดิต",
  expenese_children_education: "การศึกษาบุตร",
  debt_car: "ยานพาหนะ",
  debt_place: "ที่อยู่อาศัย",
  debt_long_other: "หนี้ระยะยาวอื่นๆ",
};

const SummaryTableFinance = ({}) => {
  const location = useLocation();

  const isBeforeMode = location?.search === "?before";
  const isAfterMode = location?.search === "?after";
  const isSensitive = location?.search === "?sensitive";

  const balanceData = useBalanceStore((state) => state.balanceData);
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const educationData = useEducationStore((state) => state.educationData);
  const userData = useUserStore((state) => state.userData);

  const retireData = useRetireStore((state) => state.retireData);
  const setRetireData = useRetireStore((state) => state.setRetireData);
  const clearRetireData = useRetireStore((state) => state.clearRetireData);
  const navigate = useNavigate();

  const tempFutureData = useMemo(() => {
    let copyFuturePlanData = futurePlanData;
    let calExpense = DebtRetire(
      futurePlanData,
      balanceData,
      educationData,
      key_under_education
    );

    copyFuturePlanData = { ...copyFuturePlanData, cash_flow_items: calExpense };
    return copyFuturePlanData;
  }, []);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setRetireData({
        ...retireData,

        bf_plan_age: loopAge(),
        bf_plan_income: loopIncome(),
        bf_plan_outcome: loopOutcome(),
        bf_plan_remain: loopRemain(),
        bf_return_investment: loopReturnInvestment(),
        bf_investment_assets: loopInvestmentAssets(),

        af_plan_age: dataAgeAF(),
        af_plan_income: loopIncomeAF(),
        af_plan_outcome: loopOutcomeAF(),
        af_plan_remain: loopRemainAF(),
        af_return_investment: loopReturnInvestmentAF(),
        af_investment_assets: loopInvestmentAssetsAF(),
      });
    }

    return () => {
      ignore = true;
    };
  }, [
    setRetireData,
    retireData?.strategy_out_come,
    retireData?.strategy_income,
    retireData?.strategy_passive_income_retire,
    retireData?.strategy_assets_generate,
    retireData?.strategy_assets_generate_per_year,
    retireData?.strategy_salary,
    retireData?.strategy_salary_peryear,
    retireData?.strategy_control_rate_outcome,
    retireData?.strategy_age_retire,
    retireData?.strategy_age_dead,
    retireData?.strategy_investment_bf_retirement,
    retireData?.strategy_investment_af_retirement,
  ]);

  const dataPlan = useMemo(() => {
    if (isBeforeMode) {
      return InvestmentReturnCal(retireData)?.map((item) => {
        return item?.asset_invest;
      });
    }
    if (isAfterMode) {
      return InvestmentReturnCalAfter(retireData)?.map((item) => {
        return item?.asset_invest;
      });
    }
    if (isSensitive) {
      return loopInvestmentAssetsAFSensitive()?.map((item) => {
        return item?.results;
      });
    }
  }, [
    retireData?.bf_investment_assets,
    retireData?.af_investment_assets,
    loopInvestmentAssetsAFSensitive(),
  ]);

  const checkColor = useCallback(
    (i) => {
      const green = [];
      const yellow = [];
      const red = [];

      for (let index = 0; index < dataPlan?.length; index++) {
        const currentValue = dataPlan[index];
        const nextValue = dataPlan[index + 1];

        //จุดแรก
        if (index === 0) {
          if (dataPlan[index] <= 0) {
            red.push(dataPlan[index]);
            yellow.push(null);
            green.push(null);
          } else {
            if (dataPlan[0] > dataPlan[1]) {
              yellow.push(dataPlan[index]);
              red.push(null);
              green.push(null);
            } else {
              green.push(dataPlan[index]);
              yellow.push(null);
              red.push(null);
            }
          }
          continue;
        }

        // red
        if (dataPlan[index] <= 0) {
          if (nextValue > currentValue && currentValue < 0 && nextValue > 0) {
            red.push(dataPlan[index]);
            green.push(dataPlan[index]);
            yellow.push(null);
            continue;
          }
          red.push(dataPlan[index]);
          yellow.push(null);
          green.push(null);
          continue;
        }

        if (dataPlan[index] < dataPlan[index - 1]) {
          // yellow => green
          if (nextValue > currentValue) {
            green.push(dataPlan[index]);
            yellow.push(dataPlan[index]);
            // yellow.push(null);
            red.push(null);
            continue;
          }

          //yellow => red
          if (nextValue < currentValue && nextValue < 0) {
            red.push(dataPlan[index]);
            green.push(null);
            yellow.push(dataPlan[index]);
            // yellow.push(null);
            continue;
          }

          yellow.push(dataPlan[index]);
          green.push(null);
          red.push(null);
        } else {
          //green => yellow

          if (nextValue < currentValue && nextValue > 0) {
            red.push(null);
            green.push(dataPlan[index]);
            yellow.push(dataPlan[index]);
            // yellow.push(null);
            continue;
          }
          //green
          green.push(dataPlan[index]);
          yellow.push(null);
          red.push(null);
        }
      }

      if (green[i] !== null) {
        return "bg-greenopacity border-maingreen/20 border ";
      }

      if (yellow[i] !== null) {
        return "bg-yellowopacity border-mainyellow/20 border ";
      }
      return "bg-redopacity border-mainred/20 border";
    },
    [dataPlan]
  );

  // console.log("is_premium", userData?.is_premium);
  // console.log("retireData",retireData)
  // console.log("OutcomeReliableCal",OutcomeReliableCal(retireData))
  // console.log("OutcomeReliableCalAfter",OutcomeReliableCalAfter(retireData))
  // console.log("==================================")


  return (
    <React.Fragment>
      {isBeforeMode ? (
        <Container>
          {/*-------------------- ตารางก่อนวางแผน --------------------*/}
          <div className={`${STYLE_MARGIN_TOP_PARENT} min-h-screen mb-20 `}>
            <HeaderTitle
              title="ตารางสรุปความเคลื่อนไหวการเงิน"
              color="bg-[#EAEAEA]"
              isGoback
              onClickGoBack={() => {
                NavigateGoBack(navigate);
              }}
            />
            {/* //Head */}
            <div className=" mt-8 px-6">
              <div className="flex w-full ">
                <div className=" shadow-md rounded-tl-[20px] rounded-bl-[20px] shadow-gray-500 w-fit sticky z-10 ">
                  <div className="  bg-gray50 h-14 px-3 font-semibold text-mainblue rounded-t-[20px]  rounded-tr-none w-12 flex gap-[38px] items-center ">
                    <div>อายุ</div>
                  </div>
                  <div className="  ">
                    <div
                      className={`  flex  items-center text-lg font-medium text-mainblue w-12 h-full `}
                    >
                      <div className=" h-14 w-full">
                        {dataAge()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={` h-full flex justify-center items-center text-mainblue w-full ${checkColor(
                                index
                              )}`}
                            >
                              {row}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" w-full max-w-2xl overflow-hidden overflow-x-scroll  h-full rounded-tr-[20px] rounded-br-[20px] bg-gray50">
                  <div className={`w-fit  `}>
                    <div
                      className={` flex font-semibold text-mainblue items-start w-full 
                  `}
                    >
                      {/* สินทรัพย์เพื่อการลงทุนสุทธิ */}
                      <div className="">
                        <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                          สินทรัพย์เพื่อการลงทุนสุทธิ
                        </div>
                        {loopInvestmentAssets()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={` whitespace-nowrap h-14 flex justify-center items-center px-3 ${checkColor(
                                index
                              )}`}
                            >
                              {numeral(
                                InvestmentReturnCal(retireData)?.[index]
                                  ?.asset_invest
                              ).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>

                      {/* รายได้ */}
                      <div className="">
                        <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                          รายได้
                        </div>
                        {retireData?.bf_plan_income?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE + " px-3 "}
                            >
                              {numeral(row).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>

                      {/* รวมรายจ่ายต่อปี */}
                      <div className="">
                        <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                          {userData?.is_premium ? "รวมรายจ่ายต่อปี" : "รายจ่าย"}
                        </div>
                        {retireData?.bf_plan_age?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE + " px-3 "}
                            >
                              {numeral(
                                OutcomeUreliableCal(retireData)[index] +
                                  OutcomeReliableCal(retireData)[index]
                              ).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>

                      {/* รายจ่ายผันแปร */}
                      {userData?.is_premium && (
                        <div className="">
                          <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                            รายจ่ายผันแปร
                          </div>
                          {OutcomeUreliableCal(retireData)?.map(
                            (row, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    STYLE_WHITE_TABLE_RETIRE + " px-3 "
                                  }
                                >
                                  {numeral(row).format("0,0.00")}
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}

                      {/* รายจ่ายคงที่ */}
                      {userData?.is_premium && (
                        <div className="">
                          <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                            รายจ่ายคงที่
                          </div>
                          {OutcomeReliableCal(retireData, balanceData)?.map(
                            (row, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    STYLE_WHITE_TABLE_RETIRE + " px-3 "
                                  }
                                >
                                  {numeral(row).format("0,0.00")}
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}

                      {/* คงเหลือ */}
                      <div className="">
                        <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                          คงเหลือ
                        </div>
                        {retireData?.bf_plan_income?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE + " px-3 "}
                            >
                              {numeral(
                                row - OutcomeReliableCal(retireData)[index]
                              ).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>

                      {/* ผลตอบแทนการลงทุน */}
                      <div className="">
                        <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                          ผลตอบแทนการลงทุน
                        </div>
                        {retireData?.bf_plan_age?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE + " px-3 "}
                            >
                              {numeral(
                                InvestmentReturnCal(retireData)?.[index]
                                  ?.return_invest
                              ).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>

                      {/* สินทรัพย์เพื่อการลงทุนสุทธิ
                      <div className="">
                        <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                          สินทรัพย์เพื่อการลงทุนสุทธิ
                        </div>
                        {retireData?.bf_plan_age?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE + " px-3 "}
                            >
                              {numeral(
                                InvestmentReturnCal(retireData)?.[index]
                                  ?.asset_invest
                              ).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div> */}

                      {merge_keys?.map((item, index) => {
                        // ========หนี้=============================

                        // หนี้ดอกลอย
                        if (item === "debt_shortterm_other") {
                          return balanceData?.debt_short_list
                            ?.find(
                              (item) =>
                                item?.options_debt_short ===
                                "debt_shortterm_other"
                            )
                            ?.short_term_others?.map((item2, index2) => {
                              const calcPeriodInterest = (index) => {
                                let year = Math.floor(item2?.period / 12);
                                let lastYearMonth = item2?.period % 12;

                                if (index >= Math.ceil(item2?.period / 12)) {
                                  return 0;
                                }
                                if (index < year) {
                                  return 12;
                                } else {
                                  return lastYearMonth;
                                }
                              };
                              return (
                                <div key={index2} className="flex flex-col">
                                  <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                    {item2?.creditor_name
                                      ? item2?.creditor_name
                                      : merge_label_key[item]}
                                  </div>
                                  {retireData?.bf_plan_age?.map(
                                    (cash_flow_items, index3) => {
                                      return (
                                        <div
                                          key={index3}
                                          className={
                                            STYLE_WHITE_TABLE_RETIRE + " px-3 "
                                          }
                                        >
                                          {numeral(
                                            item2?.period === "Infinity"
                                              ? item2?.pay_permonth * 12
                                              : item2?.pay_permonth *
                                                  calcPeriodInterest(index3)
                                          )?.format("0,0.00")}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            });
                        }

                        // หนี้ีนอกระบบ
                        if (item === "debt_informal_shortterm") {
                          return balanceData?.debt_short_list
                            ?.find(
                              (item) =>
                                item?.options_debt_short ===
                                "debt_informal_shortterm"
                            )
                            ?.informal_short_terms?.map((item2, index2) => {
                              const calcPeriodInterest = (index) => {
                                let year = Math.floor(
                                  item2?.number_of_days / 365
                                );
                                let lastYearMonth = item2?.number_of_days % 30;

                                if (
                                  index >=
                                  Math.ceil(item2?.number_of_days / 365)
                                ) {
                                  return 0;
                                }
                                if (index < year) {
                                  return 365;
                                } else {
                                  return lastYearMonth;
                                }
                              };
                              return (
                                <div key={index2} className="flex flex-col">
                                  <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                    {item2?.creditor_name
                                      ? item2?.creditor_name
                                      : merge_label_key[item]}
                                  </div>
                                  {tempFutureData?.cash_flow_items?.map(
                                    (cash_flow_items, index3) => {
                                      return (
                                        <div
                                          key={index3}
                                          className={
                                            STYLE_WHITE_TABLE_RETIRE + " px-3 "
                                          }
                                        >
                                          {numeral(
                                            item2?.period === "Infinity"
                                              ? item2?.pay_permonth * 12
                                              : item2?.pay_permonth *
                                                  calcPeriodInterest(index3)
                                          )?.format("0,0.00")}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            });
                        }

                        // เงินกู้ระยะสั้น
                        if (item === "debt_shortterm") {
                          return balanceData?.debt_short_list
                            ?.find(
                              (item) =>
                                item?.options_debt_short === "debt_shortterm"
                            )
                            ?.short_terms?.map((item2, index2) => {
                              const calcPeriodInterest = (index) => {
                                let year = Math.floor(item2?.period / 12);
                                let lastYearMonth = item2?.period % 12;

                                if (index >= Math.ceil(item2?.period / 12)) {
                                  return 0;
                                }
                                if (index < year) {
                                  return 12;
                                } else {
                                  return lastYearMonth;
                                }
                              };
                              return (
                                <div key={index2} className="flex flex-col">
                                  <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                    {item2?.creditor_name
                                      ? item2?.creditor_name
                                      : merge_label_key[item]}
                                  </div>
                                  {retireData?.bf_plan_age?.map(
                                    (cash_flow_items, index3) => {
                                      return (
                                        <div
                                          key={index3}
                                          className={
                                            STYLE_WHITE_TABLE_RETIRE + " px-3 "
                                          }
                                        >
                                          {numeral(
                                            item2?.period === "Infinity"
                                              ? item2?.pay_permonth * 12
                                              : item2?.pay_permonth *
                                                  calcPeriodInterest(index3)
                                          )?.format("0,0.00")}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            });
                        }

                        // บัตรเครดิต
                        if (item === "debt_creditcard") {
                          return balanceData?.debt_short_list
                            ?.find(
                              (item) =>
                                item?.options_debt_short === "debt_creditcard"
                            )
                            ?.credit_cards?.map((item2, index2) => {
                              const calcPeriodInterest = (index) => {
                                let period =
                                  calDebtCreditCard(item2)?.creditor_items
                                    ?.length;
                                let year = Math.floor(period / 12);
                                let lastYearMonth = period % 12;

                                if (index >= Math.ceil(period / 12)) {
                                  return 0;
                                }
                                if (index < year) {
                                  return 12;
                                } else {
                                  return lastYearMonth;
                                }
                              };
                              return (
                                <div key={index2} className="flex flex-col">
                                  <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                    {item2?.creditor_name
                                      ? item2?.creditor_name
                                      : merge_label_key[item]}
                                  </div>
                                  {retireData?.bf_plan_age?.map(
                                    (cash_flow_items, index3) => {
                                      return (
                                        <div
                                          key={index3}
                                          className={
                                            STYLE_WHITE_TABLE_RETIRE + " px-3 "
                                          }
                                        >
                                          {numeral(
                                            item2?.type_payment ===
                                              "pay_full" && index3 === 0
                                              ? item2?.pay_peryear
                                              : item2?.pay_permonth *
                                                  calcPeriodInterest(index3)
                                          )?.format("0,0.00")}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            });
                        }

                        // ========หนี้ระยะยาว=============================
                        // หนี้ระยะยาว รถ
                        if (item === "debt_car") {
                          return balanceData?.debt_long_list
                            ?.find(
                              (item) => item?.options_debt_long === "debt_car"
                            )
                            ?.debt_long_cars?.map((item2, index2) => {
                              const calcPeriodInterest = (index) => {
                                let year = Math.floor(item2?.period / 12);
                                let lastYearMonth = item2?.period % 12;

                                if (index >= Math.ceil(item2?.period / 12)) {
                                  return 0;
                                }
                                if (index < year) {
                                  return 12;
                                } else {
                                  return lastYearMonth;
                                }
                              };
                              return (
                                <div key={index2} className="flex flex-col">
                                  <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                    {item2?.title_car
                                      ? item2?.title_car
                                      : merge_label_key[item]}
                                  </div>
                                  {retireData?.bf_plan_age?.map(
                                    (cash_flow_items, index3) => {
                                      return (
                                        <div
                                          key={index3}
                                          className={
                                            STYLE_WHITE_TABLE_RETIRE + " px-3 "
                                          }
                                        >
                                          {numeral(
                                            item2?.period === "Infinity"
                                              ? item2?.pay_permonth * 12
                                              : item2?.pay_permonth *
                                                  calcPeriodInterest(index3)
                                          )?.format("0,0.00")}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            });
                        }

                        // หนี้ระยะยาว บ้าน
                        if (item === "debt_place") {
                          return balanceData?.debt_long_list
                            ?.find(
                              (item) => item?.options_debt_long === "debt_place"
                            )
                            ?.debt_long_places?.map((item2, index2) => {
                              const calcPeriodInterest = (index) => {
                                let year = Math.floor(item2?.period / 12);
                                let lastYearMonth = item2?.period % 12;

                                if (index >= Math.ceil(item2?.period / 12)) {
                                  return 0;
                                }
                                if (index < year) {
                                  return 12;
                                } else {
                                  return lastYearMonth;
                                }
                              };
                              return (
                                <div key={index2} className="flex flex-col">
                                  <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                    {item2?.address_place
                                      ? item2?.address_place
                                      : merge_label_key[item]}
                                  </div>
                                  {retireData?.bf_plan_age?.map(
                                    (cash_flow_items, index3) => {
                                      return (
                                        <div
                                          key={index3}
                                          className={
                                            STYLE_WHITE_TABLE_RETIRE + " px-3 "
                                          }
                                        >
                                          {numeral(
                                            item2?.period === "Infinity"
                                              ? item2?.pay_permonth * 12
                                              : item2?.pay_permonth *
                                                  calcPeriodInterest(index3)
                                          )?.format("0,0.00")}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            });
                        }

                        // หนี้ระยะยาว อื่นๆ
                        if (item === "debt_long_other") {
                          return balanceData?.debt_long_list
                            ?.find(
                              (item) =>
                                item?.options_debt_long === "debt_long_other"
                            )
                            ?.debt_long_others?.map((item2, index2) => {
                              const calcPeriodInterest = (index) => {
                                let year = Math.floor(item2?.period / 12);
                                let lastYearMonth = item2?.period % 12;

                                if (index >= Math.ceil(item2?.period / 12)) {
                                  return 0;
                                }
                                if (index < year) {
                                  return 12;
                                } else {
                                  return lastYearMonth;
                                }
                              };
                              return (
                                <div key={index2} className="flex flex-col">
                                  <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                    {item2?.title
                                      ? item2?.title
                                      : merge_label_key[item]}
                                  </div>
                                  {retireData?.bf_plan_age?.map(
                                    (cash_flow_items, index3) => {
                                      return (
                                        <div
                                          key={index3}
                                          className={
                                            STYLE_WHITE_TABLE_RETIRE + " px-3 "
                                          }
                                        >
                                          {numeral(
                                            item2?.period === "Infinity"
                                              ? item2?.pay_permonth * 12
                                              : item2?.pay_permonth *
                                                  calcPeriodInterest(index3)
                                          )?.format("0,0.00")}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            });
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" flex  justify-center mt-5">
              <MTButton
                // loading={loading}
                text="ย้อนกลับ"
                color="border border-maincyan "
                colortext="text-maincyan group-hover:text-white"
                hovercolor="hover:bg-maincyan group "
                onClick={() => {
                  // clearRetireData();
                  NavigateGoBack(navigate);
                }}
              />
            </div>
          </div>
        </Container>
      ) : isAfterMode ? (
        <Container>
          {/*-------------------- ตารางหลังวางแผน --------------------*/}
          <div className={`${STYLE_MARGIN_TOP_PARENT} min-h-screen mb-20 `}>
            <HeaderTitle
              title="ตารางสรุปความเคลื่อนไหวการเงิน"
              color="bg-[#EAEAEA]"
              isGoback
              onClickGoBack={() => {
                NavigateGoBack(navigate);
              }}
            />
            {/* //Head */}
            <div className=" mt-8 px-6">
              <div className=" mt-8 px-6">
                <div className="flex w-full ">
                  <div className=" shadow-md rounded-tl-[20px] rounded-bl-[20px] shadow-gray-500 w-fit sticky z-10 ">
                    <div className="  bg-gray50 h-14 px-3 font-semibold text-mainblue rounded-t-[20px]  rounded-tr-none w-12 flex gap-[38px] items-center ">
                      <div>อายุ</div>
                    </div>
                    <div className="  ">
                      <div
                        className={`  flex  items-center text-lg font-medium text-mainblue w-12 h-full `}
                      >
                        <div className=" h-14 w-full">
                          {retireData?.af_plan_remain?.map((row, index) => {
                            return (
                              <div
                                key={index}
                                className={` h-full flex justify-center items-center text-mainblue w-full ${checkColor(
                                  index
                                )}`}
                              >
                                {row?.age}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" w-full max-w-2xl overflow-hidden overflow-x-scroll  h-full rounded-tr-[20px] rounded-br-[20px] bg-gray50">
                    <div className={`w-fit  `}>
                      <div
                        className={` flex font-semibold text-mainblue items-start w-full 
                  `}
                      >
                        {/* สินทรัพย์เพื่อการลงทุนสุทธิ */}
                        <div className="">
                          <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                            สินทรัพย์เพื่อการลงทุนสุทธิ
                          </div>
                          {retireData?.af_plan_remain?.map((row, index) => {
                            return (
                              <div
                                key={index}
                                className={` whitespace-nowrap h-14 flex justify-center items-center px-3 ${checkColor(
                                  index
                                )}`}
                              >
                                {numeral(
                                  InvestmentReturnCalAfter(retireData)?.[index]
                                    ?.asset_invest
                                ).format("0,0.00")}
                              </div>
                            );
                          })}
                        </div>

                        {/* รายได้ */}
                        <div className="">
                          <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                            รายได้
                          </div>
                          {retireData?.af_plan_income?.map((row, index) => {
                            return (
                              <div
                                key={index}
                                className={STYLE_WHITE_TABLE_RETIRE + " px-3 "}
                              >
                                {numeral(row).format("0,0.00")}
                              </div>
                            );
                          })}
                        </div>

                        {/* รวมรายจ่ายต่อปี */}
                        <div className="">
                          <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                            {userData?.is_premium
                              ? "รวมรายจ่ายต่อปี"
                              : "รายจ่าย"}
                          </div>
                          {retireData?.af_plan_age?.map((row, index) => {
                            return (
                              <div
                                key={index}
                                className={STYLE_WHITE_TABLE_RETIRE + " px-3 "}
                              >
                                {numeral(
                                  OutcomeUreliableCalAfter(retireData)[index] +
                                    OutcomeReliableCalAfter(retireData)[index]
                                ).format("0,0.00")}
                              </div>
                            );
                          })}
                        </div>

                        {/* รายจ่ายผันแปร */}
                        {userData?.is_premium && (
                          <div className="">
                            <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                              รายจ่ายผันแปร
                            </div>
                            {OutcomeUreliableCalAfter(retireData)?.map(
                              (row, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={
                                      STYLE_WHITE_TABLE_RETIRE + " px-3 "
                                    }
                                  >
                                    {numeral(row).format("0,0.00")}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}

                        {/* รายจ่ายคงที่ */}
                        {userData?.is_premium && (
                          <div className="">
                            <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                              รายจ่ายคงที่
                            </div>
                            {OutcomeReliableCalAfter(
                              retireData,
                              balanceData
                            )?.map((row, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    STYLE_WHITE_TABLE_RETIRE + " px-3 "
                                  }
                                >
                                  {numeral(row).format("0,0.00")}
                                </div>
                              );
                            })}
                          </div>
                        )}

                        {/* คงเหลือ */}
                        <div className="">
                          <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                            คงเหลือ
                          </div>
                          {retireData?.af_plan_income?.map((row, index) => {
                            return (
                              <div
                                key={index}
                                className={STYLE_WHITE_TABLE_RETIRE + " px-3 "}
                              >
                                {numeral(
                                  row -
                                    OutcomeReliableCalAfter(retireData)[index]
                                ).format("0,0.00")}
                              </div>
                            );
                          })}
                        </div>

                        {/* ผลตอบแทนการลงทุน */}
                        <div className="">
                          <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                            ผลตอบแทนการลงทุน
                          </div>
                          {retireData?.af_plan_age?.map((row, index) => {
                            return (
                              <div
                                key={index}
                                className={STYLE_WHITE_TABLE_RETIRE + " px-3 "}
                              >
                                {numeral(
                                  InvestmentReturnCalAfter(retireData)?.[index]
                                    ?.return_invest
                                ).format("0,0.00")}
                              </div>
                            );
                          })}
                        </div>

                        {/* สินทรัพย์เพื่อการลงทุนสุทธิ
                      <div className="">
                        <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                          สินทรัพย์เพื่อการลงทุนสุทธิ
                        </div>
                        {retireData?.bf_plan_age?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE + " px-3 "}
                            >
                              {numeral(
                                InvestmentReturnCal(retireData)?.[index]
                                  ?.asset_invest
                              ).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div> */}

                        {merge_keys?.map((item, index) => {
                          // ========หนี้=============================

                          // หนี้ดอกลอย
                          if (item === "debt_shortterm_other") {
                            return balanceData?.debt_short_list
                              ?.find(
                                (item) =>
                                  item?.options_debt_short ===
                                  "debt_shortterm_other"
                              )
                              ?.short_term_others?.map((item2, index2) => {
                                const calcPeriodInterest = (index) => {
                                  let year = Math.floor(item2?.period / 12);
                                  let lastYearMonth = item2?.period % 12;

                                  if (index >= Math.ceil(item2?.period / 12)) {
                                    return 0;
                                  }
                                  if (index < year) {
                                    return 12;
                                  } else {
                                    return lastYearMonth;
                                  }
                                };
                                return (
                                  <div key={index2} className="flex flex-col">
                                    <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                      {item2?.creditor_name
                                        ? item2?.creditor_name
                                        : merge_label_key[item]}
                                    </div>
                                    {retireData?.bf_plan_age?.map(
                                      (cash_flow_items, index3) => {
                                        return (
                                          <div
                                            key={index3}
                                            className={
                                              STYLE_WHITE_TABLE_RETIRE +
                                              " px-3 "
                                            }
                                          >
                                            {numeral(
                                              item2?.period === "Infinity"
                                                ? item2?.pay_permonth * 12
                                                : item2?.pay_permonth *
                                                    calcPeriodInterest(index3)
                                            )?.format("0,0.00")}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              });
                          }

                          // หนี้ีนอกระบบ
                          if (item === "debt_informal_shortterm") {
                            return balanceData?.debt_short_list
                              ?.find(
                                (item) =>
                                  item?.options_debt_short ===
                                  "debt_informal_shortterm"
                              )
                              ?.informal_short_terms?.map((item2, index2) => {
                                const calcPeriodInterest = (index) => {
                                  let year = Math.floor(
                                    item2?.number_of_days / 365
                                  );
                                  let lastYearMonth =
                                    item2?.number_of_days % 30;

                                  if (
                                    index >=
                                    Math.ceil(item2?.number_of_days / 365)
                                  ) {
                                    return 0;
                                  }
                                  if (index < year) {
                                    return 365;
                                  } else {
                                    return lastYearMonth;
                                  }
                                };
                                return (
                                  <div key={index2} className="flex flex-col">
                                    <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                      {item2?.creditor_name
                                        ? item2?.creditor_name
                                        : merge_label_key[item]}
                                    </div>
                                    {tempFutureData?.cash_flow_items?.map(
                                      (cash_flow_items, index3) => {
                                        return (
                                          <div
                                            key={index3}
                                            className={
                                              STYLE_WHITE_TABLE_RETIRE +
                                              " px-3 "
                                            }
                                          >
                                            {numeral(
                                              item2?.period === "Infinity"
                                                ? item2?.pay_permonth * 12
                                                : item2?.pay_permonth *
                                                    calcPeriodInterest(index3)
                                            )?.format("0,0.00")}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              });
                          }

                          // เงินกู้ระยะสั้น
                          if (item === "debt_shortterm") {
                            return balanceData?.debt_short_list
                              ?.find(
                                (item) =>
                                  item?.options_debt_short === "debt_shortterm"
                              )
                              ?.short_terms?.map((item2, index2) => {
                                const calcPeriodInterest = (index) => {
                                  let year = Math.floor(item2?.period / 12);
                                  let lastYearMonth = item2?.period % 12;

                                  if (index >= Math.ceil(item2?.period / 12)) {
                                    return 0;
                                  }
                                  if (index < year) {
                                    return 12;
                                  } else {
                                    return lastYearMonth;
                                  }
                                };
                                return (
                                  <div key={index2} className="flex flex-col">
                                    <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                      {item2?.creditor_name
                                        ? item2?.creditor_name
                                        : merge_label_key[item]}
                                    </div>
                                    {retireData?.bf_plan_age?.map(
                                      (cash_flow_items, index3) => {
                                        return (
                                          <div
                                            key={index3}
                                            className={
                                              STYLE_WHITE_TABLE_RETIRE +
                                              " px-3 "
                                            }
                                          >
                                            {numeral(
                                              item2?.period === "Infinity"
                                                ? item2?.pay_permonth * 12
                                                : item2?.pay_permonth *
                                                    calcPeriodInterest(index3)
                                            )?.format("0,0.00")}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              });
                          }

                          // บัตรเครดิต
                          if (item === "debt_creditcard") {
                            return balanceData?.debt_short_list
                              ?.find(
                                (item) =>
                                  item?.options_debt_short === "debt_creditcard"
                              )
                              ?.credit_cards?.map((item2, index2) => {
                                const calcPeriodInterest = (index) => {
                                  let period =
                                    calDebtCreditCard(item2)?.creditor_items
                                      ?.length;
                                  let year = Math.floor(period / 12);
                                  let lastYearMonth = period % 12;

                                  if (index >= Math.ceil(period / 12)) {
                                    return 0;
                                  }
                                  if (index < year) {
                                    return 12;
                                  } else {
                                    return lastYearMonth;
                                  }
                                };
                                return (
                                  <div key={index2} className="flex flex-col">
                                    <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                      {item2?.creditor_name
                                        ? item2?.creditor_name
                                        : merge_label_key[item]}
                                    </div>
                                    {retireData?.bf_plan_age?.map(
                                      (cash_flow_items, index3) => {
                                        return (
                                          <div
                                            key={index3}
                                            className={
                                              STYLE_WHITE_TABLE_RETIRE +
                                              " px-3 "
                                            }
                                          >
                                            {numeral(
                                              item2?.type_payment ===
                                                "pay_full" && index3 === 0
                                                ? item2?.pay_peryear
                                                : item2?.pay_permonth *
                                                    calcPeriodInterest(index3)
                                            )?.format("0,0.00")}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              });
                          }

                          // ========หนี้ระยะยาว=============================
                          // หนี้ระยะยาว รถ
                          if (item === "debt_car") {
                            return balanceData?.debt_long_list
                              ?.find(
                                (item) => item?.options_debt_long === "debt_car"
                              )
                              ?.debt_long_cars?.map((item2, index2) => {
                                const calcPeriodInterest = (index) => {
                                  let year = Math.floor(item2?.period / 12);
                                  let lastYearMonth = item2?.period % 12;

                                  if (index >= Math.ceil(item2?.period / 12)) {
                                    return 0;
                                  }
                                  if (index < year) {
                                    return 12;
                                  } else {
                                    return lastYearMonth;
                                  }
                                };
                                return (
                                  <div key={index2} className="flex flex-col">
                                    <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                      {item2?.title_car
                                        ? item2?.title_car
                                        : merge_label_key[item]}
                                    </div>
                                    {retireData?.bf_plan_age?.map(
                                      (cash_flow_items, index3) => {
                                        return (
                                          <div
                                            key={index3}
                                            className={
                                              STYLE_WHITE_TABLE_RETIRE +
                                              " px-3 "
                                            }
                                          >
                                            {numeral(
                                              item2?.period === "Infinity"
                                                ? item2?.pay_permonth * 12
                                                : item2?.pay_permonth *
                                                    calcPeriodInterest(index3)
                                            )?.format("0,0.00")}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              });
                          }

                          // หนี้ระยะยาว บ้าน
                          if (item === "debt_place") {
                            return balanceData?.debt_long_list
                              ?.find(
                                (item) =>
                                  item?.options_debt_long === "debt_place"
                              )
                              ?.debt_long_places?.map((item2, index2) => {
                                const calcPeriodInterest = (index) => {
                                  let year = Math.floor(item2?.period / 12);
                                  let lastYearMonth = item2?.period % 12;

                                  if (index >= Math.ceil(item2?.period / 12)) {
                                    return 0;
                                  }
                                  if (index < year) {
                                    return 12;
                                  } else {
                                    return lastYearMonth;
                                  }
                                };
                                return (
                                  <div key={index2} className="flex flex-col">
                                    <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                      {item2?.address_place
                                        ? item2?.address_place
                                        : merge_label_key[item]}
                                    </div>
                                    {retireData?.bf_plan_age?.map(
                                      (cash_flow_items, index3) => {
                                        return (
                                          <div
                                            key={index3}
                                            className={
                                              STYLE_WHITE_TABLE_RETIRE +
                                              " px-3 "
                                            }
                                          >
                                            {numeral(
                                              item2?.period === "Infinity"
                                                ? item2?.pay_permonth * 12
                                                : item2?.pay_permonth *
                                                    calcPeriodInterest(index3)
                                            )?.format("0,0.00")}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              });
                          }

                          // หนี้ระยะยาว อื่นๆ
                          if (item === "debt_long_other") {
                            return balanceData?.debt_long_list
                              ?.find(
                                (item) =>
                                  item?.options_debt_long === "debt_long_other"
                              )
                              ?.debt_long_others?.map((item2, index2) => {
                                const calcPeriodInterest = (index) => {
                                  let year = Math.floor(item2?.period / 12);
                                  let lastYearMonth = item2?.period % 12;

                                  if (index >= Math.ceil(item2?.period / 12)) {
                                    return 0;
                                  }
                                  if (index < year) {
                                    return 12;
                                  } else {
                                    return lastYearMonth;
                                  }
                                };
                                return (
                                  <div key={index2} className="flex flex-col">
                                    <div className=" h-14 text-sm pl-1 text-center md:break-words md:whitespace-nowrap break-word bg-gray50 flex justify-center items-center  md:pr-4 ">
                                      {item2?.title
                                        ? item2?.title
                                        : merge_label_key[item]}
                                    </div>
                                    {retireData?.bf_plan_age?.map(
                                      (cash_flow_items, index3) => {
                                        return (
                                          <div
                                            key={index3}
                                            className={
                                              STYLE_WHITE_TABLE_RETIRE +
                                              " px-3 "
                                            }
                                          >
                                            {numeral(
                                              item2?.period === "Infinity"
                                                ? item2?.pay_permonth * 12
                                                : item2?.pay_permonth *
                                                    calcPeriodInterest(index3)
                                            )?.format("0,0.00")}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              });
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="flex w-full ">
                <div className=" shadow-md rounded-tl-[20px] rounded-bl-[20px] shadow-gray-500 w-fit sticky z-10 ">
                  <div className="bg-gray50 h-14 px-3 font-semibold text-mainblue rounded-t-[20px]  rounded-tr-none w-14 flex gap-[38px] items-center ">
                  
                    <div>อายุ</div>
                  </div>
                  <div className="">
                    <div
                      className={`  flex  items-center text-lg font-medium text-mainblue w-14 h-full `}
                    >
                      <div className=" h-14 w-full">
                        {dataAgeAF()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={` ${styleTableBody(
                                row?.status
                              )} h-full flex justify-center items-center text-mainblue w-full ${checkColor(
                                index
                              )}`}
                            >
                              {row}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" w-full max-w-2xl overflow-hidden overflow-x-scroll h-full rounded-tr-[20px] rounded-br-[20px] bg-gray50">
                  <div className=" w-[100vh]  ">
                    <div className=" h-14 font-semibold text-mainblue grid grid-cols-5 items-center w-full bg-gray50  ">
                      <div className=" text-sm pl-1 md:text-center md:break-words md:whitespace-normal break-word bg-gray50 h-full flex justify-center items-center  md:pr-4 ">
                        สินทรัพย์เพื่อการลงทุนสุทธิ
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center px-10  ">
                        รายได้
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center ">
                        รายจ่าย
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center  ">
                        คงเหลือ
                      </div>
                      <div className=" md:text-center md:break-words md:whitespace-normal  whitespace-nowrap bg-gray50 h-full flex justify-center items-center ">
                        ผลตอบแทนการลงทุน
                      </div>
                    </div>
                  </div>

                  
                  <div className={`w-[100vh] `}>
                    <div
                     
                      className={` font-semibold text-mainblue grid grid-cols-5  items-center w-full 
                  `}
                    >
                      <div className="">
                        {loopInvestmentAssetsAF()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={` whitespace-nowrap h-14 flex justify-center items-center  ${checkColor(
                                index
                              )}`}
                            >
                              {numeral(row?.results).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                      <div className="">
                        {loopIncomeAF()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                      <div className="">
                        {loopOutcomeAF()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                      <div className="">
                        {loopRemainAF()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row?.results).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                      <div className="">
                        {loopReturnInvestmentAF()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row?.results).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className=" flex  justify-center mt-5">
              <MTButton
                text="ย้อนกลับ"
                color="border border-maincyan "
                colortext="text-maincyan group-hover:text-white"
                hovercolor="hover:bg-maincyan group "
                onClick={() => {
                  NavigateGoBack(navigate);
                }}
              />
            </div>
          </div>
        </Container>
      ) : isSensitive ? (
        <Container>
          {/*-------------------- ตารางทดสอบความอ่อนไหว --------------------*/}
          <div className={`${STYLE_MARGIN_TOP_PARENT} min-h-screen mb-20 `}>
            <HeaderTitle
              title="ตารางสรุปความเคลื่อนไหวการเงิน"
              color="bg-[#EAEAEA]"
              isGoback
              onClickGoBack={() => {
                NavigateGoBack(navigate);
              }}
            />
            {/*---------- Head ---------*/}
            <div className=" mt-8 px-6">
              <div className="flex w-full ">
                <div className=" shadow-md rounded-tl-[20px] rounded-bl-[20px] shadow-gray-500 w-fit sticky z-10 ">
                  <div className="bg-gray50 h-14 px-3 font-semibold text-mainblue rounded-t-[20px]  rounded-tr-none w-14 flex gap-[38px] items-center ">
                    <div>อายุ</div>
                  </div>
                  <div className="">
                    <div
                      className={`flex  items-center text-lg font-medium text-mainblue w-14 h-full`}
                    >
                      <div className=" h-14 w-full">
                        {dataAgeAFSensitive()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={` ${styleTableBody(
                                row?.status
                              )} h-full flex justify-center items-center text-mainblue w-full ${checkColor(
                                index
                              )}`}
                            >
                              {row}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                {/*---------- Body ----------*/}
                <div className=" w-full max-w-2xl overflow-hidden overflow-x-scroll h-full rounded-tr-[20px] rounded-br-[20px] bg-gray50">
                  <div className=" w-[100vh]  ">
                    <div className=" h-14 font-semibold text-mainblue grid grid-cols-5 items-center w-full bg-gray50  ">
                      <div className=" text-sm pl-1 md:text-center md:break-words md:whitespace-normal break-word bg-gray50 h-full flex justify-center items-center  md:pr-4 ">
                        สินทรัพย์เพื่อการลงทุนสุทธิ
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center px-10  ">
                        รายได้
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center ">
                        รายจ่าย
                      </div>
                      <div className=" whitespace-nowrap bg-gray50 h-full flex justify-center items-center  ">
                        คงเหลือ
                      </div>
                      <div className=" md:text-center md:break-words md:whitespace-normal  whitespace-nowrap bg-gray50 h-full flex justify-center items-center ">
                        ผลตอบแทนการลงทุน
                      </div>
                    </div>
                  </div>

                  <div className={`w-[100vh] `}>
                    <div
                      className={` font-semibold text-mainblue grid grid-cols-5  items-center w-full 
                  `}
                    >
                      {/*---- สินทรัพย์เพื่อการลงทุนหลังปรับ ----*/}
                      <div className="">
                        {loopInvestmentAssetsAFSensitive()?.map(
                          (row, index) => {
                            return (
                              <div
                                key={index}
                                className={` whitespace-nowrap h-14 flex justify-center items-center  ${checkColor(
                                  index
                                )}`}
                              >
                                {numeral(row?.results).format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>

                      {/*---- รายได้หลังปรับกลยุทธ์ ----*/}
                      <div className="">
                        {loopIncomeAFSensitive()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>

                      {/*---- รายจ่ายหลังปรับกลยุทธ์ ----*/}
                      <div className="">
                        {loopOutcomeAFSensitive()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>

                      {/*---- คงเหลือหลังปรับ ----*/}
                      <div className="">
                        {loopRemainAFSensitive()?.map((row, index) => {
                          return (
                            <div
                              key={index}
                              className={STYLE_WHITE_TABLE_RETIRE}
                            >
                              {numeral(row?.results).format("0,0.00")}
                            </div>
                          );
                        })}
                      </div>

                      {/*---- ผลตอบแทนการลงทุนหลังปรับ ----*/}
                      <div className="">
                        {loopReturnInvestmentAFSensitive()?.map(
                          (row, index) => {
                            return (
                              <div
                                key={index}
                                className={STYLE_WHITE_TABLE_RETIRE}
                              >
                                {numeral(row?.results).format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" flex  justify-center mt-5">
              <MTButton
                text="ย้อนกลับ"
                color="border border-maincyan "
                colortext="text-maincyan group-hover:text-white"
                hovercolor="hover:bg-maincyan group "
                onClick={() => {
                  NavigateGoBack(navigate);
                }}
              />
            </div>
          </div>
        </Container>
      ) : (
        <Container>
          <div>Page Not Found.</div>
        </Container>
      )}
    </React.Fragment>
  );
};

export default SummaryTableFinance;
