import numeral from "numeral";
import { ImageMain } from "../../../components/img/Img.Main";
import { ChartCashFlow } from "./VIChartCashFlow.Main";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import { useEffect, useMemo, useState } from "react";
import { toFloat2 } from "../../../utils/useFunctions";
import { styleTableBody } from "../../retirement_plan/styles/TableStyle";
import { useBalanceStore } from "../../../_store/balanceStore";
import { useEducationStore } from "../../../_store/educationStore";
import {
  findDebtCreditcard,
  findDebtInformalShortterm,
  findDebtShortterm,
  findDebtShorttermOther,
  findLongDebtCar,
  findLongDebtOther,
  findLongDebtPlace,
} from "../../../functions/futurePlan/futurechartFunction";
import { CalcAll_Expense } from "../../../functions/futurePlan/futurePlanFunction";
import { key_under_education } from "../../education/functions/options";

export const FutureStepThree = ({ age, value, valueAll }) => {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const educationData = useEducationStore((state) => state.educationData);

  const tempFutureData = useMemo(() => {
    let copyFuturePlanData = futurePlanData;
    let calExpense = CalcAll_Expense(
      futurePlanData,
      balanceData,
      educationData,
      key_under_education
    );
    copyFuturePlanData = { ...copyFuturePlanData, cash_flow_items: calExpense };
    return copyFuturePlanData;
  }, []);

  const merge_keys = useMemo(() => {
    let income_keys = futurePlanData.cash_flow_keys.filter((cash_flow_key) => {
      return cash_flow_key.includes("income");
    });
    let expenese_keys = futurePlanData.cash_flow_keys.filter(
      (cash_flow_key) => {
        return cash_flow_key.includes("expenese");
      }
    );
    let merge_income_keys = [
      // ...income_keys,
      "income_peryear",
      "remain_accumulate",
      "pension_peryear",
      "income_investment",
    ];
    let merge_expenese_keys = [
      // ...expenese_keys,
      "outcome_peryear",
      "out_come_stable",
    ];

    let merge_keys = [...merge_expenese_keys, ...merge_income_keys];
    // merge_keys = [];

    // หนี้ดอกลอย
    let debtShortList = balanceData?.debt_short_list?.find(
      (item) => item?.options_debt_short === "debt_shortterm_other"
    )?.short_term_others;

    if (debtShortList?.length > 0) {
      merge_keys = [
        ...merge_keys,
        ...findDebtShorttermOther(balanceData, futurePlanData).title,
      ];
    }

    // หนี้ีนอกระบบ
    let debtInformalShortterm = balanceData?.debt_short_list?.find(
      (item) => item?.options_debt_short === "debt_informal_shortterm"
    )?.informal_short_terms;

    if (debtInformalShortterm?.length > 0) {
      merge_keys = [
        ...merge_keys,
        ...findDebtInformalShortterm(balanceData, futurePlanData).title,
      ];
    }

    // เงินกู้ระยะสั้น
    let debtShorShortList = balanceData?.debt_short_list?.find(
      (item) => item?.options_debt_short === "debt_shortterm"
    )?.short_terms;

    if (debtShorShortList?.length > 0) {
      merge_keys = [
        ...merge_keys,
        ...findDebtShortterm(balanceData, futurePlanData).title,
      ];
    }

    // บัตรเครดิต
    let debtCreditcard = balanceData?.debt_short_list?.find(
      (item) => item?.options_debt_short === "debt_creditcard"
    )?.credit_cards;

    if (debtCreditcard?.length > 0) {
      merge_keys = [
        ...merge_keys,
        ...findDebtCreditcard(balanceData, futurePlanData).title,
      ];
    }

    // หนี้ระยะยาว รถ
    let debtCar = balanceData?.debt_long_list?.find(
      (item) => item?.options_debt_long === "debt_car"
    )?.debt_long_cars;

    if (debtCar?.length > 0) {
      merge_keys = [
        ...merge_keys,
        ...findLongDebtCar(balanceData, futurePlanData).title,
      ];
    }

    // หนี้ระยะยาว บ้าน
    let DebtPlace = balanceData?.debt_long_list?.find(
      (item) => item?.options_debt_long === "debt_car"
    )?.debt_long_cars;

    if (DebtPlace?.length > 0) {
      merge_keys = [
        ...merge_keys,
        ...findLongDebtPlace(balanceData, futurePlanData).title,
      ];
    }

    // หนี้ระยะยาว อื่นๆ
    let DebtOther = balanceData?.debt_long_list?.find(
      (item) => item?.options_debt_long === "debt_long_other"
    )?.debt_long_others;

    if (DebtOther?.length > 0) {
      merge_keys = [
        ...merge_keys,
        ...findLongDebtOther(balanceData, futurePlanData).title,
      ];
    }

    return merge_keys;
  }, [futurePlanData]);

  const merge_keys_second = useMemo(() => {
    let merge_keys = [];

    // รายจ่ายบ้าน
    let expenseHome = futurePlanData?.expenese_home_items?.map(
      (item, index) => {
        let textCombine = item?.title + "_Is_type_expense" + "_home_expense";
        merge_keys = [...merge_keys, textCombine];
        return;
      }
    );

    // รายจ่ายรถ
    let expenseCar = futurePlanData?.expenese_car_items?.map((item, index) => {
      let textCombine = item?.title + "_Is_type_expense" + "_car_expense";
      merge_keys = [...merge_keys, textCombine];
      return;
    });

    // รายจ่ายลูก
    let expenseChild = futurePlanData?.expenese_children_items?.map(
      (item, index) => {
        let textCombine = item?.title + "_Is_type_expense" + "_child_expense";
        merge_keys = [...merge_keys, textCombine];
        return;
      }
    );

    // รายจ่ายการศึกษาลูก
    // console.log("educationData", educationData);
    if (futurePlanData?.expenese_children_education?.length > 0) {
      let expenseChildEducation = educationData?.map(
        (item, index) => {
          let textCombine = item?.name + "_Is_type_expense" + "_child_Education";
          merge_keys = [...merge_keys, textCombine];
          return;
        }
      );
    }

    // รายจ่ายท่องเที่ยว
    let expensetravel = futurePlanData?.expenese_travel_items?.map(
      (item, index) => {
        let textCombine = item?.title + "_Is_type_expense" + "_travel_expense";
        merge_keys = [...merge_keys, textCombine];
        return;
      }
    );

    // รายจ่ายสะสม
    let expensecollect = futurePlanData?.expenese_collection_items?.map(
      (item, index) => {
        let textCombine = item?.title + "_Is_type_expense" + "_collect_expense";
        merge_keys = [...merge_keys, textCombine];
        return;
      }
    );

    // รายจ่ายอื่นๆ
    let expenese_other_items = futurePlanData?.expenese_other_items?.map(
      (item, index) => {
        let textCombine = item?.title + "_Is_type_expense" + "_other_expense";
        merge_keys = [...merge_keys, textCombine];
        return;
      }
    );

    // รายได้มรดก
    let incomelegacy = futurePlanData?.income_legacy_items?.map(
      (item, index) => {
        let textCombine = item?.title + "_Is_type_income" + "_legacy_income";
        merge_keys = [...merge_keys, textCombine];
        return;
      }
    );

    // รายได้ธุรกิจ
    let incomeBusiness = futurePlanData?.income_business_items?.map(
      (item, index) => {
        let textCombine = item?.title + "_Is_type_income" + "_business_income";
        merge_keys = [...merge_keys, textCombine];
        return;
      }
    );

    // รายได้อสังหาริมทรัพย์
    let incomeProperty = futurePlanData?.income_property_items?.map(
      (item, index) => {
        let textCombine = item?.title + "_Is_type_income" + "_property_income";
        merge_keys = [...merge_keys, textCombine];
        return;
      }
    );

    // รายได้อื่นๆ
    let incomeOther = futurePlanData?.income_other_items?.map(
      (item, index) => {
        let textCombine = item?.title + "_Is_type_income" + "_Other_income";
        merge_keys = [...merge_keys, textCombine];
        return;
      }
    );


    return merge_keys;
  }, [futurePlanData]);

  const dataDebt = useMemo(() => {
    let dataDebt = [];

    for (const merge_key of merge_keys) {
      let dataDebtItems = [];
      for (const cash_flow_item of futurePlanData.cash_flow_items) {
        if (cash_flow_item[merge_key] !== undefined) {
          let amount = String(merge_key).startsWith("expenese")
            ? -cash_flow_item[merge_key]
            : cash_flow_item[merge_key];

          amount = Math.round(amount);
          if ([0, -0].includes(amount)) {
            amount = "";
          }

          dataDebtItems.push(amount);
        }
      }

      dataDebt.push(dataDebtItems);
    }

    dataDebt = dataDebt.filter((item) => item.length !== 0);

    // หนี้ดอกลอย
    let debtShortList = balanceData?.debt_short_list?.find(
      (item) => item?.options_debt_short === "debt_shortterm_other"
    )?.short_term_others;

    if (debtShortList?.length > 0) {
      dataDebt = [
        ...dataDebt,
        ...findDebtShorttermOther(balanceData, futurePlanData).value,
      ];
    }

    // หนี้ีนอกระบบ
    let debtInformalShortterm = balanceData?.debt_short_list?.find(
      (item) => item?.options_debt_short === "debt_informal_shortterm"
    )?.informal_short_terms;

    if (debtInformalShortterm?.length > 0) {
      dataDebt = [
        ...dataDebt,
        ...findDebtInformalShortterm(balanceData, futurePlanData).value,
      ];
    }

    // เงินกู้ระยะสั้น
    let debtShorShortList = balanceData?.debt_short_list?.find(
      (item) => item?.options_debt_short === "debt_shortterm"
    )?.short_terms;

    if (debtShorShortList?.length > 0) {
      dataDebt = [
        ...dataDebt,
        ...findDebtShortterm(balanceData, futurePlanData).value,
      ];
    }

    // บัตรเครดิต
    let debtCreditcard = balanceData?.debt_short_list?.find(
      (item) => item?.options_debt_short === "debt_creditcard"
    )?.credit_cards;

    if (debtCreditcard?.length > 0) {
      dataDebt = [
        ...dataDebt,
        ...findDebtCreditcard(balanceData, futurePlanData).value,
      ];
    }

    // หนี้ระยะยาว รถ
    let debtCar = balanceData?.debt_long_list?.find(
      (item) => item?.options_debt_long === "debt_car"
    )?.debt_long_cars;

    if (debtCar?.length > 0) {
      dataDebt = [
        ...dataDebt,
        ...findLongDebtCar(balanceData, futurePlanData).value,
      ];
    }

    // หนี้ระยะยาว บ้าน
    let DebtPlace = balanceData?.debt_long_list?.find(
      (item) => item?.options_debt_long === "debt_car"
    )?.debt_long_cars;

    if (DebtPlace?.length > 0) {
      dataDebt = [
        ...dataDebt,
        ...findLongDebtPlace(balanceData, futurePlanData).value,
      ];
    }

    // หนี้ระยะยาว อื่นๆ
    let DebtOther = balanceData?.debt_long_list?.find(
      (item) => item?.options_debt_long === "debt_long_other"
    )?.debt_long_others;

    if (DebtOther?.length > 0) {
      dataDebt = [
        ...dataDebt,
        ...findLongDebtOther(balanceData, futurePlanData).value,
      ];
    }

    return dataDebt;
  }, [futurePlanData]);

  const labels = useMemo(() => {
    let labels = [];
    for (const cash_flow_item of futurePlanData.cash_flow_items) {
      labels.push(cash_flow_item.age);
    }

    return labels;
  }, [futurePlanData]);

  const moneyDeficite = useMemo(() => {
    let deficiteList = [];
    futurePlanData?.cash_flow_items?.map((item, index) => {
      let income =
        item?.income_other_items ||
        0 + item?.income_peryear ||
        0 + item?.income_legacy_items ||
        0 + item?.income_business_items ||
        0 + item?.income_property_items ||
        0 + item?.pension_peryear ||
        0;
      let expense =
        item?.expenese_home_items ||
        0 + item?.expenese_car_items ||
        0 + item?.expenese_children_items ||
        0 + item?.expenese_travel_items ||
        0 + item?.expenese_collection_items ||
        0 + item?.expenese_other_items ||
        0 + item?.outcome_peryear ||
        0 + item?.out_come_stable ||
        0;

      let diff = income - expense;

      if (income < expense) {
        let deficiteAge = {
          age: item.age,
          diff: numeral(Math.abs(diff)).format("0,0"),
        };
        deficiteList.push(deficiteAge);
      }
    });

    return deficiteList;
  }, [futurePlanData]);

  const moneyNeed = useMemo(() => {
    const findData = tempFutureData?.cash_flow_items?.filter((item) => {
      return item?.remain_accumulate < 0 && item?.remain < 0;
    });
    return findData;
  }, []);

  const dynamicWidth = Math.max(labels.length * 80, 800);

  return (
    <div className=" flex flex-col justify-center items-center gap-2.5">
      <div className=" text-mainblue font-bold text-center ">
        กระแสเงินสดรายรับรายจ่ายของแผนอนาคต
      </div>
      <ChartCashFlow
        futurePlanData={futurePlanData}
        educationData={educationData}
        labels={labels}
        dataDebt={dataDebt}
        merge_keys={merge_keys}
        merge_keys_second={merge_keys_second}
        width={dynamicWidth}
        // height={1000}
      />
      <div className=" bg-gray50 w-full rounded-lg h-full p-3 space-y-[6px]">
        <ImageMain
          src="../images/cashflow.png"
          className=" w-12 h-12 mx-auto"
        />
        <div className=" flex flex-col text-center text-mainblue text-sm ">
          เพื่อทำให้แผนอนาคตของคุณเป็นไปตามเป้าหมาย
          {moneyNeed?.length > 0 &&
            moneyNeed?.map((item, index) => {
              return (
                <div key={index}>
                  อายุ {item?.age} คุณต้องหารายรับเพิ่มอีก
                  <span className=" font-semibold">
                    {" "}
                    {numeral(Math.abs(item?.remain))?.format("0,0")} บาท
                  </span>{" "}
                </div>
              );
            })}
        </div>
        <div className="flex justify-center text-center text-mainblue text-sm">
          รวม
          <span className=" pl-[5px] font-semibold">
            {" "}
            {numeral(
              Math.abs(
                moneyNeed.reduce((acc, cur) => {
                  return acc + cur?.remain;
                }, 0)
              )
            )?.format("0,0")}{" "}
            บาท
          </span>{" "}
        </div>
      </div>
    </div>
  );
};
