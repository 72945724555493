import { MTButton } from "../../../components/button/MTButton";
import DropDownMain from "../../../components/dropdown/DropDownMain";
import { ImageMain } from "../../../components/img/Img.Main";
import { InputMain } from "../../../components/input/InputMain";
import { getPathURL } from "../../../utils/useFunctions";
import {
  ICON_DELETE,
  ICON_EDIT,
  ICON_EDIT_PENCIL,
  ICON_EDIT_PROFILE,
  ICON_RIGHT,
  ICON_UPLOAD,
} from "../../../utils/useIcons";
import {
  STYLE_INPUT,
  STYLE_TEXT_BLUE,
  STYLE_TEXT_BLUE_EDU,
} from "../../../utils/useStyle";
import { options_education_rank, options_under_education } from "../functions/options";

const YEAR_OPTIONS = new Array(30).fill(0).map((_, index) => {
  const todayYear = new Date().getFullYear() + 543;
  const year = todayYear - index;
  return { value: year, label: year };
});

export const CardKids = ({
  name,
  year,
  age,
  img,
  no,
  onClick,
  onClickDelete,
}) => {
  return (
    <div
      onClick={onClick}
      className=" flex items-center gap-3 cursor-pointer bg-gray50 w-full rounded-[20px] px-4 py-3 relative"
    >
      {img ? (
        <div className=" bg-white rounded-[10px] w-[100px] h-[100px] overflow-hidden flex justify-center items-center">
          <ImageMain
            src={img ? img : "../images/user_circle.png"}
            className=" w-[100px] h-[100px] object-cover "
          />
        </div>
      ) : (
        <div className=" rounded-[10px] w-[100px] h-[100px] overflow-hidden flex justify-center items-center">
          <ImageMain
            src={"../images/user_circle.png"}
            className=" w-20 h-20 object-cover "
          />
        </div>
      )}

      <div className=" flex flex-col space-y-[9px]">
        <div className=" flex space-x-[5px] items-center w-full ">
          <div className=" text-maincyan text-base font-medium">
            บุตรคนที่ {no || 1}
          </div>
          <ICON_RIGHT className="w-6 h-6 text-black" onClick={onClick} />
        </div>
        <div className="flex space-x-1">
          <div className={STYLE_TEXT_BLUE_EDU}>ชื่อ :</div>
          <div
            className={`${
              name ? "text-black" : " text-gray90"
            } text-sm  font-medium `}
          >
            {name ? name : "ไม่มีข้อมูล"}
          </div>
        </div>
        <div className="flex space-x-1">
          <div className={STYLE_TEXT_BLUE_EDU}>ปีเกิด :</div>
          <div
            className={`${
              year ? "text-black" : " text-gray90"
            } text-sm  font-medium `}
          >
            {year ? year : "ไม่มีข้อมูล"}
          </div>
        </div>
        <div className="flex space-x-1">
          <div className={STYLE_TEXT_BLUE_EDU}>อายุ :</div>
          <div
            className={`${
              age ? "text-black" : " text-gray90"
            } text-sm  font-medium `}
          >
            {age ? age : "ไม่มีข้อมูล"}
          </div>
        </div>
      </div>
      <ICON_DELETE
        onClick={onClickDelete}
        className="w-5 h-5 absolute right-4 top-4"
      />
    </div>
  );
};

export const CardKidsInfo = ({
  index,
  onClickUpload,
  name,
  year,
  age,
  loading,
  onClickSubmit,
  onChangeEducation,
  onClickEdit,
  onClickSaveEdit,
  isEdit,
  isEditable,
  isFutureplan=false,
  img = "",
  currentEducation,
  targetEducation,
  underEducatiion
}) => {

  const currentEdu = options_education_rank?.find((item)=> item?.value === currentEducation )?.label
  const targetEdu = options_education_rank?.find((item)=> item?.value === targetEducation )?.label
  const underEdu = options_under_education?.find((item)=> item?.value === underEducatiion )?.label


  const getDisplayImg = () => {
    if (String(img).includes("base64")) {
      return img;
    } else if (img) {
      return getPathURL(img);
    }
    return "../images/user_circle.png";
  }

  return (
    <div className=" cursor-pointer bg-gray50 w-full rounded-[20px] px-4 py-3 relative">
      <div className=" text-maincyan text-base font-medium text-center">
        บุตรคนที่ {index + 1}
      </div>
      {/* !isEdit */}
      {!isEdit ? (
        isEditable && (<div className=" absolute top-3 right-4" onClick={onClickEdit}>
          <div className="  text-xs flex text-red500 items-center font-semibold border-b border-b-red500 w-fit">
            <ICON_EDIT className={" w-3 h-3"} />
            <div>แก้ไข</div>
          </div>
        </div>)
      ) : (
        ""
      )}
      <div
        className={`grid grid-cols-12 space-x-3 ${
          isEdit ? "mt-[9px]" : "mt-1"
        } `}
      >
        {/* ------- upload image ------ */}
        <label className="col-span-4 md:col-span-3 relative">
          {img ? (
            <div className=" bg-white rounded-[10px] overflow-hidden w-[100px] h-[100px] flex justify-center items-center">
              <ImageMain
                src={getDisplayImg()}
                className="w-[100px] h-[100px] md:w-full md:h-full object-cover "
              />
            </div>
          ) : (
            <div className=" rounded-[10px] w-[100px] h-[100px] overflow-hidden flex justify-center items-center">
              <ImageMain
                src={"../images/user_circle.png"}
                className=" w-20 h-20 object-cover "
              />
            </div>
          )}

          {isEdit && (
            <div className=" bg-maincyan w-[22px] h-[22px] rounded-full absolute bottom-1 -right-2 md:right-10 flex justify-center items-center">
              <ICON_UPLOAD className="w-3 h-3 text-white" />
              <input
                hidden
                type="file"
                multiple={false}
                onChange={onClickUpload}
              />
            </div>
          )}
        </label>

        <div
          className={`col-span-8 md:col-span-9 flex flex-col ${
            isEdit ? "space-y-1" : "space-y-[9px]"
          }`}
        >
          <div className="  grid grid-cols-5  md:flex md:gap-5  items-center">
            <div className={STYLE_TEXT_BLUE_EDU}>ชื่อ{isEdit ? "" : ":"} </div>

            {isEdit ? (
              <InputMain
                className={`${STYLE_INPUT} col-span-4`}
                value={name}
                onChange={onChangeEducation("name")}
              />
            ) : (
              <div
                className={` text-sm  font-medium text-black col-span-4 md:col-span-none `}
              >
                {name}
              </div>
            )}
          </div>
          <div className=" flex flex-row items-center w-full">
            <div className={`${STYLE_TEXT_BLUE_EDU}  `}>
              ปีเกิด{isEdit ? "" : ":"}
            </div>
            {isEdit ? (
              <DropDownMain
                value={YEAR_OPTIONS.find((option) => option.value === year)}
                options={YEAR_OPTIONS}
                className="flex-1 ml-2"
                placeholder="เลือกปีเกิด"
                onChange={onChangeEducation("year")}
              />
            ) : (
              <div className={` text-sm  font-medium text-black col-span-4 `}>
                {year}
              </div>
            )}
          </div>

          <div className="flex space-x-2">
            <div className={STYLE_TEXT_BLUE_EDU}>อายุ{isEdit ? "" : ":"}</div>
            <div className={` text-sm  font-medium text-black col-span-4 `}>
              {age}
            </div>
          </div>

          {
        isFutureplan &&(
          <>
          <div className="flex space-x-2">
            <div className={STYLE_TEXT_BLUE_EDU}>การศึษาปัจจุบัน:</div>
            <div className={` text-sm  font-medium text-black col-span-4 `}>
              {currentEdu}
            </div>
          </div>
          <div className="flex space-x-2">
            <div className={STYLE_TEXT_BLUE_EDU}>เป้าหมายการศึษา:</div>
            <div className={` text-sm  font-medium text-black col-span-4 `}>
              {targetEdu}
            </div>
          </div>
          <div className="flex space-x-2">
            <div className={STYLE_TEXT_BLUE_EDU}>สังกัดการศึกษา:</div>
            <div className={` text-sm  font-medium text-black col-span-4 `}>
              {underEdu}
            </div>
          </div>
          </>
        )
      }
        </div>
      </div>

      {isEdit ? (
        <div className=" flex justify-center mt-[9px]">
          <MTButton
            text="บันทึก"
            loading={loading}
            onClick={onClickSubmit}
            width="w-[228px]"
          />
        </div>
      ) : (
        ""
      )}

     
    </div>
  );
};
