import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTaxStore } from "../../../_store/taxplanStore";
import { useUserStore } from "../../../_store/userStore";
import { LeavingCalc } from "../../../functions/taxFucntion/premium/salaryIncome";
import { numberWithoutComma } from "../../balance/component/VIDrawerDebt.Main";
import { cn } from "../../../utils/useFunctions";
import {
  STYLE_PAGE,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_GRAY_11,
} from "../styles/pdf.style";
import PdfHeader from "../component/pdf.c.header";
import PdfLine from "../component/pdf.c.line";
import { PdfHeaderText } from "../component/pdf.c.headerText";
import { PdfCard, PdfCardGray, PdfCardWhite } from "../component/pdf.c.card";
import numeral from "numeral";
import PdfFooter from "../component/pdf.c.footer";
import Container from "../../../components/layout/Container";
import {
  STYLE_INPUT,
  STYLE_INPUT_CARD_BALANCE,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_CARD_BALANCE,
  STYLE_PARENT_PADDING,
  STYLE_TEXT_BLUE,
  STYLE_TITLE_CARD_DRAWER_BL,
} from "../../../utils/useStyle";
import { HeaderTitle } from "../../../components/header/Header";
import { CardMain } from "../../../components/card/CardMain";
import { NumericFormat } from "react-number-format";
import { CardTaxPremium } from "../../tax_plan/styles/Card/CardMenuPremium";
import { TotalExpense } from "../../../functions/taxFucntion/premium/businessFunction";
import {
  option_cal_express,
  option_first_cal,
} from "../../tax_plan/functions/options";

const SalaryIncomePdf = ({ page, taxData={} }) => {


  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="วางแผนภาษี"
        icon="/images/tax_plan.svg"
        classNameIcon="w-[24px] h-[27px]"
      />

      <Container classnameInner={""}>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <div className={STYLE_PARENT_PADDING}>
            <CardMain
              icon={false}
              marginyellow="mt-3"
              title={
                <div className="text-[12px] font-[500]">
                  เงินเดือนหรือเงินได้ตามสัญญาจ้างแรงงาน (มาตรา 40(1))
                </div>
              }
              panel={
                <div className=" px-3 -mt-3">
                  <div className="w-full flex justify-between text-[11px] font-[500]">
                    <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      เงินได้ทั้งหมด
                    </div>
                    <div
                      className={cn(STYLE_TEXT_BLUE, {
                        "w-fit whitespace-nowrap": true,
                      })}
                    >
                      {" "}
                      {`${numeral(taxData?.tax_income)?.format(
                        "0,0"
                      )} บาท`}{" "}
                    </div>
                  </div>
                  <div className="w-full flex justify-between text-[11px] font-[500]">
                    <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      ภาษีหัก ณ ที่จ่าย 
                    </div>
                    <div
                      className={cn(STYLE_TEXT_BLUE, {
                        "w-fit whitespace-nowrap": true,
                      })}
                    >
                      {" "}
                      {`${numeral(taxData?.tax_withholding)?.format(
                        "0,0"
                      )} บาท`}{" "}
                    </div>
                  </div>
                </div>
              }
            />
            {taxData?.tax_QuitJob?.isQuitJob && (
              <CardMain
                border={false}
                icon={false}
                marginyellow="mt-3"
                title={
                  <div className="text-[12px] font-[500]">
                    เงินได้ที่นายจ้างจ่ายให้ครั้งเดียวเพราะเหตุออกจากงาน
                    (กรณีไม่นำไปรวมคำนวณภาษี) (มาตรา 40(1))
                  </div>
                }
                panel={
                  <div className=" px-3 mt-3 ">
                    {taxData?.tax_QuitJob?.isQuitJob && (
                      <div className=" space-y-2.5 mt-2.5">
                        <div className="w-full flex justify-between text-[11px] font-[500]">
                          <div
                            className={`${STYLE_TEXT_BLUE} w-full text-[11px] font-[500] mb-1`}
                          >
                            ชื่อนายจ้าง
                          </div>
                          <div
                            className={cn(STYLE_TEXT_BLUE, {
                              "w-fit whitespace-nowrap": true,
                            })}
                          >
                            {taxData?.tax_QuitJob?.employer_name}
                          </div>
                        </div>
                        <div className="w-full flex justify-between text-[11px] font-[500]">
                          <div
                            className={`${STYLE_TEXT_BLUE} w-full text-[11px] font-[500] mb-1`}
                          >
                            จำนวนปีที่ทำงาน
                          </div>
                          <div
                            className={cn(STYLE_TEXT_BLUE, {
                              "w-fit whitespace-nowrap": true,
                            })}
                          >
                            {`${numeral(taxData?.tax_QuitJob?.year_work).format(
                              "0,0"
                            )} ปี`}
                          </div>
                        </div>
                        <div className="font-semibold text-[11px] text-black">
                          การคำนวณเงินได้
                        </div>
                        <div className="w-full flex justify-between text-[11px] font-[500]">
                          <div
                            className={`${STYLE_TEXT_BLUE} w-full text-[11px] font-[500] mb-1`}
                          >
                            เงินบำเหน็จฯ ข้าราชการ
                          </div>
                          <div
                            className={cn(STYLE_TEXT_BLUE, {
                              "w-fit whitespace-nowrap": true,
                            })}
                          >
                            {`${numeral(
                              taxData?.tax_QuitJob?.pension_money
                            ).format("0,0")} บาท`}
                          </div>
                        </div>

                        <div className="w-full flex justify-between text-[11px] font-[500]">
                          <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                            เงินที่จ่ายจากกองทุน เช่น กองทุนสำรองเลี้ยงชีพ กบข.
                          </div>
                          <div
                            className={cn(STYLE_TEXT_BLUE, {
                              "w-fit whitespace-nowrap": true,
                            })}
                          >
                            {`${numeral(
                              taxData?.tax_QuitJob?.fund_money
                            ).format("0,0")} บาท`}
                          </div>
                        </div>

                        <div>
                          <div className="w-full flex justify-between text-[11px] font-[500]">
                            <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                              เงินชดเชยตามกฎหมายแรงงานที่ได้รับการยกเว้นแล้ว
                            </div>
                            <div
                              className={cn(STYLE_TEXT_BLUE, {
                                "w-fit whitespace-nowrap": true,
                              })}
                            >
                              {`${numeral(
                                taxData?.tax_QuitJob?.Compensation_money
                              ).format("0,0")} บาท`}
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-between text-[11px] font-[500]">
                          <div className={`${STYLE_TEXT_BLUE} mb-1`}>
                            เงินที่จ่ายให้ครั้งเดียวมีวิธีการดำเนินการต่างจาก
                            บำเหน็จฯ ข้าราชการ
                          </div>
                          <div
                            className={cn(STYLE_TEXT_BLUE, {
                              "w-fit whitespace-nowrap": true,
                            })}
                          >
                            {`${numeral(
                              taxData?.tax_QuitJob?.single_pay_money
                            ).format("0,0")} บาท`}
                          </div>
                        </div>
                        <div className="font-semibold  text-[11px] text-black">
                          การคำนวณค่าใช้จ่าย
                        </div>
                        <div className="flex justify-between text-[11px] font-[500]">
                          <div className={`${STYLE_TEXT_BLUE} mb-1`}>
                            วิธีที่ใช้คำนวณ
                          </div>
                          <div
                            className={cn(STYLE_TEXT_BLUE, {
                              "w-fit whitespace-nowrap": true,
                            })}
                          >
                            {
                              option_cal_express?.find(
                                (item) =>
                                  item?.value ===
                                  taxData?.tax_QuitJob?.calculate_method
                              )?.label
                            }
                          </div>
                        </div>
                        <div className="flex justify-between text-[11px] font-[500]">
                          <div className={`${STYLE_TEXT_BLUE} mb-1`}>
                            เงินเดือนรับจากนายจ้าง
                          </div>
                          <div
                            className={cn(STYLE_TEXT_BLUE, {
                              "w-fit whitespace-nowrap": true,
                            })}
                          >
                            {`${numeral(
                              taxData?.tax_QuitJob?.salary_from_employer
                            ).format("0,0")} บาท`}
                          </div>
                        </div>
                        <div className="flex justify-between text-[11px] font-[500]">
                          <div className={`${STYLE_TEXT_BLUE} mb-1`}>
                            จำนวนปี
                          </div>
                          <div
                            className={cn(STYLE_TEXT_BLUE, {
                              "w-fit whitespace-nowrap": true,
                            })}
                          >
                            {`${numeral(
                              taxData?.tax_QuitJob?.year_from_employer
                            ).format("0,0")} บาท`}
                          </div>
                        </div>
                        <div className="font-semibold text-[11px] text-black">
                          ค่าใช้จ่ายส่วนแรกคำนวณจาก
                        </div>
                        <div className="flex justify-between text-[11px] font-[500]">
                          <div className={`${STYLE_TEXT_BLUE} mb-1`}>
                            ค่าใช้จ่ายส่วนแรก
                          </div>
                          <div
                            className={cn(STYLE_TEXT_BLUE, {
                              "w-fit whitespace-nowrap": true,
                            })}
                          >
                            {
                              option_first_cal?.find(
                                (item) =>
                                  item?.value ===
                                  taxData?.tax_QuitJob?.calc_default
                              )?.label
                            }
                          </div>
                        </div>
                        <div className="flex justify-between text-[11px] font-[500]">
                          <div className={`${STYLE_TEXT_BLUE} mb-1`}>
                            ภาษีหัก ณ ที่จ่าย
                          </div>
                          <div
                            className={cn(STYLE_TEXT_BLUE, {
                              "w-fit whitespace-nowrap": true,
                            })}
                          >
                            {numeral(
                              taxData?.tax_QuitJob?.with_holding_tax
                            ).format("0,0")}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                }
              />
            )}
          </div>
        </div>
      </Container>
      <PdfFooter page={page} />
    </div>
  );
};

export default SalaryIncomePdf;
