import numeral from "numeral";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import { MTButton } from "../../../components/button/MTButton";
import { HeaderTitle } from "../../../components/header/Header";
import { ImageMain } from "../../../components/img/Img.Main";
import Container from "../../../components/layout/Container";
import { STYLE_REPORT_CONTAIN, STYLE_TEXT_CYAN } from "../../../utils/useStyle";
import { ChartCashFlow } from "../../future_plan/components/VIChartCashFlow.Main";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { HeaderReport } from "../components/header.main";

const FlowSection = ({ futurePlanData }) => {
  const merge_keys = useMemo(() => {
    let income_keys = futurePlanData.cash_flow_keys.filter((cash_flow_key) => {
      return cash_flow_key.includes("income");
    });
    let expenese_keys = futurePlanData.cash_flow_keys.filter(
      (cash_flow_key) => {
        return cash_flow_key.includes("expenese");
      }
    );
    let merge_income_keys = [
      ...income_keys,
      "income_peryear",
      "remain_accumulate",
    ];
    let merge_expenese_keys = [...expenese_keys, "outcome_peryear"];
    let merge_keys = [...merge_expenese_keys, ...merge_income_keys];
    return merge_keys;
  }, [futurePlanData]);

  const dataDebt = useMemo(() => {
    let dataDebt = [];

    for (const merge_key of merge_keys) {
      let dataDebtItems = [];
      for (const cash_flow_item of futurePlanData.cash_flow_items) {
        if (cash_flow_item[merge_key] !== undefined) {
          let amount = String(merge_key).startsWith("expenese")
            ? -cash_flow_item[merge_key]
            : cash_flow_item[merge_key];

          amount = Math.round(amount);
          if ([0, -0].includes(amount)) {
            amount = "";
          }

          dataDebtItems.push(amount);
        }
      }
      dataDebt.push(dataDebtItems);
    }

    return dataDebt;
  }, [futurePlanData]);

  // const labels = ["33", "34", "35", "36", "37", "38", "39"];

  const labels = useMemo(() => {
    let labels = [];
    for (const cash_flow_item of futurePlanData.cash_flow_items) {
      labels.push(cash_flow_item.age);
    }
    return labels;
  }, [futurePlanData]);

  return (
    <div className=" flex flex-col justify-center items-center gap-2.5">
      <div className=" text-mainblue font-bold text-center ">
      กระแสเงินสดรายรับรายจ่ายของแผนอนาคต 
      </div>
      <ChartCashFlow
        labels={labels}
        dataDebt={dataDebt}
        merge_keys={merge_keys}
        width={320}
      />
      <div className=" bg-gray50 w-full rounded-lg h-full p-3 space-y-[6px]">
        {/* <div className=" text-maingray text-sm ">
        อายุ {age} ปี ต้องหารายรับเพิ่มอีก {numeral(value)?.format?.("0,0")}{" "}
        บาท
      </div> */}
        <ImageMain
          src="../images/cashflow.png"
          className=" w-12 h-12 mx-auto"
        />
        <div className=" text-center text-mainblue text-sm ">
          เพื่อทำให้แผนอนาคตของคุณเป็นไปตามเป้าหมาย
          <div>
            คุณต้องหารายรับเพิ่มอีก
            <span className=" font-semibold">
              {" "}
              {numeral(futurePlanData?.sum_diff_total)?.format("0,0")} บาท
            </span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

const mock_table_expense = [
  {
    title: "รถยนต์",
    year: 5,
    value: 2000000,
    end_year: 15,
  },
  {
    title: "กระเป๋าแบรนด์เนม",
    year: 1,
    value: 200000,
    end_year: 1,
  },
];

const mock_table_income = [
  {
    title: "ปล่อยเช่าอสังหาริมทรัพย์",
    year: 1,
    value: 6000000,
    duration: 10,
    isIncome_peryear: true,
  },
  {
    title: "กระเป๋าแบรนด์เนม",
    year: 1,
    value: 200000,
    end_year: 1,
  },
];

const ExpenseSection = ({ futureExpensesItems }) => {
  return (
    <div>
      <div className={` ${STYLE_TEXT_CYAN} text-xl text-center `}>
        รายจ่ายในอนาคต
      </div>

      {futureExpensesItems.map((item, index) => {
        return (
          <div className=" my-5">
            <div className="flex items-center gap-2.5 px-3 py-2 bg-gray50 text-black  rounded-t-[20px] rounded-b-none">
              <div>
                <img
                  src={`/images/${item.icon}`}
                  className="w-[20px] h-[20px]"
                  alt=""
                />
              </div>
              <div className=" font-semibold">{item.label}</div>
            </div>

            <div className="bg-[#fcfcfc] rounded-t-none rounded-b-[20px] px-3 py-2 text-base w-full h-full space-y-1">
              {item.options.map((item, index) => {
                return (
                  <div key={index} className="flex justify-between space-y-1">
                    <div>{item.label}</div>
                    <div className={` flex space-x-2 text-mainblue `}>
                      {item.value}
                    </div>
                  </div>
                );
              })}

              <div className="mt-2 flex justify-between">
                <div>ต้องชำระงวดละ</div>
                <div className=" text-mainblue">
                  {numeral(item.amount).format("0,0")} บาท
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const IncomeSection = ({ futureIncomeItems }) => {
  return (
    <div>
      <div className={` ${STYLE_TEXT_CYAN} text-xl text-center `}>
        รายรับในอนาคต
      </div>
      {futureIncomeItems.map((item, index) => {
        return (
          <div className=" my-5">
            <div className="flex items-center gap-2.5 px-3 py-2 bg-gray50 text-black  rounded-t-[20px] rounded-b-none">
              <div>
                <img
                  src={`/images/${item.icon}`}
                  className="w-[20px] h-[20px]"
                  alt=""
                />
              </div>
              <div className=" font-semibold">{item.label}</div>
            </div>

            <div className="bg-[#fcfcfc] rounded-t-none rounded-b-[20px] px-3 py-2 text-base w-full h-full space-y-1">
              {item.options.map((item, index) => {
                return (
                  <div key={index} className="flex justify-between space-y-1">
                    <div>{item.label}</div>
                    <div className={` flex space-x-2 text-mainblue `}>
                      {item.value}
                    </div>
                  </div>
                );
              })}

              <div className="mt-2 flex justify-between">
                <div>ต้องชำระงวดละ</div>
                <div className=" text-mainblue">
                  {numeral(item.amount).format("0,0")} บาท
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ReportFuturePlanPage = () => {
  const navigate = useNavigate();
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);

  const futureExpensesItems = useMemo(() => {
    const newFutureItems = [];
    const mergeItems = [
      {
        key: "ท่องเที่ยว",
        label: "ท่องเที่ยว",
        icon: "car_tour.png",
        title: "-",
        year: "-",
        amount: "",
      },
      {
        key: "วางแผนมีลูก",
        label: "วางแผนมีลูก",
        icon: "baby.png",
        title: "",
        year: "",
        amount: "",
      },
      {
        key: "ซื้อของสะสม",
        label: "ซื้อของสะสม",
        icon: "buy.png",
        title: "",
        year: "",
        amount: "",
      },
      {
        key: "อื่น ๆ",
        label: "อื่น ๆ",
        icon: "smile_open.png",
        title: "",
        year: "",
        amount: "",
      },
    ];

    if (futurePlanData?.expenese_travel_items?.length > 0) {
      const findIndex = mergeItems.findIndex(
        (item) => item.key === "ท่องเที่ยว"
      );
      mergeItems[findIndex] = {
        ...mergeItems[findIndex],
        ...futurePlanData?.expenese_travel_items[0],
      };
    }
    if (futurePlanData?.expenese_children_items?.length > 0) {
      const findIndex = mergeItems.findIndex(
        (item) => item.key === "วางแผนมีลูก"
      );
      mergeItems[findIndex] = {
        ...mergeItems[findIndex],
        ...futurePlanData?.expenese_children_items[0],
      };
    }
    if (futurePlanData?.expenese_collection_items?.length > 0) {
      const findIndex = mergeItems.findIndex(
        (item) => item.key === "ซื้อของสะสม"
      );
      mergeItems[findIndex] = {
        ...mergeItems[findIndex],
        ...futurePlanData?.expenese_collection_items[0],
      };
    }
    if (futurePlanData?.expenese_other_items?.length > 0) {
      const findIndex = mergeItems.findIndex((item) => item.key === "อื่น ๆ");
      mergeItems[findIndex] = {
        ...mergeItems[findIndex],
        ...futurePlanData?.expenese_other_items[0],
      };
    }

    if (mergeItems.length > 0) {
      for (const mergeItem of mergeItems) {
        newFutureItems.push({
          label: mergeItem.label,
          icon: mergeItem.icon,
          ...mergeItem,
          options: [
            {
              label: `ชื่อรายการ${mergeItem.key}`,
              value: `${mergeItem.title}`,
            },
            {
              label: "คาดการณ์ไว้อีก",
              value: `${mergeItem.year} ปี`,
            },
          ],
        });
      }
    }
    return newFutureItems;
  }, [futurePlanData]);

  const futureIncomeItems = useMemo(() => {
    const newFutureItems = [];
    const mergeItems = [
      {
        key: "รับมรดก",
        label: "รับมรดก",
        icon: "two_hearts.png",
        title: "-",
        year: "-",
        amount: "",
      },
      {
        key: "ขายธุรกิจ",
        label: "ขายธุรกิจ",
        icon: "saving_expenses.png",
        title: "",
        year: "",
        amount: "",
      },
      {
        key: "ปล่อยเช่าอสังหาริมทรัพย์",
        label: "ปล่อยเช่าอสังหาริมทรัพย์",
        icon: "invest_insuarance_all.png",
        title: "",
        year: "",
        amount: "",
      },
      {
        key: "อื่น ๆ",
        label: "อื่น ๆ",
        icon: "taxicon3.png",
        title: "",
        year: "",
        amount: "",
      },
    ];

    if (futurePlanData?.income_legacy_items?.length > 0) {
      const findIndex = mergeItems.findIndex((item) => item.key === "รับมรดก");
      mergeItems[findIndex] = {
        ...mergeItems[findIndex],
        ...futurePlanData?.income_legacy_items[0],
      };
    }
    if (futurePlanData?.income_business_items?.length > 0) {
      const findIndex = mergeItems.findIndex(
        (item) => item.key === "ขายธุรกิจ"
      );
      mergeItems[findIndex] = {
        ...mergeItems[findIndex],
        ...futurePlanData?.income_business_items[0],
      };
    }
    if (futurePlanData?.income_property_items?.length > 0) {
      const findIndex = mergeItems.findIndex(
        (item) => item.key === "ปล่อยเช่าอสังหาริมทรัพย์"
      );
      mergeItems[findIndex] = {
        ...mergeItems[findIndex],
        ...futurePlanData?.income_property_items[0],
      };
    }
    if (futurePlanData?.income_other_items?.length > 0) {
      const findIndex = mergeItems.findIndex((item) => item.key === "อื่น ๆ");
      mergeItems[findIndex] = {
        ...mergeItems[findIndex],
        ...futurePlanData?.income_other_items[0],
      };
    }

    if (mergeItems.length > 0) {
      for (const mergeItem of mergeItems) {
        newFutureItems.push({
          label: mergeItem.label,
          icon: mergeItem.icon,
          ...mergeItem,
          options: [
            {
              label: `ชื่อรายการ${mergeItem.key}`,
              value: `${mergeItem.title}`,
            },
            {
              label: "คาดการณ์ไว้อีก",
              value: `${mergeItem.year} ปี`,
            },
          ],
        });
      }
    }
    return newFutureItems;
  }, [futurePlanData]);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div>
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />

        <div className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5`}>
          <HeaderReport
            icon={"/images/banknote-report.png"}
            title={"แผนอนาคต"}
            className={" z-20"}
          />
          <FlowSection futurePlanData={futurePlanData} />
          <ExpenseSection futureExpensesItems={futureExpensesItems} />
          <IncomeSection futureIncomeItems={futureIncomeItems} />
          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportFuturePlanPage;
