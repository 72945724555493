import { NumericFormat } from "react-number-format";
import { CardMain, CardProduct } from "../../../components/card/CardMain";
import {
  STYLE_INPUT,
  STYLE_PADDING_MAIN,
  STYLE_TEXT_BLUE,
  STYLE_TEXT_CYAN,
} from "../../../utils/useStyle";
import { useState, useEffect } from "react";
import numeral from "numeral";
import { CheckBoxSecond } from "../../../components/checkbox/CheckboxSecond";
import { RadioMain } from "../../../components/radio/Radio.Main";
import ViewTableFamily from "./VITableFamily.Main";
import { ImageMain } from "../../../components/img/Img.Main";
import { MTButton } from "../../../components/button/MTButton";
import { FooterAfterSum } from "../../../components/FooterAfterSum/FooterAfterSum";
import { StatusCard } from "../styles/Card";
import { first_reload } from "../functions/firstLoad";
import NavigateGoBack from "../../../functions/Navigate.Goback";
import { useNavigate } from "react-router-dom";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import CustomHorizontalBarChart from "../../pdf/component/pdf.c.familyChart";
import { useBalanceStore } from "../../../_store/balanceStore";

const ViewDisable_Final = ({ setFinal, setTab }) => {
  const navigate = useNavigate();
  const insuranceData = useInsuranceStore((state) => state.insuranceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  // console.log("insuranceData", insuranceData);
  // console.log("educationData", educationData);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  const getStatus = () => {
    if (insuranceData.sum_disabled_insurance_more === 0) {
      return "normal";
    } else if (insuranceData.sum_disabled_insurance_more < 0) {
      return "toomuch";
    }
    return "more";
  };

  return (
    <div id="first_reload">
      <div className={`${STYLE_PADDING_MAIN} my-3 space-y-5 `}>
        <CardMain
          title={
            <div className=" text-mainblue font-semibold ">
              ผลลัพธ์วางแผนทุนประกันคุ้มครองค่าใช้จ่าย
            </div>
          }
          panel={
            <div className=" px-3  space-y-5 font-medium">
              {/* <div>
                <div>ทุนประกันที่ควรมีเพื่อคุ้มครองครอบครัว 5 ปี</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(insuranceData?.sum_disabled_family_five_year)?.format("0,0")}{" "}
                  บาท
                </div>
              </div> */}
              {/* <div>
                <div>ทุนประกันสำหรับหนี้สิน</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(0)?.format("0,0")} บาท
                </div>
              </div> */}

              <div>
                <div>ทุนประกันแนะนำ</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(insuranceData?.sum_disabled_insurance_net)?.format(
                    "0,0"
                  )}{" "}
                  บาท
                </div>
              </div>
              <div>
                <div>ทุนประกันที่คุณมี ณ ปัจจุบัน</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(
                    insuranceData?.sum_disabled_insurance_current
                  )?.format("0,0")}{" "}
                  บาท
                </div>
              </div>
              <div>
                <div>รวมมูลค่าสินทรัพย์</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(insuranceData?.sum_disabled_asset_current)?.format(
                    "0,0"
                  )}{" "}
                  บาท
                </div>
                <div className=" text-gray-800 text-xs">
                  (สินทรัพย์สภาพคล่อง + สินทรัพย์ลงทุน + สินทรัพย์ไม่มีตัวตน
                  ไม่รวมสินทรัพย์ส่วนตัว)
                </div>
              </div>

              <div className="flex flex-col border border-gray-400 rounded p-3">
                <StatusCard
                  status={getStatus()}
                  value={
                    insuranceData.sum_disabled_insurance_net -
                    insuranceData.sum_disabled_insurance_current -
                    insuranceData.sum_disabled_asset_current
                  }
                />
                {/* <div className="underline text-center">ทุนประกันแนะนำ</div>
                <div className="my-4 text-maincyan font-semibold text-2xl text-center">
                  {numeral(insuranceData?.sum_insurance_more)?.format("0,0")}{" "}
                  บาท
                </div> */}
                <div className="text-center">
                  ทุนประกันแนะนำ - (สินทรัพย์สภาพคล่อง + สินทรัพย์ลงทุน +
                  สินทรัพย์ไม่มีตัวตน ไม่รวมสินทรัพย์ส่วนตัว)
                </div>
                <div className="my-2 text-center">
                  {numeral(insuranceData.sum_disabled_insurance_net).format(
                    "0,0"
                  )}{" "}
                  - ({numeral(balanceData?.sum_property_asset).format("0,0")} +{" "}
                  {numeral(balanceData?.sum_property_intangible).format("0,0")}{" "}
                  + {numeral(balanceData?.sum_property_invest).format("0,0")}){" "}
                  ={" "}
                  {numeral(
                    insuranceData.sum_disabled_insurance_net -
                      insuranceData.disabled_asset_all
                  ).format("0,0")}
                </div>
                <div className="w-[300px] flex justify-center mx-auto">
                  <CustomHorizontalBarChart
                    width={120}
                    yourData={[
                      insuranceData.sum_insurance_current +
                        insuranceData.sum_asset_current,
                      0,
                    ]}
                    recomenedData={[
                      0,
                      insuranceData?.sum_family +
                        insuranceData.sum_family_debt +
                        insuranceData.sum_insurance_education,
                    ]}
                    barThickness={40}
                  />
                </div>
              </div>
              {/* <div>
                <div>ทุนประกันแนะนำ</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(insuranceData?.sum_disabled_insurance_more)?.format(
                    "0,0"
                  )}{" "}
                  บาท
                </div>
              </div> */}
              {/* <div className="w-[300px] flex justify-center mx-auto">
                <CustomHorizontalBarChart
                  width={120}
                  yourData={[insuranceData?.sum_disabled_insurance_current, 0]}
                  recomenedData={[0, insuranceData.sum_disabled_insurance_more]}
                  barThickness={40}
                />
              </div> */}
              {/* <StatusCard
                status={getStatus()}
                value={insuranceData.sum_disabled_insurance_more}
              /> */}
            </div>
          }
        />
        <MTButton
          onClick={() => {
            setFinal(false);
            setTab(2);
          }}
          text="ย้อนกลับ"
          color="bg-white"
          border="border border-maincyan"
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          width="w-full"
        />
        <CardProduct />
        <FooterAfterSum />
      </div>
    </div>
  );
};

export default ViewDisable_Final;
