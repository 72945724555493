import { Dialog, Drawer, Progress } from "@material-tailwind/react";
import {
  ICON_CLOSE_NO_CIIRCLE,
  ICON_INFO,
  ICON_NAVIGATE,
  ICON_PLUS,
  ICON_WARNING_CIRCLE,
} from "../../../utils/useIcons";
import { MTButton } from "../../../components/button/MTButton";
import {
  STYLE_ACCORDION_BODY_TAX_2,
  STYLE_BG_BLUE_DRAWER,
  STYLE_BG_BLUE_DRAWER_2,
  STYLE_BOX_DONATE,
  STYLE_CARD_BG,
  STYLE_GRAY_DRAWER_TAB,
  STYLE_ICON_CLOSE_DW,
  STYLE_IMG_DRAWER,
  STYLE_INPUT,
  STYLE_INPUT_INSET,
  STYLE_MAX_W_DRAWER,
  STYLE_MAX_W_DRAWER_TAX,
  STYLE_PARENT_BUTTON_DRAWER,
  STYLE_PARENT_BUTTON_DRAWER_2,
  STYLE_PARENT_BUTTON_DRAWER_3,
  STYLE_TITLE_DISPLAY,
  STYLE_TITLE_DRAWER,
} from "../../../utils/useStyle";
import { HeaderTitle } from "../../../components/header/Header";
import {
  CardDeduction,
  CardDeductionChild,
  CardMain,
} from "../../../components/card/CardMain";
import { useEffect, useMemo, useState } from "react";
import {
  calInsuarant,
  calInsuarant2,
  calPercentValue,
  calSum5Value,
  checkChild,
  checkError,
  checkIncome,
  checkIncome2,
  checkMinusMaxValue,
  checkSSF,
  checkSpouseIncome,
  deduction_default_result,
  formReset,
  formatChildReset,
  formatDeduction,
  formatDeductionReset,
  getInsurancePaid,
  getPercent,
  getcalPaid,
  getcalcRemainEducaid,
} from "../functions";
import { useTaxPlanContext } from "../Tax_Plan.Main";
import { PanelTax } from "../styles/Panel_Main";
import numeral, { Numeral } from "numeral";
import { useWindowSize } from "../../../utils/useWindowSize";
import { useIsMobile } from "../../../utils/useIsMobile";
import _, { max } from "lodash";
import { NumericFormat } from "react-number-format";
import { useFamilyStore } from "../../../_store/familyStore";
import { PopupMain } from "../../../components/popup/PopupMain";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";
import { useBalanceStore } from "../../../_store/balanceStore";
import { InfoDrawer } from "../../../components/drawer/InfoDrawer";
import { cn } from "../../../utils/useFunctions";
import { TabTwoExpenseCalculation, TabTwoSumIncomeCalculation } from "../../../functions/taxFucntion/premium/tab2function";

export const generateSummary = (form) => {
  const result = formatDeduction(form);
  const sumResult = _?.sumBy(result, "amount");
  const sumResultExceptEducationDonate = _?.sumBy(
    result?.filter((el) => el?.key !== "education_donate"),
    "amount"
  );
  const sumResultExceptOtherDonate = _?.sumBy(
    result?.filter((el) => el?.key !== "other_donate"),
    "amount"
  );

  return {
    result,
    sumResult,
    sumResultExceptEducationDonate,
    sumResultExceptOtherDonate,
  };
};

// Sum Except Education Donate || Sum Except Other Donate
const getValueremian = (form, key) => {
  let income = form?.tax_income;
  let deductAll = form?.[key];
  let results = income - 100000 - deductAll;
  // console.log("results", results);
  return results;
};

export const PayerIncome = ({ openInfo, setOpenInfo, setOpenDrawer }) => {
  const isMobile = useIsMobile();

  return (
    <div index={1}>
      {isMobile ? (
        <Drawer
          placement="bottom"
          open={openInfo}
          onClose={() => {
            // setOpenDrawer(true);
            setOpenInfo(false);
          }}
          className=" rounded-t-[40px] !max-h-[50vh] overflow-y-scroll "
        >
          <div className={``}>
            <div className=" w-[90px] h-[3px] rounded-full mx-auto bg-[#E0DFDF] mb-7 mt-5"></div>
            <img
              src="/images/img_popup.png"
              className="animate-bounce mx-auto w-64 h-auto"
              alt=""
            />
            <div className=" w-[61px] h-[3px] rounded-full mx-auto bg-maincyan mb-2 mt-3"></div>
            <div className="px-10 h-fit">
              <div className=" text-xl font-IBMPlexSansThai font-semibold text-mainblue text-center">
                ได้รับสิทธิลดหย่อน
              </div>
              <div className={` ${STYLE_TITLE_DRAWER} mt-1`}>
                {`60,000 `} บาท
              </div>
            </div>
            <img
              src="/images/bgblue.png"
              className={STYLE_BG_BLUE_DRAWER}
              alt=""
            />
          </div>
        </Drawer>
      ) : (
        <Dialog
          // onClose={() => {
          //   setOpenInfo(false);
          // }}
          open={openInfo}
          className="bg-blueopacity shadow-none p-4 rounded-[30px] font-IBMPlexSansThai"
        >
          <ICON_CLOSE_NO_CIIRCLE
            className={` ml-auto cursor-pointer `}
            onClick={() => {
              setOpenInfo(false);
            }}
          />
          <div className={``}>
            <img
              src="/images/img_popup.png"
              className="animate-bounce mx-auto w-64 h-auto mt-10"
              alt=""
            />
            <div className=" w-[61px] h-[3px] rounded-full mx-auto bg-maincyan mb-2 mt-3"></div>
            <div className="px-10 h-fit">
              <div className=" text-xl font-IBMPlexSansThai font-semibold text-mainblue text-center">
                ได้รับสิทธิลดหย่อน
              </div>
              <div className={` ${STYLE_TITLE_DRAWER} mt-1`}>
                {`60,000 `} บาท
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export const Spouse = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
  isMarriage,
  setIsMarriage,
  ishasIncome,
  setIshasIncome,
  isSpouseDisable,
  setIsSpouseDisable,
}) => {
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const {
        result,
        sumResult,
        sumResultExceptEducationDonate,
        sumResultExceptOtherDonate,
      } = generateSummary({
        ...form,
        deduct_spouse: checkSpouseIncome(
          ishasIncome,
          isMarriage,
          isSpouseDisable
        ),
      });

      setForm({
        ...form,
        deduct_spouse: checkSpouseIncome(
          ishasIncome,
          isMarriage,
          isSpouseDisable
        ),
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
        deduct_summary_except_other_donate: sumResultExceptOtherDonate,
      });
    }
    return () => {
      ignore = true;
    };
  }, [ishasIncome, isMarriage, isSpouseDisable]);

  const displayDeductionSpouse = ishasIncome ? (
    <div className=" text-xl font-IBMPlexSansThai font-semibold text-mainblue text-center ">
      ไม่ได้รับสิทธิลดหย่อน
    </div>
  ) : isMarriage ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}>60,000 บาท</div>
    </div>
  ) : isSpouseDisable ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}>60,000 บาท</div>
    </div>
  ) : (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}>60,000 บาท</div>
    </div>
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div>
        <div className={STYLE_MAX_W_DRAWER_TAX}>
          <div className=" flex justify-center ">
            <div className={`${STYLE_TITLE_DRAWER} `}>
              คู่สมรสมีรายได้หรือไม่
            </div>
            <div>
              <ICON_INFO
                onClick={() => {
                  setOpenInfoPopUp(!openInfoPopUp);
                }}
                className={` w-4 h-4 text-maingray mt-1`}
              />
            </div>
          </div>

          <div className={`flex gap-3 items-center justify-center mt-6`}>
            <MTButton
              text="มี"
              color={`border-maincyan ${ishasIncome && "bg-maincyan"}`}
              colortext={`text-maincyan font-medium  ${
                ishasIncome && "text-white"
              } `}
              variant="outlined"
              onClick={() => {
                setIshasIncome(true);
              }}
            />
            <MTButton
              text="ไม่มี"
              color={`border-maincyan ${!ishasIncome && "bg-maincyan"}`}
              colortext={`text-maincyan font-medium  ${
                !ishasIncome && "text-white"
              } `}
              variant="outlined"
              onClick={() => {
                setIshasIncome(false);
                setIsMarriage(true);
              }}
            />
          </div>
          <div className="">
            {!isMarriage && (
              <div className={`${STYLE_TITLE_DRAWER} mt-16`}>
                <div>แพทย์รับรองว่าคู่สมรสทุพพลภาพหรือไม่</div>
                <div className={`flex gap-3 items-center justify-center mt-6`}>
                  <MTButton
                    text="ใช่"
                    color={`border-maincyan ${
                      isSpouseDisable ? "bg-maincyan" : ""
                    }`}
                    colortext={`text-maincyan font-medium  ${
                      isSpouseDisable ? "text-white" : ""
                    } `}
                    variant="outlined"
                    onClick={() => {
                      setIsSpouseDisable(true);
                      // setIshasIncome(true);
                      // setIsMarriage(true);
                    }}
                  />
                  <MTButton
                    text="ไม่ใช่"
                    color={`border-maincyan ${
                      !isSpouseDisable && "bg-maincyan"
                    }`}
                    colortext={`text-maincyan font-medium  ${
                      !isSpouseDisable && "text-white"
                    } `}
                    variant="outlined"
                    onClick={() => {
                      setIsSpouseDisable(false);
                      setIshasIncome(false);
                      setIsMarriage(false);
                      // setIshasIncome(false);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={STYLE_PARENT_BUTTON_DRAWER_2}>
          <div
            className={` ${
              ishasIncome ? "" : ""
            } animate-fade-up bg-grayopacity/40 w-full h-20  flex flex-col justify-center items-center p-[14px]`}
          >
            {displayDeductionSpouse}
          </div>
          <div className={STYLE_PARENT_BUTTON_DRAWER}>
            <MTButton
              text="ยกเลิก"
              color="border border-maincyan "
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
              onClick={() => {
                setOpenDrawer(true);
                setOpenInfo(false);
              }}
            />
            <MTButton
              text="ยืนยัน"
              onClick={() => {
                setForm({
                  ...form,
                  deduct_spouse: checkSpouseIncome(
                    ishasIncome,
                    isMarriage,
                    isSpouseDisable
                  ),
                });
                setOpenDrawer(true);
                setOpenInfo(false);
              }}
            />
          </div>
        </div>
      </div>
      <img
        src="/images/bgblue.png"
        className={`w-full absolute -bottom-20 -z-10`}
      />
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <p>
              <strong>
                ค่าลดหย่อนคู่สมรส ใช้เป็นค่าลดหย่อน แบบเหมา ฿60,000 ต่อปี
              </strong>
              สำหรับผู้ที่ดูแลคู่สมรสที่ไม่มีรายได้
              และแม้คู่สมรสจะเสียชีวิตระหว่างปีก็ยังสามารถใช้ลดหย่อนภาษีสำหรับปีภาษี
              ที่เสียชีวิตได้อยู่
            </p>
          </div>
        }
      />
    </Drawer>
  );
};

export const Child = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
  ishasChild,
  setIshasChild,
}) => {
  const [showPopup, setShowPopup] = useState();
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  // const { chlidList, setChildList } = useTaxPlanContext();
  const chlidList = useFamilyStore((state) => state.chlidList);
  const setChildList = useFamilyStore((state) => state.setChildList);

  useEffect(() => {
    let ignore = false;
    if (chlidList?.length > 0) {
      setIshasChild(true);
    }
    if (!ignore) {
      const {
        result,
        sumResult,
        sumResultExceptEducationDonate,
        sumResultExceptOtherDonate,
      } = generateSummary({ ...form, deduct_child: checkChild(chlidList) });

      setForm({
        ...form,
        deduct_child: checkChild(chlidList),
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
        deduct_summary_except_other_donate: sumResultExceptOtherDonate,
      });
    }
    return () => {
      ignore = true;
    };
  }, [chlidList]);

  const sumDeductionChild = useMemo(() => checkChild(chlidList), [chlidList]);

  const displayDeductChild = ishasChild ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}>
        {" "}
        {numeral(sumDeductionChild).format("0,0")} บาท
      </div>
    </div>
  ) : (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}>0 บาท</div>
    </div>
  );

  // console.log("openInfoPopUp", openInfoPopUp);
  return (
    <>
      <Drawer
        placement="bottom"
        open={openInfo}
        className=" !max-h-screen overflow-y-scroll"
      >
        <div className={` ${STYLE_MAX_W_DRAWER}  md:mt-32 mt-16 `}>
          <div className="flex items-center justify-between px-6  md:px-12  ">
            <div className="flex">
              <div className={`${STYLE_TITLE_DRAWER}  `}>ลูก</div>
              <div>
                <ICON_INFO
                  onClick={() => {
                    setOpenInfoPopUp(!openInfoPopUp);
                  }}
                  className={` w-4 h-4 text-maingray mt-1`}
                />
              </div>
            </div>

            <MTButton
              disabled={chlidList?.length === 3}
              width={`w-fit px-3  `}
              iconleft
              icon={<ICON_PLUS className="w-4 h-4 text-white flex-none mr-2" />}
              text={`เพิ่ม`}
              onClick={() => {
                if (chlidList.length === 0) {
                  setIshasChild(true);
                  setChildList([...chlidList, 30000]);
                } else {
                  setShowPopup(true);
                }
              }}
            />
          </div>
          {ishasChild ? (
            <div className="  px-6  md:px-12 ">
              {chlidList?.map((row, index) => {
                // console.log(row);
                return (
                  <div className=" my-5">
                    <CardDeductionChild
                      title={` คนที่ ${index + 1}`}
                      amount={numeral(Number(row))?.format("0,0")}
                      minus={true}
                      onClickDelete={() => {
                        const newChild = [...chlidList];
                        newChild.splice(index, 1);
                        setChildList(newChild);
                      }}
                      colorbg="bg-gray50"
                      hasicon={false}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className=" flex flex-col justify-center items-center mt-5">
              <img src="/images/no_child.svg" className="w-[115px] h-[115px]" />
              <div className="text-center text-gray80">
                ท่านยังไม่ได้เพิ่มข้อมูล
              </div>
            </div>
          )}

          {showPopup && (
            <div className=" px-6 lg:px-12  md:px-12 ">
              <div className=" text-center p-2.5 rounded-[10px] border border-gray80 text-maingray text-sm :text-md mt-[37px]">
                <div className=" flex  justify-center items-center gap-1 ">
                  <ICON_WARNING_CIRCLE
                    className={` w-6 h-6 text-mainyellow flex-none `}
                  />
                  <div className="mt-1 text-center">
                    บุตร (ตั้งแต่คนที่ 2 เป็นต้นไปที่เกิดใน
                  </div>
                </div>
                <div className=" mt-1">
                  {" "}
                  หรือหลังปี พ.ศ.2561) คนละ 60,000 บาท
                </div>
              </div>
              <div className={`flex gap-3 items-center justify-center mt-6`}>
                <MTButton
                  text="ใช่"
                  color={`border-maincyan ${chlidList ? "bg-maincyan" : ""}`}
                  colortext={`text-maincyan font-medium  ${
                    chlidList ? "text-white" : ""
                  } `}
                  variant="outlined"
                  onClick={() => {
                    setChildList([...chlidList, 60000]);
                    setShowPopup(false);
                  }}
                />
                <MTButton
                  text="ไม่ใช่"
                  color={`border-maincyan ${!chlidList && "bg-maincyan"}`}
                  colortext={`text-maincyan font-medium  ${
                    !chlidList && "text-white"
                  } `}
                  variant="outlined"
                  onClick={() => {
                    setChildList([...chlidList, 30000]);
                    setShowPopup(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div className={STYLE_PARENT_BUTTON_DRAWER_2}>
          <div className={STYLE_GRAY_DRAWER_TAB}>{displayDeductChild}</div>
          <div className={STYLE_PARENT_BUTTON_DRAWER}>
            <MTButton
              text="ยกเลิก"
              color="border border-maincyan "
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
              onClick={() => {
                setChildList([]);
                setForm((prev) => {
                  const newForm = { ...prev };
                  newForm.deduct_child = newForm.deduct_child_prev;
                  return newForm;
                });
                setOpenDrawer(true);
                setOpenInfo(false);
              }}
            />
            <MTButton
              text="ยืนยัน"
              onClick={() => {
                setForm({
                  ...form,
                  deduct_child: checkChild(chlidList),
                  deduct_child_prev: checkChild(chlidList),
                });
                setOpenDrawer(true);
                setOpenInfo(false);
              }}
            />
          </div>
        </div>
        <PopupMain
          img={null}
          onClick={() => {
            setOpenInfoPopUp(false);
          }}
          open={openInfoPopUp}
          text={
            <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
              {/* ค่าลดหย่อนบุตร เป็น ค่าลดหย่อนแบบเหมาจ่าสำหรับคนที่มีลูก
              โดยเราสามารถลดหย่อนลูกได้ คนละ ฿30,000 ต่อปี
              แต่ถ้ามีลูกตั้งแต่คนที่สองเป็นต้นไปเป็นบุตรชอบด้วยกฎหมายที่เกิดในปี
              พ.ศ. 2561 หรือต่อจากนี้
              จะสามารถลดหย่อนลูกตั้งแต่คนที่สองเป็นต้นไปได้ คนละ ฿60,000 ต่อปี!
              และแม้บุตรจะเสียชีวิตระหว่างปีก็ยังสามารถใช้ลดหย่อนภาษีสำหรับ
              ปีภาษี ที่เสียชีวิตได้อยู่ */}
              <p>
                <strong>ค่าลดหย่อนบุตร</strong> เป็น
                ค่าลดหย่อนแบบเหมาสำหรับคนที่มีลูกโดยเราสามารถลดหย่อนลูกได้
                <strong>คนละ ฿30,000 ต่อปี</strong>
                แต่ถ้ามีลูกตั้งแต่คนที่สองเป็นต้นไปเป็นบุตรชอบด้วยกฎหมายที่เกิดในปี
                พ.ศ. 2561 หรือหลังจากนี้
                จะสามารถลดหย่อนลูกตั้งแต่คนที่สองเป็นต้นไปได้
                <strong>คนละ ฿60,000 ต่อปี</strong>
                และแม้บุตรจะเสียชีวิตระหว่างปีก็ยังสามารถใช้ลดหย่อนภาษีสำหรับ
                ปีภาษี ที่เสียชีวิตได้อยู่
              </p>
            </div>
          }
        />

        <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
      </Drawer>
    </>
  );
};

export const Mother = ({
  openInfo,
  setOpenInfo,
  setMother,
  setForm,
  form,
  setOpenDrawer,
  ishasIncomeMother,
  setIshasIncomeMother,
}) => {
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const {
        result,
        sumResult,
        sumResultExceptEducationDonate,
        sumResultExceptOtherDonate,
      } = generateSummary({
        ...form,
        deduct_mother: checkIncome(ishasIncomeMother),
      });

      setForm({
        ...form,
        deduct_mother: checkIncome(ishasIncomeMother),
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
        deduct_summary_except_other_donate: sumResultExceptOtherDonate,
      });
    }
    return () => {
      ignore = true;
    };
  }, [ishasIncomeMother]);

  const displayValue = ishasIncomeMother ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}> 30,000 บาท</div>
    </div>
  ) : (
    <div className={STYLE_TITLE_DISPLAY}>ไม่ได้รับสิทธิลดหย่อน</div>
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <p>
              <strong>ค่าลดหย่อนบิดามารดา</strong>
              ใช้เป็นค่าลดหย่อนแบบเหมาต่อพ่อหรือแม่หนึ่งคนได้
              <strong>คนละ ฿30,000 ต่อปี</strong> สำหรับคนที่เลี้ยงดูพ่อแม่เอง
              และแม้พ่อแม่จะเสียชีวิตระหว่างปีก็ยังสามารถใช้ลดหย่อนภาษีสำหรับ
              ปีภาษี ที่เสียชีวิตได้อยู่
            </p>
          </div>
        }
      />
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <div className={`${STYLE_TITLE_DRAWER} flex justify-center w-full `}>
          อุปการะเลี้ยงดูแม่หรือไม่
          <ICON_INFO
            onClick={() => {
              setOpenInfoPopUp(!openInfoPopUp);
            }}
            className={` w-4 h-4 text-maingray mt-1`}
          />
        </div>
        <div className={`flex gap-3 items-center justify-center mt-6`}>
          <MTButton
            text="ใช่"
            color={`border-maincyan ${ishasIncomeMother && "bg-maincyan"}`}
            colortext={`text-maincyan font-medium  ${
              ishasIncomeMother && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeMother(true);
            }}
          />
          <MTButton
            text="ไม่"
            color={`border-maincyan ${!ishasIncomeMother && "bg-maincyan"}`}
            colortext={`text-maincyan font-medium  ${
              !ishasIncomeMother && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeMother(false);
            }}
          />
        </div>
      </div>
      <div className={STYLE_PARENT_BUTTON_DRAWER_3}>
        <div className={STYLE_GRAY_DRAWER_TAB}>{displayValue}</div>
        <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
          <MTButton
            text="ยกเลิก"
            color=" border border-maincyan "
            colortext="text-maincyan group-hover:text-white"
            hovercolor="hover:bg-maincyan group "
            onClick={() => {
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
          <MTButton
            text="ยืนยัน"
            onClick={() => {
              setForm({
                ...form,
                deduct_mother: checkIncome(ishasIncomeMother),
              });
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
        </div>
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Father = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
  ishasIncomeFather,
  setIshasIncomeFather,
}) => {
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const {
        result,
        sumResult,
        sumResultExceptEducationDonate,
        sumResultExceptOtherDonate,
      } = generateSummary({
        ...form,
        deduct_father: checkIncome(ishasIncomeFather),
      });

      setForm({
        ...form,
        deduct_father: checkIncome(ishasIncomeFather),
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
        deduct_summary_except_other_donate: sumResultExceptOtherDonate,
      });
    }
    return () => {
      ignore = true;
    };
  }, [ishasIncomeFather]);

  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  const displayValue = ishasIncomeFather ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}> 30,000 บาท</div>
    </div>
  ) : (
    <div className={STYLE_TITLE_DISPLAY}>ไม่ได้รับสิทธิลดหย่อน</div>
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <p>
              <strong>ค่าลดหย่อนบิดามารดา</strong>
              ใช้เป็นค่าลดหย่อนแบบเหมาต่อพ่อหรือแม่หนึ่งคนได้
              <strong>คนละ ฿30,000 ต่อปี</strong> สำหรับคนที่เลี้ยงดูพ่อแม่เอง
              และแม้พ่อแม่จะเสียชีวิตระหว่างปีก็ยังสามารถใช้ลดหย่อนภาษีสำหรับ
              ปีภาษี ที่เสียชีวิตได้อยู่
            </p>
          </div>
        }
      />
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <div className={`${STYLE_TITLE_DRAWER} flex-none`}>
          <div className={`${STYLE_TITLE_DRAWER} flex justify-center w-full `}>
            อุปการะเลี้ยงดูพ่อหรือไม่
            <ICON_INFO
              onClick={() => {
                setOpenInfoPopUp(!openInfoPopUp);
              }}
              className={` w-4 h-4 text-maingray mt-1`}
            />
          </div>
        </div>
        <div className={`flex gap-3 items-center justify-center mt-6`}>
          <MTButton
            text="ใช่"
            color={`border-maincyan ${ishasIncomeFather && "bg-maincyan"}`}
            colortext={`text-maincyan font-medium  ${
              ishasIncomeFather && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeFather(true);
            }}
          />
          <MTButton
            text="ไม่"
            color={`border-maincyan ${!ishasIncomeFather && "bg-maincyan"}`}
            colortext={`text-maincyan font-medium  ${
              !ishasIncomeFather && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeFather(false);
            }}
          />
        </div>
      </div>
      <div className={STYLE_PARENT_BUTTON_DRAWER_3}>
        <div className={STYLE_GRAY_DRAWER_TAB}>{displayValue}</div>
        <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
          <MTButton
            text="ยกเลิก"
            color=" border border-maincyan "
            colortext="text-maincyan group-hover:text-white"
            hovercolor="hover:bg-maincyan group "
            onClick={() => {
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
          <MTButton
            text="ยืนยัน"
            onClick={() => {
              setForm({
                ...form,
                deduct_father: checkIncome(ishasIncomeFather),
              });
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
        </div>
      </div>

      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Disable = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
  ishasIncomeDisable,
  setIshasIncomeDisable,
}) => {
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const {
        result,
        sumResult,
        sumResultExceptEducationDonate,
        sumResultExceptOtherDonate,
      } = generateSummary({
        ...form,
        deduct_disable_people: checkIncome2(ishasIncomeDisable),
      });

      setForm({
        ...form,
        deduct_disable_people: checkIncome2(ishasIncomeDisable),
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
        deduct_summary_except_other_donate: sumResultExceptOtherDonate,
      });
    }
    return () => {
      ignore = true;
    };
  }, [ishasIncomeDisable]);

  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  const displayValue = ishasIncomeDisable ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}> 60,000 บาท</div>
    </div>
  ) : (
    <div className={STYLE_TITLE_DISPLAY}>ไม่ได้รับสิทธิลดหย่อน</div>
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <p>
              <strong>ค่าลดหย่อนผู้พิการ หรือทุพพลภาพ</strong>{" "}
              ใช้เป็นค่าลดหย่อนแบบเหมาได้ <strong>คนละ ฿60,000 ต่อปี</strong>{" "}
              สำหรับคนที่ดูแลคนพิการหรือทุพพลภาพ
              และแม้คนพิการ/ทุพพลภาพจะเสียชีวิตระหว่างปีก็ยังสามารถใช้ลดหย่อนภาษีสำหรับ{" "}
              ปีภาษี ที่เสียชีวิตได้อยู่
            </p>
          </div>
        }
      />
      <div className="mt-16">
        <div className={`${STYLE_TITLE_DRAWER} `}>
          ผู้พิการท่านนี้มี
          <div className="flex justify-center">
            บัตรประจำตัวผู้พิการหรือไม่{" "}
            <ICON_INFO
              onClick={() => {
                setOpenInfoPopUp(!openInfoPopUp);
              }}
              className={` w-4 h-4 text-maingray mt-1`}
            />
          </div>
        </div>
        <div className={`flex gap-3 items-center justify-center mt-6`}>
          <MTButton
            text="มี"
            color={`border-maincyan ${ishasIncomeDisable && "bg-maincyan"}`}
            colortext={`text-maincyan font-medium  ${
              ishasIncomeDisable && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeDisable(true);
            }}
          />
          <MTButton
            text="ไม่มี"
            color={`border-maincyan ${!ishasIncomeDisable && "bg-maincyan"}`}
            colortext={`text-maincyan font-medium  ${
              !ishasIncomeDisable && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeDisable(false);
            }}
          />
        </div>
        {ishasIncomeDisable ? (
          ""
        ) : (
          <div className=" px-10">
            <div className=" p-2.5 rounded-[10px] border border-gray80 text-maingray text-md mt-7">
              <div className=" flex items-center gap-1">
                <ICON_WARNING_CIRCLE
                  className={` w-6 h-6 text-mainyellow flex-none `}
                />
                <div className="mt-1">
                  ไม่ได้รับสิทธิลดหย่อนภาษีเนื่องจาก
                  <div>ผู้พิการท่านนี้ไม่มีบัตรประจำตัวผู้พิการ</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={STYLE_PARENT_BUTTON_DRAWER_3}>
        <div className={STYLE_GRAY_DRAWER_TAB}>{displayValue}</div>
        <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
          <MTButton
            text="ยกเลิก"
            color=" border border-maincyan "
            colortext="text-maincyan group-hover:text-white"
            hovercolor="hover:bg-maincyan group "
            onClick={() => {
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
          <MTButton
            text="ยืนยัน"
            onClick={() => {
              setForm({
                ...form,
                deduct_disable_people: checkIncome2(ishasIncomeDisable),
              });
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
        </div>
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Disabilities = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
  ishasIncomeDisabilities,
  setIshasIncomeDisabilities,
}) => {
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const {
        result,
        sumResult,
        sumResultExceptEducationDonate,
        sumResultExceptOtherDonate,
      } = generateSummary({
        ...form,
        deduct_disabilities_people: checkIncome2(ishasIncomeDisabilities),
      });

      setForm({
        ...form,
        deduct_disabilities_people: checkIncome2(ishasIncomeDisabilities),
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
        deduct_summary_except_other_donate: sumResultExceptOtherDonate,
      });
    }
    return () => {
      ignore = true;
    };
  }, [ishasIncomeDisabilities]);

  const displayValue = ishasIncomeDisabilities ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}> 60,000 บาท</div>
    </div>
  ) : (
    <div className={STYLE_TITLE_DISPLAY}>ไม่ได้รับสิทธิลดหย่อน</div>
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <p>
              <strong>ค่าลดหย่อนผู้พิการ หรือทุพพลภาพ</strong>{" "}
              ใช้เป็นค่าลดหย่อนแบบเหมาได้ <strong>คนละ ฿60,000 ต่อปี</strong>{" "}
              สำหรับคนที่ดูแลคนพิการหรือทุพพลภาพ
              และแม้คนพิการ/ทุพพลภาพจะเสียชีวิตระหว่างปีก็ยังสามารถใช้ลดหย่อนภาษีสำหรับ{" "}
              ปีภาษี ที่เสียชีวิตได้อยู่
            </p>
          </div>
        }
      />
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <div className={`${STYLE_TITLE_DRAWER}  `}>
          ผู้พิการท่านนี้มีบัตรประจำตัว
          <div className="flex justify-center">
            ผู้ทุพพลภาพหรือไม่{" "}
            <ICON_INFO
              onClick={() => {
                setOpenInfoPopUp(!openInfoPopUp);
              }}
              className={` w-4 h-4 text-maingray mt-1`}
            />
          </div>
        </div>
        <div className={`flex gap-3 items-center justify-center mt-6`}>
          <MTButton
            text="ใช่"
            color={`border-maincyan ${
              ishasIncomeDisabilities && "bg-maincyan"
            }`}
            colortext={`text-maincyan font-medium  ${
              ishasIncomeDisabilities && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeDisabilities(true);
            }}
          />
          <MTButton
            text="ไม่"
            color={`border-maincyan ${
              !ishasIncomeDisabilities && "bg-maincyan"
            }`}
            colortext={`text-maincyan font-medium  ${
              !ishasIncomeDisabilities && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeDisabilities(false);
            }}
          />
        </div>
        {ishasIncomeDisabilities ? (
          ""
        ) : (
          <div className="">
            <div className=" p-2.5 rounded-[10px] border border-gray80 text-maingray text-md mt-7">
              <div className=" flex items-center gap-1">
                <ICON_WARNING_CIRCLE
                  className={` w-6 h-6 text-mainyellow flex-none `}
                />
                <div className="mt-1">
                  ไม่ได้รับสิทธิลดหย่อนภาษีเนื่องจาก
                  <div>ผู้พิการท่านนี้ไม่มีบัตรประจำตัวผู้พิการ</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={STYLE_PARENT_BUTTON_DRAWER_3}>
        <div className={STYLE_GRAY_DRAWER_TAB}>{displayValue}</div>
        <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
          <MTButton
            text="ยกเลิก"
            color=" border border-maincyan "
            colortext="text-maincyan group-hover:text-white"
            hovercolor="hover:bg-maincyan group "
            onClick={() => {
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
          <MTButton
            text="ยืนยัน"
            onClick={() => {
              setForm({
                ...form,
                deduct_disabilities_people: checkIncome2(
                  ishasIncomeDisabilities
                ),
              });
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
        </div>
      </div>

      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Parents = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    // if (Number(output) > 15000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, deduct_insurance_premiums_parents: output });

    setForm({
      ...form,
      deduct_insurance_premiums_parents: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.deduct_insurance_premiums_parents, 15000),
    [form?.deduct_insurance_premiums_parents, 15000]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.deduct_insurance_premiums_parents, 15000),
    [form?.deduct_insurance_premiums_parents, 15000]
  );

  // console.log("form", form?.deduct_insurance_premiums_parents);
  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="เบี้ยประกันสุขภาพบิดามารดา"
          reverse={true}
          remain={displayRemain}
          value={form.deduct_insurance_premiums_parents}
          maxvalue={15000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance.png"
          error={form?.deduct_insurance_premiums_parents > 15000 ? true : false}
          errortext={``}
          infoPopUpHeader={``}
          infoPopUpText={
            <div>
              <p>
                <strong>เบี้ยประกันสุขภาพบิดามารดา</strong>
                หรือประกันสุขภาพพ่อแม่ ใช้เป็นค่าลดหย่อนได้
                <strong>ตามที่จ่ายจริง รวมสูงสุดปีละ ฿15,000</strong>
                สำหรับคนที่จ่ายเบี้ยประกันสุขภาพบิดามารดา
              </p>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER}`}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.deduct_insurance_premiums_parents =
                newForm.deduct_insurance_premiums_parents_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.deduct_insurance_premiums_parents > 15000) {
            //   return;
            // }
            setForm({
              ...form,
              deduct_insurance_premiums_parents: Number(
                form.deduct_insurance_premiums_parents
              ),
              deduct_insurance_premiums_parents_prev: Number(
                form.deduct_insurance_premiums_parents
              ),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const InsuranceLife = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceLifeChange = (e) => {
    const value = e.target.value;
    // if (isNaN(e.target.value)) return;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (e.target.value > 100000) return;
    // if (output + Number(form?.insurance_health) > 100000) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, insurance_life: output });

    setForm({
      ...form,
      insurance_life: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const AllInsurance = () => {
    let sum = 0;
    sum = 100000 - Number(form?.insurance_health);
    return sum;
  };

  const displayRemain = useMemo(() => {
    // calInsuarant(form?.insurance_life, 100000)
    return 100000 - form?.insurance_life;
  }, [form?.insurance_life, 100000]);

  const getPercentBar = useMemo(
    () => getPercent(form?.insurance_life, 100000),
    [form?.insurance_life, 100000]
  );

  // useEffect(() => {
  //   let ignore = false;
  //   if (!ignore) {
  //     setForm({
  //       ...form,
  //       insurance_life_health: AllInsurance(),
  //     });
  //   }
  //   return () => {
  //     ignore = true;
  //   };
  // }, [form?.insurance_life_health]);

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="ประกันชีวิต"
          remain={displayRemain}
          reverse={true}
          value={form.insurance_life}
          maxvalue={100000}
          valueprogress={getPercentBar}
          onChange={onInsuranceLifeChange}
          img="/images/health-insurance-2.png"
          error={form?.insurance_life > 100000 ? true : false}
          // errortext={displayRemain === 0 ? `100,000` : 0}
          etc
          textetc={`เมื่อรวมกับประกันสุขภาพ จะต้องไม่เกิน 100,000 บาท`}
          errorstyle1={form?.insurance_life > 100000 ? "border-red-500" : ""}
          infoPopUpHeader=""
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <strong>เบี้ยประกันชีวิตทั่วไป</strong> ใช้เป็นค่าลดหย่อนได้
                ตามที่จ่ายจริง <strong>แต่ต้องไม่เกิน ฿100,000</strong>{" "}
                สำหรับคนที่ทำประกันชีวิตแบบทั่วไปให้ตัวเอง เช่น
                ประกันชีวิตแบบออมทรัพย์/สะสมทรัพย์, ประกันชีวิตแบบชั่วระยะเวลา
                (Term), ประกันชีวิตแบบตลอดชีพ รวมถึง เงินฝากแบบมีประกันชีวิต
                ด้วย
              </p>
              <p>
                ส่วนเบี้ยประกันชีวิตแบบทั่วไปสำหรับประกันชีวิตของคู่สมรสที่ไม่มีรายได้
                สามารถหักลดหย่อนได้ตามที่จ่ายจริง แต่ต้องไม่เกิน ฿10,000
              </p>
              <p>
                เบี้ยประกันชีวิตทั่วไป เป็นสิทธิประโยชน์สำหรับ{" "}
                ภาษีเงินได้บุคคลธรรมดา{" "}
                ซึ่งมีหลักเกณฑ์การลดหย่อนแตกต่างจากเบี้ยประกันชีวิตแบบบำนาญ
              </p>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.insurance_life = newForm.insurance_life_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.insurance_life > 100000) {
            //   return;
            // }
            setForm({
              ...form,
              insurance_life: Number(form.insurance_life),
              insurance_life_prev: Number(form.insurance_life),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const InsuaranceHealth = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    // if (output > 25000) return;
    // if (output > 100000 - form?.insurance_life) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, insurance_health: output });

    setForm({
      ...form,
      insurance_health: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const maxvalue = useMemo(() => {
    let results = 100000 - form?.insurance_life;
    if (results > 25000) {
      return 25000;
    }
    if (results - form?.insurance_health < 0) {
      return 25000;
    }
    return results;
  }, [form?.insurance_life, form?.insurance_health]);

  const getPercentBar = useMemo(
    () => getPercent(form.insurance_health, maxvalue),
    [form?.insurance_health, maxvalue]
  );

  const displayAllRemain = useMemo(() => {
    // calInsuarant(form.insurance_life, form?.insurance_health)
    let results = 100000 - form?.insurance_life;
    if (results > 25000) {
      return 25000 - form?.insurance_health;
    }

    if (results - form?.insurance_health < 0) {
      return 0;
    }

    return results - form?.insurance_health;
  }, [form?.insurance_life, form?.insurance_health]);

  const displayRemain = () => {
    let results = 100000 - form?.insurance_life;
    if (results > 25000) {
      return 25000 - form?.insurance_health;
    }
    return results - form?.insurance_health;
  };

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={9}
          title="ประกันสุขภาพ "
          remain={displayAllRemain < 0 ? 0 : displayAllRemain}
          reverse={true}
          value={form.insurance_health}
          maxvalue={25000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-2.png"
          // error={Number(form?.insurance_health) >= maxvalue}
          error={false}
          errortext={`25,000`}
          // full={displayAllRemain === 0 ? true : false}
          // disabled={displayAllRemain < 0}
          etc
          textetc={`เมื่อรวมกับประกันชีวิต จะต้องไม่เกิน 100,000 บาท`}
          infoPopUpHeader="หลักเกณฑ์การยกเว้นเงินได้สำหรับเบี้ยประกันสุขภาพ"
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <strong>เบี้ยประกันสุขภาพตนเอง</strong> ใช้เป็นลดหย่อนภาษีได้{" "}
                <strong>ตามที่จ่ายจริง</strong>{" "}
                สำหรับคนที่ทำประกันสุขภาพให้ตัวเอง
                <strong>
                  แต่ต้องไม่เกิน ฿25,000 และเมื่อรวมกับ เบี้ยประกันชีวิตทั่วไป
                  และ เงินฝากแบบมีประกันชีวิต แล้วจะต้องไม่เกิน ฿100,000
                </strong>{" "}
                ด้วย
              </p>
              <p>
                เบี้ยประกันชีวิตสุขภาพตนเอง เป็นสิทธิประโยชน์สำหรับ{" "}
                ภาษีเงินได้บุคคลธรรมดา
                ซึ่งมีหลักเกณฑ์การลดหย่อนแตกต่างจากเบี้ยประกันชีวิตทั่วไปแต่มีความเกี่ยวพันกันเนื่องจากต้องใช้เพดานสิทธิลดหย่อน
                ฿100,000 ร่วมกัน
              </p>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.insurance_health = newForm.insurance_health;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.insurance_health > 25000) {
            //   return;
            // }
            setForm({
              ...form,
              insurance_health: Number(form.insurance_health),
              insurance_health_prev: Number(form.insurance_health_prev),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const InsuranceAnnuity = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
  userData
}) => {
  const [error, setError] = useState(false);
  const balanceData = useBalanceStore((state) => state.balanceData);

  const onInsuranceAnnuityChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 200000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, insurance_annuity: output });

    setForm({
      ...form,
      insurance_annuity: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const checkSum = useMemo(
    () =>
      calSum5Value([
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value,
        form?.fund_GorBorKhor_value,
        form?.fund_PrivateSchoolTeacher_value,
      ]),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
      form?.fund_GorBorKhor_value,
      form?.fund_PrivateSchoolTeacher_value,
    ]
  );

  const maxvalue = useMemo(() => {
    let tempIncome = form?.tax_income
    // TabTwoSumIncomeCalculation()-TabTwoExpenseCalculation() - Number(displaySumDeduction)
    if(userData?.is_premium){
      tempIncome = TabTwoSumIncomeCalculation()-TabTwoExpenseCalculation() 
    }
    return tempIncome * 0.15 >= 200000
      ? 200000
      : tempIncome * 0.15;
  }, [balanceData]);

  const checkError = () => {
    const amouth = checkSum > 500000;
    return { amouth };
  };

  const displayRemain = () => {
    let results = 0;
    results = maxvalue - form.insurance_annuity;
    return results;
  };

  const getPercentBar = useMemo(
    () => getPercent(form.insurance_annuity, maxvalue),
    [form?.insurance_annuity, maxvalue]
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="ประกันชีวิตแบบบำนาญ"
          remain={displayRemain()}
          value={form.insurance_annuity}
          maxvalue={maxvalue}
          valueprogress={getPercentBar}
          onChange={onInsuranceAnnuityChange}
          img="/images/health-insurance-2.png"
          error={form?.insurance_annuity > maxvalue}
          // errortext={`200,000`}
          errortext={maxvalue}
          etc
          textetc={`เบี้ยประกันชีวิตแบบบำนาญสูงสุด ไม่เกินร้อยละ 15 ของรายได้พึงประเมินและไม่เกิน200,000 บาทต่อปี`}
          textetc2={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมกองทุนออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF), กองทุนสงเคราะห์ครูโรงเรียนเอกชน, กองทุนบำเหน็จบำนาญข้าราชการ เมื่อรวมกันแล้วต้องไม่เกิน 500,000 บาท`}
          errorstyle2={checkSum > 500000 ? "text-red-500" : ""}
          // infoPopUpHeader="หลักเกณฑ์การยกเว้นเงินได้สำหรับเบี้ยประกันแบบบำนาญ"
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <strong>เบี้ยประกันชีวิตแบบบำนาญ</strong> ใช้ลดหย่อนภาษีได้{" "}
                <strong>
                  ตามที่จ่ายจริง แต่ต้องไม่เกิน 15% ของ “เงินได้ที่ต้องเสียภาษี”
                </strong>
                <strong>
                  {" "}
                  (ไม่ว่าจะเอามารวมยื่นภาษีด้วยหรือไม่) และไม่เกิน ฿200,000
                </strong>{" "}
                <strong>ต่อปี</strong> สำหรับคนที่ซื้อประกันชีวิตแบบบำนาญ
                และอาจจะลดหย่อนได้สูงสุด ฿300,000 ถ้ายังไม่ได้ใช้สิทธิลดหย่อน
                เบี้ยประกันชีวิตทั่วไป
              </p>
              <p>
                เบี้ยประกันชีวิตแบบบำนาญเป็นสิทธิประโยชน์สำหรับ{" "}
                ภาษีเงินได้บุคคลธรรมดา
                ซึ่งใช้หลักเกณฑ์การลดหย่อนแตกต่างจากเบี้ยประกันชีวิตทั่วไป
              </p>
            </div>
          }
        />
        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
          <span className="text-mainblue font-semibold ">
            ** ตอนนี้ใช้ไปแล้ว {numeral(checkSum).format("0,0")} บาท
            {checkSum > 500000 && (
              <div className="text-red-500 font-semibold text-center mt-1">
                เกินสิทธิ
              </div>
            )}
          </span>
        </div>
      </div>

      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.insurance_annuity = newForm.insurance_annuity_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.insurance_annuity > 200000) {
            //   return;
            // }
            // const { amouth } = checkError(form);
            // if (amouth) {
            //   return setError(true);
            // } else {
            //   setForm({
            //     ...form,
            //     insurance_annuity: Number(form.insurance_annuity),
            //     insurance_annuity_prev: Number(form.insurance_annuity),
            //   });
            //   setOpenDrawer(true);
            //   setOpenInfo(false);
            // }
            setForm({
              ...form,
              insurance_annuity: Number(form.insurance_annuity),
              insurance_annuity_prev: Number(form.insurance_annuity),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER_2} />
    </Drawer>
  );
};

export const FundProvident = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [error, setError] = useState(false);

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_provident: output });

    setForm({
      ...form,
      fund_provident: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const maxvalue = useMemo(() => {
    let tempData = (form?.tax_income * 0.15) > 500000 ? 500000 : form?.tax_income * 0.15;
    return tempData
  },[form?.tax_income])

  const getValue = useMemo(
    () => calPercentValue(form.tax_income, maxvalue),
    [form?.tax_income, maxvalue]
  );

  // Get Remain
  const getRemainValue = maxvalue - form?.fund_provident;

  const getNotSurplus = () => {
    let value = form?.fund_provident;
    let result = 0;
    if (value <= 10000) {
      result = value;
    } else if (value > 10000) {
      result = 10000;
    }
    return result;
  };

  const getSurplus = () => {
    let value = form?.fund_provident;
    let result = 0;
    if (value > 10000) {
      result = value - 10000;
    }
    return result;
  };

  const getPercentBar = useMemo(
    () => getPercent(form.fund_provident, getValue),
    [form?.fund_provident, getValue]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.fund_provident, 10000),
    [form?.fund_provident, 10000]
  );

  const checkSum = useMemo(
    () =>
      calSum5Value([
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value,
        form?.fund_GorBorKhor_value,
        form?.fund_PrivateSchoolTeacher_value,
      ]),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
      form?.fund_GorBorKhor_value,
      form?.fund_PrivateSchoolTeacher_value,
    ]
  );

  const checkError = () => {
    const amouth = checkSum > 500000;
    return { amouth };
  };

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="เงินสะสม"
          title2="กองทุนสำรองเลี้ยงชีพ"
          // subtitle={`(ส่วนที่ไม่เกิน 10,000 บาท)`}
          remain={getRemainValue}
          value={form?.fund_provident}
          maxvalue={maxvalue}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_provident > maxvalue ? true : false}
          errortext={maxvalue}
          etc
          textetc={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมกองทุนออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF), กองทุนสงเคราะห์ครูโรงเรียนเอกชน, กองทุนบำเหน็จบำนาญข้าราชการ เมื่อรวมกันแล้วต้องไม่เกิน 500,000 บาท`}
          errorstyle2={checkSum > 500000 ? "text-red-500" : ""}
          panel2={
            <div className="  w-full px-4  mt-5 text-maingray ">
              <div className=" flex justify-between items-center ">
                <div className=" ">ส่วนที่ไม่เกิน 10,000 บาท</div>
                <div className=" font-semibold text-mainblue">
                  {numeral(getNotSurplus()).format("0,0")} บาท
                </div>
              </div>
              <div className=" flex justify-between items-center ">
                <div className=" ">ส่วนที่เกิน 10,000 บาท</div>
                <div className=" font-semibold text-mainblue">
                  {numeral(getSurplus()).format("0,0")} บาท
                </div>
              </div>
            </div>
          }
          // infoPopUpHeader="เงินสะสมกองทุนสำรองเลี้ยงชีพ"
          infoPopUpHeader=""
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <strong>เงินสะสมเข้ากองทุนสํารองเลี้ยงชีพ</strong>{" "}
                ใช้เป็นค่าลดหย่อนได้ ตามที่จ่ายจริง แต่ต้องไม่เกิน 15% ของ{" "}
                ค่าจ้าง และไม่เกิน ฿500,000{" "}
                <strong>เฉพาะเงินส่วนที่ลูกจ้างจ่ายเงินสะสม</strong>
                เข้ากองทุนสํารองเลี้ยงชีพ
              </p>
              <p>
                เงินสะสมเข้ากองทุนสํารองเลี้ยงชีพเป็นสิทธิประโยชน์สำหรับ{" "}
                ภาษีเงินได้บุคคลธรรมดา
              </p>
            </div>
          }
        />
        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
          <span className="text-mainblue font-semibold ">
            *ตอนนี้ใช้ไปแล้ว {numeral(checkSum).format("0,0")} บาท
            {checkSum > 500000 && (
              <div className="text-red-500 font-semibold text-center mt-1">
                เกินสิทธิ
              </div>
            )}
          </span>
        </div>
      </div>

      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_provident = newForm.fund_provident_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />

        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.fund_provident > getValue) {
            //   return;
            // } else {
            //   const { amouth } = checkError(form);
            //   if (amouth) {
            //     return setError(true);
            //   } else {
            //     setForm({
            //       ...form,
            //       fund_provident: Number(form.fund_provident),
            //       fund_provident_prev: Number(form.fund_provident),
            //     });
            //     setOpenDrawer(true);
            //     setOpenInfo(false);
            //   }
            // }
            setForm({
              ...form,
              fund_provident: Number(form.fund_provident),
              fund_provident_prev: Number(form.fund_provident),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER_2} />
    </Drawer>
  );
};

export const FundNSF = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [error, setError] = useState(false);

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_nsf: output });

    setForm({
      ...form,
      fund_nsf: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.fund_nsf, 30000),
    [form?.fund_nsf, 30000]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.fund_nsf, 30000),
    [form?.fund_nsf, 30000]
  );
  const checkSum = useMemo(
    () =>
      calSum5Value([
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value,
        form?.fund_GorBorKhor_value,
        form?.fund_PrivateSchoolTeacher_value,
      ]),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
      form?.fund_GorBorKhor_value,
      form?.fund_PrivateSchoolTeacher_value,
    ]
  );

  const checkError = () => {
    const amouth = checkSum > 500000;
    return { amouth };
  };

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="เงินสะสมกองทุนการออมแห่งชาติ (กอช)  "
          remain={displayRemain}
          value={form.fund_nsf}
          maxvalue={30000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_nsf > 30000 ? true : false}
          errortext={30000}
          etc
          textetc={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมกองทุนออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF), กองทุนสงเคราะห์ครูโรงเรียนเอกชน, กองทุนบำเหน็จบำนาญข้าราชการ เมื่อรวมกันแล้วต้องไม่เกิน 500,000 บาท`}
          errorstyle1={checkSum > 500000 ? "text-red-500" : ""}
          // infoPopUpHeader="หลักเกณฑ์การหักเงินสะสมกองทุนการออมแห่งชาติ"
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <strong>เงินสะสมกองทุนการออมแห่งชาติ (กอช.)</strong> ใช้เป็น
                ค่าลดหย่อน ได้ ตามที่จ่ายจริง รวมสูงสุดปีละ ฿30,000
                สำหรับคนที่จ่ายเงินสะสมเข้ากองทุนการออมแห่งชาติ
              </p>
              <p>
                เงินสะสมกองทุนการออมแห่งชาติเป็นสิทธิประโยชน์สำหรับ
                ภาษีเงินได้บุคคลธรรมดา
              </p>
            </div>
          }
        />
        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
          <span className="text-mainblue font-semibold ">
            *ตอนนี้ใช้ไปแล้ว {numeral(checkSum).format("0,0")} บาท
            {checkSum > 500000 && (
              <div className="text-red-500 font-semibold text-center mt-1">
                เกินสิทธิ
              </div>
            )}
          </span>
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_nsf = newForm.fund_nsf_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.fund_nsf > 13200) {
            //   return;
            // } else {
            //   const { amouth } = checkError(form);
            //   if (amouth) {
            //     return setError(true);
            //   } else {
            //     setForm({
            //       ...form,
            //       fund_nsf: Number(form.fund_nsf),
            //       fund_nsf_prev: Number(form.fund_nsf),
            //     });
            //     setOpenDrawer(true);
            //     setOpenInfo(false);
            //   }
            // }
            setForm({
              ...form,
              fund_nsf: Number(form.fund_nsf),
              fund_nsf_prev: Number(form.fund_nsf),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const FundESG = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [error, setError] = useState(false);

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_esg: output });

    setForm({
      ...form,
      fund_esg: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const maxvalue = useMemo(() => {
    return form?.tax_income * 0.3 > 300000
      ? 300000
      : form?.tax_income * 0.3;
  }, [form?.fund_esg]);

  const getPercentBar = useMemo(
    () => getPercent(form.fund_esg, maxvalue),
    [form?.fund_esg, maxvalue]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.fund_esg, maxvalue),
    [form?.fund_esg, maxvalue]
  );

  const checkError = () => {
    const amouth = form?.fund_esg > maxvalue;
    return { amouth };
  };

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG)"
          remain={displayRemain}
          value={form.fund_esg}
          maxvalue={maxvalue}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_esg > maxvalue ? true : false}
          errortext={maxvalue}
          paidPerYear={""}
          paidPerYearBorder={null}
          paidPerYearMargin={null}
          // infoPopUpHeader={"หลักเกณฑ์การหักลดหย่อน Thai ESG"}
          infoPopUpText={
            <div className="flex flex-col space-y-[10px] ">
              <p>
                <strong>
                  เงินค่าซื้อหน่วยลงทุนในกองทุนรวมไทยเพื่อความยั่งยืน (กองทุน
                  Thai ESG หรือ TESG) ใช้เป็น ค่าลดหย่อน ได้ ตามที่จ่ายจริง
                  แต่ต้องไม่เกิน 30% ของ “เงินได้ที่ต้องเสียภาษี”
                  (ไม่ว่าจะเอามารวมยื่นภาษีด้วยหรือไม่) และไม่เกิน ฿300,000
                </strong>
                โดยมีเงื่อนไขว่า ต้องถือครบ 8 ปีนับจากวันที่ซื้อ โดยเริ่มตั้งแต่
                21 พฤศจิกายน 2566 ถึง 31 ธันวาคม 2575
              </p>
              <p>
                อย่างไรก็ตาม ในปี 2567
                คณะรัฐมนตรีมีมติปรับปรุงหลักเกณฑ์ของกองทุน Thai ESG
                โดยเพิ่มสิทธิลดหย่อนขึ้นจากสูงสุด<em>ไม่เกิน ฿100,000 เป็น</em>{" "}
                <strong>ไม่เกิน ฿300,000</strong> และลดระยะเวลาถือครองจากที่ต้อง
                <em>ครบ 8 ปีนับจากวันที่ซื้อ</em>เหลือ
                <strong>เพียงครบ 5 ปีนับจากวันที่ซื้อ</strong> โดยเริ่มตั้งแต่ 1
                มกราคม 2567 ถึง 31 ธันวาคม 2569
                <em> (รอประกาศเป็นกฎหมายอย่างเป็นทางการในราชกิจจานุเบกษา)</em>
              </p>
            </div>
          }
          etc
          textetc={`
          ให้หักเป็นรายการยกเว้นในอัตราไม่เกินร้อยละ 30 ของเงินได้พึงประเมิน แต่ไม่เกิน 300,000 บาท สำหรับปีภาษีนั้น
          `}
          // textetc2={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมกองทุนการออมแห่งชาติ (กอช)  , ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF) เมื่อรวมกันต้องไม่เกิน 500,000 บาท`}
          // errorstyle1={checkSum > 500000 ? "text-red-500" : ""}
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_esg = newForm.fund_esg_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.fund_esg > 100000) {
            //   return;
            // } else {
            //   const { amouth } = checkError(form);
            //   if (amouth) {
            //     return setError(true);
            //   } else {
            //     setForm({
            //       ...form,
            //       fund_esg: Number(form.fund_esg),
            //       fund_esg_prev: Number(form.fund_esg),
            //     });
            //     setOpenDrawer(true);
            //     setOpenInfo(false);
            //   }
            // }
            setForm({
              ...form,
              fund_esg: Number(form.fund_esg),
              fund_esg_prev: Number(form.fund_esg),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const FundRMF = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [errorRMF, setErrorRMF] = useState(false);
  const balanceData = useBalanceStore((state) => state.balanceData);
  // console.log("balanceData: ",balanceData?.sum_salary_peryear)

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_provident_rmf_value: output });

    setForm({
      ...form,
      fund_provident_rmf_value: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const displaymaxvalue = useMemo(
    () => checkSSF(form.tax_income, form?.deduction_expenses),
    [form?.tax_income, form?.deduction_expenses]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.fund_provident_rmf_value, (form?.tax_income * 0.3) > 500000 ? 500000: (form?.tax_income * 0.3)),
    [form.fund_provident_rmf_value, form?.tax_income]
  );

  const getPercentBar = useMemo(
    () => getPercent(form.fund_provident_rmf_value, (form?.tax_income * 0.3) > 500000 ? 500000: (form?.tax_income * 0.3)),
    [form?.fund_provident_rmf_value, form?.tax_income]
  );

  const error = useMemo(
    () =>
      checkError(form.tax_income, 0.3, form?.fund_provident_rmf_value, 200000),
    [form.tax_income, 0.3, form?.fund_provident_rmf_value, 200000]
  );

  const checkSum = useMemo(
    () =>
      calSum5Value([
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value,
        form?.fund_GorBorKhor_value,
        form?.fund_PrivateSchoolTeacher_value,
      ]),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
      form?.fund_GorBorKhor_value,
      form?.fund_PrivateSchoolTeacher_value,
    ]
  );

  const checkErrorRMF = () => {
    const amouth = checkSum > 500000;
    return { amouth };
  };

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="ค่าซื้อหน่วยลงทุนใน"
          title2="กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)"
          remain={displayRemain}
          value={form.fund_provident_rmf_value}
          maxvalue={
            form?.tax_income * 0.3 > 500000 ? 500000 : form?.tax_income * 0.3
          }
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={
            form?.fund_provident_rmf_value > form?.tax_income * 0.3 ||
            form?.fund_provident_rmf_value > 500000
          }
          errortext={`500,000`}
          etc
          textetc={`ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (RMF) ไม่เกินร้อยละ 30 ของรายได้พึงประเมิน และไม่เกิน 500,000 บาท`}
          textetc2={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมกองทุนออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF), กองทุนสงเคราะห์ครูโรงเรียนเอกชน, กองทุนบำเหน็จบำนาญข้าราชการ เมื่อรวมกันแล้วต้องไม่เกิน 500,000 บาท`}
          // infoPopUpHeader={
          //   "หลักเกณฑ์การยกเว้นเงินได้สำหรับกองทุนรวมเพื่อการเลี้ยงชีพ (RMF)"
          // }
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <strong>
                  เงินค่าซื้อหน่วยลงทุนใน กองทุนรวมเพื่อการเลี้ยงชีพ (RMF:{" "}
                  <b>Retirement Mutual Fund</b>)
                </strong>{" "}
                ใช้เป็นค่าลดหย่อน ได้ ตามที่จ่ายจริง แต่ต้องไม่เกิน 30% ของ
                “เงินได้ที่ต้องเสียภาษี” (ไม่ว่าจะเอามารวมยื่นภาษีด้วยหรือไม่)
                และไม่เกิน ฿500,000 สำหรับคนที่ซื้อกองทุน RMF ทั้งนี้
                ไม่กำหนดจำนวนกองทุนที่ต้องซื้อขั้นต่ำ
              </p>
              <p>
                <strong>กำไรจากการขายกองทุน RMF จะได้รับยกเว้นภาษีด้วย</strong>
              </p>
              <p>
                ทั้งเงินค่าซื้อกองทุน RMF และกำไรจากการขายกองทุน RMF
                เป็นสิทธิประโยชน์สำหรับ ภาษีเงินได้บุคคลธรรมดา
              </p>
            </div>
          }
        />
        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
          <span className="text-mainblue font-semibold ">
            **ตอนนี้ใช้ไปแล้ว {numeral(checkSum).format("0,0")} บาท
            {checkSum > 500000 && (
              <div className="text-red-500 font-semibold text-center mt-1">
                เกินสิทธิ
              </div>
            )}
          </span>
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_provident_rmf_value =
                newForm.fund_provident_rmf_value_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (
            //   form?.fund_provident_rmf_value >
            //   balanceData?.sum_salary_peryear * 0.3
            // ) {
            //   return;
            // } else {
            //   const { amouth } = checkErrorRMF(form);
            //   if (amouth) {
            //     return setErrorRMF(true);
            //   } else {
            //     setForm({
            //       ...form,
            //       fund_provident_rmf_value: Number(
            //         form.fund_provident_rmf_value
            //       ),
            //       fund_provident_rmf_value_prev: Number(
            //         form.fund_provident_rmf_value
            //       ),
            //     });
            //     setOpenDrawer(true);
            //     setOpenInfo(false);
            //   }
            // }
            setForm({
              ...form,
              fund_provident_rmf_value: Number(form.fund_provident_rmf_value),
              fund_provident_rmf_value_prev: Number(
                form.fund_provident_rmf_value
              ),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img
        src="/images/bgblue.png"
        className={`w-full absolute -bottom-36 -z-10`}
      />
    </Drawer>
  );
};

export const FundSSF = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [error, setError] = useState(false);

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 200000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_provident_ssf_value: output });

    setForm({
      ...form,
      fund_provident_ssf_value: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const displaymaxvalue = useMemo(
    () => checkSSF(form.tax_income, form?.deduction_expenses),
    [form?.tax_income, form?.deduction_expenses]
  );

  const maxvalue = useMemo(() => {
    return form?.tax_income * 0.3 >= 200000 ? 200000 : form?.tax_income * 0.3;
  })

  const displayRemain = useMemo(
    () => getcalPaid(form.fund_provident_ssf_value, maxvalue),
    [form.fund_provident_ssf_value, maxvalue]
  );

  const getPercentBar = useMemo(
    () => getPercent(form.fund_provident_ssf_value, maxvalue),
    [form?.fund_provident_ssf_value, maxvalue]
  );
  const checkSum = useMemo(
    () =>
      calSum5Value([
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value,
        form?.fund_GorBorKhor_value,
        form?.fund_PrivateSchoolTeacher_value,
      ]),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
      form?.fund_GorBorKhor_value,
      form?.fund_PrivateSchoolTeacher_value,
    ]
  );

  const checkError = () => {
    const amouth = checkSum > 500000;
    return { amouth };
  };

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="ค่าซื้อหน่วยลงทุนในกองทุน"
          title2={`รวมเพื่อการออม (SSF)`}
          remain={displayRemain}
          value={form.fund_provident_ssf_value}
          maxvalue={maxvalue}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_provident_ssf_value > 200000 ? true : false}
          errortext={maxvalue}
          etc
          textetc={`ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF) ไม่เกินร้อยละ 30 ของรายได้พึงประเมิน และไม่เกิน 200,000 บาท`}
          textetc2={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมกองทุนออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF), กองทุนสงเคราะห์ครูโรงเรียนเอกชน, กองทุนบำเหน็จบำนาญข้าราชการ เมื่อรวมกันแล้วต้องไม่เกิน 500,000 บาท`}
          // infoPopUpHeader={"หลักเกณฑ์การหักลดหย่อน SSF"}
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <strong>เงินสะสมเข้ากองทุนสงเคราะห์ครูโรงเรียนเอกชน</strong>
                ใช้เป็น ค่าลดหย่อน ได้ ตามที่จ่ายจริง แต่ไม่เกิน ฿500,000
                สำหรับคนที่จ่ายเงินสะสมเข้ากองทุนสงเคราะห์ครูโรงเรียนเอกชน
              </p>
            </div>
          }
        />
        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
          <span className="text-mainblue font-semibold ">
            **ตอนนี้ใช้ไปแล้ว {numeral(checkSum).format("0,0")} บาท
            {checkSum > 500000 && (
              <div className="text-red-500 font-semibold text-center mt-1">
                เกินสิทธิ
              </div>
            )}
          </span>
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_provident_ssf_value =
                newForm.fund_provident_ssf_value_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.fund_provident_ssf_value > 200000) {
            //   return;
            // } else {
            //   const { amouth } = checkError(form);
            //   if (amouth) {
            //     return setError(true);
            //   } else {
            //     setForm({
            //       ...form,
            //       fund_provident_ssf_value: Number(
            //         form.fund_provident_ssf_value
            //       ),
            //       fund_provident_ssf_value_prev: Number(
            //         form.fund_provident_ssf_value
            //       ),
            //     });
            //     setOpenDrawer(true);
            //     setOpenInfo(false);
            //   }
            // }
            setForm({
              ...form,
              fund_provident_ssf_value: Number(form.fund_provident_ssf_value),
              fund_provident_ssf_value_prev: Number(
                form.fund_provident_ssf_value
              ),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img
        src="/images/bgblue.png"
        className={`w-full absolute -bottom-36 -z-10`}
      />
    </Drawer>
  );
};

export const HomeLoan = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 100000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, home_loan: output });

    setForm({
      ...form,
      home_loan: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.home_loan, 100000),
    [form?.home_loan, 100000]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.home_loan, 100000),
    [form?.home_loan, 100000]
  );
  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="ดอกเบี้ยเงินกู้ยืมเพื่อซื้อ"
          title2="เช่าหรือสร้างอาคารอยู่อาศัย"
          remain={displayRemain}
          value={form.home_loan}
          maxvalue={100000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-5.png"
          error={form?.home_loan > 100000 ? true : false}
          errortext={100000}
          paidPerYear="จำนวนดอกเบี้ยที่กู้ยืม"
          // infoPopUpHeader="หลักเกณฑ์การลดหย่อนดอกเบี้ยเงินกู้ยืมเพื่อที่อยู่อาศัย"
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <strong>ดอกเบี้ยบ้าน</strong>&nbsp;หรือที่เรียกชื่อเต็มว่า{" "}
                <strong>
                  ดอกเบี้ยเงินกู้ยืมเพื่อซื้อหรือสร้างที่อยู่อาศัย
                </strong>{" "}
                ใช้เป็น ค่าลดหย่อน ได้ ตามที่จ่ายจริง{" "}
                <strong>สูงสุดปีละ ฿100,000</strong>{" "}
                สำหรับคนที่กู้เงินซื้อบ้านเพื่ออยู่อาศัย ซึ่งจะเป็นบ้านเดี่ยว,
                คอนโด, ห้องชุด หรืออาคาร ก็ได้
              </p>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.home_loan = newForm.home_loan_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.home_loan > 100000) {
            //   return;
            // } else {
            //   setForm({
            //     ...form,
            //     home_loan: Number(form.home_loan),
            //     home_loan_prev: Number(form.home_loan),
            //   });
            // }
            setForm({
              ...form,
              home_loan: Number(form.home_loan),
              home_loan_prev: Number(form.home_loan_prev),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const FundSocial = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 9000) return;
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_social_security: output });

    setForm({
      ...form,
      fund_social_security: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form?.fund_social_security, 9000),
    [form?.fund_social_security, 9000]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form?.fund_social_security, 9000),
    [form?.fund_social_security, 9000]
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="เงินสมทบกองทุนประกันสังคม"
          remain={displayRemain}
          value={form?.fund_social_security}
          maxvalue={9000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_social_security > 9000 ? true : false}
          errortext={9000}
          // infoPopUpHeader="เงินสมทบกองทุนประกันสังคม"
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <strong>เงินประกันสังคม</strong>{" "}
                ใช้เป็นลดหย่อนภาษีได้ตามที่จ่ายจริง
                สำหรับคนที่จ่ายเงินสมทบเข้ากองทุนประกันสังคม
                ซึ่งปัจจุบันสามารถจ่ายเงินสะสมได้สูงสุดเดือนละ ฿750 (ตกปีละ
                ฿9,000)
              </p>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_social_security = newForm?.fund_social_security_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.fund_social_security > 9000) {
            //   return;
            // } else {
            //   setForm({
            //     ...form,
            //     fund_social_security: Number(form?.fund_social_security),
            //     fund_social_security_prev: Number(form?.fund_social_security),
            //   });
            //   setOpenDrawer(true);
            //   setOpenInfo(false);
            // }
            setForm({
              ...form,
              fund_social_security: Number(form?.fund_social_security),
              fund_social_security_prev: Number(form?.fund_social_security),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Prenatal = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 60000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, prenatal_care_cost: output });

    setForm({
      ...form,
      prenatal_care_cost: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.prenatal_care_cost, 60000),
    [form?.prenatal_care_cost, 60000]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.prenatal_care_cost, 60000),
    [form?.prenatal_care_cost, 60000]
  );
  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="ค่าฝากครรภ์และค่าคลอดบุตร"
          remain={displayRemain}
          value={form.prenatal_care_cost}
          maxvalue={60000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-4.png"
          error={form?.prenatal_care_cost > 60000 ? true : false}
          errortext={`60,000`}
          // infoPopUpHeader="ค่าฝากครรภ์และค่าคลอดบุตร"
          infoPopUpText={
            <div>
              <p>
                <strong>ค่าฝากครรภ์และค่าคลอดบุตร</strong>{" "}
                <strong>
                  ใช้เป็นค่าลดหย่อนได้ ตามที่จ่ายจริง สูงสุดท้องละ ฿60,000
                </strong>{" "}
                สำหรับค่าฝากครรภ์และค่าคลอดบุตรของตนที่จ่ายตั้งแต่ 1 ม.ค. 2561
                เป็นต้นไป
              </p>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.prenatal_care_cost = newForm.prenatal_care_cost_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.prenatal_care_cost > 60000) {
            //   return;
            // } else {
            //   setForm({
            //     ...form,
            //     prenatal_care_cost: Number(form.prenatal_care_cost),
            //     prenatal_care_cost_prev: Number(form.prenatal_care_cost),
            //   });
            // }
            setForm({
              ...form,
              prenatal_care_cost: Number(form.prenatal_care_cost),
              prenatal_care_cost_prev: Number(form.prenatal_care_cost),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Political = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, political_donate: output });

    setForm({
      ...form,
      political_donate: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.political_donate, 10000),
    [form?.political_donate, 10000]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.political_donate, 10000),
    [form?.political_donate, 10000]
  );
  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="เงินที่บริจาคแก่พรรคการเมือง"
          remain={displayRemain}
          value={form.political_donate}
          maxvalue={10000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.political_donate > 10000 ? true : false}
          errortext={`10,000`}
          // infoPopUpHeader="หลักเกณฑ์การหักลดหย่อน"
          infoPopUpText={
            <div>
              <p>
                <strong>เงินบริจาคพรรคการเมือง</strong> ใช้เป็นค่าลดหย่อนได้{" "}
                <strong>ตามที่จ่ายจริง แต่ไม่เกิน ฿10,000</strong>
                โดยเริ่มตั้งแต่ 1 ม.ค. 2561 เป็นต้นไป
              </p>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.political_donate = newForm.political_donate_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.political_donate > 60000) {
            //   return;
            // } else {
            //   setForm({
            //     ...form,
            //     political_donate: Number(form.political_donate),
            //     political_donate_prev: Number(form.political_donate),
            //   });
            // }
            setForm({
              ...form,
              political_donate: Number(form.political_donate),
              political_donate_prev: Number(form.political_donate),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Education = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const displayMaxDeductSum = useMemo(
    () =>
      getcalcRemainEducaid(
        getValueremian(form, "deduct_summary_except_education_donate")
      ),
    [getValueremian()]
  );

  const checkDisplayMaxDeductSum = useMemo(
    () => checkMinusMaxValue(displayMaxDeductSum),
    [displayMaxDeductSum]
  );

  const displayRemain = displayMaxDeductSum - form?.education_donate;

  const onEducationChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""));

    let rawOutput = output;
    let finalOutput = output * 2;

    const { result, sumResult, sumResultExceptOtherDonate } = generateSummary({
      ...form,
      education_donate: isNaN(output) ? 60000 : finalOutput,
    });

    if (isNaN(output)) {
      return setForm({
        ...form,
        education_donate: "",
        raw_education_donate: "",
        deduct_summary_except_other_donate: sumResultExceptOtherDonate - 60000,
      });
    }

    setForm({
      ...form,
      education_donate: finalOutput,
      raw_education_donate: rawOutput,
      deduct_summary: sumResult,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form?.education_donate, checkDisplayMaxDeductSum),
    [form?.education_donate, checkDisplayMaxDeductSum]
  );

  // console.log("getValueremian",getValueremian(form, "deduct_summary_except_education_donate"))
  // console.log("form",form)

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="เงินบริจาคสนับสนุนการศึกษา"
          title2={`/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ`}
          IsDisplayRemain={false}
          remain={displayRemain}
          value={form?.education_donate}
          valueDisplay={form?.raw_education_donate}
          maxvalue={checkDisplayMaxDeductSum}
          valueprogress={getPercentBar}
          onChange={onEducationChange}
          img="/images/health-insurance-3.png"
          error={false}
          errortext={false}
          infoPopUpHeader="หลักเกณฑ์การหักบริจาคเงินสนับสนุนการศึกษา/สถานพยาบาล/อื่นๆ"
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <strong>
                  เงินบริจาคพิเศษ ใช้เป็นค่าลดหย่อนได้ 2 เท่าของที่บริจาคจริง
                </strong>{" "}
                แต่ไม่เกิน 10% ของรายได้หลังหักค่าใช้จ่ายและ ค่าลดหย่อน อื่นๆ
                ซึ่งได้แก่ เงินบริจาคเพื่อการศึกษา การกีฬา และการพัฒนาสังคม
                รวมถึงเงินบริจาคให้โรงพยาบาลรัฐ ทั้งนี้ สามารถบริจาคผ่าน{" "}
                ระบบบริจาคอิเล็กทรอนิกส์ (e-Donation) ได้
              </p>
              <p>
                อัพเดตล่าสุด 2566งินบริจาคพิเศษที่ที่เกิดขึ้น ตั้งแต่ 16 สิงหาคม
                2566 เป็นต้นไป จะต้องเป็นการบริจาคผ่าน
                <strong>ระบบ e-Donation เท่านั้น</strong>
                จึงจะรับสิทธิลดหย่อนภาษี 2 เท่า
              </p>
            </div>
          }
        />
        <div className="flex justify-center text-center text-mainblue mt-2 font-medium">
          {" "}
          สิทธิที่ได้รับสองเท่า{" "}
          {numeral?.(form?.education_donate)?.format("0,0")} บาท
        </div>

        <div className={STYLE_BOX_DONATE}>
          {checkDisplayMaxDeductSum === 0 ? (
            <div>
              ขอชื่นชมที่ท่านมีจิตกุศลช่วยเหลือสังคม
              <div>แต่ท่านไม่สามารถใช้สิทธิลดหย่อนหมวดนี้ได้</div>
            </div>
          ) : (
            "ขอชื่นชมที่ท่านมีจิตกุศลช่วยเหลือสังคม"
          )}
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.education_donate = newForm.education_donate_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.education_donate > checkDisplayMaxDeductSum) {
            //   return;
            // } else {
            //   setForm({
            //     ...form,
            //     education_donate: Number(form?.education_donate),
            //     education_donate_prev: Number(form?.education_donate),
            //   });
            // }
            setForm({
              ...form,
              education_donate: Number(form?.education_donate),
              education_donate_prev: Number(form?.education_donate),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} alt="" />
    </Drawer>
  );
};

export const OtherDonate = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  // const getValueremian = () => {
  //   let income = form?.tax_income;
  //   let deductAll = form?.deduct_summary_except_other_donate;
  //   let results = income - 100000 - deductAll;
  //   // console.log("results", results);
  //   return results;
  // };

  const displayMaxDeductSum = useMemo(
    () =>
      getcalcRemainEducaid(
        getValueremian(form, "deduct_summary_except_other_donate")
      ),
    [getValueremian()]
  );

  const checkDisplayMaxDeductSum = useMemo(
    () => checkMinusMaxValue(displayMaxDeductSum),
    [displayMaxDeductSum]
  );

  const displayRemain = displayMaxDeductSum - form?.other_donate;

  const onChangeOtherDonate = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const { result, sumResult, sumResultExceptEducationDonate } =
      generateSummary({ ...form, other_donate: output });

    setForm({
      ...form,
      other_donate: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.other_donate, checkDisplayMaxDeductSum),
    [form?.other_donate, checkDisplayMaxDeductSum]
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="เงินบริจาคอื่น ๆ"
          remain={displayRemain}
          value={form?.other_donate}
          maxvalue={checkDisplayMaxDeductSum}
          valueprogress={getPercentBar}
          onChange={onChangeOtherDonate}
          IsDisplayRemain={false}
          img="/images/health-insurance-3.png"
          error={false}
          errortext={false}
          infoPopUpHeader="หลักเกณฑ์การหักลดหย่อนเงินบริจาค"
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <strong>เงินบริจาคทั่วไป</strong>{" "}
                <strong>
                  ใช้เป็นค่าลดหย่อนได้ตามที่จ่ายจริง แต่ไม่เกิน 10%
                  ของรายได้หลังหักค่าใช้จ่าย{" "}
                </strong>
                และค่าลดหย่อนอื่นๆ (รวม เงินบริจาคเพื่อการศึกษา การกีฬา
                การพัฒนาสังคม ด้วย) แล้ว
                ซึ่งโดยปกติจะเป็นเงินที่บริจาคให้แก่วัดวาอาราม
                สถานศึกษาของทางราชการ หรือองค์การของรัฐบาล สถานศึกษาเอกชน
                สถานสาธารณกุศลและกองทุนสวัสดิการภายใน ส่วนราชการและกองทุน
                โดยสามารถตรวจสอบรายชื่อองค์กรการกุศลที่มีสิทธิขอหักลดหย่อนเงินบริจาคได้ที่{" "}
                <a
                  href="http://www.rd.go.th/publish/29157.0.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.rd.go.th
                </a>{" "}
                ทั้งนี้สามารถบริจาคผ่าน ระบบบริจาคอิเล็กทรอนิกส์ (e-Donation)
                ได้
              </p>
            </div>
          }
        />
        <div className={STYLE_BOX_DONATE}>
          {checkDisplayMaxDeductSum === 0 ? (
            <div>
              ขอชื่นชมที่ท่านมีจิตกุศลช่วยเหลือสังคม
              <div>แต่ท่านไม่สามารถใช้สิทธิลดหย่อนหมวดนี้ได้</div>
            </div>
          ) : (
            "ขอชื่นชมที่ท่านมีจิตกุศลช่วยเหลือสังคม"
          )}
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.other_donate = newForm.other_donate_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.other_donate > checkDisplayMaxDeductSum) {
            //   return;
            // } else {
            //   setForm({
            //     ...form,
            //     other_donate: Number(form.other_donate),
            //     other_donate_prev: Number(form.other_donate),
            //   });
            // }
            setForm({
              ...form,
              other_donate: Number(form.other_donate),
              other_donate_prev: Number(form.other_donate),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} alt="" />
    </Drawer>
  );
};

export const Special = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (isNaN(e.target.value)) return;
    // if (Number(e.target.value) > 1000000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, special_discount_gov: output });

    setForm({
      ...form,
      special_discount_gov: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const onInsuranceChangeName = (e) => {
    setForm({ ...form, special_discount_gov_name: e.target.value });
  };
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <div className="font-medium">
              ค่าซื้อสินค้าหรือบริการ ช้อปดีมีคืน (ตั้งแต่วันที่ 1 ม.ค. 2566 -
              15 ก.พ. 2566)
            </div>
            <div className="font-normal">
              <div>
                หลักเกณฑ์การยกเว้นเงินได้สำหรับค่าซื้อสินค้าหรือบริการ
                ช้อปดีมีคืน
              </div>
              <div>
                - ค่าซื้อสินค้าหรือค่าบริการ
                ที่จ่ายให้แก่ผู้ประกอบการหรือผู้ประกอบการจดทะเบียนมูลค่าเพิ่มแล้วแต่กรณี
                สำหรับการซื้อสินค้าหรือบริการในราชอาณาจักร
                เป็นเงินได้พึงประเมินที่ได้รับยกเว้นไม่ต้องรวมคำนวณ
                เพื่อเสียภาษีเงินได้บุคคลธรรมดา ทั้งนี้ ไม่ว่าใบกำกับภาษี
                หรือใบรับจะได้จัดทำในรูปแบบกระดาษหรือโดยวิธีการทางอิเล็กทรอนิกส์
              </div>
              <div>
                - ตั้งแต่วันที่ 1 มกราคม พ.ศ. 2566 ถึงวันที่ 15 กุมภาพันธ์ พ.ศ.
                2566
              </div>
              <div>- ตามจำนวนที่จ่ายจริงแต่ไม่เกิน 40,000 บาท</div>
              <div>
                - ใบกำกับภาษีรูปแบบอิเล็กทรอนิกส์และใบกำกับรูปแบบกระดาษ
                รวมกันไม่เกิน 40,000 บาท
              </div>
              <div>- ใบกำกับรูปแบบกระดาษอย่างเดียว ไม่เกิน 30,000 บาท</div>
              <div>
                - ผู้มีเงินได้ที่ได้รับสิทธิยกเว้นภาษีเงินได้
                ต้องไม่เป็นห้างหุ้นส่วนสามัญหรือคณะบุคคลที่มิใช่นิติบุคคล
              </div>
              <div>
                - ให้เป็นไปตามหลักเกณฑ์ และเงื่อนไข ตามกฎกระทรวง ฉบับที่ 386
                (พ.ศ. 2565) และประกาศอธิบดีกรมสรรพากร เกี่ยวกับภาษีเงินได้ (
                ฉบับที่ 431)
              </div>
            </div>
          </div>
        }
      />
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <div className="mt-16">
          <div className={`${STYLE_TITLE_DRAWER} flex justify-center `}>
            สิทธิลดหย่อนพิเศษ{" "}
            <ICON_INFO
              onClick={() => {
                setOpenInfoPopUp(!openInfoPopUp);
              }}
              className={` w-4 h-4 text-maingray mt-1`}
            />
          </div>
          <div className=" text-md text-center text-mainblue font-semibold mt-1">
            จากนโยบายรัฐบาล
          </div>
          <div className="  pt-6">
            <div className={`${STYLE_CARD_BG} p-[10px] mb-5`}>
              <div className="flex justify-between gap-4 w-full ">
                <div className=" text-sm font-medium pl-[14px] text-black">
                  ระบุชื่อโครงการ
                  <div className=" mt-2">
                    <input
                      placeholder="กรุณาระบุชื่อ"
                      style={{
                        borderRadius: "6px",
                      }}
                      className={`${STYLE_INPUT_INSET} w-full`}
                      value={form.special_discount_gov_name}
                      onChange={onInsuranceChangeName}
                    />
                  </div>
                </div>
                <img
                  src={`images/health-insurance-3.png`}
                  className={STYLE_IMG_DRAWER}
                />
              </div>
            </div>
            <CardMain
              title="เบี้ยประกันที่จ่ายตลอดทั้งปี"
              panel={
                <div className="">
                  <div className=" flex justify-center items-center gap-2.5 mt-5">
                    <div>จำนวนเงิน</div>

                    <NumbericValidateRed
                      // disabled={disabled}
                      maxLength={7}
                      value={form.special_discount_gov}
                      placeholder="0"
                      onChange={onInsuranceChange}
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                      className={STYLE_INPUT_INSET}
                    />
                    <div>บาท</div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.special_discount_gov = newForm.special_discount_gov_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            setForm({
              ...form,
              special_discount_gov_name: form.special_discount_gov_name,
              special_discount_gov: Number(form.special_discount_gov),
              special_discount_gov_prev: Number(form.special_discount_gov),
            });

            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const FundGorBorKhor = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [error, setError] = useState(false);

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 200000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_GorBorKhor_value: output });

    setForm({
      ...form,
      fund_GorBorKhor_value: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const displaymaxvalue = useMemo(
    () => checkSSF(form.tax_income, form?.deduction_expenses),
    [form?.tax_income, form?.deduction_expenses]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.fund_GorBorKhor_value, 500000),
    [form.fund_GorBorKhor_value, 500000]
  );

  const getPercentBar = useMemo(
    () => getPercent(form.fund_GorBorKhor_value, 500000),
    [form?.fund_GorBorKhor_value, 500000]
  );
  const checkSum = useMemo(
    () =>
      calSum5Value([
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value,
        form?.fund_GorBorKhor_value,
        form?.fund_PrivateSchoolTeacher_value,
      ]),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
      form?.fund_GorBorKhor_value,
      form?.fund_PrivateSchoolTeacher_value,
    ]
  );

  const checkError = () => {
    const amouth = checkSum > 500000;
    return { amouth };
  };

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="กองทุนบำเหน็จบำนาญข้าราชการ"
          remain={displayRemain}
          value={form.fund_GorBorKhor_value}
          maxvalue={500000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_GorBorKhor_value > 500000 ? true : false}
          errortext={`500000`}
          etc
          textetc={`เงินสะสมเข้ากองทุนบำเหน็จบำนาญข้าราชการ (กบข.) ใช้เป็น ค่าลดหย่อน ได้ตามที่จ่ายจริง แต่ไม่เกิน ฿500,000 สำหรับคนที่จ่ายเงินสะสมเข้ากองทุนสํารองเลี้ยงชีพ`}
          textetc2={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมกองทุนออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF), กองทุนสงเคราะห์ครูโรงเรียนเอกชน, กองทุนบำเหน็จบำนาญข้าราชการ เมื่อรวมกันแล้วต้องไม่เกิน 500,000 บาท`}
          // infoPopUpHeader={"หลักเกณฑ์การหักลดหย่อน SSF"}
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <strong>เงินสะสมเข้ากองทุนบำเหน็จบำนาญข้าราชการ (กบข.)</strong>
                ใช้เป็น ค่าลดหย่อน ได้ตามที่จ่ายจริง แต่ไม่เกิน ฿500,000
                สำหรับคนที่จ่ายเงินสะสมเข้ากองทุนสํารองเลี้ยงชีพ
              </p>
            </div>
          }
        />
        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
          <span className="text-mainblue font-semibold ">
            **ตอนนี้ใช้ไปแล้ว {numeral(checkSum).format("0,0")} บาท
            {checkSum > 500000 && (
              <div className="text-red-500 font-semibold text-center mt-1">
                เกินสิทธิ
              </div>
            )}
          </span>
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_GorBorKhor_value =
                newForm.fund_GorBorKhor_value_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.fund_GorBorKhor_value > 500000) {
            //   return;
            // } else {
            //   const { amouth } = checkError(form);
            //   if (amouth) {
            //     return setError(true);
            //   } else {
            //     setForm({
            //       ...form,
            //       fund_GorBorKhor_value: Number(form.fund_GorBorKhor_value),
            //       fund_GorBorKhor_value_prev: Number(
            //         form.fund_GorBorKhor_value_prev
            //       ),
            //     });
            //     setOpenDrawer(true);
            //     setOpenInfo(false);
            //   }
            // }
            setForm({
              ...form,
              fund_GorBorKhor_value: Number(form.fund_GorBorKhor_value),
              fund_GorBorKhor_value_prev: Number(
                form.fund_GorBorKhor_value_prev
              ),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img
        src="/images/bgblue.png"
        className={`w-full absolute -bottom-36 -z-10`}
      />
    </Drawer>
  );
};

export const FundPrivateSchoolTeacher = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [error, setError] = useState(false);

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 200000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_PrivateSchoolTeacher_value: output });

    setForm({
      ...form,
      fund_PrivateSchoolTeacher_value: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const displaymaxvalue = useMemo(
    () => checkSSF(form.tax_income, form?.deduction_expenses),
    [form?.tax_income, form?.deduction_expenses]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.fund_PrivateSchoolTeacher_value, 500000),
    [form.fund_PrivateSchoolTeacher_value, 500000]
  );

  const getPercentBar = useMemo(
    () => getPercent(form.fund_PrivateSchoolTeacher_value, 500000),
    [form?.fund_PrivateSchoolTeacher_value, 500000]
  );
  const checkSum = useMemo(
    () =>
      calSum5Value([
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value,
        form?.fund_GorBorKhor_value,
        form?.fund_PrivateSchoolTeacher_value,
      ]),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
      form?.fund_PrivateSchoolTeacher_value,
    ]
  );

  // console.log("checkSum",checkSum)

  const checkError = () => {
    const amouth = checkSum > 500000;
    return { amouth };
  };
  // มม

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="กองทุนสงเคราะห์ครูโรงเรียนเอกชน"
          // title2={`รวมเพื่อการออม (SSF)`}
          remain={displayRemain}
          value={form.fund_PrivateSchoolTeacher_value}
          maxvalue={500000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_PrivateSchoolTeacher_value > 500000 ? true : false}
          errortext={`500,000`}
          etc
          textetc={`เงินสะสมเข้ากองทุนบำเหน็จบำนาญข้าราชการ (กบข.) ใช้เป็น ค่าลดหย่อน ได้ตามที่จ่ายจริง แต่ไม่เกิน ฿500,000 สำหรับคนที่จ่ายเงินสะสมเข้ากองทุนสํารองเลี้ยงชีพ`}
          textetc2={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมกองทุนออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF), กองทุนสงเคราะห์ครูโรงเรียนเอกชน, กองทุนบำเหน็จบำนาญข้าราชการ เมื่อรวมกันแล้วต้องไม่เกิน 500,000 บาท`}
          // infoPopUpHeader={"หลักเกณฑ์การหักลดหย่อน SSF"}
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                <p>
                  <strong>
                    เงินสะสมเข้ากองทุนบำเหน็จบำนาญข้าราชการ (กบข.)
                  </strong>
                  ใช้เป็น ค่าลดหย่อน ได้ตามที่จ่ายจริง แต่ไม่เกิน ฿500,000
                  สำหรับคนที่จ่ายเงินสะสมเข้ากองทุนสํารองเลี้ยงชีพ
                </p>
              </p>
            </div>
          }
        />
        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
          <span className="text-mainblue font-semibold ">
            **ตอนนี้ใช้ไปแล้ว {numeral(checkSum).format("0,0")} บาท
            {checkSum > 500000 && (
              <div className="text-red-500 font-semibold text-center mt-1">
                เกินสิทธิ
              </div>
            )}
          </span>
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_PrivateSchoolTeacher_value =
                newForm.fund_PrivateSchoolTeacher_value_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            setForm({
              ...form,
              fund_PrivateSchoolTeacher_value: Number(
                form.fund_PrivateSchoolTeacher_value
              ),
              fund_PrivateSchoolTeacher_value_prev: Number(
                form.fund_PrivateSchoolTeacher_value
              ),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img
        src="/images/bgblue.png"
        className={`w-full absolute -bottom-36 -z-10`}
      />
    </Drawer>
  );
};

export const FundCompensationMoney = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [error, setError] = useState(false);

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 200000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_CompensationMoney_value: output });

    setForm({
      ...form,
      fund_CompensationMoney_value: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const displaymaxvalue = useMemo(
    () => checkSSF(form.tax_income, form?.deduction_expenses),
    [form?.tax_income, form?.deduction_expenses]
  );

  const displayRemain = useMemo(
    () => 600000-_.get(form,"fund_CompensationMoney_value",0),
    [form.fund_CompensationMoney_value, form]
  );

  const getPercentBar = useMemo(
    () => getPercent(form.fund_CompensationMoney_value, 600000),
    [form?.fund_CompensationMoney_value, 600000]
  );
  const checkSum = useMemo(
    () =>
      calSum5Value([
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value,
        form?.fund_GorBorKhor_value,
        form?.fund_CompensationMoney_value,
      ]),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
      form?.fund_CompensationMoney_value,
    ]
  );

  // console.log("checkSum",checkSum)

  const checkError = () => {
    const amouth = checkSum > 600000;
    return { amouth };
  };
  // มม

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={8}
          title="เงินค่าชดเชยที่ได้รับตามกฎหมายแรงงาน"
          remain={displayRemain < 0 ? 0:displayRemain}
          value={form.fund_CompensationMoney_value}
          maxvalue={600000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={false}
          errortext={`600,000`}
          infoPopUpText={
            <div className="flex flex-col space-y-[10px]">
              <p>
                ค่าชดเชยตามกฎหมายว่าด้วยการคุ้มครองแรงงาน หรือตามกฎหมาย
                ว่าด้วยพนักงานรัฐวิสาหกิจสัมพันธ์
                แต่ไม่รวมค่าชดเชยที่ได้รับเพราะเหตุเกษียณอายุหรือสิ้นสุดสัญญาจ้าง
                เฉพาะค่าชดเชยส่วนที่ไม่เกินค่าจ้างของการทำงาน 400 วันสุดท้าย
                แต่ไม่เกิน 600,000 บาท *** เป็นการได้รับเงินกรณีเลิกจ้าง /
                ลูกจ้างต้องไม่สมัครใจขอลาออกจากงาน
                /ไม่รวมเกษียณและสิ้นสุดการจ้าง ***
              </p>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_CompensationMoney_value =
                newForm.fund_CompensationMoney_value_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            // if (form?.fund_CompensationMoney_value > 200000) {
            //   return;
            // } else {
            //   const { amouth } = checkError(form);
            //   if (amouth) {
            //     return setError(true);
            //   } else {
            //     setForm({
            //       ...form,
            //       fund_CompensationMoney_value: Number(
            //         form.fund_CompensationMoney_value
            //       ),
            //       fund_CompensationMoney_value_prev: Number(
            //         form.fund_CompensationMoney_value_prev
            //       ),
            //     });
            //     setOpenDrawer(true);
            //     setOpenInfo(false);
            //   }
            // }
            setForm({
              ...form,
              fund_CompensationMoney_value: Number(
                form.fund_CompensationMoney_value
              ),
              fund_CompensationMoney_value_prev: Number(
                form.fund_CompensationMoney_value_prev
              ),
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img
        src="/images/bgblue.png"
        className={`w-full absolute -bottom-36 -z-10`}
      />
    </Drawer>
  );
};

export const DrawerMain = ({
  openDrawer,
  setOpen,
  openInfo,
  setOpenInfo,
  form,
  setForm,
  setOpenDrawer,
}) => {
  return (
    <div className="">
      <DeductionDrawerMain
        open={openDrawer}
        setOpen={setOpen}
        setOpenDrawer={setOpenDrawer}
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
        form={form}
        setForm={setForm}
      />
    </div>
  );
};

const DeductionDrawerMain = ({
  open,
  setOpen,
  form,
  setForm,
  setOpenInfo,
  setOpenDrawer,
  setIshasChild,
  index,
}) => {
  const [loading, setLoading] = useState();

  const [errorEducationDonate, setErrorEducationDonate] = useState(false);
  const [errorOtherDonate, setErrorOtherDonate] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  // Max Education Donate
  const maxDeductEducationDonateSum = useMemo(
    () =>
      getcalcRemainEducaid(
        getValueremian(form, "deduct_summary_except_education_donate")
      ),
    [getValueremian()]
  );

  // Max Other Donate
  const maxDeductOtherDonateSum = useMemo(
    () =>
      getcalcRemainEducaid(
        getValueremian(form, "deduct_summary_except_other_donate")
      ),
    [getValueremian()]
  );

  const checkMaxEducationDonate = useMemo(
    () => checkMinusMaxValue(maxDeductEducationDonateSum),
    [maxDeductEducationDonateSum]
  );

  const checkMaxOtherDonate = useMemo(
    () => checkMinusMaxValue(maxDeductOtherDonateSum),
    [maxDeductOtherDonateSum]
  );

  useEffect(() => {
    // Check Education Donate
    if (form?.education_donate > checkMaxEducationDonate) {
      setErrorEducationDonate(true);
    } else {
      setErrorEducationDonate(false);
    }
    // Check Other Donate
    if (form?.other_donate > checkMaxOtherDonate) {
      setErrorOtherDonate(true);
    } else {
      setErrorOtherDonate(false);
    }
  }, [checkMaxEducationDonate, checkMaxOtherDonate]);

  useEffect(() => {
    setOpenAlert(errorEducationDonate || errorOtherDonate);
  }, [errorEducationDonate, errorOtherDonate]);

  const TitleStyle = ({
    title,
    value,
    onClick,
    error = false,
    errorValue = "*ค่าเกิน",
    disabled = false,
    hasErrorDescription = false,
    errorDescription = "",
  }) => {
    return (
      <div
        onClick={disabled ? () => {} : onClick}
        className=" border-b border-b-gray55 cursor-pointer hover:bg-secoundblue/20 duration-200"
      >
        <div
          className={cn(" flex justify-between px-6 py-2.5", {
            "pb-[0px]": hasErrorDescription && error,
          })}
        >
          <div className={`text-black text-md font-medium col-span-3`}>
            {title}{" "}
            {error && (
              <span className="text-red-500 text-sm pl-1">{errorValue}</span>
            )}
          </div>
          {value ? (
            <div
              className={` ${
                error ? "text-sm text-red-500" : "text-maincyan"
              } text-md font-medium text-right col-span-2 flex-none `}
            >
              {value}
            </div>
          ) : (
            <div className="text-maincyan text-md font-medium text-right col-span-2 flex-none">
              0 บาท
            </div>
          )}
        </div>
        {hasErrorDescription && error && errorDescription}
      </div>
    );
  };
  const { deduction, setDeduction } = useTaxPlanContext();

  const size = useWindowSize();

  // console.log("deduction",deduction)

  return (
    <Drawer
      size={size.width}
      placement="right"
      open={open}
      className=" overflow-y-scroll "
    >
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <div className="font-normal mb-[10px]">
              1.คุณสามารถกรอกค่าลดหย่อนที่ได้จ่าย ตามจริงได้เลย
              โดยระบบจะคานวณสิทธิ ลดหย่อนให้สูงสุดตามที่กฎหมายกาหนด อัตโนมัติ
            </div>
            <div className="font-normal">
              2.กรณีค่าลดหย่อน ที่ใช้สิทธิลดหย่อน รวมกัน เช่น
              กลุ่มประกันของคุณและ หน่วยลงทุนรวมกันไม่เกิน 500,000 บาท
              หากไม่ต้องการให้ระบบคานวณอัตโนมัติ
              คุณต้องคานวณค่าลดหย่อนแต่ละประเภท โดยไม่เกินสิทธิ และกรอกตัวเลขเอง
            </div>
          </div>
        }
      />
      <div className={STYLE_MAX_W_DRAWER}>
        <div className="  flex justify-center mt-10 ml-6 text-center pb-1 w-full">
          <div
            className={`${STYLE_TITLE_DRAWER} flex justify-center items-center w-fit pr-[10px] `}
          >
            เพิ่มค่าลดหย่อน
          </div>
          <ICON_INFO
            onClick={() => {
              setOpenInfoPopUp(!openInfoPopUp);
            }}
            className={` w-4 h-4 text-maingray mt-1`}
          />
        </div>
        <div
          style={{
            backgroundImage: "url(/images/circle.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundSize: "50% 50%",
            backgroundPosition: "0% ",
          }}
          className="min-h-screen "
        >
          <div className="mb-6">
            <HeaderTitle title="คุณและครอบครัว" />
            <TitleStyle
              title={`ผู้มีเงินได้`}
              value={`60,000` + " บาท"}
              onClick={() => {
                // setOpenInfo(1);
                // setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`คู่สมรส`}
              // value={numeral(form?.deduct_spouse)?.format("0,0") + " บาท"}
              value={form?.deduct_spouse > 0 ? `60,000` + " บาท" : "0 บาท"}
              onClick={() => {
                setOpenInfo(2);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ลูก`}
              value={numeral(form?.deduct_child)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(3);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`แม่`}
              value={numeral(form?.deduct_mother)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(4);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`พ่อ`}
              value={numeral(form?.deduct_father)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(5);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เลี้ยงดูคนพิการ`}
              errorValue=""
              value={
                form?.deduct_disabilities_people > 0
                  ? "ใช้สิทธิเลี้ยงดูคนทุพพลภาพแล้ว"
                  : numeral(form?.deduct_disable_people)?.format("0,0") + " บาท"
              }
              onClick={() => {
                setOpenInfo(6);
                setOpenDrawer(false);
              }}
              error={form?.deduct_disabilities_people > 0 ? true : false}
              disabled={form?.deduct_disabilities_people > 0 ? true : false}
            />
            <TitleStyle
              title={`เลี้ยงดูคนทุพพลภาพ`}
              errorValue=""
              value={
                form?.deduct_disable_people > 0
                  ? "ใช้สิทธิเลี้ยงดูคนพิการแล้ว"
                  : numeral(form?.deduct_disabilities_people)?.format("0,0") +
                    " บาท"
              }
              onClick={() => {
                setOpenInfo(7);
                setOpenDrawer(false);
              }}
              error={form?.deduct_disable_people > 0 ? true : false}
              disabled={form?.deduct_disable_people > 0 ? true : false}
            />
            <TitleStyle
              title={`เบี้ยประกันสุขภาพบิดามารดา`}
              value={
                numeral(form?.deduct_insurance_premiums_parents)?.format(
                  "0,0"
                ) + " บาท"
              }
              errorValue=""
              error={false}
              onClick={() => {
                setOpenInfo(8);
                setOpenDrawer(false);
              }}
              hasErrorDescription={true}
              errorDescription={
                <div className="w-full px-6 flex justify-between font-medium ">
                  <div>ใช้สิทธิได้</div>
                  <div className="text-maincyan">15,000 บาท </div>
                </div>
              }
            />
          </div>

          <div className="mb-6">
            <HeaderTitle title="ประกันของคุณ" />
            <TitleStyle
              title={`ประกันชีวิต`}
              errorValue=""
              value={numeral(form?.insurance_life)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(9);
                setOpenDrawer(false);
              }}
              error={false}
              hasErrorDescription={true}
              errorDescription={
                <div className="w-full px-6 flex justify-between font-medium ">
                  <div>ใช้สิทธิได้</div>
                  <div className="text-maincyan">100,000 บาท </div>
                </div>
              }
            />
            <TitleStyle
              title={`ประกันสุขภาพ `}
              value={numeral(form?.insurance_health)?.format("0,0") + " บาท"}
              error={false}
              errorValue=""
              hasErrorDescription={true}
              errorDescription={
                <div className="w-full px-6 flex justify-between font-medium ">
                  <div>ใช้สิทธิได้</div>
                  <div className="text-maincyan">
                    {100000 - form?.insurance_life < 0
                      ? 0
                      : numeral(100000 - form?.insurance_life)?.format(
                          "0,0"
                        )}{" "}
                    บาท{" "}
                  </div>
                </div>
              }
              onClick={() => {
                setOpenInfo(10);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ประกันชีวิตแบบบำนาญ`}
              value={numeral(form?.insurance_annuity)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(11);
                setOpenDrawer(false);
              }}
            />
          </div>

          <div className="mb-6">
            <HeaderTitle title="หน่วยลงทุน" />
            <TitleStyle
              title={`เงินสะสมกองทุนสำรองเลี้ยงชีพ`}
              value={numeral(form?.fund_provident)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(12);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`กองทุน กบข. `}
              value={
                numeral(form?.fund_GorBorKhor_value)?.format("0,0") + " บาท"
              }
              onClick={() => {
                setOpenInfo(24);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`กองทุนสงเคราะห์ครูเอกชน`}
              value={
                numeral(form?.fund_PrivateSchoolTeacher_value)?.format("0,0") +
                " บาท"
              }
              onClick={() => {
                setOpenInfo(25);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เงินค่าชดเชยที่ได้รับตามกฏหมายแรงงาน(กรณีนำมาคำนวนภาษี)`}
              value={
                numeral(form?.fund_CompensationMoney_value)?.format("0,0") +
                " บาท"
              }
              onClick={() => {
                setOpenInfo(26);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เงินสะสมกองทุนการออมแห่งชาติ (กอช)  `}
              value={numeral(form?.fund_nsf)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(13);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG) `}
              value={numeral(form?.fund_esg)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(14);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ค่าซื้อหน่วยลงทุนในกองทุนรวม
              เพื่อการเลี้ยงชีพ (RMF) `}
              value={
                numeral(form?.fund_provident_rmf_value)?.format("0,0") + " บาท"
              }
              onClick={() => {
                setOpenInfo(15);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ค่าซื้อหน่วยลงทุนในกองทุนรวม
              เพื่อการออม (SSF) `}
              value={
                numeral(form?.fund_provident_ssf_value)?.format("0,0") + " บาท"
              }
              onClick={() => {
                setOpenInfo(16);
                setOpenDrawer(false);
              }}
            />
          </div>

          <div className="mb-6">
            <HeaderTitle title="อื่น ๆ" />
            <TitleStyle
              title={`ดอกเบี้ยเงินกู้ยืมเพื่อ ซื้อ เช่าซื้อ 
              หรือสร้างอาคารอยู่อาศัย`}
              value={numeral(form?.home_loan)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(17);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เงินสมทบกองทุนประกันสังคม`}
              value={
                numeral(form?.fund_social_security)?.format("0,0") + " บาท"
              }
              onClick={() => {
                setOpenInfo(18);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ค่าฝากครรภ์และค่าคลอดบุตร `}
              value={numeral(form?.prenatal_care_cost)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(19);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เงินที่บริจาคแก่พรรคการเมือง `}
              value={numeral(form?.political_donate)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(20);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เงินบริจาคสนับสนุนการศึกษา / สถานพยาบาล / สภากาชาดไทย / อื่นๆ `}
              value={numeral(form?.education_donate)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(21);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เงินบริจาคอื่น ๆ`}
              // error={errorOtherDonate}
              value={numeral(form?.other_donate)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(22);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`สิทธิลดหย่อนพิเศษจาก นโยบายรัฐบาล `}
              value={
                numeral(form?.special_discount_gov)?.format("0,0") + " บาท"
              }
              onClick={() => {
                setOpenInfo(23);
                setOpenDrawer(false);
              }}
            />
          </div>

          <div className={`flex justify-center mb-6 gap-4 `}>
            <MTButton
              text="บันทึก"
              onClick={() => {
                // if (errorEducationDonate || errorOtherDonate) {
                //   return;
                // }
                const { result, sumResult, sumResultExceptEducationDonate } =
                  generateSummary(form);

                setForm((prev) => ({
                  ...prev,
                  deduct_summary: sumResult,
                  deduct_summary_except_education_donate:
                    sumResultExceptEducationDonate,
                }));
                setDeduction(result);
                setOpenDrawer(false);
              }}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default DeductionDrawerMain;
