import { useState } from "react";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  STYLE_PADDING_MAIN,
  STYLE_SPACE_GRAY_MENU,
} from "../../../utils/useStyle";
import { CardFutureCheckBox } from "../styles/Card";
import { FutureStepOne } from "./VIFuture_StepOne";
import { MTButton } from "../../../components/button/MTButton";
import { FutureStepTwo } from "./VIFuture_StepTwo";
import { FutureStepThree } from "./VIFuture_StepThree";
import { FooterAfterSum } from "../../../components/FooterAfterSum/FooterAfterSum";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import { useRetireStore } from "../../../_store/retireStore";
import _ from "lodash";
import FutureResultTable from "./FutureResultTable";
import { useBalanceStore } from "../../../_store/balanceStore";
import {
  Credit_Debt,
  Short_Term_Debt,
} from "../../../functions/futurePlan/futurePlanFunction";
import { cn } from "../../../utils/useFunctions";

const FuturePlan = ({ isPlan, setIsPlan, tab, setTab }) => {
  const retireData = useRetireStore((state) => state.retireData);
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const setFuturePlanData = useFuturePlanStore(
    (state) => state.setFuturePlanData
  );

  const balanceData = useBalanceStore((state) => state?.balanceData);
  const setBalanceData = useBalanceStore((state) => state?.setBalanceData);

  const [displaySumary, setDisplaySumary] = useState(false);

  const calculateCashFlow = () => {
    let cash_flow_items = [];
    let future_keys = Object.keys(futurePlanData);
    let diff_age = futurePlanData?.age_dead - futurePlanData?.age_current;
    let out_come_stable = futurePlanData?.out_come_stable;
    let sum_expenese_total = 0;
    let sum_income_total = 0;

    const futureMoreKeys = [];
    for (const future_key of future_keys) {
      if (
        futurePlanData[future_key]?.length > 0 &&
        future_key !== "cash_flow_items" &&
        future_key !== "cash_flow_keys"
      ) {
        futureMoreKeys.push(future_key);
      }
    }

    for (let index = 0; index < diff_age + 1; index++) {
      let createItem = {};

      const prev_remain = index === 0 ? 0 : cash_flow_items[index - 1].remain;
      const prev_remain_accumulate =
        index === 0 ? 0 : cash_flow_items[index - 1].remain_accumulate;

      const re_salary_peryear = futurePlanData?.salary * 12;
      const re_outcome_peryear = futurePlanData?.out_come * 12;
      const age = futurePlanData?.age_current + index;

      // -----หนี้ระยะสั้น--------
      const debt_short_list = Short_Term_Debt(
        balanceData?.debt_short_list?.find((item) => {
          return item?.options_debt_short === "debt_shortterm";
        }),
        index,
        diff_age + 1
      );
      const debt_creditCard = Credit_Debt(
        balanceData?.debt_short_list?.find((item) => {
          return item?.options_debt_short === "debt_creditcard";
        }),
        index,
        diff_age + 1
      );

      // console.log("debt_creditCard", debt_creditCard);
      // console.log("debt_short_list", debt_short_list);

      let pension_peryear =
        age < futurePlanData?.age_retire + 1
          ? 0
          : futurePlanData?.pension_peryear;

      const income_peryear =
        age > futurePlanData?.age_retire
          ? 0
          : re_salary_peryear *
            (1 + futurePlanData?.salary_peryear / 100) ** index;

      const outcome_peryear =
        age > futurePlanData?.age_retire
          ? re_outcome_peryear *
            0.7 *
            (1 + futurePlanData?.out_come_peryear / 100) ** index
          : re_outcome_peryear *
            (1 + futurePlanData?.out_come_peryear / 100) ** index;

      const income_investment =
        index === 0
          ? (futurePlanData?.income_investment *
              futurePlanData?.income_investment_percent) /
            100
          : age > futurePlanData?.age_retire
          ? (prev_remain_accumulate *
              (futurePlanData?.income_investment_percent - 1)) /
            100
          : (prev_remain_accumulate *
              futurePlanData?.income_investment_percent) /
            100;

      const remain =
        income_peryear +
        pension_peryear -
        (outcome_peryear + out_come_stable * 12) -
        debt_short_list.pay_sum_alls[index] -
        debt_creditCard.pay_sum_alls[index];

      const remain_accumulate =
        index === 0
          ? futurePlanData?.income_investment +
            income_investment +
            prev_remain +
            remain
          : prev_remain_accumulate + income_investment + remain;

      if (age <= futurePlanData?.age_retire) {
        createItem = {
          index,
          age,
          income_peryear,
          outcome_peryear,
          remain,
          remain_accumulate,
          pension_peryear: 0,
          out_come_stable: out_come_stable * 12,
          outcome_total: out_come_stable * 12 + outcome_peryear,
          income_investment,
          debt_short_list,
          debt_creditCard,
        };
      } else {
        createItem = {
          index,
          age,
          income_peryear: 0,
          outcome_peryear,
          remain,
          remain_accumulate,
          pension_peryear,
          out_come_stable: out_come_stable * 12,
          outcome_total: out_come_stable * 12 + outcome_peryear,
          income_investment,
          debt_short_list,
          debt_creditCard,
        };
      }

      const itemKeys = Object.keys(createItem);

      for (const itemKey of itemKeys) {
        if (_.isNaN(createItem[itemKey])) {
          createItem[itemKey] = 0;
        }
      }

      for (const futureMoreKey of futureMoreKeys) {
        if (
          [`expenese_home_items`, `expenese_car_items`].includes(futureMoreKey)
        ) {
          const year = futurePlanData[futureMoreKey][0]?.year;
          const end_year =
            futurePlanData[futureMoreKey][0]?.year +
            futurePlanData[futureMoreKey][0]?.period -
            1;

          let item_more_amount =
            futurePlanData[futureMoreKey][0]?.sum_amount_per_period;

          if (index >= year && end_year >= index) {
            const prevItem = index === 0 ? 0 : cash_flow_items[index - 1];

            if (!prevItem?.[futureMoreKey]) {
              item_more_amount += futurePlanData[futureMoreKey][0]?.down;
            }
            createItem = {
              ...createItem,
              [futureMoreKey]: item_more_amount,
            };
          } else {
            createItem = {
              ...createItem,
              [futureMoreKey]: 0,
            };
          }
        } else {
          const year = futurePlanData[futureMoreKey][0]?.year;

          if (index === year) {
            let item_more_amount = futurePlanData[futureMoreKey].reduce(
              (acc, item) => {
                return acc + item?.amount;
              },
              0
            );
            createItem = {
              ...createItem,
              [futureMoreKey]: item_more_amount,
            };
          } else {
            createItem = {
              ...createItem,
              [futureMoreKey]: 0,
            };
          }
        }
      }
      cash_flow_items.push(createItem);
    }

    const negativeLists = [
      "out_come_stable",
      "outcome_peryear",
      "expenese_car_items",
      "expenese_children_items",
      "expenese_travel_items",
      "expenese_collection_items",
      "expenese_other_items",
    ];
    const possitiveLists = [
      "income_legacy_items",
      "income_business_items",
      "income_property_items",
      "income_other_items",
      "income_peryear",
      "remain_accumulate",
    ];

    for (const futureMoreKey of futureMoreKeys) {
      if (negativeLists.includes(futureMoreKey)) {
        sum_expenese_total = cash_flow_items.reduce((acc, item) => {
          return acc + item[futureMoreKey];
        }, 0);
      } else if (possitiveLists.includes(futureMoreKey)) {
        sum_income_total = cash_flow_items.reduce((acc, item) => {
          return acc + item[futureMoreKey];
        }, 0);
      }
    }

    let sum_diff_total = sum_income_total - sum_expenese_total;

    setFuturePlanData({
      ...futurePlanData,
      sum_expenese_total,
      sum_income_total,
      sum_diff_total,
      cash_flow_keys: futureMoreKeys,
      cash_flow_items,
    });
  };

  const checkLengthIncomeOutcome = () => {
    let checkLength =
      futurePlanData?.expenese_home_items?.length > 0 ||
      futurePlanData?.expenese_car_items?.length > 0 ||
      futurePlanData?.expenese_children_items?.length > 0 ||
      futurePlanData?.expenese_travel_items?.length > 0 ||
      futurePlanData?.expenese_collection_items?.length > 0 ||
      futurePlanData?.expenese_other_items?.length > 0 ||
      futurePlanData?.income_legacy_items?.length > 0 ||
      futurePlanData?.income_business_items?.length > 0 ||
      futurePlanData?.income_property_items?.length > 0 ||
      futurePlanData?.income_other_items?.length > 0 ||
      futurePlanData?.expenese_children_education?.length > 0;
    return !checkLength;
    // return false
  };

  return (
    <div className={`${STYLE_SPACE_GRAY_MENU} ${STYLE_PADDING_MAIN}`}>
      {tab === 0 && <FutureStepOne />}
      {tab === 1 && <FutureStepTwo />}
      {tab === 2 && <FutureStepThree />}
      <div className=" flex justify-center gap-4 mb-10 px-5">
        {tab === 2 ? (
          <div className="flex space-x-[20px] ">
            <MTButton
              text="ดูรายละเอียด"
              color="border border-maincyan "
              colortext={
                displaySumary
                  ? "text-white"
                  : "text-maincyan group-hover:text-white"
              }
              hovercolor={
                displaySumary ? "bg-maincyan" : "hover:bg-maincyan group "
              }
              onClick={() => {
                setDisplaySumary(!displaySumary);
              }}
            />
            <MTButton
              text="ย้อนกลับ"
              color="border border-maincyan "
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
              onClick={() => {
                if (checkLengthIncomeOutcome()) {
                  setTab((prev) => prev - 2);
                  return;
                }
                setDisplaySumary(false);
                setTab((prev) => prev - 1);
              }}
            />
          </div>
        ) : (
          <div className=" flex justify-center items-center gap-2">
            <MTButton
              text="ย้อนกลับ"
              color="border border-maincyan "
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
              onClick={() => {
                if (tab === 0) {
                  setIsPlan(false);
                }

                if (checkLengthIncomeOutcome()) {
                  setTab((prev) => prev - 2);
                  return;
                }

                setDisplaySumary(false);
                setTab((prev) => prev - 1);
              }}
            />
            <MTButton
              text={"ถัดไป"}
              color="bg-maincyan"
              onClick={(e) => {
                if (tab === 0) {
                  calculateCashFlow();
                }
                if (tab === 1) {
                  calculateCashFlow();
                }

                if (checkLengthIncomeOutcome()) {
                  setTab((prev) => (tab === 2 ? false : prev + 2));
                  return;
                }

                setTab((prev) => (tab === 2 ? false : prev + 1));
              }}
            />
          </div>
        )}
      </div>
      {tab === 2 && (
        <>
          <FooterAfterSum />
        </>
      )}
      {tab === 2 && <div
        className={cn("min-w-screen flex justify-center mt-[32px] ", {
          "hidden":  !(displaySumary && tab === 2),
        })}
      >
        <FutureResultTable tab={tab} displaySumary={displaySumary}/>
      </div>}
    </div>
  );
};
export default FuturePlan;
