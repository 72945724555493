import numeral from "numeral";
import React from "react";
import Container from "../../../components/layout/Container";
import { LeavingCalc } from "../../../functions/taxFucntion/premium/salaryIncome";
import { cn } from "../../../utils/useFunctions";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_PADDING
} from "../../../utils/useStyle";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import { PdfHeaderText } from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE
} from "../styles/pdf.style";

const QuitJobIncomePdf = ({ page, taxData = {} }) => {
  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="วางแผนภาษี"
        icon="/images/tax_plan.svg"
        classNameIcon="w-[24px] h-[27px]"
      />

      <Container classnameInner={""}>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <div className={STYLE_PARENT_PADDING}>
            <div className=" bg-gray50 rounded-[10px] py-4 px-2.5">
              <div className=" flex justify-between items-center">
                <div className=" text-black text-sm font-semibold">
                  สรุปการคำนวณภาษีเงินได้สุทธิ
                </div>
                <div className=" text-maincyan text-xl font-medium">
                  {" "}
                  {numeral(
                    LeavingCalc(
                      taxData?.tax_QuitJob?.year_work,
                      taxData?.tax_QuitJob?.pension_money,
                      taxData?.tax_QuitJob?.fund_money,
                      taxData?.tax_QuitJob?.Compensation_money,
                      taxData?.tax_QuitJob?.single_pay_money,
                      taxData?.tax_QuitJob?.calculate_method,
                      taxData?.tax_QuitJob?.salary_from_employer,
                      taxData?.tax_QuitJob?.year_from_employer,
                      taxData?.tax_QuitJob?.calc_default,
                      taxData?.tax_QuitJob?.with_holding_tax
                    )?.totalIcome
                  )?.format("0,0")}{" "}
                  บาท
                </div>
              </div>
              <div className=" flex justify-between items-center">
                <div className=" text-black text-sm font-semibold">
                  ภาษีคำนวณจากเงินได้สุทธิ
                </div>
                <div className=" text-maincyan text-xl font-medium">
                  {" "}
                  {numeral(
                    LeavingCalc(
                      taxData?.tax_QuitJob?.year_work,
                      taxData?.tax_QuitJob?.pension_money,
                      taxData?.tax_QuitJob?.fund_money,
                      taxData?.tax_QuitJob?.Compensation_money,
                      taxData?.tax_QuitJob?.single_pay_money,
                      taxData?.tax_QuitJob?.calculate_method,
                      taxData?.tax_QuitJob?.salary_from_employer,
                      taxData?.tax_QuitJob?.year_from_employer,
                      taxData?.tax_QuitJob?.calc_default,
                      taxData?.tax_QuitJob?.with_holding_tax
                    )?.taxCalc
                  )?.format("0,0")}{" "}
                  บาท
                </div>
              </div>
              <div className=" flex justify-between items-center">
                <div className=" text-black text-sm font-semibold">
                  ภาษีที่ชำระไว้เกิน
                </div>
                <div className=" text-maincyan text-xl font-medium">
                  {" "}
                  {numeral(
                    LeavingCalc(
                      taxData?.tax_QuitJob?.year_work,
                      taxData?.tax_QuitJob?.pension_money,
                      taxData?.tax_QuitJob?.fund_money,
                      taxData?.tax_QuitJob?.Compensation_money,
                      taxData?.tax_QuitJob?.single_pay_money,
                      taxData?.tax_QuitJob?.calculate_method,
                      taxData?.tax_QuitJob?.salary_from_employer,
                      taxData?.tax_QuitJob?.year_from_employer,
                      taxData?.tax_QuitJob?.calc_default,
                      taxData?.tax_QuitJob?.with_holding_tax
                    )?.excessTax
                  )?.format("0,0")}{" "}
                  บาท
                </div>
              </div>
              <div className=" flex justify-between items-center">
                <div className=" text-black text-sm font-semibold">
                  ภาษีที่ต้องชำระเพิ่ม
                </div>
                <div className=" text-maincyan text-xl font-medium">
                  {" "}
                  {numeral(
                    LeavingCalc(
                      taxData?.tax_QuitJob?.year_work,
                      taxData?.tax_QuitJob?.pension_money,
                      taxData?.tax_QuitJob?.fund_money,
                      taxData?.tax_QuitJob?.Compensation_money,
                      taxData?.tax_QuitJob?.single_pay_money,
                      taxData?.tax_QuitJob?.calculate_method,
                      taxData?.tax_QuitJob?.salary_from_employer,
                      taxData?.tax_QuitJob?.year_from_employer,
                      taxData?.tax_QuitJob?.calc_default,
                      taxData?.tax_QuitJob?.with_holding_tax
                    )?.moreTax
                  )?.format("0,0")}{" "}
                  บาท
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <PdfFooter page={page} />
    </div>
  );
};

export default QuitJobIncomePdf;
