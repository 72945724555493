import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import NavigateGoBack from "../../../functions/Navigate.Goback";
import {
  STYLE_CARD_BG,
  STYLE_FLEX_STATUS,
  STYLE_HEAD_STRAGADY,
  STYLE_MARGIN_TOP_PARENT,
} from "../../../utils/useStyle";
import { ImageMain } from "../../../components/img/Img.Main";
import { useRetireStore } from "../../../_store/retireStore";
import LineChart2Main from "../../../components/chart/LineChart2Main";
import LineChartMain from "../../../components/chart/LineChartMain";
import { dataAgeAF } from "../functions/functionRetriementPlan";
import { ICON_CHECK, ICON_CLOSE, ICON_WARNING } from "../../../utils/useIcons";
import { useIsMobile } from "../../../utils/useIsMobile";
import { FooterAfterSum } from "../../../components/FooterAfterSum/FooterAfterSum";
import { useEffect, useState } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { MTButton } from "../../../components/button/MTButton";
import {
  lastResultInvestmentAssetsAFSensitive,
  loopInvestmentAssetsAFSensitive,
} from "../functions/functionRetrementSensitivePlan";
import numeral from "numeral";
import { Checkbox } from "@material-tailwind/react";

const ViewSensitiveTest = () => {
  const retireData = useRetireStore((state) => state.retireData);
  const setRetireData = useRetireStore((state) => state.setRetireData);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [retireSensitiveData, setRetireSensitiveData] = useState([]);

  const dataPlanSensitive = retireSensitiveData?.map((item) => {
    return item?.results;
  });

  const lastResultInvest = lastResultInvestmentAssetsAFSensitive();

  // หาอายุสุดท้าย
  const getAgeSensitive = retireSensitiveData?.filter((item) => {
    if (item?.results > 0) return item;
  });
  const ageSensitive = getAgeSensitive?.[getAgeSensitive?.length - 1]?.age;

  useEffect(() => {
    setRetireSensitiveData(loopInvestmentAssetsAFSensitive());
  }, [retireData]);

  const onChangeCheckImagine = (e) => {
    setRetireData({ ...retireData, [e.target.name]: e.target.checked });
  };

  // console.log("dataPlanSensitive", dataPlanSensitive);

  return (
    <div id="first_reload">
      <Container>
        <HeaderTitle
          title="ทดสอบความอ่อนไหว"
          isGoback
          onClickGoBack={() => {
            setRetireSensitiveData([]);
            NavigateGoBack(navigate);
          }}
        />
        <div className={`${STYLE_MARGIN_TOP_PARENT} mb-10 px-6  `}>
          <div className=" mb-[18px]">
            {/*---------- ผลลัพธ์ ----------*/}
            {lastResultInvest > 0 ? (
              <div className=" text-center text-black text-md flex flex-col gap-[9px]">
                <ImageMain
                  src="/images/goodface.svg"
                  className="w-18 h-18 mx-auto"
                />
                <div>
                  <div>ยินดีด้วย...</div>
                  ท่านสามารถใช้ชีวิตหลังเกษียณได้ตามที่ท่านตั้งใจไว้
                </div>
              </div>
            ) : (
              <div className=" text-center text-black text-md flex flex-col gap-[9px]">
                <ImageMain
                  src="/images/badface.svg"
                  className="w-18 h-18 mx-auto"
                />
                <div>
                  ไม่นะ..เมื่อท่านอายุ {retireData?.age_dead} ปี
                  <div>เงินของท่านจะหมดลง ก่อนสิ้นอายุขัย</div>
                </div>
              </div>
            )}
          </div>

          {/*---- ความเคลื่อนไหวทางการเงิน ----*/}
          <div className={`${STYLE_CARD_BG} mb-[18px]`}>
            <div className=" flex flex-col gap-2 mb-[8px] ">
              <div className={STYLE_HEAD_STRAGADY}>
                ความเคลื่อนไหวทางการเงิน 
              </div>
              <div className={`border-b border-mainyellow w-[295px] `}></div>
            </div>
            <div className="px-4">
              <LineChartMain
                labels={dataAgeAF()}
                dataPlan={dataPlanSensitive}
                head={`สินทรัพย์เพื่อการลงทุนสุทธิ`}
              />
            </div>
            <div className=" mt-[26px] mb-6 space-y-2.5 px-2">
              <div className={STYLE_FLEX_STATUS}>
                <ICON_CHECK className=" w-6 text-maincyan flex-none " />
                <div className="  text-sm flex-none">
                  เริ่มวางแผนเกษียณตอนอายุ{" "}
                  <span className="font-medium">
                    {retireData?.age_current} ปี
                  </span>
                </div>
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_WARNING className=" w-6 text-mainyellow" />
                <div className="  text-sm flex-none">
                  สามารถใช้จนได้ถึงอายุ
                  <span className=" font-medium"> {ageSensitive} ปี </span>
                </div>
              </div>
              {/* <div className={STYLE_FLEX_STATUS}>
                <ICON_CLOSE className=" w-6 text-mainred" />
                {isMobile ? (
                  <div className="  text-sm flex-none">
                    <div>
                      ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                      <span className=" font-medium">
                        {retireData?.age_dead} ปี
                      </span>{" "}
                    </div>
                    ยังขาดอยู่ทั้งหมด{" "}
                    <span className=" font-medium text-mainblue">
                      {lastResultInvest < 0
                        ? numeral(lastResultInvest * -1)?.format("0,0")
                        : numeral(lastResultInvest)?.format("0,0")}
                      {""} บาท
                    </span>
                  </div>
                ) : (
                  <div className="  text-sm flex-none">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                    <span className=" font-medium">
                      {retireData?.age_dead} ปี
                    </span>{" "}
                    ยังขาดอยู่ทั้งหมด{" "}
                    <span className=" font-medium text-mainblue">
                      {lastResultInvest < 0
                        ? numeral(lastResultInvest * -1)?.format("0,0")
                        : numeral(lastResultInvest)?.format("0,0")}
                      {""} บาท
                    </span>
                  </div>
                )}
              </div> */}
              <MTButton
                width="w-full"
                onClick={() => navigate("/summarytablemovements?sensitive")}
                text="ดูรายละเอียด"
                color="border-maincyan bg-white"
                colortext="text-maincyan group-hover:text-white font-medium"
                hovercolor="hover:bg-maincyan group "
                variant="outlined"
              />
            </div>
          </div>

          {/*---------- อธิบายทดสอบความอ่อนไหว ----------*/}
          <div className={`${STYLE_CARD_BG} mb-[18px]`}>
            <div className=" flex flex-col gap-2 mb-[8px] ">
              <div className={STYLE_HEAD_STRAGADY}>สถานการณ์สมมติ</div>
              <div className={`border-b border-mainyellow w-[295px] `}></div>
            </div>
            <div className=" mt-[26px] mb-6 space-y-2.5 px-2">
              <div className="flex space-x-3">
                <Checkbox
                  size={"sm"}
                  containerProps={{
                    className: "p-1",
                  }}
                  ripple="false"
                  color="cyan"
                  name="check_imagine_one"
                  checked={retireData?.check_imagine_one}
                  onChange={onChangeCheckImagine}
                />
                <div className={STYLE_FLEX_STATUS}>
                  <ImageMain
                    src="/images/assetdown.svg"
                    className="w-6 h-6 flex-none"
                  />
                  <div className="  text-sm flex-none">
                    สถานการณ์ที่ 1
                    <div className="  text-sm ">
                      เศรษฐกิจถดถอย ผลตอบแทนการลงทุน
                      <div>
                        หลังเกษียณ เหลือเพียง{" "}
                        <span className=" font-semibold text-red300">1 %</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex space-x-3">
                <Checkbox
                  size={"sm"}
                  containerProps={{
                    className: "p-1",
                  }}
                  color="cyan"
                  name="check_imagine_two"
                  checked={retireData?.check_imagine_two}
                  onChange={onChangeCheckImagine}
                />
                <div className={STYLE_FLEX_STATUS}>
                  <ImageMain
                    src="/images/heart_medical.svg"
                    className="w-6 h-6 flex-none"
                  />
                  <div className="  text-sm ">
                    สถานการณ์ที่ 2
                    <div className="  text-sm ">
                      เจ็บป่วยโรคร้ายแรงมีค่ารักษาพยาบาล
                      <div>
                        1 ล้านบาท ตอนอายุ{" "}
                        <span className=" font-semibold text-mainorange">
                          {retireData?.age_current + 5}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex space-x-3">
                <Checkbox
                  size={"sm"}
                  containerProps={{
                    className: "p-1",
                  }}
                  color="cyan"
                  name="check_imagine_three"
                  checked={retireData?.check_imagine_three}
                  onChange={onChangeCheckImagine}
                />
                <div className={STYLE_FLEX_STATUS}>
                  <ImageMain
                    src="/images/moneybag.svg"
                    className="w-6 h-6 flex-none"
                  />
                  <div className="  text-sm ">
                    สถานการณ์ที่ 3
                    <div className="  text-sm ">
                      ค้ำประกันให้ผู้อื่น เกิดหนี้ 5 ล้านบาท{" "}
                      <div>
                        ตอนอายุ{" "}
                        <span className=" font-semibold text-maingreen">
                          {retireData?.age_current + 10}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*--------- รอด / ไม่รอด ----------*/}
          {lastResultInvest > 0 ? (
            <div className=" flex flex-col justify-center mt-[18px]">
              <div className=" flex  justify-center items-center gap-[3px]">
                <ImageMain src="../images/taxicon3.png" className="w-8 h-8" />
                <div className=" text-mainblue font-semibold text-xl">
                  ยอดเยี่ยม
                </div>
              </div>
              <div className=" text-mainblue text-center text-sm mt-2">
                ถ้าเกิดสถานการณ์เหล่านี้ขึ้นจริง
                <div> การปรับกลยุทธ์เกษียณของคุณเป็นแผนที่ยอดเยี่ยม</div>
                <div>แต่ก็อย่าประมาทล่ะ</div>
              </div>
            </div>
          ) : (
            <div className=" flex flex-col justify-center mt-[18px]">
              <div className=" flex  justify-center items-center gap-[3px]">
                <ImageMain src="../images/badface_2.png" className="w-8 h-8" />
                <div className=" text-mainblue font-semibold text-xl">
                  ยังไม่ปลอดภัย
                </div>
              </div>
              <div className=" text-mainblue text-center text-sm mt-2">
                ถ้าเกิดสถานการณ์เหล่านี้ขึ้นจริง
                <div>การปรับกลยุทธ์เกษียณของคุณยังไม่ปลอดภัยเท่าที่ควร</div>
              </div>
            </div>
          )}

          <div className=" my-[18px]">
            <MTButton
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
              width="w-full"
              onClick={() => {
                setRetireSensitiveData([]);
                NavigateGoBack(navigate);
              }}
            />
          </div>

          <FooterAfterSum />
        </div>
      </Container>
    </div>
  );
};

export default ViewSensitiveTest;
