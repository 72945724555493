import { Checkbox } from "@material-tailwind/react";
import CheckBoxMain from "../../../components/checkbox/CheckboxMain";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  ButtonCounter,
  DeleteButtonCard,
} from "../../../components/button/MTButton";
import { ICON_ADD, ICON_DELETE } from "../../../utils/useIcons";
import { NumericFormat } from "react-number-format";
import {
  STYLE_INPUT,
  STYLE_INPUT_BORDER,
  STYLE_INPUT_CARD_BALANCE,
  STYLE_INPUT_SLIDER,
  STYLE_LABEL_FUTURE,
  STYLE_PARENT_CARD_BALANCE,
  STYLE_TEXT_BLUE,
  STYLE_TEXT_CYAN,
  STYLE_TITLE_CARD_DRAWER_BL,
} from "../../../utils/useStyle";
import { SliderSingle } from "../../../components/slider/SliderSingle";
import { InputMain } from "../../../components/input/InputMain";
import numeral, { _ } from "numeral";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";
import { CalcAllChildExpense } from "../../../functions/futurePlan/futurePlanFunction";
import {
  key_under_education,
  options_under_education,
} from "../../education/functions/options";
import { RadioMain } from "../../../components/radio/Radio.Main";

export const CardFutureCheckBox = ({
  checked,
  icon,
  icon_width = " w-6 h-6",
  label,
  onChange,
}) => {
  return (
    <div className=" bg-white rounded-[10px] px-5 py-2.5 flex  items-center ">
      <Checkbox
        onChange={onChange}
        // onClick={() => {
        //   setOpen(value);
        // }}
        containerProps={{
          className: "p-0",
        }}
        size={"sm"}
        className="p-0"
        color="cyan"
        checked={checked}
        label={
          <div className="  flex items-center gap-2.5 ml-2 ">
            {icon && <img src={icon} className={icon_width} alt="" />}
            <div className=" text-md md:text-lg text-mainblue  font-medium  hover:none ">
              {label}
            </div>
          </div>
        }
        ripple={false}
      />
    </div>
  );
};

export const CardFuture = ({
  title,
  text_color = "text-black",
  icon,
  onChangeForm,
  item,
  primary_key,
  index,
  deleteForm,
}) => {
  return (
    <div className="">
      <div className=" flex items-center gap-2 px-3 py-2 bg-[#f6f6f6] text-mainblue font-semibold rounded-t-[20px]">
        <ImageMain src={icon} className=" w-6 h-6 " />
        <div> ซื้อ{title}</div>
        <button
          onClick={() => {
            deleteForm(primary_key);
          }}
          className="ml-auto"
        >
          <ICON_DELETE className=" w-5 h-5 text-mainred " />
        </button>
      </div>
      <div
        className={`bg-[#fcfcfc] rounded-b-[20px] px-3 py-2 ${text_color} text-sm w-full h-full space-y-4 `}
      >
        {/* ------ คาดการณ์ไว้อีกกี่ปีข้างหน้า ------ */}

        <div className="">
          <div>
            <div className={STYLE_LABEL_FUTURE}>
              คาดการณ์ไว้อีกกี่ปีข้างหน้า
            </div>
            <div className=" flex justify-center">
              <NumericFormat
                maxLength={11}
                placeholder="0"
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className={STYLE_INPUT_SLIDER}
                onChange={onChangeForm(primary_key, "year", index)}
                value={item?.year}
              />
            </div>
            <SliderSingle
              year={true}
              onChange={onChangeForm(primary_key, "year", index)}
              value={item?.year}
              valuemax={25}
            />
          </div>
        </div>

        {/* ------ เงินดาวน์ ------ */}
        <div>
          <div className={STYLE_LABEL_FUTURE}>เงินดาวน์</div>
          <NumbericValidateRed
            maxLength={11}
            placeholder="0"
            thousandSeparator=","
            inputMode="decimal"
            pattern="[0-9],*"
            className={STYLE_INPUT_BORDER}
            onChange={onChangeForm(primary_key, "down", index)}
            value={item?.down}
          />
        </div>
        {/* ------ ออมเงิน/ลงทุน เพื่อเงินดาวน์ได้ดอกเบี้ย (ต่อปี) ------ */}
        <div>
          <div className={STYLE_LABEL_FUTURE}>
            ออมเงิน/ลงทุน เพื่อเงินดาวน์{title}
            <div>ผลตอบแทน (ต่อปี)</div>
          </div>
          <div className=" flex justify-center">
            <NumbericValidateRed
              placeholder="0"
              className={STYLE_INPUT_SLIDER}
              onChange={onChangeForm(
                primary_key,
                "invest_down_interest",
                index
              )}
              value={item?.invest_down_interest}
            />
          </div>
          <SliderSingle
            year={false}
            onChange={onChangeForm(primary_key, "invest_down_interest", index)}
            value={item?.invest_down_interest}
            valuemax={25}
          />
          <div className=" mt-2">
            <div className={`${STYLE_LABEL_FUTURE} text-center`}>
              มีเวลาออม {item?.year} ปี
            </div>
            <div className={`${STYLE_TEXT_CYAN} text-center text-xl`}>
              ต้องออมปีละ {numeral(item?.sum_invest_five).format("0,0")} บาท
            </div>
          </div>
        </div>

        {/* ------ มูลค่า ------ */}
        <div>
          <div className={STYLE_LABEL_FUTURE}>มูลค่า{title} (บาท)</div>
          <NumbericValidateRed
            // type="number"
            maxLength={11}
            placeholder="0"
            thousandSeparator=","
            inputMode="decimal"
            pattern="[0-9],*"
            className={STYLE_INPUT_BORDER}
            onChange={onChangeForm(primary_key, "amount", index)}
            value={item?.amount}
          />
        </div>
        {/* ------ ดอกเบี้ย (ต่อปี) ------ */}
        <div>
          <div className={STYLE_LABEL_FUTURE}>ดอกเบี้ย{title} (ต่อปี)</div>
          <div className=" flex justify-center">
            <NumbericValidateRed
              // type="number"
              maxLength={11}
              placeholder="0"
              className={STYLE_INPUT_SLIDER}
              onChange={onChangeForm(primary_key, "interest", index)}
              value={item?.interest}
            />
          </div>
          <SliderSingle
            year={false}
            onChange={onChangeForm(primary_key, "interest", index)}
            value={item?.interest}
            valuemax={25}
          />
        </div>
        {/* ------ ระยะเวลาที่ต้องการผ่อน ------ */}
        <div>
          <div className={STYLE_LABEL_FUTURE}>ระยะเวลาที่ต้องการผ่อน</div>
          <div className=" flex justify-center">
            <NumbericValidateRed
              // type="number"
              maxLength={11}
              placeholder="0"
              className={STYLE_INPUT_SLIDER}
              onChange={onChangeForm(primary_key, "period", index)}
              value={item?.period}
            />
          </div>
          <SliderSingle
            year={true}
            onChange={onChangeForm(primary_key, "period", index)}
            value={item?.period}
            valuemax={50}
          />
          <div className=" mt-2">
            <div className={`${STYLE_LABEL_FUTURE} text-center`}>
              ต้องชำระงวดละ (บาท)
            </div>
            <div className={`${STYLE_TEXT_CYAN} text-center text-xl`}>
              {numeral(item?.sum_amount_per_period).format("0,0")} บาท
            </div>
          </div>
        </div>

        {/* เจ้าหนี้ */}
        <div className="bg-gray-200 w-ful px-3 py-2 rounded mx-2 flex-col">
          <div className="flex justify-between">
            <div className="text-maingray text-[12px]">
              เจ้าหนี้จะได้เงินไปทั้งสิ้น
            </div>
            <div className="text-maingray text-[12px]">
              {numeral(item?.amount_all).format("0,0")} บาท
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-maingray text-[12px]">เป็นดอกเบี้ยเงินกู้</div>
            <div className="text-maingray text-[12px]">
              {numeral(item?.interest_amount).format("0,0")} บาท
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-maingray text-[12px]">จากเงินกู้</div>
            <div className="text-maingray text-[12px]">
              {numeral(item?.amount).format("0,0")} บาท
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CardFutureTwo = ({
  title,
  text_color = "text-black",
  icon,
  onChangeForm,
  all_data = [],
  primary_key,
  index,
  deleteForm,
  onAddList,
  onDeleteList,
  onChangeCheckBox,
  onChangeCheckBoxValue,
}) => {
  const hasrent = [`รายรับจากอสังหาริมทรัพย์ (ขาย เช่า เซ้ง)`];
  return (
    <div className="">
      <div className=" flex items-center gap-2 p-3 bg-[#f6f6f6] text-mainblue font-semibold rounded-t-[20px]">
        <ImageMain src={icon} className=" w-6 h-6" />
        <div> {title}</div>
        <button
          onClick={() => {
            deleteForm(primary_key);
          }}
          className="ml-auto"
        >
          <ICON_DELETE className=" w-5 h-5 text-mainred " />
        </button>
        {/* <ICON_DELETE className=" w-5 h-5 text-mainred ml-auto" /> */}
      </div>
      <div
        className={`bg-[#fcfcfc] rounded-b-[20px] px-3 py-2 ${text_color} text-sm w-full h-full space-y-4 `}
      >
        <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
          <div className=" text-sm text-black font-medium">{title}</div>
          {/* ------ Card ตอนกดปุ่มบวกเพิ่ม ------ */}
          <div className="flex space-x-[5px]  ">
            <div className="aspect-square w-[22px] bg-[#F4F2F3] rounded-[4px] text-[#18488A] flex justify-center font-medium items-center text-center ">
              {all_data.length}
            </div>
            <button
              onClick={() => {
                onAddList(primary_key);
              }}
            >
              <ICON_ADD />
            </button>
          </div>
        </div>
        <div className=" bg-white rounded-lg px-4 py-[6px] ">
          {all_data?.map((item, index) => {
            return (
              <div key={index} className="pt-[20px]">
                <div className={` text-mainblue text-sm font-semibold `}>
                  {title}
                </div>
                <div className=" flex items-center">
                  <InputMain
                    placeholder={`ระบุรายรายการ${title}`}
                    value={item?.title}
                    onChange={onChangeForm(
                      primary_key,
                      "title",
                      index,
                      "string"
                    )}
                  />
                  <ICON_DELETE
                    onClick={() => {
                      onDeleteList(primary_key, _, index);
                    }}
                    className=" w-5 h-5 text-mainred ml-auto"
                  />
                </div>
                <div>
                  <div className={`text-mainblue font-semibold my-2 `}>
                    คาดการณ์ไว้อีกกี่ปีข้างหน้า
                  </div>

                  <div className=" flex justify-center">
                    <NumericFormat
                      maxLength={11}
                      placeholder="0"
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                      className={STYLE_INPUT_SLIDER}
                      value={item?.year}
                      onChange={onChangeForm(primary_key, "year", index)}
                    />
                  </div>
                  <SliderSingle
                    showInputMax={false}
                    year={true}
                    value={item?.year}
                    onChange={onChangeForm(primary_key, "year", index)}
                    valuemax={25}
                  />
                </div>
                {(!item?.is_rent && title !== `อื่น ๆ` ) && (
                  <div className={STYLE_PARENT_CARD_BALANCE}>
                    <div
                      className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                    >
                      จำนวนเงิน
                    </div>
                    <NumericFormat
                      placeholder="0"
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                      className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      value={item?.amount}
                      onChange={onChangeForm(primary_key, "amount", index)}
                    />
                  </div>
                )}
                {title === `รายรับจากอสังหาริมทรัพย์ (ขาย เช่า เซ้ง)`&& (
                  <div className="mt-2">
                    <Checkbox
                      onChange={(e) => {
                        onChangeCheckBox(e, primary_key, "is_rent", index);
                      }}
                      // value={option.value}
                      containerProps={{
                        className: "p-0",
                      }}
                      size={"sm"}
                      className="p-0 "
                      color="cyan"
                      checked={item?.is_rent || false}
                      label={
                        <div className="  flex flex-wrap items-start ml-2 font-medium   ">
                          <div className="px-[5px] text-mainblue w-fit h-fit">
                            กรณีปล่อยเช่า
                          </div>
                        </div>
                      }
                      ripple={false}
                    />
                  </div>
                )}
                {(item?.is_rent || title === `อื่น ๆ`) && (
                  <>
                    <div>
                      <div className={`text-mainblue font-semibold my-2 `}>
                        ระยะเวลาที่จะมีรายได้ (ปี)
                      </div>
                      <div className=" flex justify-center">
                        <NumericFormat
                          maxLength={11}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={STYLE_INPUT_SLIDER}
                          value={item?.year_rent}
                          onChange={onChangeForm(
                            primary_key,
                            "year_rent",
                            index
                          )}
                        />
                      </div>
                      <SliderSingle
                        showInputMax={false}
                        year={true}
                        value={item?.year_rent}
                        onChange={onChangeForm(primary_key, "year_rent", index)}
                        valuemax={25}
                      />
                    </div>
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        จำนวนเงินที่ได้ต่อปี
                      </div>
                      <NumericFormat
                        placeholder="0"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                        value={item?.amount_perYear}
                        onChange={onChangeForm(
                          primary_key,
                          "amount_perYear",
                          index
                        )}
                      />
                    </div>
                  </>
                )}
              </div>
            )
          })}
        </div>
        
      </div>
    </div>
  );
};

export const CardFutureThree = ({
  title,
  title_number,
  text_color = "text-black",
  icon,
  onChangeForm,
  onAddList = () => {},
  onDeleteList = () => {},
  onChangeCheckBoxValue = () => {},
  primary_key,
  deleteForm,
  all_data,
  isHasPeriod = true,
  isHasEducation = false,
  onChangeCheckBox = () => {},
  futurePlanData = {},
}) => {
  const nameMap = {
    ซื้อรถ: "รถยนต์",
    ซื้อบ้าน: "บ้าน",
  };

  // console.log("all_data",all_data)

  return (
    <div className=" flex flex-col ">
      <div className=" flex items-center gap-2 px-3 py-2 bg-[#f6f6f6] text-mainblue font-semibold rounded-t-[20px]">
        <ImageMain src={icon} className=" w-6 h-6 " />
        <div> {title}</div>
        <button
          onClick={() => {
            deleteForm(primary_key);
          }}
          className="ml-auto"
        >
          <ICON_DELETE className=" w-5 h-5 text-mainred " />
        </button>
      </div>

      <div
        className={`bg-[#fcfcfc] rounded-b-[20px] px-3 py-2 ${text_color} text-sm w-full h-full space-y-4 `}
      >
        {/* ------- เพิ่ม/ลดจำนวน ------------ */}
        <div className="flex items-center justify-between w-full py-[7px] pl-[16px] pr-[9px] bg-white rounded-[20px] ">
          <div className="font-IBMPlexSansThai font-medium text-sm ">
            {title_number}
          </div>
          <div className="flex space-x-[5px]  ">
            <div className="aspect-square w-[22px] bg-[#F4F2F3] rounded-[4px] text-[#18488A] flex justify-center font-medium items-center text-center ">
              {all_data.length}
            </div>
            <button
              onClick={() => {
                onAddList(primary_key);
              }}
            >
              <ICON_ADD />
            </button>
          </div>
        </div>

        {all_data.map((item, index) => {
          return (
            <>
              {/* ------ คาดการณ์ไว้อีกกี่ปีข้างหน้า ------ */}
              <div className="">
                <div className={` text-mainblue text-sm font-semibold `}>
                  {title}
                </div>
                <div className=" flex items-center">
                  <InputMain
                    placeholder={`ระบุรายรายการ${title}`}
                    value={item?.title}
                    onChange={onChangeForm(
                      primary_key,
                      "title",
                      index,
                      "string"
                    )}
                  />
                  <ICON_DELETE
                    onClick={() => {
                      onDeleteList(primary_key, _, index);
                    }}
                    className=" w-5 h-5 text-mainred ml-auto"
                  />
                </div>
                <div>
                  <div className={STYLE_LABEL_FUTURE}>
                    คาดการณ์ไว้อีกกี่ปีข้างหน้า
                  </div>
                  <div className=" flex justify-center">
                    <NumbericValidateRed
                      maxLength={11}
                      placeholder="0"
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                      className={STYLE_INPUT_SLIDER}
                      onChange={onChangeForm(primary_key, "year", index)}
                      value={item?.year}
                    />
                  </div>
                  <SliderSingle
                    year={true}
                    onChange={onChangeForm(primary_key, "year", index)}
                    value={item?.year}
                    valuemax={25}
                  />
                </div>
              </div>

              {/* ------ เงินดาวน์ ------ */}
              <div>
                <div className={STYLE_LABEL_FUTURE}>
                  {[
                    "วางแผนมีลูก",
                    "ท่องเที่ยว",
                    "ซื้อของสะสม",
                    "อื่น ๆ",
                  ].includes(title)
                    ? "ค่าใช้จ่าย"
                    : "เงินดาวน์"}
                </div>
                <NumericFormat
                  maxLength={11}
                  placeholder="0"
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={STYLE_INPUT_BORDER}
                  onChange={onChangeForm(primary_key, "down", index)}
                  value={item?.down}
                />
              </div>
              {/* ------ ออมเงิน/ลงทุน เพื่อเงินดาวน์ได้ดอกเบี้ย (ต่อปี) ------ */}
              <div>
                <div className={STYLE_LABEL_FUTURE}>
                  ออมเงิน/ลงทุน เพื่อเงินดาวน์{title}
                  <div>ผลตอบแทน (ต่อปี)</div>
                </div>
                <div className=" flex justify-center">
                  <NumericFormat
                    maxLength={11}
                    placeholder="0"
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={STYLE_INPUT_SLIDER}
                    onChange={onChangeForm(
                      primary_key,
                      "invest_down_interest",
                      index
                    )}
                    value={item?.invest_down_interest}
                  />
                </div>
                <SliderSingle
                  year={false}
                  onChange={onChangeForm(
                    primary_key,
                    "invest_down_interest",
                    index
                  )}
                  value={item?.invest_down_interest}
                  valuemax={25}
                />
                <div className=" mt-2">
                  <div className={`${STYLE_LABEL_FUTURE} text-center`}>
                    มีเวลาออม {item?.year} ปี
                  </div>
                  <div className={`${STYLE_TEXT_CYAN} text-center text-xl`}>
                    ต้องออมปีละ {numeral(item?.sum_invest_five).format("0,0")}{" "}
                    บาท
                  </div>
                </div>
              </div>
              {isHasPeriod && (
                <>
                  {/* ------ มูลค่า (บาท) ------ */}
                  <div>
                    <div className={STYLE_LABEL_FUTURE}>
                      มูลค่า{nameMap[title]} (บาท)
                    </div>
                    <NumbericValidateRed
                      maxLength={11}
                      placeholder="0"
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                      className={STYLE_INPUT_BORDER}
                      onChange={onChangeForm(primary_key, "value", index)}
                      value={item?.value}
                    />
                  </div>
                  {/* ---------ดอกเบี้ย (ต่อปี)------- */}
                  <div>
                    <div className={STYLE_LABEL_FUTURE}>
                      ดอกเบี้ย{nameMap[title]} (ต่อปี)
                    </div>
                    <div className=" flex justify-center">
                      <NumbericValidateRed
                        maxLength={11}
                        placeholder="0"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={STYLE_INPUT_SLIDER}
                        onChange={onChangeForm(
                          primary_key,
                          "interest_year",
                          index
                        )}
                        value={item?.interest_year}
                      />
                    </div>
                    <SliderSingle
                      year={false}
                      onChange={onChangeForm(
                        primary_key,
                        "interest_year",
                        index
                      )}
                      value={item?.interest_year}
                      valuemax={55}
                    />
                  </div>

                  {/* ---------ระยะเวลาที่ต้องการผ่อน------- */}
                  <div>
                    <div className={STYLE_LABEL_FUTURE}>
                      ระยะเวลาที่ต้องการผ่อน
                    </div>
                    <div className=" flex justify-center">
                      <NumbericValidateRed
                        maxLength={11}
                        placeholder="0"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={STYLE_INPUT_SLIDER}
                        onChange={onChangeForm(
                          primary_key,
                          "period_year",
                          index
                        )}
                        value={item?.period_year}
                      />
                    </div>
                    <SliderSingle
                      year={true}
                      onChange={onChangeForm(primary_key, "period_year", index)}
                      value={item?.period_year}
                      valuemax={55}
                    />
                  </div>
                  <div className=" mt-2">
                    <div className={`${STYLE_LABEL_FUTURE} text-center`}>
                      {/* มีเวลาออม {item?.year} ปี */}
                      ต้องชำระงวดละ (บาท)
                    </div>
                    <div className={`${STYLE_TEXT_CYAN} text-center text-xl`}>
                      {numeral(item?.pay_per_year).format("0,0")} บาท
                    </div>
                  </div>
                </>
              )}
              {isHasEducation && (
                <>
                  <div className=" mt-2">
                    <div
                      className={`${STYLE_LABEL_FUTURE} text-start justify-start`}
                    >
                      <span className="h-fit">
                        นำค่าใช้จ่ายในการดำรงชีวิตและค่าใช้จ่ายในการศึกษาบุตรมาคำนวณด้วย
                      </span>{" "}
                      <span className="h-fit">
                        เป้าหมายการศึกษาระดับปริญาตรี
                      </span>
                    </div>
                    {options_under_education
                      ?.filter((item) => item?.label !== "กำหนดเอง")
                      .map((option) => {
                        return (
                          <div className="mt-2">
                            <Checkbox
                              onChange={(e) => {
                                onChangeCheckBox(
                                  e,
                                  primary_key,
                                  "ishasEducation",
                                  index
                                );
                                onChangeCheckBoxValue(
                                  e,
                                  primary_key,
                                  "education_under",
                                  index
                                );
                              }}
                              value={option.value}
                              containerProps={{
                                className: "p-0",
                              }}
                              size={"sm"}
                              className="p-0"
                              color="cyan"
                              checked={
                                (item?.education_under === option.value &&
                                  item?.ishasEducation) ||
                                false
                              }
                              label={
                                <div className="  flex flex-wrap items-start ml-2 font-medium   ">
                                  <div>{option.label} ปีละ</div>
                                  <div className="px-[5px] text-mainblue w-fit h-fit">
                                    {" "}
                                    {numeral(
                                      key_under_education[option.value]
                                        ?.default_value
                                    ).format("0,0")}{" "}
                                  </div>
                                  <div>บาท</div>
                                  <div>รวมงบประมาณที่ต้องใช้ </div>
                                  <div className="px-[5px] text-mainblue w-fit h-fit">
                                    {numeral(
                                      CalcAllChildExpense(
                                        all_data,
                                        futurePlanData,
                                        index,
                                        option.value,
                                        option.value
                                      )
                                    ).format("0,0")}
                                  </div>
                                  <div>บาท</div>
                                </div>
                              }
                              ripple={false}
                            />
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};
