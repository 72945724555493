import { useMemo } from "react";
import { useBalanceStore } from "../../_store/balanceStore";

export const SumDebtInvestValue = () => {
  const balanceData = useBalanceStore((state) => state.balanceData);

  const carLists = balanceData?.debt_long_list
    ?.map((item) => item?.debt_long_cars)
    ?.flat()
    ?.filter((item) => item?.personal_or_investment === "investment")
    ?.reduce((acc, item) => acc + Number(item?.current_price || 0), 0);

  const placeLists = balanceData?.debt_long_list
    ?.map((item) => item?.debt_long_places)
    ?.flat()
    ?.filter((item) => item?.personal_or_investment === "investment")
    ?.reduce((acc, item) => acc + Number(item?.current_price || 0), 0);

  const otherLists = balanceData?.debt_long_list
    ?.map((item) => item?.debt_long_others)
    ?.flat()
    ?.filter((item) => item?.personal_or_investment === "investment")
    ?.reduce((acc, item) => acc + Number(item?.current_price || 0), 0);

  const InsuranceLife = balanceData?.expenses_fixed_list
    ?.find((item) => item?.options_expenses_fixed === "expenses_insuarant_life")
    ?.expenses_insuarant_lifes
    ?.reduce(
      (acc, item) => acc + Number(item?.insurance_policy_cash_surrender || 0),
      0
    );

  return (
    Number(carLists || 0) + Number(placeLists || 0) + Number(otherLists || 0) + Number(InsuranceLife || 0)
  );
};

export const SumOtherDebtAmount = () => {
  const balanceData = useBalanceStore((state) => state.balanceData);

  let amountSum = 0;

  balanceData?.debt_long_list
    ?.find((item) => item.options_debt_long === "debt_long_other")
    ?.debt_long_others?.map((item, index) => {
      if (item?.personal_or_investment === "personal") {
        return (amountSum += item?.current_price);
      }
      return (amountSum += 0);
    });
  return amountSum;
};
