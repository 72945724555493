import { Progress } from "@material-tailwind/react";
import _ from "lodash";
import numeral from "numeral";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTaxStore } from "../../../_store/taxplanStore";
import { FooterAfterSum } from "../../../components/FooterAfterSum/FooterAfterSum";
import { CardDetailsTax, CardMain } from "../../../components/card/CardMain";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  DeductionSum,
  ExceptionSum,
} from "../../../functions/taxFucntion/taxHeirachy";
import { ICON_BAHT, ICON_GRAPH } from "../../../utils/useIcons";
import {
  STYLE_CARD_BG,
  STYLE_NUMBER_PLAN_BAR,
  STYLE_PARENT_TWO_BUTTON,
} from "../../../utils/useStyle";
import {
  calculateTax,
  calculateTaxProperty,
  checkSum,
  getPercent,
  getcalPaid,
  getcalSum,
} from "../functions";
import { first_reload } from "../functions/firstLoad";
import {
  TabThreeExpenseCalculation,
  TabThreeSumIncomeCalculation,
  TabThreeWithHoldingTax,
} from "../../../functions/taxFucntion/premium/tab3function";
import {
  ExpenseTab4Calculation,
  ExpenstDetail,
  IncomeDetail,
  IncomeTab4Calculation,
  sum408RecieveMoney,
  sum408property,
} from "../../../functions/taxFucntion/premium/tab4function";

const mock = [
  {
    title: "ผู้มีเงินได้",
    value: 60000,
    value2: 60000,
  },
  {
    title: "คู่สมรส",
    value: 120000,
    value2: 120000,
  },
  {
    title: "ประกันชีวิต",
    value: 1111,
    value2: 1111,
  },
  {
    title: "ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG)",
    value: 1111,
    value2: 1111,
  },
  {
    title: "ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพ",
    value: 1111,
    value2: 1111,
  },
  {
    title: "ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)",
    value: 100000,
    value2: 100000,
  },
  {
    title: "เงินค่าชดเชยที่ได้รับตามกฎหมายแรงงาน",
    value: 1111,
    value2: 1111,
  },
  {
    title: "ดอกเบี้ยเงินกู้ยืมเพื่อ ซื้อ เช่าซื้อ หรือสร้างอาคารอยู่อาศัย",
    value: 1111,
    value2: 1111,
  },
  {
    title: "เงินสมทบกองทุนประกันสังคม",
    value: 1111,
    value2: 1111,
  },
  {
    title: "ค่าฝากครรภ์และค่าคลอดบุตร",
    value: 1111,
    value2: 1111,
  },
  {
    title: "เงินที่บริจาคแก่พรรคการเมือง",
    value: 1111,
    value2: 1111,
  },
  {
    title: "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ",
    value: 2222,
    value2: 4444,
  },
  {
    title: "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ",
    value: 2222,
    value2: 4444,
  },
  {
    title: "เงินบริจาคอื่น ๆ",
    value: 1111,
    value2: 1111,
  },
  {
    title: "สิทธิลดหย่อนพิเศษจากนโยบายรัฐบาล",
    value: 1111,
    value2: 1111,
  },
  {
    title: "กองทุน กบข.",
    value: 300000,
    value2: 300000,
  },
  {
    title: "กองทุนสงเคราะห์ครูโรงเรียนเอกชน",
    value: 100000,
    value2: 100000,
  },
  {
    title: "ประกันชีวิตแบบบำนาญ",
    value: 1000,
    value2: 0,
  },
  {
    title: "เงินสะสมกองทุนการออมแห่งชาติ (กอช)  ",
    value: 1000,
    value2: 0,
  },
  {
    title: "กองทุนรวมเพื่อการเลี้ยงชีพ(RMF)",
    value: 1000,
    value2: 0,
  },
  {
    title: "เงินสะสมกองทุนสำรองเลี้ยงชีพ",
    value: 1000,
    value2: 1000,
  },
  {
    title: "ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)",
    value: 100000,
    value2: 100000,
  },
];

const TaxPlanContext = createContext();
export const useTaxPlanContext = () => useContext(TaxPlanContext);

const ViewStepFourTaxPlan = ({
  onClick,
  setForm,
  form,
  error,
  setError,
  openTaxPlanInfo,
  setOpenTaxPlanInfo,
  setTab,
  tab,
  userData,
}) => {
  // const form = useTaxStore((state) => state.form);
  const taxData = useTaxStore((state) => state.taxData);
  const clearTaxData = useTaxStore((state) => state.clearTaxData);
  const setTaxData = useTaxStore((state) => state.setTaxData);

  const [open, setOpen] = useState(0);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const navigate = useNavigate();

  const displayTaxPaid = useMemo(() => {
    let result = calculateTax(form.tax_income);
    if (userData?.is_premium) {
      result =
        TabThreeSumIncomeCalculation(taxData) +
        sum408property(taxData) +
        sum408RecieveMoney(taxData);
    }
    return result;
  }, [form?.tax_income]);

  const getCaltax = () => {
    let result = 0;
    result = form.tax_income - form?.deduction_expenses - form?.deduct_summary;

    if (userData?.is_premium) {
      result =
        TabThreeSumIncomeCalculation(taxData) -
        TabThreeExpenseCalculation(taxData) -
        form?.deduct_summary +
        sum408property(taxData) +
        sum408RecieveMoney(taxData);
    }

    return calculateTax(result);
  };

  const displaySumBudget = () => {
    let sum = 0;
    sum =
      Number(form?.tax_plan_insurance_life) +
      Number(form?.tax_plan_insurance_health) +
      Number(form?.tax_plan_insurance_annuity) +
      Number(form?.tax_fund_provident) +
      Number(form?.tax_fund_nsf) +
      Number(form?.tax_plan_rmf) +
      Number(form?.tax_plan_ssf) +
      Number(form?.tax_plan_political_donate) +
      Number(form?.tax_fund_esg);
    return Number(sum);
  };

  const displaySumAfterTaxPaid = () => {
    let result = 0;
    result =
      Number(form?.tax_income || 0) -
      Number(form?.deduction_expenses || 0) -
      Number(form?.deduct_summary || 0) -
      Number(displaySumBudget() || 0);
    if (userData?.is_premium) {
      result =
        TabThreeSumIncomeCalculation(taxData) -
        TabThreeExpenseCalculation(taxData) -
        Number(form?.deduct_summary || 0) -
        Number(displaySumBudget() || 0);
    }
    return calculateTax(result);
  };

  const getSumAllInsurance = useMemo(
    () => getcalSum(form?.tax_plan_insurance_life, form?.insurance_life),
    [form?.tax_plan_insurance_life, form?.insurance_life]
  );

  const getSumAllInsuranceHelth = useMemo(
    () => getcalSum(form?.tax_plan_insurance_health, form?.insurance_health),
    [form?.tax_plan_insurance_health, form?.insurance_health]
  );

  const getSumAllFund = useMemo(
    () => getcalSum(form?.tax_plan_rmf, form?.fund_provident_rmf_value),
    [form?.tax_plan_rmf, form?.fund_provident_rmf_value]
  );

  const RemainInsuaLife = useMemo(
    () => getcalPaid(getSumAllInsurance, 100000),
    [getSumAllInsurance, 100000]
  );

  const RemianFund = useMemo(
    () => getcalPaid(getSumAllFund, 100000),
    [getSumAllFund, 100000]
  );

  const RemainInsuaHealth = useMemo(
    () => getcalPaid(getSumAllInsuranceHelth, 100000),
    [getSumAllInsuranceHelth, 100000]
  );

  // คำนวณ SliderBar ก่อนวางแผน
  const getPercentBar = useMemo(
    () => getPercent(getCaltax(), displayTaxPaid),
    [getCaltax(), displayTaxPaid]
  );

  // คำนวณ SliderBar หลังวางแผน
  const getPercentBarAfter = useMemo(
    () => getPercent(displaySumAfterTaxPaid(), displayTaxPaid),
    [displaySumAfterTaxPaid(), displayTaxPaid]
  );

  // คำนวณ SliderBar ยอดรวมสรุปภาษี
  const getPercentBarFinalTax = useMemo(
    () =>
      getPercent(
        Math.abs(
          form.final_tax + sum408property(taxData) + sum408RecieveMoney(taxData)
        ),
        displayTaxPaid
      ),
    [form.final_tax, displayTaxPaid]
  );

  const MAX_EXCEPTION = 500000;

  // คำนวนยกเว้น กบข
  const getExceptionGorBorKhor = useMemo(() => {
    const foundGorBorKhor = _.get(
      _.find(mock, {
        title: "กองทุน กบข.",
      }),
      "value2",
      0
    );
    const foundProvident = _.get(
      _.find(mock, {
        title: "เงินสะสมกองทุนสำรองเลี้ยงชีพ",
      }),
      "value2",
      0
    );
    const foundPrivateSchoolTeacher = _.get(
      _.find(mock, {
        title: "กองทุนสงเคราะห์ครูโรงเรียนเอกชน",
      }),
      "value2",
      0
    );
    const foundNsf = _.get(
      _.find(mock, {
        title: "กองทุนการออมแห่งชาติ (กอช)",
      }),
      "value2",
      0
    );
    const foundAnnuity = _.get(
      _.find(mock, {
        title: "เบี้ยประกันแบบบำนาญ",
      }),
      "value2",
      0
    );
    const foundRmf = _.get(
      _.find(mock, {
        title: "กองทุนรวมเพื่อการเลี้ยงชีพ(RMF)",
      }),
      "value2",
      0
    );
    const foundSsf = _.get(
      _.find(mock, {
        title: "ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)",
      }),
      "value2",
      0
    );
    let someInclude = false;
    let total = 0;

    // check 1 ใน 6 มีค่าเป็น 0 หรือไม่
    if (
      form?.fund_GorBorKhor_value > 0 &&
      form?.fund_provident > 0 &&
      form?.fund_nsf > 0 &&
      form?.fund_provident_rmf_value > 0 &&
      form?.fund_provident_ssf_value > 0 &&
      form?.insurance_annuity > 0 &&
      form?.fund_PrivateSchoolTeacher_value > 0
    ) {
      someInclude = true;
      total =
        // foundGorBorKhor +
        foundProvident +
        foundPrivateSchoolTeacher +
        foundNsf +
        foundRmf +
        foundAnnuity +
        foundSsf;

      if (total >= MAX_EXCEPTION) {
        total =
          MAX_EXCEPTION -
          (foundProvident +
            foundPrivateSchoolTeacher +
            foundNsf +
            foundRmf +
            foundAnnuity +
            foundSsf);
      } else {
        total = foundGorBorKhor;
      }
    }

    if (total < 0) {
      total = 0;
    }
    return { someInclude, total, amount: form?.fund_GorBorKhor_value };
  }, [form]);

  const getPercentInsuaranceLife = useMemo(
    () =>
      getPercent(form?.tax_plan_insurance_life + form?.insurance_life, 100000),
    [form?.tax_plan_insurance_life + form?.insurance_life, 100000]
  );
  const getPercentDonate = useMemo(
    () => getPercent(form?.tax_plan_donate, 100000),
    [form?.tax_plan_donate, 100000]
  );

  // Percent Sum Tax
  const checkPercent = () => {
    let result = 0;
    result = ((getCaltax() - form.after_tax_paid) / getCaltax()) * 100;
    if (isNaN(result)) {
      return 0;
    }
    return Number(result)?.toFixed(2);
  };
  // Display Sum Tax
  const displaySumTax = useMemo(
    () => checkSum(getCaltax(), form.after_tax_paid, 0, 0),
    [getCaltax(), form.after_tax_paid, 0, 0]
  );

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  // IncomeTab4Calculation(taxData) -
  //                             Number(ExceptionSum(form) || 0) -
  //                             Number(form?.deduction_expenses || 0) -
  //                             Number(DeductionSum(form)) -
  //                             ExpenseTab4Calculation(taxData)

  // console.log("IncomeTab4Calculation(taxData) ",IncomeTab4Calculation(taxData) )
  // console.log("Number(ExceptionSum(form) || 0)",Number(ExceptionSum(form) || 0))
  // console.log("Number(form?.deduction_expenses || 0)",Number(form?.deduction_expenses || 0))
  // console.log("Number(DeductionSum(form))",Number(DeductionSum(form)))
  // console.log("ExpenseTab4Calculation(taxData)",ExpenseTab4Calculation(taxData))
  // console.log("-------------------------------------")

  return (
    <div id="first_reload">
      <div className={`my-9`}>
        {/*---------- กราฟ เปรียบเทียบ ก่อนวางแผนหลัง และ หลังวางแผน ----------*/}
        {form.final_tax +
          sum408property(taxData) +
          sum408RecieveMoney(taxData) >
          0 && (
          <div className="mb-5">
            <HalfChartMain
              text={false}
              value={`0`}
              beforevalue={getCaltax()}
              aftervalue={form.after_tax_paid}
              maxvalue={displayTaxPaid}
            />
            <div className=" flex justify-center gap-5 w-full -mt-10">
              <div
                className={`bg-gray50 rounded-[10px] py-[7px] px-2  text-mainblue text-xl font-semibold  w-[127px] md:w-40 flex gap-[15px] justify-center items-center`}
              >
                <ICON_GRAPH className="w-6 h-6 text-maincyan " />
                <span className=" flex-none"> {checkPercent()} %</span>
              </div>
              <div
                className={`${STYLE_CARD_BG} text-mainblue text-xl font-semibold w-[127px] md:w-40 flex gap-[15px]  justify-center items-center`}
              >
                <ICON_BAHT className="w-6 h-6 text-maincyan " />
                {numeral(displaySumTax).format("0,0")}
              </div>
            </div>
          </div>
        )}

        {/*---------- คุณขอคืนภาษีได้ ----------*/}
        {form.final_tax +
          sum408property(taxData) +
          sum408RecieveMoney(taxData) <
          0 && (
          <div className="flex flex-col items-center -mt-9 mb-5">
            <ImageMain src="../images/taxicon1.png" className=" w-20 h-20 " />
            <div className=" text-mainblue font-semibold text-[20px]">
              คุณขอคืนภาษีได้ !
            </div>
            <div className="flex flex-col items-center">
              <div className="flex items-center gap-2">
                <div className="text-mainblue font-semibold text-sm">
                  ขอคืนภาษีได้
                </div>
                <div className="text-maincyan font-semibold text-[20px]">
                  {numeral(
                    Math.abs(
                      form.final_tax +
                        sum408property(taxData) +
                        sum408RecieveMoney(taxData)
                    )
                  )?.format("0,0")}{" "}
                  บาท
                </div>
              </div>
              <div className="text-xs text-gray100">
                * ({" "}
                {userData?.is_premium
                  ? numeral(TabThreeWithHoldingTax(taxData))?.format("0,0")
                  : numeral(form.tax_withholding)?.format("0,0")}{" "}
                - {numeral(form.after_tax_paid)?.format("0,0")} ={" "}
                {numeral(form.final_tax * -1)?.format("0,0")} )
              </div>
            </div>
          </div>
        )}

        {/*---------- คุณไม่ต้องเสียภาษี ----------*/}
        {form.final_tax +
          sum408property(taxData) +
          sum408RecieveMoney(taxData) ===
          0 && (
          <div className="flex flex-col items-center -mt-9 mb-5">
            <ImageMain src="../images/taxicon1.png" className=" w-20 h-20 " />
            <div className=" text-mainblue font-semibold text-[20px]">
              คุณไม่ต้องเสียภาษี !
            </div>
          </div>
        )}

        {/*---------- ก่อนวางแผนหลัง (ยังไม่ได้คำนวณ ภาษีหัก ณ ที่จ่าย) ----------*/}
        <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5 mb-4`}>
          <div className=" flex justify-between mb-[6px]">
            <div className=" text-red-500 text-sm font-semibold">
              ก่อนวางแผน
            </div>
            <div className={STYLE_NUMBER_PLAN_BAR}>
              {String(getCaltax()) === "Infinity"
                ? "0"
                : numeral(getCaltax())?.format("0,0")}{" "}
              บาท
            </div>
          </div>
          <Progress value={getPercentBar} color="red" className="bg-white" />
          <div className="text-right text-xs text-maingray mt-2.5 -mb-2.5">
            * ยังไม่ได้คำนวณ ภาษีหัก ณ ที่จ่าย
          </div>
        </div>

        {/*---------- หลังวางแผน (ยังไม่ได้คำนวณ ภาษีหัก ณ ที่จ่าย) ----------*/}
        <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5 mb-4`}>
          <div className=" flex justify-between mb-[6px]">
            <div className="text-maingreen text-sm font-semibold">
              หลังวางแผน
            </div>
            <div className={STYLE_NUMBER_PLAN_BAR}>
              {String(displaySumAfterTaxPaid()) === "Infinity"
                ? "0"
                : numeral(displaySumAfterTaxPaid())?.format("0,0")}{" "}
              บาท
            </div>
          </div>
          <Progress
            value={getPercentBarAfter}
            color="green"
            className="bg-white"
          />
          <div className="text-right text-xs text-maingray mt-2.5 -mb-2.5">
            * ยังไม่ได้คำนวณ ภาษีหัก ณ ที่จ่าย
          </div>
        </div>

        {/*------------------ ภาษีหัก ณ ที่จ่าย -------------------*/}
        <div className={`${STYLE_CARD_BG} px-5 mb-4`}>
          <div className=" flex justify-between ">
            <div className=" text-mainblue text-sm font-semibold">
              หัก ภาษีหัก ณ ที่จ่าย
            </div>
            <div className={STYLE_NUMBER_PLAN_BAR}>
              {userData?.is_premium
                ? numeral(TabThreeWithHoldingTax(taxData))?.format("0,0")
                : numeral(form?.tax_withholding)?.format("0,0")}{" "}
              บาท
            </div>
          </div>
        </div>

        {/*------------------ ภาษีจากการขายอสังหาริมทรัพย์กรณีแยกคำนวณ -------------------*/}
        {taxData?.tax_408_property
          ?.map((item) => item.calculating_way)
          ?.some((item) => item === "แยกคำนวณ") && (
          <div className={`${STYLE_CARD_BG} px-5 mb-4`}>
            <div className=" flex justify-between ">
              <div className=" text-mainblue text-sm font-semibold">
                {sum408property(taxData) > 0 ? "บวก" : "หัก"}{" "}
                ภาษีจากการขายอสังหาริมทรัพย์กรณีแยกคำนวณ
              </div>
              <div className={STYLE_NUMBER_PLAN_BAR}>
                {numeral(Math.abs(sum408property(taxData)))?.format("0,0")} บาท
              </div>
            </div>
          </div>
        )}

        {/*------------------ ภาษีจากใบแนบออกจากงาน -------------------*/}
        {Math.abs(taxData?.tax_QuitJob?.moreTax) > 0 && (
          <div className={`${STYLE_CARD_BG} px-5 mb-4`}>
            <div className=" flex justify-between ">
              <div className=" text-mainblue text-sm font-semibold">
                บวก ภาษีจากใบแนบออกจากงาน
              </div>
              <div className={STYLE_NUMBER_PLAN_BAR}>
                {numeral(Math.abs(taxData?.tax_QuitJob?.moreTax))?.format(
                  "0,0"
                )}{" "}
                บาท
              </div>
            </div>
          </div>
        )}

        {/*------------------ ภาษีจากเงินได้จากการให้หรือรับโดยเลือกเสียภาษีในอัตราร้อยละ 5 -------------------*/}
        {taxData?.heritage?.tax_calc_include && (
          <div className={`${STYLE_CARD_BG} px-5 mb-4`}>
            <div className=" flex justify-between ">
              <div className=" text-mainblue text-sm font-semibold">
                ภาษีจากเงินได้จากการให้หรือรับโดยเลือกเสียภาษีในอัตราร้อยละ 5
              </div>
              <div className={STYLE_NUMBER_PLAN_BAR}>
                {numeral(sum408RecieveMoney(taxData))?.format("0,0")} บาท
              </div>
            </div>
          </div>
        )}

        {/*------------------ ยอดรวมสรุปภาษีทั้งหมด -------------------*/}
        {Number(form?.final_tax || 0) +
          sum408property(taxData) +
          sum408RecieveMoney(taxData) +
          Number(taxData?.tax_QuitJob?.moreTax || 0) >
          0 && (
          <div className=" mb-4">
            <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5`}>
              <div className=" flex justify-between mb-[6px]">
                <div className=" text-mainorange text-sm font-semibold">
                  ภาษีที่ต้องจ่าย
                </div>
                <div className={STYLE_NUMBER_PLAN_BAR}>
                  {numeral(
                    Number(form?.final_tax || 0) +
                      Number(sum408property(taxData) || 0) +
                      Number(sum408RecieveMoney(taxData) || 0) +
                      Number(taxData?.tax_QuitJob?.moreTax || 0)
                  )?.format("0,0")}{" "}
                  บาท
                </div>
              </div>
              {/* <Progress
                value={getPercentBarFinalTax}
                color="orange"
                className="bg-white"
              /> */}
            </div>
          </div>
        )}
        {form.final_tax +
          sum408property(taxData) +
          sum408RecieveMoney(taxData) <
          0 && (
          <div className=" mb-4">
            <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5`}>
              <div className=" flex justify-between mb-[6px]">
                <div className=" text-blue400 text-sm font-semibold">
                  ภาษีที่ขอคืนได้
                </div>
                <div className={STYLE_NUMBER_PLAN_BAR}>
                  {numeral(
                    Math.abs(
                      form?.final_tax +
                        +sum408property(taxData) +
                        sum408RecieveMoney(taxData)
                    )
                  )?.format("0,0")}{" "}
                  บาท
                </div>
              </div>
              {/* <Progress
                value={getPercentBarFinalTax}
                color="blue"
                className="bg-white"
              /> */}
            </div>
          </div>
        )}
        {form.final_tax +
          sum408property(taxData) +
          sum408RecieveMoney(taxData) ===
          0 && (
          <div className=" mb-4">
            <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5`}>
              <div className=" flex justify-between mb-[6px]">
                <div className=" text-blue400 text-sm font-semibold">
                  คุณไม่ต้องเสียภาษี
                </div>
                <div className={STYLE_NUMBER_PLAN_BAR}>{0} บาท</div>
              </div>
              <Progress value={0} color="blue" className="bg-white" />
            </div>
          </div>
        )}

        {/*----------------------------------------------------------*/}

        <CardMain
          icon={false}
          title="รายละเอียดหลังวางแผนภาษี"
          panel={
            <div className=" space-y-2.5 ">
              <CardDetailsTax
                title={"รายได้"}
                value={
                  userData?.is_premium
                    ? numeral(IncomeTab4Calculation(taxData))?.format("0,0")
                    : numeral(form?.tax_income)?.format("0,0")
                }
                path={() => {
                  setTab("income");
                }}
              />
              <CardDetailsTax
                title={"ยกเว้น"}
                value={numeral(ExceptionSum(form))?.format("0,0")}
                path={() => {
                  setForm({
                    ...form,
                    exceptions: [
                      {
                        label: "กบข",
                        key: "fund_GorBorKhor_value",
                        item: getExceptionGorBorKhor,
                      },
                    ],
                  });
                  setTab("exception");
                }}
              />
              <CardDetailsTax
                title={"ค่าใช้จ่าย"}
                value={
                  userData?.is_premium
                    ? numeral(
                        ExpenstDetail(taxData)?.reduce((total, num) => {
                          return total + Number(num.value);
                        }, 0)
                      )?.format("0,0")
                    : numeral(form?.deduction_expenses || 0)?.format("0,0")
                }
                path={() => {
                  setTab("expense");
                }}
              />
              <CardDetailsTax
                title={"ค่าลดหย่อน"}
                value={numeral(DeductionSum(form) || 0)?.format("0,0")}
                path={() => {
                  setTab("deduct");
                }}
              />
              <CardDetailsTax
                title={"เงินได้สุทธิ"}
                value={
                  userData?.is_premium
                    ? numeral(
                        IncomeTab4Calculation(taxData) -
                          Number(ExceptionSum(form) || 0) -
                          Number(form?.deduction_expenses || 0) -
                          Number(DeductionSum(form) || 0) <
                          0
                          ? 0
                          : IncomeTab4Calculation(taxData) -
                              Number(ExceptionSum(form) || 0) -
                              Number(form?.deduction_expenses || 0) -
                              Number(DeductionSum(form))
                      )?.format("0,0")
                    : numeral(
                        Number(form?.tax_income || 0) -
                          Number(ExceptionSum(form) || 0) -
                          Number(form?.deduction_expenses || 0) -
                          Number(DeductionSum(form) || 0) <
                          0
                          ? 0
                          : Number(form?.tax_income || 0) -
                              Number(ExceptionSum(form) || 0) -
                              Number(form?.deduction_expenses || 0) -
                              Number(DeductionSum(form))
                      )?.format("0,0")
                }
                path={() => {
                  setTab("netincome");
                }}
              />
            </div>
          }
        />

        {/*-------------------- มาทำแผนให้เป็นจริงกันเถอะ ! --------------------*/}
        <FooterAfterSum />

        <div className={STYLE_PARENT_TWO_BUTTON}></div>
      </div>
    </div>
  );
};
export default ViewStepFourTaxPlan;
