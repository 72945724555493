import numeral from "numeral";
import React from "react";
import { CardMain } from "../../../components/card/CardMain";
import Container from "../../../components/layout/Container";
import { cn } from "../../../utils/useFunctions";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_PADDING,
  STYLE_TEXT_BLUE,
} from "../../../utils/useStyle";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import { PdfHeaderText } from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import { STYLE_PAGE } from "../styles/pdf.style";
import { tax405Percent } from "../../../functions/taxFucntion/premium/businessFunction";

const Income408StockPdf = ({
  page,
  taxData = {},
  datalist = [],
  allList = [],
  isFirst = false,
  isLast = false,
}) => {

  const sumStockDividend = () => {
    let sum = allList
      ?.filter((item) => item?.type_job?.label === "หุ้น")
      ?.reduce((total, num) => {
        return total + Number(num?.dividend || 0);
      }, 0);

    return numeral(sum)?.format("0,0");
  };

  const sumTaxCreditDividend = () => {
    let sum = allList?.reduce((total, num) => {
      return total + Number(num?.tax_credit || 0);
    }, 0);

    return numeral(sum)?.format("0,0");
  };

  const sumFundDividend = () => {
    let sum = allList
      ?.filter((item) => item?.type_job?.label === "กองทุน")
      ?.reduce((total, num) => {
        return total + Number(num?.dividend || 0);

      }, 0);

    return numeral(sum)?.format("0,0");
  };

  const sumWithHoldingTaxDividend = () => {
    let sum = allList?.reduce((total, num) => {
      return total + Number(num?.with_holding_tax || 0);
    }, 0);

    return numeral(sum)?.format("0,0");
  };


  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="วางแผนภาษี"
        icon="/images/tax_plan.svg"
        classNameIcon="w-[24px] h-[27px]"
      />

      <Container classnameInner={""}>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <div className={STYLE_PARENT_PADDING}>
            {isFirst && (
              <CardMain
                border={false}
                icon={false}
                marginyellow="mt-0"
                padding=" py-0 "
                panel={
                  <div className=" px-3 -mt-3">
                    <div className=" text-xl font-medium pr-3 flex">
                      เงินปันผล ส่วนแบ่งกำไรจากหุ้น/กองทุน (มาตรา 40(4)(ข))
                    </div>
                  </div>
                }
              />
            )}
            {datalist?.map((item, index) => {
              return (
                <CardMain
                  border={false}
                  icon={false}
                  marginyellow="mt-3"
                  panel={
                    <div className=" px-3 -mt-3">
                      <div className="w-fit flex justify-between text-[11px] font-[500]">
                        <div
                          className={` text-base text-mainblue font-semibold w-full mb-1`}
                        >
                          รายการที่
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap  text-base text-mainblue font-semibold": true,
                          })}
                        >
                          {item?.order}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          ประเภทเงินปันผล
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${item?.type_job?.label}`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          ชื่อบริษัท
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${item?.company_name}`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          อัตราภาษี
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${numeral(item?.interest_rate?.label).format('0,0')}`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          จำนวนเงินปันผล/ ส่วนแบ่งกำไร
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${numeral(item?.dividend)?.format("0,0")} บาท`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          เครดิตภาษี
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${numeral(item?.tax_credit)?.format(
                            "0,0"
                          )} บาท`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                        ภาษีหัก ณ ที่จ่าย

                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${numeral(item?.with_holding_tax)?.format(
                            "0,0"
                          )} บาท`}{" "}
                        </div>
                      </div>

                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          ภาษีหัก ณ ที่จ่าย
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${numeral(item?.with_holding_tax)?.format(
                            "0,0"
                          )} บาท`}{" "}
                        </div>
                      </div>
                    </div>
                  }
                />
              );
            })}
            {isLast && (
              <CardMain
                border={false}
                icon={false}
                marginyellow="mt-3"
                panel={
                  <div className=" px-3 -mt-3">
                    {/* <div
                      className={` text-base text-black font-semibold w-full mb-1 text-center`}
                    >
                      การคำนวณค่าใช้จ่าย
                    </div> */}
                    <div className="w-full flex justify-between text-[11px] font-[500]">
                      <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                        {`ผลรวมของส่วนของผู้มีเงินได้ จำนวน ${allList?.length} รายการ`}
                      </div>
                    </div>
                    <div className="w-full flex justify-between text-[11px] font-[500]">
                      <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      รวมเงินปันผลจากหุ้น
                      </div>
                      <div
                        className={cn(STYLE_TEXT_BLUE, {
                          "w-fit whitespace-nowrap": true,
                        })}
                      >
                        {`${numeral(sumStockDividend())?.format(
                          "0,0"
                        )} บาท`}
                      </div>
                    </div>
                    <div className="w-full flex justify-between text-[11px] font-[500]">
                      <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      รวมเครดิตภาษี
                      </div>
                      <div
                        className={cn(STYLE_TEXT_BLUE, {
                          "w-fit whitespace-nowrap": true,
                        })}
                      >
                        {`${numeral(sumTaxCreditDividend())?.format(
                          "0,0"
                        )} บาท`}
                      </div>
                    </div>
                    <div className="w-full flex justify-between text-[11px] font-[500]">
                      <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      รวมเงินปันผลจากกองทุน
                      </div>
                      <div
                        className={cn(STYLE_TEXT_BLUE, {
                          "w-fit whitespace-nowrap": true,
                        })}
                      >
                        {`${numeral(sumFundDividend())?.format(
                          "0,0"
                        )} บาท`}
                      </div>
                    </div>
                    <div className="w-full flex justify-between text-[11px] font-[500]">
                      <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      รวมภาษี หัก ณ ที่จ่าย
                      </div>
                      <div
                        className={cn(STYLE_TEXT_BLUE, {
                          "w-fit whitespace-nowrap": true,
                        })}
                      >
                        {`${numeral(
                          sumWithHoldingTaxDividend()
                        )?.format("0,0")} บาท`}
                      </div>
                    </div>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </Container>
      <PdfFooter page={page} />
    </div>
  );
};

export default Income408StockPdf;
