import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import { CardFuture, CardFutureThree, CardFutureTwo } from "../styles/Card";
import { numberWithoutComma } from "../../balance/component/VIDrawerDebt.Main";
import _ from "lodash";
import { calMonthlyPayment, calPMT } from "../../education/functions/function";
import { toFloat2 } from "../../../utils/useFunctions";
import { useEducationStore } from "../../../_store/educationStore";
import { useState } from "react";
import { CardKidsInfo } from "../../education/styles/CardMain";

export const FutureStepTwo = () => {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const setFuturePlanData = useFuturePlanStore(
    (state) => state.setFuturePlanData
  );

  const educationData = useEducationStore((state) => state.educationData);

  // const [localEducationData, setLocalEducationData] = useState({
  //   ...currentEducation,
  // });

  // console.log("educationData", educationData);

  const onChangeForm =
    (primary_key, key, index, type = "number") =>
    (e) => {
      try {
        let value = e.target.value;
        if (type === "number") {
          value = numberWithoutComma(value);
        }

        if (_.isNaN(value)) {
          value = "";
        }

        const newFuturePlan = { ...futurePlanData };

        if (
          [`expenese_home_items`, `expenese_car_items`].includes(primary_key)
        ) {
          let sum_invest_five = 0;
          let invest_down_interest_amount = 0;
          let sum_amount_per_period = 0;
          let interest_amount = 0;
          let amount_all = 0;
          let diff_amount = 0;

          newFuturePlan[primary_key][index] = {
            ...newFuturePlan[primary_key][index],
            [key]: value,
          };

          invest_down_interest_amount =
            (newFuturePlan[primary_key][index].down *
              newFuturePlan[primary_key][index].invest_down_interest) /
            100;
          diff_amount =
            newFuturePlan[primary_key][index].amount -
            newFuturePlan[primary_key][index].down;

          sum_invest_five = calPMT(
            newFuturePlan[primary_key][index].invest_down_interest / 100,
            newFuturePlan[primary_key][index].year,
            0,
            -newFuturePlan[primary_key][index].down
          );

          interest_amount =
            newFuturePlan[primary_key][index].amount *
            (newFuturePlan[primary_key][index].interest / 100);
          amount_all =
            newFuturePlan[primary_key][index].amount + interest_amount;

          sum_amount_per_period = calMonthlyPayment(
            newFuturePlan[primary_key][index]?.amount,
            newFuturePlan[primary_key][index]?.interest / 100 / 12,
            newFuturePlan[primary_key][index]?.period * 12
          );

          if (
            _.isNaN(sum_amount_per_period) ||
            String(sum_amount_per_period) === "Infinity" ||
            String(sum_amount_per_period) === -"Infinity"
          ) {
            sum_amount_per_period = 0;
          }
          if (
            _.isNaN(sum_invest_five) ||
            String(sum_invest_five) === "Infinity"
          ) {
            sum_invest_five = 0;
          }
          newFuturePlan[primary_key][index] = {
            ...newFuturePlan[primary_key][index],
            sum_invest_five: sum_invest_five,
            invest_down_interest_amount: invest_down_interest_amount,
            sum_amount_per_period: sum_amount_per_period,
            interest_amount: interest_amount,
            amount_all: amount_all,
          };
        }
        setFuturePlanData(newFuturePlan);
      } catch (error) {
        console.log("error", error);
      }
    };

  const onChangeFormTwo =
    (primary_key, key, index, type = "number") =>
    (e) => {
      let value = e.target.value;
      if (type === "number") {
        value = numberWithoutComma(value);
      }

      if (_.isNaN(value)) {
        value = "";
      }

      const newFuturePlan = { ...futurePlanData };
      newFuturePlan[primary_key][index] = {
        ...newFuturePlan[primary_key][index],
        [key]: value,
      };
      setFuturePlanData(newFuturePlan);
    };

  const onChangeFormThree =
    (primary_key, key, index, type = "number") =>
    (e) => {
      try {
        let value = e.target.value;
        if (type === "number") {
          value = numberWithoutComma(value);
          value = value < 1 ? 1 : value;
        }

        if (_.isNaN(value)) {
          value = "";
        }

        let sum_invest_five = 0;
        let invest_down_interest_amount = 0;
        let sum_amount_per_period = 0;
        let interest_amount = 0;
        let amount_all = 0;
        let diff_amount = 0;
        let pay_per_year = 0;

        const newFuturePlan = { ...futurePlanData };

        newFuturePlan[primary_key][index] = {
          ...newFuturePlan[primary_key][index],
          [key]: value,
        };

        if (type === "number") {
          sum_invest_five = calPMT(
            newFuturePlan[primary_key][index].invest_down_interest / 100,
            newFuturePlan[primary_key][index].year,
            0,
            -newFuturePlan[primary_key][index].down
          );

          pay_per_year = calPMT(
            newFuturePlan[primary_key][index].interest_year / 100 / 12,
            newFuturePlan[primary_key][index].period_year * 12,
            -(
              newFuturePlan[primary_key][index].value -
              newFuturePlan[primary_key][index].down
            )
          );

          newFuturePlan[primary_key][index] = {
            ...newFuturePlan[primary_key][index],
            sum_invest_five,
            pay_per_year,
          };
        }

        setFuturePlanData(newFuturePlan);
      } catch (error) {
        console.log("error", error);
      }
    };

  const onAddList = (primary_key, key, index, type = "number") => {
    try {
      const newFuturePlan = { ...futurePlanData };
      newFuturePlan[primary_key].push({
        amount: "",
        down: "",
        interest: "",
        period: "",
        invest_down_interest: "",
        year: "",
      });
      setFuturePlanData(newFuturePlan);
    } catch (error) {
      console.log(primary_key, "error :", error);
    }
  };

  const onDeleteList = (primary_key, key, index, type = "number") => {
    try {
      const newFuturePlan = { ...futurePlanData };
      newFuturePlan[primary_key].splice(index, 1);
      setFuturePlanData(newFuturePlan);
    } catch (error) {
      console.log(primary_key, "error :", error);
    }
  };

  const onChangeCheckBox = (e, primary_key, key, index, type = "boolean") => {
    try {
      let value = e.target.checked;

      if (type === "number") {
        value = numberWithoutComma(value);
      }

      if (_.isNaN(value)) {
        value = "";
      }

      let sum_invest_five = 0;
      let invest_down_interest_amount = 0;
      let sum_amount_per_period = 0;
      let interest_amount = 0;
      let amount_all = 0;
      let diff_amount = 0;
      let pay_per_year = 0;

      const newFuturePlan = { ...futurePlanData };

      newFuturePlan[primary_key][index] = {
        ...newFuturePlan[primary_key][index],
        [key]: value,
      };

      if (type === "number") {
        sum_invest_five = calPMT(
          newFuturePlan[primary_key][index].invest_down_interest / 100,
          newFuturePlan[primary_key][index].year,
          0,
          -newFuturePlan[primary_key][index].down
        );

        pay_per_year = calPMT(
          newFuturePlan[primary_key][index].interest_year / 100 / 12,
          newFuturePlan[primary_key][index].period_year * 12,
          -(
            newFuturePlan[primary_key][index].value -
            newFuturePlan[primary_key][index].down
          )
        );

        newFuturePlan[primary_key][index] = {
          ...newFuturePlan[primary_key][index],
          sum_invest_five,
          pay_per_year,
        };
      }

      setFuturePlanData(newFuturePlan);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onChangeCheckBoxValue = (
    e,
    primary_key,
    key,
    index,
    type = "string"
  ) => {
    try {
      let value = e.target.value;
      let isCheck = e.target.checked;

      // console.log("value",value)
      // console.log("isCheck",isCheck)
      // console.log("----------------")

      const newFuturePlan = { ...futurePlanData };

      newFuturePlan[primary_key][index] = {
        ...newFuturePlan[primary_key][index],
        [key]: "",
      };

      if (isCheck) {
        newFuturePlan[primary_key][index] = {
          ...newFuturePlan[primary_key][index],
          [key]: value,
        };
      }

      setFuturePlanData(newFuturePlan);
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteForm = (primary_key) => {
    setFuturePlanData({ ...futurePlanData, [primary_key]: [] });
  };

  return (
    <div className=" flex flex-col gap-2.5">
      <div className=" text-maincyan text-xl font-semibold text-left">
        รายจ่ายในอนาคต
      </div>

      {futurePlanData?.expenese_home_items?.length > 0 && (
        <CardFutureThree
          title={`ซื้อบ้าน`}
          title_number={`จำนวนบ้าน`}
          icon={`../images/house_welcome.png`}
          primary_key={`expenese_home_items`}
          all_data={futurePlanData?.expenese_home_items}
          onChangeForm={onChangeFormThree}
          onAddList={onAddList}
          onDeleteList={onDeleteList}
          deleteForm={deleteForm}
        />
      )}

      {futurePlanData?.expenese_car_items?.length > 0 && (
        <CardFutureThree
          title={`ซื้อรถ`}
          title_number={`จำนวนรถ`}
          icon={`../images/car_insuarance_all.png`}
          primary_key={`expenese_car_items`}
          all_data={futurePlanData?.expenese_car_items}
          onChangeForm={onChangeFormThree}
          onAddList={onAddList}
          onDeleteList={onDeleteList}
          deleteForm={deleteForm}
        />
      )}
      {futurePlanData?.expenese_children_items?.length > 0 && (
        <CardFutureThree
          title={`วางแผนมีลูก`}
          title_number={`จำนวนบุตร`}
          icon={`../images/baby.png`}
          primary_key={`expenese_children_items`}
          all_data={futurePlanData?.expenese_children_items}
          futurePlanData={futurePlanData}
          onChangeForm={onChangeFormThree}
          onChangeCheckBox={onChangeCheckBox}
          onChangeCheckBoxValue={onChangeCheckBoxValue}
          onAddList={onAddList}
          onDeleteList={onDeleteList}
          deleteForm={deleteForm}
          isHasPeriod={false}
          isHasEducation={true}
        />
      )}

      {futurePlanData?.expenese_travel_items?.length > 0 && (
        <CardFutureThree
          title={`ท่องเที่ยว`}
          title_number={`จำนวนท่องเที่ยว`}
          icon={`../images/car_tour.png`}
          primary_key={`expenese_travel_items`}
          all_data={futurePlanData?.expenese_travel_items}
          onChangeForm={onChangeFormThree}
          onAddList={onAddList}
          onDeleteList={onDeleteList}
          deleteForm={deleteForm}
          isHasPeriod={false}
        />
      )}

      {futurePlanData?.expenese_collection_items?.length > 0 && (
        <CardFutureThree
          title={`ซื้อของสะสม`}
          title_number={`จำนวนของสะสม`}
          icon={`../images/buy.png`}
          primary_key={`expenese_collection_items`}
          all_data={futurePlanData?.expenese_collection_items}
          onChangeForm={onChangeFormThree}
          onAddList={onAddList}
          onDeleteList={onDeleteList}
          deleteForm={deleteForm}
          isHasPeriod={false}
        />
      )}

      {futurePlanData?.expenese_other_items?.length > 0 && (
        <CardFutureThree
          title={`อื่น ๆ`}
          title_number={`จำนวนของอื่น ๆ`}
          icon={`../images/smile_open.png`}
          primary_key={`expenese_other_items`}
          all_data={futurePlanData?.expenese_other_items}
          onChangeForm={onChangeFormThree}
          onAddList={onAddList}
          onDeleteList={onDeleteList}
          deleteForm={deleteForm}
          isHasPeriod={false}
        />
      )}

      {futurePlanData?.expenese_children_education?.length > 0 &&
        educationData?.map((item, index) => {
          return (
            <CardKidsInfo
              isEdit={false}
              isEditable={false}
              isFutureplan={true}
              index={index}
              name={item.name}
              year={item.year}
              age={item.age}
              img={item.img}
              onClickEdit={() => {}}
              onChangeEducation={() => {}}
              currentEducation={item?.current_education}
              targetEducation={item?.target_education}
              underEducatiion={item?.under_education}
            />
          );
        })}

      {futurePlanData?.income_legacy_items?.length > 0 && (
        <CardFutureTwo
          title={`รับมรดก`}
          icon={`../images/two_hearts.png`}
          all_data={futurePlanData?.income_legacy_items}
          primary_key={`income_legacy_items`}
          onChangeForm={onChangeFormThree}
          onAddList={onAddList}
          onDeleteList={onDeleteList}
          deleteForm={deleteForm}
        />
      )}

      {futurePlanData?.income_business_items?.length > 0 && (
        <CardFutureTwo
          title={`ขายธุรกิจ`}
          icon={`../images/saving_expenses.png`}
          all_data={futurePlanData?.income_business_items}
          primary_key={`income_business_items`}
          onChangeForm={onChangeFormThree}
          onAddList={onAddList}
          onDeleteList={onDeleteList}
          deleteForm={deleteForm}
        />
      )}

      {futurePlanData?.income_property_items?.length > 0 && (
        <CardFutureTwo
          title={`รายรับจากอสังหาริมทรัพย์ (ขาย เช่า เซ้ง)`}
          icon={`../images/invest_insuarance_all.png`}
          all_data={futurePlanData?.income_property_items}
          primary_key={`income_property_items`}
          onChangeCheckBox={onChangeCheckBox}
          onChangeCheckBoxValue={onChangeCheckBoxValue}
          onChangeForm={onChangeFormThree}
          onAddList={onAddList}
          onDeleteList={onDeleteList}
          deleteForm={deleteForm}
        />
      )}

      {futurePlanData?.income_other_items?.length > 0 && (
        <CardFutureTwo
          title={`อื่น ๆ`}
          icon={`../images/taxicon3.png`}
          all_data={futurePlanData?.income_other_items}
          primary_key={`income_other_items`}
          onChangeCheckBox={onChangeCheckBox}
          onChangeCheckBoxValue={onChangeCheckBoxValue}
          onChangeForm={onChangeFormThree}
          onAddList={onAddList}
          onDeleteList={onDeleteList}
          deleteForm={deleteForm}
        />
      )}

      {/* <CardFuture title={`รถยนต์`} icon={"../images/car_insuarance_all.png"} />
      <CardFutureTwo title={"ท่องเที่ยว"} icon={`../images/car_tour.png`} /> */}
    </div>
  );
};
