import _ from "lodash";
import { numberWithoutComma } from "../../../pages/balance/component/VIDrawerDebt.Main";
import { Tax408PropertyPercent, tax405Percent } from "./businessFunction";
import { calculateTaxProperty } from "../../../pages/tax_plan/functions";

export const IncomeTab4Calculation = (taxData) => {
  let salary_Income = numberWithoutComma(_.get(taxData, "tax_income", 0) || 0);
  let freeLance_Income = Number(taxData?.freelance?.tax_income || 0);
  let freeJob_Income = taxData?.freeJob?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_403 = taxData?.tax_403?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_405 = taxData?.tax_405?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_407 = taxData?.tax_407?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_408 = taxData?.tax_408?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_408_property = taxData?.tax_408_property?.reduce(
    (total, num) => {
      return total + Number(num?.tax_income || 0);
    },
    0
  );

  let income_tax_404 = taxData?.tax_404?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  // tax_404_stock
  let income_tax_404_stock = taxData?.tax_404_stock?.reduce((total, num) => {
    if (num?.type_job?.label === "หุ้น") {
      return total + Number(num?.dividend || 0) + Number(num?.tax_credit || 0);
    }
    return total + Number(num?.dividend || 0);
  }, 0);

  let income_not_include_tax = _.get(taxData, "income_not_include_tax", 0);

  let RMF_Profit =
    Number(taxData?.RMF_Profit?.selling_price || 0) -
    Number(taxData?.RMF_Profit?.cost_price || 0);
  let SSF_Profit =
    Number(taxData?.SSF_Profit?.selling_price || 0) -
    Number(taxData?.SSF_Profit?.cost_price || 0);

  let Income_42_26 = 0;
  let Income_42_27 = 0;
  let Income_42_28 = 0;

  if (!taxData?.heritage?.tax_calc_include) {
    Income_42_26 = Number(taxData?.heritage?.income_42_26 || 0);
    Income_42_27 = Number(taxData?.heritage?.income_42_27 || 0);
    Income_42_28 = Number(taxData?.heritage?.income_42_28 || 0);
  }

  let tax408_property = taxData?.tax_408_property?.reduce((total, num) => {
    if (num?.calculating_way === "ไม่แยกคำนวณ") {
      return total + Number(num?.tax_income || 0);
    }
    return total;
  }, 0);

  return (
    Number(salary_Income || 0) +
    Number(freeJob_Income || 0) +
    Number(freeLance_Income || 0) +
    Number(income_tax_403 || 0) +
    Number(income_tax_405 || 0) +
    Number(income_tax_407 || 0) +
    Number(income_tax_408 || 0) +
    // income_tax_408_property +
    Number(income_tax_404 || 0) +
    Number(income_tax_404_stock || 0) +
    Number(income_not_include_tax || 0) +
    Number(RMF_Profit || 0) +
    Number(SSF_Profit || 0) +
    Number(Income_42_26 || 0) +
    Number(Income_42_27 || 0) +
    Number(Income_42_28 || 0) +
    Number(tax408_property || 0)
  );
};

export const ExpenseTab4Calculation = (taxData) => {
  let salary_expense =
    numberWithoutComma(_.get(taxData, "tax_income", 0) || 0) / 2 > 100000
      ? 100000
      : numberWithoutComma(_.get(taxData, "tax_income", 0) || 0) / 2;

  let freelance =
    numberWithoutComma(taxData?.freelance?.tax_income || 0) / 2 > 100000
      ? 100000
      : numberWithoutComma(taxData?.freelance?.tax_income || 0) / 2;

  //   วิชาชีพอิสระ
  let freeJob_expense = taxData?.freeJob?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(3)
  let expense_tax_403 = taxData?.tax_403?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(5)
  let expense_tax_405 = taxData?.tax_405?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(7)
  let expense_tax_407 = taxData?.tax_407?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(8)
  let expense_tax_408 = taxData?.tax_408?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(8)_property
  let expense_tax_408_property = taxData?.tax_408_property?.reduce(
    (total, num) => {
      if (num?.calculating_method === "หักแบบเหมา") {
        return (
          total +
          (Number(num?.tax_income || 0) -
            Number(num?.income_for_calculating_all_in || 0))
        );
      }
      if (num?.calculating_method === "หักตามจริง") {
        return (
          total +
          (Number(num?.tax_income || 0) -
            Number(num?.income_for_calculating || 0))
        );
      }
      return total + 0;
    },
    0
  );

  let sumExpense =
    Number(salary_expense || 0) +
    Number(freelance || 0) +
    Number(freeJob_expense || 0) +
    Number(expense_tax_403 || 0) +
    Number(expense_tax_405 || 0) +
    Number(expense_tax_407 || 0) +
    Number(expense_tax_408 || 0) +
    Number(expense_tax_408_property || 0);

  return sumExpense;
};

export const ExpenstDetail = (taxData) => {
  // console.log("taxData",taxData)
  let expenselists = [];
  let salary = {
    label:
      "เงินเดือน ค่าจ้างที่ได้รับจากบริษัทฯ บุคคลทั่วไป ฯลฯ และเบี้ยประชุม ค่านายหน้า ค่าส่วนลดที่ได้รับจากบริษัทฯ บุคคลทั่วไป ฯลฯ 40(1)",
    percent: 50,
    value:
      numberWithoutComma(taxData?.tax_income) / 2 > 100000
        ? 100000
        : numberWithoutComma(taxData?.tax_income) / 2,
  };

  let freelance = {
    label:
      "เงินได้จากการรับจ้าง ฟรีแลนซ์ ค่าตำแหน่งเบี้ยประชุม หรือค่านายหน้า 40(2)",
    percent: 50,
    value:
      numberWithoutComma(taxData?.freelance?.tax_income || 0) / 2 > 100000
        ? 100000
        : numberWithoutComma(taxData?.freelance?.tax_income || 0) / 2,
  };

  let freejob403 = () => {
    let templabel = taxData?.tax_403?.map((item, index) => {
      if (index === 1) {
        return item?.type_job?.label;
      }
      if (index === 2) {
        return item?.type_job?.label;
      }
      return item?.type_job?.label;
    });

    if (templabel?.length === 2) {
      templabel = templabel[0] + "และ" + templabel[1] + " 40(3)";
    }

    if (templabel?.length === 3) {
      templabel =
        templabel[0] + ", " + templabel[1] + "และ" + templabel[2] + " 40(3)";
    }

    let expense = 0;

    taxData?.tax_403?.map((item, index) => {
      if (item?.calculating_method === "หักตามจริง") {
        expense += item?.income_for_calculating;
      } else {
        expense += item?.income_for_calculating_all_in;
      }
    });

    return { label: String(templabel), percent: 50, value: expense };
  };

  let tax405 = taxData?.tax_405?.map((item, index) => {
    let label = item?.type_job?.label + " 40(5)";
    let percent = "";
    let value = 0;

    if (item?.calculating_method === "หักแบบเหมา") {
      percent =
        "ร้อยละ " + tax405Percent.find((item) => item.label === label)?.value;
      value = item?.tax_income - item?.income_for_calculating_all_in;
    } else {
      percent = "ตามจริง";
      value = item?.Tax_Real;
    }

    return { label, percent, value };
  });

  let tax406 = taxData?.freeJob?.map((item, index) => {
    let label = item?.type_job?.label + " 40(6)";
    let percent = "";
    let value = 0;

    if (item?.calculating_method === "หักแบบเหมา") {
      percent =
        "ร้อยละ " +
        (item?.type_job?.value === " tax_income_medicine" ? "60" : "30");
      value = item?.tax_income - item?.income_for_calculating_all_in;
    } else {
      percent = "ตามจริง";
      value = item?.Tax_Real;
    }

    return { label, percent, value };
  });

  let tax407 = taxData?.tax_407?.map((item, index) => {
    let label = item?.type_job?.label + " 40(7)";
    let percent = "";
    let value = 0;

    if (item?.calculating_method === "หักแบบเหมา") {
      percent = "ร้อยละ " + 60;
      value = item?.tax_income - item?.income_for_calculating_all_in;
    } else {
      percent = "ตามจริง";
      value = item?.Tax_Real;
    }

    return { label, percent, value };
  });
  // console.log("tax407",tax407)

  let tax408 = taxData?.tax_408?.map((item, index) => {
    let label = item?.type_job?.label + " 40(8)";
    let percent = "";
    let value = 0;

    if (item?.calculating_method === "หักแบบเหมา") {
      percent = "ร้อยละ " + 60;
      value = item?.tax_income - item?.income_for_calculating_all_in;
    } else {
      percent = "ตามจริง";
      value = item?.Tax_Real;
    }

    return { label, percent, value };
  });

  let tax408_property = taxData?.tax_408_property?.map((item, index) => {
    let label = item?.type_job?.label + " 40(8)";
    let percent = "";
    let value = 0;

    if (item?.calculating_way === "ไม่แยกคำนวณ") {
      if (item?.calculating_method === "หักแบบเหมา") {
        percent =
          item?.type_job?.label ===
          "การขายอสังหาริมทรัพย์ที่ได้มาโดยมิได้มุ่งในทางการค้าหรือหากำไร"
            ? "ร้อยละ " + Tax408PropertyPercent(item?.tax_holding_year || 0)
            : 50;
        value = item?.tax_income - item?.income_for_calculating_all_in;
      }

      if (item?.calculating_method === "หักตามจริง") {
        percent = "ตามจริง";
        value = item?.tax_income - item?.income_for_calculating;
      }
    }

    return { label, percent, value };
  });

  expenselists.push(salary);
  expenselists.push(freelance);
  expenselists.push(freejob403());
  expenselists = [...expenselists, ...(tax405 || [])];
  expenselists = [...expenselists, ...(tax406 || [])];
  expenselists = [...expenselists, ...(tax407 || [])];
  expenselists = [...expenselists, ...(tax408 || [])];
  expenselists = [...expenselists, ...(tax408_property || [])];

  //   console.log("expenselists",expenselists)

  return expenselists.filter((item) => item.value > 0);
};

export const IncomeDetail = (taxData) => {
  let expenselists = [];
  let salary = {
    label:
      "เงินเดือน ค่าจ้างที่ได้รับจากบริษัทฯ บุคคลทั่วไป ฯลฯ และเบี้ยประชุม ค่านายหน้า ค่าส่วนลดที่ได้รับจากบริษัทฯ บุคคลทั่วไป ฯลฯ (40(1))",
    value: numberWithoutComma(taxData?.tax_income || 0),
  };

  let freelance = {
    label:
      "เงินได้จากการรับจ้าง ฟรีแลนซ์ ค่าตำแหน่ง เบี้ยประชุม หรือค่านายหน้า (40(2))",
    value: taxData?.freelance?.tax_income,
  };

  // console.log("tax_income", taxData?.tax_income);

  let freejob403 = () => {
    let templabel = taxData?.tax_403?.map((item, index) => {
      if (index === 1) {
        return item?.type_job?.label;
      }
      if (index === 2) {
        return item?.type_job?.label;
      }
      return item?.type_job?.label;
    });

    if (templabel?.length === 2) {
      templabel = templabel[0] + "และ" + templabel[1] + " (40(3))";
    }

    if (templabel?.length === 3) {
      templabel =
        templabel[0] + ", " + templabel[1] + "และ" + templabel[2] + " 40(3)";
    }

    let expense = 0;

    taxData?.tax_403?.map((item, index) => {
      expense += item?.tax_income;
    });

    return { label: templabel?.toString(), value: expense };
  };

  let freeJob = taxData?.freeJob?.map((item, index) => {
    let label = item?.type_job?.label + " (40(6))";
    let percent = "";
    let value = 0;

    value = item?.tax_income;

    return { label, percent, value };
  });

  let tax405 = taxData?.tax_405?.map((item, index) => {
    let label = item?.type_job?.label + " (40(5))";
    let percent = "";
    let value = 0;

    value = item?.tax_income;

    return { label, percent, value };
  });


  let tax407 = taxData?.tax_407?.map((item, index) => {
    let label = item?.type_job?.label + " (40(7))";
    let percent = "";
    let value = 0;

    value = item?.tax_income;

    return { label, percent, value };
  });

  let tax408 = taxData?.tax_408?.map((item, index) => {
    let label = item?.type_job?.label + "(40(8))";
    let percent = "";
    let value = 0;

    value = item?.tax_income;

    return { label, percent, value };
  });

  let tax404 = taxData?.tax_404?.map((item, index) => {
    let label = item?.type_job?.label + " (40(4))";
    let percent = "";
    let value = 0;

    value = item?.tax_income;

    return { label, percent, value };
  });

  let tax404_stock = taxData?.tax_404_stock?.map((item, index) => {
    let label = item?.type_job?.label + " (40(4))ข";
    let percent = "";
    let value = 0;

    value = Number(item?.dividend || 0) + Number(item?.tax_credit || 0);

    return { label, percent, value };
  });

  let RMF_Profit = Number(
    taxData?.RMF_Profit?.non_excepting_tax_differnce || 0
  );
  let SSF_Profit = Number(
    taxData?.SSF_Profit?.non_excepting_tax_differnce || 0
  );

  let RMFIncome = {
    label: "กำไรจากการขายกองทุนรวมเพื่อการเลี้ยงชีพ (RMF) (40(4))ข",
    value: RMF_Profit,
  };
  let SSFIncome = {
    label: "กำไรจากการขายกองทุนเพื่อการออม (SSF) (40(4))ข",
    value: SSF_Profit,
  };

  let Income_42_26 = {
    label:
      "เงินได้จากการโอนกรรมสิทธิ / สิทธิครอบครอง ในอสังหาริมทรัพย์ตามมาตรา 42 (26)",
    value: Number(taxData?.heritage?.income_42_26 || 0),
  };
  let Income_42_27 = {
    label:
      "เงินได้ที่ได้รับจากการอุปการะ/จากการให้ โดยเสน่หาจากบุพการี ตามมาตรา 42 (27)",
    value: Number(taxData?.heritage?.income_42_27 || 0),
  };
  let Income_42_28 = {
    label:
      "เงินได้ที่ได้รับจากการอุปการะ/จากการให้ โดยเสน่หาจากบุพการี ตามมาตรา 42 (28)",
    value: Number(taxData?.heritage?.income_42_28 || 0),
  };

  let tax408_property = taxData?.tax_408_property?.map((item, index) => {
    let label = item?.type_job?.label + " (40(8))";
    let percent = "";
    let value = item?.tax_income;

    if (item?.calculating_way !== "ไม่แยกคำนวณ") {
      label = item?.type_job?.label;
      value = 0;
    }

    return { label, percent, value };
  });

  expenselists.push(salary);
  expenselists.push(freelance);
  expenselists.push(freejob403());
  expenselists = [...expenselists, ...(tax405||[])];
  expenselists = [...expenselists, ...(freeJob||[])];
  expenselists = [...expenselists, ...(tax407||[])];
  expenselists = [...expenselists, ...(tax408||[])];
  expenselists = [...expenselists, ...(tax408_property||[])];
  expenselists = [...expenselists, ...(tax404||[])];
  expenselists = [...expenselists, ...(tax404_stock||[])];
  expenselists.push(RMFIncome);
  expenselists.push(SSFIncome);
  expenselists.push(Income_42_26);
  expenselists.push(Income_42_27);
  expenselists.push(Income_42_28);

  return expenselists.filter((item) => item.value > 0);
};

export const sum408property = (taxData) => {
  let findProperty = taxData?.tax_408_property
    ?.map((item) => item.calculating_way)
    ?.some((item) => item === "แยกคำนวณ");
  if (findProperty) {
    let totalTax = taxData?.tax_408_property
      ?.filter((item) => item?.calculating_way === "แยกคำนวณ")
      ?.reduce((total, num) => {
        let year = Number(num?.tax_holding_year || 0);
        let income = Number(num?.tax_income || 0);
        let expense = Number(num?.Tax_Real || 0);

        let witholding_tax = Number(num?.with_holding_tax || 0);
        let calTax =
          calculateTaxProperty((income - expense) / year) * year -
          witholding_tax;

        return total + calTax;
      }, 0);
    return totalTax;
  }
  return 0;
};

export const sum408RecieveMoney = (taxData) => {
  if (taxData?.heritage?.tax_calc_include) {
    return (
      (taxData?.heritage?.income_42_26 +
        taxData?.heritage?.income_42_27 +
        taxData?.heritage?.income_42_28) *
      0.05
    );
  }
  return 0;
};
