import numeral from "numeral";
import React from "react";
import { CardMain } from "../../../components/card/CardMain";
import Container from "../../../components/layout/Container";
import { cn } from "../../../utils/useFunctions";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_PADDING,
  STYLE_TEXT_BLUE,
} from "../../../utils/useStyle";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import { PdfHeaderText } from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import { STYLE_PAGE } from "../styles/pdf.style";
import { tax405Percent } from "../../../functions/taxFucntion/premium/businessFunction";

const IncomeTaxNotIncludePdf = ({
  page,
  taxData = {},
  allList = [],
  isFirst = false,
}) => {
  const sumStockDividend = () => {
    let sum = allList
      ?.filter((item) => item?.type_job?.label === "หุ้น")
      ?.reduce((total, num) => {
        return total + Number(num?.dividend || 0);
      }, 0);

    return numeral(sum)?.format("0,0");
  };

  const sumTaxCreditDividend = () => {
    let sum = allList?.reduce((total, num) => {
      return total + Number(num?.tax_credit || 0);
    }, 0);

    return numeral(sum)?.format("0,0");
  };

  const sumFundDividend = () => {
    let sum = allList
      ?.filter((item) => item?.type_job?.label === "กองทุน")
      ?.reduce((total, num) => {
        return total + Number(num?.dividend || 0);
      }, 0);

    return numeral(sum)?.format("0,0");
  };

  const sumWithHoldingTaxDividend = () => {
    let sum = allList?.reduce((total, num) => {
      return total + Number(num?.with_holding_tax || 0);
    }, 0);

    return numeral(sum)?.format("0,0");
  };

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="วางแผนภาษี"
        icon="/images/tax_plan.svg"
        classNameIcon="w-[24px] h-[27px]"
      />

      <Container classnameInner={""}>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <div className={STYLE_PARENT_PADDING}>
            <CardMain
              border={false}
              icon={false}
              marginyellow="mt-0"
              padding=" py-0 "
              panel={
                <div className=" px-3 -mt-3">
                  <div className=" text-xl font-medium pr-3 flex">
                    เงินได้พึงประเมินที่ได้ใช้สิทธิเลือกเสียภาษีโดย
                    ไม่ต้องนำมารวมคำนวณภาษีกับเงินได้อื่น
                  </div>
                </div>
              }
            />
            <CardMain
              border={false}
              icon={false}
              marginyellow="mt-3"
              panel={
                <div className=" px-3 -mt-3">
                  <div className="w-full flex justify-between text-[11px] font-[500]">
                    <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      เงินได้พึงประเมินที่นำมาเป็นฐานในการคำนวณ ซื้อหน่วยลงทุน
                    </div>
                    <div
                      className={cn(STYLE_TEXT_BLUE, {
                        "w-fit whitespace-nowrap": true,
                      })}
                    >
                      {" "}
                      {`${taxData?.income_not_include_tax}`}{" "}
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </Container>
      <PdfFooter page={page} />
    </div>
  );
};

export default IncomeTaxNotIncludePdf;
