import { Drawer } from "@material-tailwind/react";

import {
  STYLE_BORDER_YELLOW_PROPOTION,
  STYLE_CARD_BALANCE,
  STYLE_CARD_PROPOTION,
  STYLE_HEADER_SECTION_PROPOTION,
  STYLE_IMG_BALANCE,
  STYLE_TITLE_BALANCE,
  STYLE_TITLE_PROPOTION,
  STYLE_VALUE_BALANCE,
} from "../../../utils/useStyle";
import { useBalanceContext } from "../Balance.Main";
import numeral from "numeral";
import { useEffect, useMemo } from "react";
import {
  ICON_BUILDING,
  ICON_CONTRACT,
  ICON_HOUSE,
  ICON_NAVIGATE_BALANCE,
} from "../../../utils/useIcons";
import {
  DonutChartEmpty,
  DonutChartMain,
  DonutChartProperty,
} from "../../../components/chart/DonutChartMain";
import { BoxWhite } from "../styles/Card";
import { useBalanceStore } from "../../../_store/balanceStore";
import { SumDebtInvestValue, SumOtherDebtAmount } from "../../../functions/Balance/sumValueBalance";

const ViewPropertyProportion = ({ formBL, onClick }) => {
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);

  const balanceData = useBalanceStore((state) => state.balanceData);
  
  const tempSumPropertyInvest = useMemo(() => {
    if (balanceData?.expenses_fixed_list) {
      return balanceData?.expenses_fixed_list?.filter(
        (item) => item.options_expenses_fixed === "expenses_insuarant_life"
      )?.map((item)=> item?.expenses_insuarant_lifes)?.flat()
      ?.reduce((a, b) => a + Number(b?.more_details?.policy_surrender_value || 0), 0)
    }
    return 0
  }, [balanceData]);

  return (
    <div>
      <div className={STYLE_CARD_PROPOTION}>
        <div className={`${STYLE_HEADER_SECTION_PROPOTION} `}>
          <div className="">
            <div className={STYLE_TITLE_PROPOTION}>สัดส่วนสินทรัพย์</div>
            <div className={STYLE_BORDER_YELLOW_PROPOTION}></div>
          </div>
          <ICON_NAVIGATE_BALANCE onClick={onClick} />
        </div>

        {balanceData?.property_propotion+SumDebtInvestValue()+SumOtherDebtAmount() <= 0 ||
        isNaN(balanceData?.property_propotion+SumDebtInvestValue()+SumOtherDebtAmount()) ? (
          <DonutChartEmpty text={`สินทรัพย์`} />
        ) : (
          <DonutChartProperty
            text={`สินทรัพย์`}
            value={numeral(Number(balanceData?.property_propotion||0)+Number(SumDebtInvestValue()||0)+Number(SumOtherDebtAmount()||0)).format("0,0")}
            data1={balanceData?.sum_property_asset}
            data2={balanceData?.sum_property_invest+SumDebtInvestValue()}
            data3={balanceData?.sum_property_private+SumOtherDebtAmount()}
            data4={balanceData?.sum_property_intangible}
          />
        )}
        <BoxWhite
          title="สินทรัพย์สภาพคล่อง"
          icon="/images/proporty_asset2.png"
          colortext="text-purple700"
          value={balanceData?.sum_property_asset}
        />
        <BoxWhite
          title="สินทรัพย์เพื่อการลงทุน"
          icon={
            <ICON_BUILDING classNames=" w-8 h-8 fill-[#7f1eff] stroke-[#7f1eff] " />
          }
          colortext="text-[#7f1eff]"
          value={balanceData?.sum_property_invest+tempSumPropertyInvest+SumDebtInvestValue()}
          isIconTypeImage={false}
        />
        <BoxWhite
          title="สินทรัพย์ใช้ส่วนตัว" 
          icon={
            <ICON_HOUSE classNames=" w-8 h-8 stroke-[#8c37fc] fill-none " />
          }
          colortext="text-[#8c37fc]"
          value={balanceData?.sum_property_private+SumOtherDebtAmount()}
          isIconTypeImage={false}
        />
        <BoxWhite
          title="สินทรัพย์ไม่มีตัวตน"
          icon={
            <ICON_CONTRACT classNames=" w-8 h-8 fill-[#8D32E8] stroke-[#8D32E8] " />
          }
          colortext="text-purple600"
          value={balanceData?.sum_property_intangible}
          isIconTypeImage={false}
        />
      </div>
    </div>
  );
};

export default ViewPropertyProportion;
