import numeral from "numeral";
import React from "react";
import { CardMain } from "../../../components/card/CardMain";
import Container from "../../../components/layout/Container";
import { cn } from "../../../utils/useFunctions";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_PADDING,
  STYLE_TEXT_BLUE,
} from "../../../utils/useStyle";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import { PdfHeaderText } from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import { STYLE_PAGE } from "../styles/pdf.style";
import { tax405Percent } from "../../../functions/taxFucntion/premium/businessFunction";

const Income404Pdf = ({
  page,
  taxData = {},
  datalist = [],
  isFirst = false,
  isLast = false,
}) => {
  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="วางแผนภาษี"
        icon="/images/tax_plan.svg"
        classNameIcon="w-[24px] h-[27px]"
      />

      <Container classnameInner={""}>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <div className={STYLE_PARENT_PADDING}>
            {isFirst && (
              <CardMain
                border={false}
                icon={false}
                marginyellow="mt-0"
                padding=" py-0 "
                panel={
                  <div className=" px-3 -mt-3">
                    <div className=" text-xl font-medium pr-3 flex">
                      ดอกเบี้ย เงินปันผลจากบริษัทต่างประเทศ ประโยชน์ใดๆ
                      จากคริปโทเคอร์เรนซีหรือโทเคนดิจิทัล เงินเพิ่มทุน เงินลดทุน
                      (มาตรา 40(4))
                    </div>
                  </div>
                }
              />
            )}
            {datalist?.map((item, index) => {
              return (
                <CardMain
                  border={false}
                  icon={false}
                  marginyellow="mt-3"
                  panel={
                    <div className=" px-3 -mt-3">
                      <div className="w-fit flex justify-between text-[11px] font-[500]">
                        <div
                          className={` text-base text-mainblue font-semibold w-full mb-1`}
                        >
                          รายการที่
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap  text-base text-mainblue font-semibold": true,
                          })}
                        >
                          {item?.order}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          ประเภทธุรกิจ
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${item?.type_job?.label}`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          เงินได้ทั้งหมด
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${numeral(item?.tax_income)?.format(
                            "0,0"
                          )} บาท`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          ภาษีหัก ณ ที่จ่าย
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${numeral(item?.with_holding_tax)?.format(
                            "0,0"
                          )} บาท`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          เลขผู้จ่ายเงินได้
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${item?.tax_payer_ID}`}{" "}
                        </div>
                      </div>
                    </div>
                  }
                />
              );
            })}
          </div>
        </div>
      </Container>
      <PdfFooter page={page} />
    </div>
  );
};

export default Income404Pdf;
