import { cn, mapStatus } from "../../../utils/useFunctions";
import { PdfCard, PdfCardWhite } from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_11,
} from "../styles/pdf.style";
import { useBalanceStore } from "../../../_store/balanceStore";
import numeral from "numeral";
import { useEffect, useMemo } from "react";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import {
  ICON_BAHT,
  ICON_CHECK,
  ICON_CLOSE,
  ICON_GRAPH,
  ICON_SAVING,
  ICON_STAR,
  ICON_WARNING,
} from "../../../utils/useIcons";
import { STYLE_CARD_BG } from "../../../utils/useStyle";
import PdfFooter from "../component/pdf.c.footer";
import LineChartMain from "../../../components/chart/LineChartMain";
import { useRetireStore } from "../../../_store/retireStore";
import {
  dataAge,
  dataAgeAF,
  loopInvestmentAssets,
  loopInvestmentAssetsAF,
} from "../../retirement_plan/functions/functionRetriementPlan";
import { calPMT } from "../../education/functions/function";
import { calculateTax, checkSum } from "../../tax_plan/functions";
import { useTaxStore } from "../../../_store/taxplanStore";
import { useStrategyStore } from "../../../_store/strategyRetireStore";
import { avgWeight } from "../../retirement_plan/functions/functionMain";
import {
  lastResultInvestmentAssetsAFSensitive,
  loopInvestmentAssetsAFSensitive,
} from "../../retirement_plan/functions/functionRetrementSensitivePlan";
import { ImageMain } from "../../../components/img/Img.Main";

function PdfPageRetireThree({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);
  const retireData = useRetireStore((state) => state.retireData);
  const taxData = useTaxStore((state) => state.taxData);
  const strategy = useStrategyStore((state) => state.strategy);

  const getCaltax = () => {
    let result = 0;
    result =
      taxData?.tax_income -
      taxData?.deduction_expenses -
      taxData?.deduct_summary;
    return calculateTax(result);
  };

  const displaySumAfterTaxPaid = () => {
    let result = 0;
    result =
      Number(taxData?.tax_income) -
      Number(taxData?.deduction_expenses) -
      Number(taxData?.deduct_summary_af);

    return calculateTax(result);
  };

  const displayTaxPaid = useMemo(
    () => calculateTax(taxData?.tax_income),
    [taxData?.tax_income]
  );

  const displaySumTax = useMemo(
    () => checkSum(getCaltax(), taxData?.after_tax_paid, 0, 0),
    [getCaltax(), taxData?.after_tax_paid, 0, 0]
  );

  const checkPercent = () => {
    let result = 0;
    result = ((getCaltax() - taxData?.after_tax_paid) / getCaltax()) * 100;
    if (isNaN(result)) {
      return 0;
    }
    return Number(result)?.toFixed(2);
  };

  const dataPlanBf = retireData?.bf_investment_assets?.map(
    (bf_investment_assets) => {
      return bf_investment_assets?.results;
    }
  );

  // สภาพคล่อง
  const valueProperty = useMemo(() => {
    let sum = 0;
    const expenses_permonth = balanceData?.expenses_propotion / 12;
    if (balanceData?.property_propotion) {
      sum =
        expenses_permonth > 0
          ? balanceData?.property_propotion / expenses_permonth
          : 0;
    }
    return Math.round(sum);
  }, [balanceData]);
  const getPropertyStatus = () => {
    if (!balanceData?.property_propotion) return;

    if (valueProperty < 6) {
      return "bad";
    } else if (valueProperty === 6) {
      return "good";
    } else {
      return "chance";
    }
  };

  // หนี้สินต่อทรัพย์สิน
  const valueDebttoTotalAsset = useMemo(() => {
    let sum = 0;
    if (balanceData?.debt_propotion) {
      sum =
        balanceData?.property_propotion > 0
          ? (balanceData?.debt_propotion / balanceData?.property_propotion) *
            100
          : 0;
    }
    return sum;
  }, [balanceData]);
  const getDebttoTotalAssetStatus = () => {
    if (!balanceData?.debt_propotion) return;

    if (valueDebttoTotalAsset > 50) {
      return "bad";
    }
    return "good";
  };

  // ชำระหนี้ต่อรายได้
  const valueDebttoSalary = useMemo(() => {
    let sum = 0;
    const debtPermonth =
      balanceData?.sum_debt_short_permonth +
      balanceData?.sum_debt_long_permonth;
    const salaryPermonth = balanceData?.salary_propotion
      ? balanceData?.salary_propotion / 12
      : 0;
    sum = salaryPermonth > 0 ? (debtPermonth / salaryPermonth) * 100 : 0;
    return sum;
  }, [balanceData]);
  const getDebttoSalaryStatus = () => {
    if (valueDebttoSalary > 50) {
      return "bad";
    }
    return "good";
  };

  // การออม
  const valueConservation = useMemo(() => {
    let sum = 0;
    if (balanceData?.sum_expenses_invest_saving) {
      sum = balanceData?.sum_expenses_invest_saving;
    }
    const finalResult =
      sum > 0 ? (sum / balanceData?.salary_propotion) * 100 : 0;
    return finalResult;
  }, [balanceData]);
  const getConservationStatus = () => {
    if (!balanceData?.sum_expenses_invest_saving) return;

    if (valueConservation < 10) {
      return "bad";
    }
    return "good";
  };

  // การลงทุน
  const valueInvest = useMemo(() => {
    let sum = 0;
    if (balanceData?.sum_property_invest) {
      sum =
        balanceData?.net_wealth > 0
          ? (balanceData?.sum_property_invest / balanceData?.net_wealth) * 100
          : 0;
    }
    return sum;
  }, [balanceData]);
  const getInvestStatus = () => {
    if (!balanceData?.sum_property_invest) return;

    if (valueInvest > 50) {
      return "good";
    }
    return "bad";
  };

  // วางแผนเกษียณ
  const getInvestSum = loopInvestmentAssets();
  const lastResultBF = getInvestSum[getInvestSum?.length - 1];
  const retirementAge = retireData?.age_retire;
  const retireBF = getInvestSum.find(
    (result) => result.age === retirementAge
  )?.results;
  const getremainBF = getInvestSum?.filter((thisRemain, i) => {
    if (thisRemain?.results <= 0) return { ...thisRemain };
  })?.[0];
  const remainBF = getremainBF?.age - 1;
  const beforeDiffYear = retireData?.age_retire - retireData?.age_current;
  const sumBeforeRetireInvest = useMemo(() => {
    const convertedMonth = beforeDiffYear * 12;
    const sumPMT = calPMT(
      retireData?.investment_bf_retirement / 100 / 12,
      convertedMonth,
      -lastResultBF?.results * -1
    );
    return sumPMT;
  }, [retireData]);
  const strategyChange = retireData?.strategy_sum ? true : false;

  const dataPlanAF = retireData?.af_investment_assets?.map(
    (af_investment_assets) => {
      return af_investment_assets?.results;
    }
  );

  const getInvestSumAF = loopInvestmentAssetsAF();
  const lastResultAF = getInvestSumAF[getInvestSumAF?.length - 1];
  const getremainAF = getInvestSumAF?.filter((thisRemain, i) => {
    if (thisRemain?.results <= 0) return { ...thisRemain };
  })?.[0];
  const remainAF = Number(getremainAF?.age) - 1;
  const afterDiffYear =
    retireData?.strategy_age_retire - retireData?.age_current;

  const convertYearToMonth = (year) => {
    return year * 12;
  };

  const sumAfterRetireInvest = useMemo(() => {
    const convertedMonth = convertYearToMonth(afterDiffYear);
    const sumPMT = calPMT(
      retireData?.strategy_investment_bf_retirement / 100 / 12,
      convertedMonth,
      -lastResultAF?.results * -1
    );
    return sumPMT;
  }, [retireData]);

  const getDetailSensitive = () => {
    return [
      {
        icon: (
          <ImageMain src="/images/assetdown.svg" className="w-4 h-4 mb-3" />
        ),
        render: () => {
          return (
            <div className="text-[10px] flex gap-1">
              <div className="   flex-none">
                สถานการณ์ที่ 1
                <div className="  ">
                  เศรษฐกิจถดถอย ผลตอบแทนการลงทุน
                  <div>
                    หลังเกษียณ เหลือเพียง{" "}
                    <span className=" font-semibold text-red300">1 %</span>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        icon: (
          <ImageMain src="/images/heart_medical.svg" className="w-4 h-4 mb-3" />
        ),
        render: () => {
          return (
            <div className="text-[10px] flex gap-1">
              <div className="   flex-none">
                สถานการณ์ที่ 2
                <div className="  ">
                  เจ็บป่วยโรคร้ายแรงมีค่ารักษาพยาบาล
                  <div>
                    1 ล้านบาท ตอนอายุ{" "}
                    <span className=" font-semibold text-red300">
                      {" "}
                      {retireData?.age_current
                        ? retireData?.age_current + 5
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        icon: <ImageMain src="/images/moneybag.svg" className="w-4 h-4 mb-3" />,
        render: () => {
          return (
            <div className="text-[10px] flex gap-1">
              <div className="   ">
                สถานการณ์ที่ 3
                <div className="   ">
                  ค้ำประกันให้ผู้อื่น เกิดหนี้ 5 ล้านบาท{" "}
                  <div>
                    ตอนอายุ{" "}
                    <span className=" font-semibold text-maingreen">
                      {retireData?.age_current
                        ? retireData?.age_current + 10
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
    ];
  };

  const retireSensitiveData = loopInvestmentAssetsAFSensitive();
  const dataPlanSensitive = loopInvestmentAssetsAFSensitive()?.map((item) => {
    return item?.results;
  });
  const lastResultInvest = lastResultInvestmentAssetsAFSensitive();

  // หาอายุสุดท้าย
  const getAgeSensitive = retireSensitiveData?.filter((item) => {
    if (item?.results > 0) return item;
  });
  const ageSensitive = getAgeSensitive?.[getAgeSensitive?.length - 1]?.age;

  const weightValue = useMemo(
    // let avgWeight = (3 * 10000 + 6000 * 9) / (10000 + 6000);
    () =>
      avgWeight(
        Number(retireData?.investment_bf_retirement),
        Number(retireData?.investment_assets),
        Number(retireData?.strategy_assets_generate),
        Number(retireData?.strategy_assets_generate_per_year)
      ),
    [
      Number(retireData?.investment_bf_retirement),
      Number(retireData?.investment_assets),
      Number(retireData?.strategy_assets_generate),
      Number(retireData?.strategy_assets_generate_per_year),
    ]
  );

  const getFormattedValue = (row) => {
    if (row?.key === "strategy_out_come") {
      return numeral(retireData?.out_come)?.format("0,0");
    } else if (row?.key === "strategy_income") {
      return numeral(retireData?.salary)?.format("0,0");
    } else if (row?.key === "strategy_passive_income_retire") {
      return numeral(retireData?.pension)?.format("0,0");
    } else if (row?.key === "strategy_assets_generate") {
      return numeral(retireData?.investment_assets)?.format("0,0");
    } else if (row?.key === "strategy_assets_generate_per_year") {
      return numeral(retireData?.investment_bf_retirement)?.format("0,0");
    } else if (row?.key === "strategy_salary") {
      return numeral(retireData?.salary)?.format("0,0");
    } else if (row?.key === "strategy_salary_peryear") {
      return numeral(retireData?.salary_peryear)?.format("0,0");
    } else if (row?.key === "strategy_control_rate_outcome") {
      return numeral(retireData?.out_come_peryear)?.format("0,0");
    } else if (row?.key === "strategy_age_retire") {
      return retireData?.age_retire;
    } else if (row?.key === "strategy_age_dead") {
      return retireData?.age_dead;
    } else if (row?.key === "strategy_investment_bf_retirement") {
      return retireData?.investment_bf_retirement;
    } else if (row?.key === "strategy_investment_af_retirement") {
      return retireData?.investment_af_retirement;
    }
    return 0;
  };

  const getFormattedValueAfter = (row) => {
    if (row?.key === "strategy_out_come") {
      return numeral(
        retireData?.out_come - retireData?.strategy_out_come
      )?.format("0,0");
    } else if (row?.key === "strategy_income") {
      return numeral(retireData?.salary + retireData?.strategy_income)?.format(
        "0,0"
      );
    } else if (row?.key === "strategy_passive_income_retire") {
      return numeral(
        retireData?.pension + retireData?.strategy_passive_income_retire
      )?.format("0,0");
    } else if (row?.key === "strategy_assets_generate") {
      return numeral(
        retireData?.strategy_assets_generate + retireData?.investment_assets
      )?.format("0,0");
    } else if (row?.key === "strategy_assets_generate_per_year") {
      // return weightValue;
      return numeral(retireData?.strategy_assets_generate_per_year)?.format(
        "0,0"
      );
    } else if (row?.key === "strategy_salary") {
      return numeral(retireData?.strategy_salary)?.format("0,0");
    } else if (row?.key === "strategy_salary_peryear") {
      return numeral(retireData?.strategy_salary_peryear)?.format("0,0");
    } else if (row?.key === "strategy_control_rate_outcome") {
      return numeral(retireData?.strategy_control_rate_outcome)?.format("0,0");
    } else if (row?.key === "strategy_age_retire") {
      return retireData?.strategy_age_retire;
    } else if (row?.key === "strategy_age_dead") {
      return retireData?.strategy_age_dead;
    } else if (row?.key === "strategy_investment_bf_retirement") {
      return retireData?.strategy_investment_bf_retirement;
    } else if (row?.key === "strategy_investment_af_retirement") {
      return retireData?.strategy_investment_af_retirement;
    }
    return 0;
  };

  const getIcon = (row) => {
    if (row?.key === "strategy_out_come") {
      return "../images/stg_1.png";
    } else if (row?.key === "strategy_income") {
      return "../images/saving_expenses.png";
    } else if (row?.key === "strategy_passive_income_retire") {
      return "../images/stg_2.png";
    } else if (row?.key === "strategy_assets_generate") {
      return "../images/saving_save.png";
    } else if (row?.key === "strategy_assets_generate_per_year") {
      return "../images/saving_save.png";
    } else if (row?.key === "strategy_salary") {
      return "../images/stg_3.png";
    } else if (row?.key === "strategy_salary_peryear") {
      return "../images/stg_3.png";
    } else if (row?.key === "strategy_control_rate_outcome") {
      return "../images/stg_1.png";
    } else if (row?.key === "strategy_age_retire") {
      return "../images/stg_4.png";
    } else if (row?.key === "strategy_age_dead") {
      return "../images/stg_5.png";
    } else if (row?.key === "strategy_investment_bf_retirement") {
      return "../images/stg_4.png";
    } else if (row?.key === "strategy_investment_af_retirement") {
      return "../images/stg_4.png";
    }
  };

  const getETC = (row) => {
    if (row?.key === "strategy_assets_generate_per_year") {
      return (
        <div className=" text-xs w-full border-t border-gray-300 text-maingray">
          <div className=" mt-3 mb-1">
            *อัตราผลตอบแทนที่ใช้คำนวณ {weightValue}
          </div>
          <div>
            ** (สินทรัพย์มีไว้ลงทุน x อัตราผลตอบแทนก่อนเกษียณ) +
            (แปลงสินทรัพย์ให้มีผลตอบแทน x อัตราผลตอบแทนที่คาดหวัง) /
            (สินทรัพย์มีไว้ลงทุน + แปลงสินทรัพย์ให้มีผลตอบแทน){" "}
          </div>
        </div>
      );
    }
  };

  const keyUnit = [
    "strategy_out_come",
    "strategy_income",
    "strategy_passive_income_retire",
    "strategy_salary",
    "strategy_assets_generate",
  ];

  const keyUnitPercent = [
    "strategy_assets_generate",
    "strategy_assets_generate_per_year",
    "strategy_salary_peryear",
    "strategy_control_rate_outcome",
    "strategy_investment_bf_retirement",
    "strategy_investment_af_retirement",
  ];

  const getUnit = (row) => {
    return keyUnit?.includes(row?.key)
      ? "บาท"
      : keyUnitPercent?.includes(row?.key)
      ? "%"
      : "ปี";
  };

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="วางแผนเกษียณ" icon="/images/grandparents.svg" />
      {/* ทดสอบความอ่อนไหว */}
      <PdfCard title={"ทดสอบความอ่อนไหว"}>
        <PdfCardWhite
          className={"flex flex-col px-3 items-center justify-center"}
        >
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
            ความเคลื่อนไหวทางการเงิน 
          </div>
          <div className="flex space-x-2">
            <div className={cn("w-[230px] mr-4")}>
              <LineChartMain
                axisSize={6}
                labels={dataAgeAF()}
                dataPlan={dataPlanSensitive}
                fontSize="text-[10px]"
                head={``}
                // height={250}
                width={250}
                classNameBath={"md:top-[10%]"}
                classNameAge={"right-[-4%] md:right-[0%]"}
              />
            </div>

            <div className="flex flex-col">
              {lastResultInvest > 0 ? (
                <div
                  className={cn(
                    "w-full text-center text-black items-center text-md flex flex-col gap-[9px]",
                    STYLE_PDF_TEXT_NORMAL_11
                  )}
                >
                  <img src="/images/goodface.svg" className="w-6 h-6 mx-auto" />
                  <div>
                    <div>ยินดีด้วย...</div>
                    ท่านสามารถใช้ชีวิตหลังเกษียณได้ตามที่ท่านตั้งใจไว้
                  </div>
                </div>
              ) : (
                <div
                  className={cn(
                    "w-full text-center text-black text-md flex items-center flex-col gap-[9px]",
                    STYLE_PDF_TEXT_NORMAL_11
                  )}
                >
                  <img src="/images/badface.svg" className="w-6 h-6 mx-auto" />
                  <div>
                    ไม่นะ..เมื่อท่านอายุ {retireData?.age_dead} ปี
                    <div>เงินของท่านจะหมดลง ก่อนสิ้นอายุขัย</div>
                  </div>
                </div>
              )}
              <div className="mt-2 space-y-1 flex flex-col items-center justify-center">
                {getDetailSensitive()?.map((item, index) => {
                  return (
                    <div key={index} className="flex items-center gap-2.5">
                      <div>{item?.icon}</div>
                      {item?.render && item?.render()}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </PdfCardWhite>
      </PdfCard>

      {/* กลยุทธ์ที่ใช้ในการปรับ */}
      <PdfCard title={"กลยุทธ์ที่ใช้ในการปรับ"}>
        <PdfCardWhite className={"flex flex-col px-3"}>
          {strategy?.map((row, index) => {
            return (
              <div key={index} className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div>
                    <img className="w-[18px] h-[18px]" src={getIcon(row)} />
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {row?.title}
                  </div>
                </div>

                <div className="flex items-center space-x-2">
                  <div
                    className={cn(STYLE_PDF_TEXT_MEDIUM_11, "text-[#747474]")}
                  >
                    {getFormattedValue(row)} {getUnit(row)}
                  </div>
                  <div>
                    <img src="/images/arrow_left.svg" />
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_11)}>
                    {getFormattedValueAfter(row)} {getUnit(row)}
                  </div>
                </div>
              </div>
            );
          })}
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageRetireThree;
