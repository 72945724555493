import _ from "lodash";
import { useTaxStore } from "../../../_store/taxplanStore";
import { numberWithoutComma } from "../../../pages/balance/component/VIDrawerDebt.Main";

export const TabThreeSumIncomeCalculation = (taxData) => {
  let salary_Income = numberWithoutComma(_.get(taxData, "tax_income", 0) || 0);
  let freeLance_Income = Number(taxData?.freelance?.tax_income || 0);
  let freeJob_Income = taxData?.freeJob?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_403 = taxData?.tax_403?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_405 = taxData?.tax_405?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_407 = taxData?.tax_407?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_408 = taxData?.tax_408?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  let income_tax_408_property = taxData?.tax_408_property?.reduce(
    (total, num) => {
      return total + Number(num?.tax_income || 0);
    },
    0
  );

  let income_tax_404 = taxData?.tax_404?.reduce((total, num) => {
    return total + Number(num?.tax_income || 0);
  }, 0);

  // tax_404_stock
  let income_tax_404_stock = taxData?.tax_404_stock?.reduce((total, num) => {
    if (num?.type_job?.label === "หุ้น") {
      return total + Number(num?.dividend || 0) + Number(num?.tax_credit || 0);
    }
    return total + Number(num?.dividend || 0);
  }, 0);

  let income_not_include_tax = _.get(taxData, "income_not_include_tax", 0);

  let RMF_Profit =
    Number(taxData?.RMF_Profit?.selling_price || 0) -
    Number(taxData?.RMF_Profit?.cost_price || 0);
  let SSF_Profit =
    Number(taxData?.SSF_Profit?.selling_price || 0) -
    Number(taxData?.SSF_Profit?.cost_price || 0);

  let Income_42_26 = 0;
  let Income_42_27 = 0;
  let Income_42_28 = 0;

  if (!taxData?.heritage?.tax_calc_include) {
    Income_42_26 = Number(taxData?.heritage?.income_42_26 || 0);
    Income_42_27 = Number(taxData?.heritage?.income_42_27 || 0);
    Income_42_28 = Number(taxData?.heritage?.income_42_28 || 0);
  }

  let tax408_property = taxData?.tax_408_property?.reduce((total, num) => {
    if (num?.calculating_way === "ไม่แยกคำนวณ") {
      return total + Number(num?.tax_income || 0);
    }
    return total;
  }, 0);

  // console.log("salary_Income", salary_Income);
  // console.log("freeJob_Income", freeJob_Income);
  // console.log("freeLance_Income", freeLance_Income);
  // console.log("income_tax_403", income_tax_403);
  // console.log("income_tax_405", income_tax_405);
  // console.log("income_tax_407", income_tax_407);
  // console.log("income_tax_408", income_tax_408);
  // console.log("income_tax_404", income_tax_404);
  // console.log("income_tax_404_stock", income_tax_404_stock);
  // console.log("income_not_include_tax", income_not_include_tax);
  // console.log("RMF_Profit", RMF_Profit);
  // console.log("SSF_Profit", SSF_Profit);
  // console.log("Income_42_26", Income_42_26);
  // console.log("Income_42_27", Income_42_27);
  // console.log("Income_42_28", Income_42_28);
  // console.log("tax408_property", tax408_property);
  // console.log("---------------------------------------");

  return (
    Number(salary_Income || 0) +
    Number(freeJob_Income || 0) +
    Number(freeLance_Income || 0) +
    Number(income_tax_403 || 0) +
    Number(income_tax_405 || 0) +
    Number(income_tax_407 || 0) +
    Number(income_tax_408 || 0) +
    Number(income_tax_404 || 0) +
    Number(income_tax_404_stock || 0) +
    Number(income_not_include_tax || 0) +
    Number(RMF_Profit || 0) +
    Number(SSF_Profit || 0) +
    Number(Income_42_26 || 0) +
    Number(Income_42_27 || 0) +
    Number(Income_42_28 || 0) +
    Number(tax408_property || 0)
  );
};

export const TabThreeExpenseCalculation = (taxData) => {
  let salary_expense =
    numberWithoutComma(_.get(taxData, "tax_income", 0) || 0) / 2 > 100000
      ? 100000
      : numberWithoutComma(_.get(taxData, "tax_income", 0) || 0) / 2;

  //   วิชาชีพอิสระ
  let freeJob_expense = taxData?.freeJob?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(3)
  let expense_tax_403 = taxData?.tax_403?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(5)
  let expense_tax_405 = taxData?.tax_405?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(7)
  let expense_tax_407 = taxData?.tax_407?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(8)
  let expense_tax_408 = taxData?.tax_408?.reduce((total, num) => {
    if (num?.calculating_method === "หักแบบเหมา") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating_all_in || 0))
      );
    }
    if (num?.calculating_method === "หักตามจริง") {
      return (
        total +
        (Number(num?.tax_income || 0) -
          Number(num?.income_for_calculating || 0))
      );
    }
    return total + 0;
  }, 0);

  //   40(8)_property
  let expense_tax_408_property = taxData?.tax_408_property?.reduce(
    (total, num) => {
      if (num?.calculating_way === "ไม่แยกคำนวณ") {
        if (num?.calculating_method === "หักแบบเหมา") {
          return (
            total +
            (Number(num?.tax_income || 0) -
              Number(num?.income_for_calculating_all_in || 0))
          );
        }
        if (num?.calculating_method === "หักตามจริง") {
          return (
            total +
            (Number(num?.tax_income || 0) -
              Number(num?.income_for_calculating || 0))
          );
        }
      }
      return total + 0;
    },
    0
  );

  let sumExpense =
    Number(salary_expense || 0) +
    Number(freeJob_expense || 0) +
    Number(expense_tax_403 || 0) +
    Number(expense_tax_405 || 0) +
    Number(expense_tax_407 || 0) +
    Number(expense_tax_408 || 0) +
    Number(expense_tax_408_property || 0);

  //   console.log("freeJob_expense",freeJob_expense)
  //   console.log("expense_tax_403", expense_tax_403);
  //   console.log("tax_403", taxData?.tax_403);

  return sumExpense;
};

export const TabThreeWithHoldingTax = (taxData) => {
  let taxWithholdingNormal = numberWithoutComma(
    _.get(taxData, "tax_withholding", 0) || 0
  );

  let quitjob = taxData?.tax_QuitJob?.isQuitJob ? numberWithoutComma(taxData?.tax_QuitJob?.with_holding_tax || 0):0;

  let freelanceWithholding = Number(taxData?.freelance?.with_holding_tax || 0);
  let freeJobWithholding = taxData?.freeJob?.reduce((total, num) => {
    return total + Number(num?.with_holding_tax || 0);
  }, 0);

  let Withholding403 = taxData?.tax_403?.reduce((total, num) => {
    return total + Number(num?.with_holding_tax || 0);
  }, 0);

  let Withholding405 = taxData?.tax_405?.reduce((total, num) => {
    return total + Number(num?.with_holding_tax || 0);
  }, 0);

  let Withholding407 = taxData?.tax_407?.reduce((total, num) => {
    return total + Number(num?.with_holding_tax || 0);
  }, 0);

  let Withholding408 = taxData?.tax_408?.reduce((total, num) => {
    return total + Number(num?.with_holding_tax || 0);
  }, 0);

  let Withholding408_property = taxData?.tax_408_property?.reduce(
    (total, num) => {
      if (num?.calculating_way === "ไม่แยกคำนวณ") {
        return total + Number(num?.with_holding_tax || 0);
      }
      return total;
    },
    0
  );

  let income_tax_404 = taxData?.tax_404?.reduce((total, num) => {
    return total + Number(num?.with_holding_tax || 0);
  }, 0);

  let income_tax_404_stock = taxData?.tax_404_stock?.reduce((total, num) => {
    return (
      total + Number(num?.with_holding_tax || 0) + Number(num?.tax_credit || 0)
    );
  }, 0);

  let RMF_Profit = Number(taxData?.RMF_Profit?.withholding_tax || 0);
  let SSF_Profit = Number(taxData?.SSF_Profit?.withholding_tax || 0);

  let sumWitholdingTax =
    taxWithholdingNormal +
    quitjob +
    freelanceWithholding +
    freeJobWithholding +
    Withholding403 +
    Withholding405 +
    Withholding407 +
    Withholding408 +
    Withholding408_property +
    income_tax_404 +
    income_tax_404_stock +
    RMF_Profit +
    SSF_Profit;
  return sumWitholdingTax;
};
