import React from 'react'
import { cn } from '../../../utils/useFunctions';
import { STYLE_PAGE } from '../styles/pdf.style';
import PdfHeader from '../component/pdf.c.header';
import PdfLine from '../component/pdf.c.line';
import { PdfHeaderText } from '../component/pdf.c.headerText';
import { STYLE_MARGIN_TOP_PARENT, STYLE_PARENT_PADDING, STYLE_TEXT_BLUE } from '../../../utils/useStyle';
import { CardMain } from '../../../components/card/CardMain';
import numeral from 'numeral';
import { option_cal_express, option_first_cal } from '../../tax_plan/functions/options';
import PdfFooter from '../component/pdf.c.footer';
import Container from '../../../components/layout/Container';

const Income402Pdf = ({ page, taxData={} }) => {
    return (
        <div className={cn(STYLE_PAGE)}>
          <PdfHeader />
          <PdfLine />
          <PdfHeaderText
            title="วางแผนภาษี"
            icon="/images/tax_plan.svg"
            classNameIcon="w-[24px] h-[27px]"
          />
    
          <Container classnameInner={""}>
            <div className={STYLE_MARGIN_TOP_PARENT}>
              <div className={STYLE_PARENT_PADDING}>
                <CardMain
                  icon={false}
                  marginyellow="mt-3"
                  title={
                    <div className="text-[12px] font-[500]">
                      เงินได้จากการรับจ้าง ฟรีแลนซ์ ค่าตำแหน่ง เบี้ยประชุม หรือค่านายหน้า (มาตรา 40(2))
                    </div>
                  }
                  panel={
                    <div className=" px-3 -mt-3">
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          เงินได้ทั้งหมด
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${numeral(taxData?.freelance?.tax_income)?.format(
                            "0,0"
                          )} บาท`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          ภาษีหัก ณ ที่จ่าย 
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${numeral(taxData?.freelance?.with_holding_tax)?.format(
                            "0,0"
                          )} บาท`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                        เลขผู้จ่ายเงินได้
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${taxData?.freelance?.tax_payer_ID||"-"}`}{" "}
                        </div>
                      </div>
                    </div>
                  }
                />
               
              </div>
            </div>
          </Container>
          <PdfFooter page={page} />
        </div>
      );
}

export default Income402Pdf