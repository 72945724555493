import { Bar } from "react-chartjs-2";
import { STYLE_TITLE_DONUTCHART } from "../../../utils/useStyle";
import { useMemo } from "react";
import { size } from "lodash";
import { key_under_education } from "../../education/functions/options";

export const ChartCashFlow = ({
  futurePlanData = {},
  educationData={},
  dataDebt,
  labels,
  merge_keys = [],
  merge_keys_second=[],
  isRemoveGray = false,
  width = 500,
  height = 200,
}) => {
  const getRandomHexColor = () => {
    const randomColor = `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")}`;
    return randomColor;
  };
  const startingColor = "#D21329";
  const endingColor = "#ECBC3A";
  // const randomColor = randomColorInRange(startingColor, endingColor);
  const randomColor = getRandomHexColor();

  const TestClicklegend = (event, legendItem, legend) => {
    // console.log("event", event);
    // console.log("legendItem", legendItem);
    // console.log("legend", legend);
    // console.log("======================");
  };


  const colors = useMemo(() => {
    return merge_keys?.map((key) => {
      if (key === "income_peryear") {
        return "#47A3F7";
      } else if (key === "remain_accumulate") {
        return "#00B61D";
      } else if (key === "remain_accumulate") {
        return "#00B61D";
      } else if (key === "outcome_peryear") {
        return "#F7A647";
      } else if (key === "out_come_stable") {
        return "#ff33b2";
      } else if (key === "expenese_car_items") {
        return "#FF0000";
      } else if (key === "expenese_children_items") {
        return "#FF0000";
      } else if (key === "expenese_travel_items") {
        return "#FF0000";
      } else if (key === "expenese_collection_items") {
        return "#FF0000";
      } else if (key === "expenese_other_items") {
        return "#FF0000";
      } else if (key === "income_legacy_items") {
        return "#00BDCE";
      } else if (key === "income_business_items") {
        return "#00FFFF";
      } else if (key === "income_property_items") {
        return "#FFA500";
      } else if (key === "income_other_items") {
        return "#0000FF";
      } else if (key === "pension_peryear") {
        return "#0bdaf2";
      }
    });
  }, [dataDebt]);

  const merge_label_key = {
    expenese_home_items: "บ้าน",
    expenese_car_items: "รถ",
    expenese_children_items: "ลูก",
    expenese_travel_items: "ท่องเที่ยว",
    expenese_collection_items: "ของสะสม",
    expenese_other_items: "รายจ่ายอื่นๆ",
    income_legacy_items: "รายได้จากมรดก",
    income_business_items: "รายได้จากธุรกิจ",
    income_property_items: "รายได้จากอสังหาริม",
    income_other_items: "รายได้อื่นๆ",
    income_peryear: "รายได้",
    remain_accumulate: "สินทรัพย์ลงทุน",
    outcome_peryear: "จ่ายผันแปร",
    out_come_stable: "จ่ายคงที่ (ไม่รวมหนี้)",
    outcome_total: "รวมรายจ่ายต่อปี ",
    pension_peryear: "บำนาญต่อปี",
    remain: "เงินคงเหลือ",
    income_investment: "ผลตอบแทนการลงทุน ",
    debt_shortterm_other: "หนี้ดอกลอย",
    debt_informal_shortterm: "หนี้นอกระบบ",
    debt_shortterm: "หนี้ระยะสั้น",
    debt_creditcard: "หนี้บัตรเครดิต",
    expenese_children_education: "การศึกษาบุตร",
    sum_expenese_total: "รวมรายจ่ายต่อปี",
  };

  const negativeList = [
    "บ้าน",
    "รถ",
    "ลูก",
    "ท่องเที่ยว",
    "รายจ่ายอื่นๆ",
    "จ่ายผันแปร",
    "จ่ายคงที่ (ไม่รวมหนี้)",
    "หนี้นอกระบบ",
    "หนี้ระยะสั้น",
    "หนี้บัตรเครดิต",
    "การศึกษาบุตร",
  ];

  const datasets = dataDebt
  .map((data, index) => {
    let merge_key =
      merge_label_key[merge_keys[index]] !== undefined
        ? merge_label_key[merge_keys[index]]
        : merge_keys[index];
    let getrandomColor = getRandomHexColor();
    let tempExpenseSet = [];

    if (
      negativeList.includes(merge_key) 
    ) {
      tempExpenseSet = data?.map((item, index) =>
        Math.abs(item) === -0 || item === "" ? 0 : -Math.abs(item)
      );
    } else {
      tempExpenseSet = data?.map((item, index) =>
        item === -0 || item === "" ? 0 : item
      );
    }

    return {
      label: `${merge_key}`,
      data: tempExpenseSet,
      backgroundColor:
        colors[index] === undefined ? getrandomColor : colors[index],
      barThickness: 20,
    };
  })
  ?.filter(
    (item) =>
      item?.label !== "sum_expenese_total" &&
      item?.label !== "sum_income_total"
  );


  const datasets_second = merge_keys_second?.map((item,index)=>{
    let merge_key = '';
    let getrandomColor = getRandomHexColor();
    let tempExpenseSet = [];

    // รายจ่ายบ้าน
    if (item?.includes("_Is_type_expense_home_expense")) {
      merge_key = item?.split("_Is_type_expense_home_expense")[0]
      let finddata = futurePlanData?.expenese_home_items?.find((item)=> item?.title === merge_key )

      tempExpenseSet = futurePlanData?.cash_flow_items?.map((itemA,indexA)=>{
        if (finddata?.year > indexA) {
          return finddata?.sum_invest_five*(-1) ;
        }
        if (finddata?.year + finddata?.period_year > indexA) {
          return finddata?.pay_per_year * (-12);
        }
        return 0;
      })
    }

    // รายจ่ายรถ
    if (item?.includes("_Is_type_expense_car_expense")) {
      merge_key = item?.split("_Is_type_expense_car_expense")[0]
      let finddata = futurePlanData?.expenese_car_items?.find((item)=> item?.title === merge_key )

      tempExpenseSet = futurePlanData?.cash_flow_items?.map((itemA,indexA)=>{
        if (finddata?.year > indexA) {
          return finddata?.sum_invest_five*(-1) ;
        }
        if (finddata?.year + finddata?.period_year > indexA) {
          return finddata?.pay_per_year * (-12);
        }
        return 0;
      })
    }

    // รายจ่ายลูก
    if (item?.includes("_Is_type_expense_child_expense")) {
      merge_key = item?.split("_Is_type_expense_child_expense")[0]

      let finddata = futurePlanData?.expenese_children_items?.find((item)=> item?.title === merge_key )

      tempExpenseSet = futurePlanData?.cash_flow_items?.map((itemA,indexA)=>{
        if (finddata?.ishasEducation) {
          if (finddata?.year > indexA) {
            return finddata?.sum_invest_five*(-1);
          }

          if (
            finddata?.year - 1 < indexA &&
            finddata?.year + 19 > indexA
          ) {
            let eduExpense = Math.ceil(
              key_under_education?.[finddata?.education_under]
                ?.default_value * Math.pow(1.06, indexA - 1)
            );
            return eduExpense*(-1);
          }

          return 0;
        }

        if (finddata?.year - 1 > indexA) {
          return finddata?.sum_invest_five*(-1);
        }

        return 0;
      })
    }

    // รายจ่ายการศึกษาลูก
    if (item?.includes("_Is_type_expense_child_Education")) {
      merge_key = item?.split("_Is_type_expense_child_Education")[0]

      let finddata = educationData?.find((item)=> item?.name === merge_key )

      tempExpenseSet = finddata?.education_items?.map((itemA,indexA)=>{
        return itemA?.sum_under_education*(-1);
      })
    }

    // รายจ่ายท่องเที่ยว
    if (item?.includes("_Is_type_expense_travel_expense")) {
      merge_key = item?.split("_Is_type_expense_travel_expense")[0]

      let finddata = futurePlanData?.expenese_travel_items?.find((item)=> item?.title === merge_key )

      tempExpenseSet = futurePlanData?.cash_flow_items?.map((itemA,indexA)=>{
        if (finddata?.year > indexA) {
          return finddata?.sum_invest_five*(-1);
        }

        return 0;
      })
    }

    // รายจ่ายสะสม
    if (item?.includes("_Is_type_expense_collect_expense")) {
      merge_key = item?.split("_Is_type_expense_collect_expense")[0]

      let finddata = futurePlanData?.expenese_collection_items?.find((item)=> item?.title === merge_key )

      tempExpenseSet = futurePlanData?.cash_flow_items?.map((itemA,indexA)=>{
        if (finddata?.year > indexA) {
          return finddata?.sum_invest_five*(-1);
        }

        return 0;
      })
    }

    // รายจ่ายอื่น ๆ
    if (item?.includes("_Is_type_expense_other_expense")) {
      merge_key = item?.split("_Is_type_expense_other_expense")[0]

      let finddata = futurePlanData?.expenese_other_items?.find((item)=> item?.title === merge_key )
      
      tempExpenseSet = futurePlanData?.cash_flow_items?.map((itemA,indexA)=>{
        if (finddata?.year > indexA) {
          return finddata?.sum_invest_five*(-1);
        }

        return 0;
      })
    }

    // รายได้มรดก
    if (item?.includes("_Is_type_income_legacy_income")) {
      merge_key = item?.split("_Is_type_income_legacy_income")[0]

      let finddata = futurePlanData?.income_legacy_items?.find((item)=> item?.title === merge_key )
      
      tempExpenseSet = futurePlanData?.cash_flow_items?.map((itemA,indexA)=>{
        if ( finddata?.year === indexA) {
          return finddata?.amount;
        }

        return 0;
      })
    }

    // รายได้ธุรกิจ
    if (item?.includes("_Is_type_income_business_income")) {
      merge_key = item?.split("_Is_type_income_business_income")[0]

      let finddata = futurePlanData?.income_business_items?.find((item)=> item?.title === merge_key )
      
      tempExpenseSet = futurePlanData?.cash_flow_items?.map((itemA,indexA)=>{
        if ( finddata?.year === indexA) {
          return finddata?.amount;
        }

        return 0;
      })
    }

    // รายได้อสังหาริมทรัพย์
    if (item?.includes("_Is_type_income_property_income")) {
      merge_key = item?.split("_Is_type_income_property_income")[0]

      let finddata = futurePlanData?.income_property_items?.find((item)=> item?.title === merge_key )
      
      tempExpenseSet = futurePlanData?.cash_flow_items?.map((itemA,indexA)=>{
        if (finddata?.is_rent) {
          if (finddata?.year > indexA) {
            return 0;
          }
          if (
            finddata?.year <= indexA &&
            finddata?.year + finddata?.year_rent > indexA
          ) {
            return finddata?.amount_perYear;
          }
          return 0;
        }

        return indexA === finddata?.year ? finddata?.amount : 0;
      })
    }

    // รายได้อื่นๆ
    if (item?.includes("_Is_type_income_Other_income")) {
      merge_key = item?.split("_Is_type_income_Other_income")[0]

      let finddata = futurePlanData?.income_other_items?.find((item)=> item?.title === merge_key )
      
      tempExpenseSet = futurePlanData?.cash_flow_items?.map((itemA,indexA)=>{
        if (finddata?.year > indexA) {
          return 0;
        }
        if (
          finddata?.year <= indexA &&
          finddata?.year + finddata?.year_rent > indexA
        ) {
          return finddata?.amount_perYear;
        }
        return 0;
      })
    }

    return {
      label: `${merge_key}`,
      data: tempExpenseSet,
      backgroundColor:
        colors[index] === undefined ? getrandomColor : colors[index],
      barThickness: 20,
    };
  })


  const new_datasets = [...datasets,...datasets_second]

  const data = {
    labels: labels,
    datasets: new_datasets,
  };

  const config = {
    type: "bar",
    data: data,
  };

  const options = {
    responsive: false,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        // barThickness: 30,
        categoryPercentage: 0.8, // Adjust spacing between bar groups
        barPercentage: 0.9, // Adjust spacing between individual bars
        ticks: {
          autoSkip: false,
          font: {
            size: 8,
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          font: {
            size: 8,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          boxWidth: 14,
          boxHeight: 14,
        },
        position: "bottom",
        align: "start",
      },
    },
    barThickness: 30,
  };

  const maxValue = Math.max(...datasets.flatMap((dataset) => dataset?.data));

  const dynamicHeight = Math.min(4000 + maxValue * 100, 800);

  return (
    <>
      <div className="overflow-auto w-full h-[350px]">
        <div className={` relative w-full h-${dynamicHeight}`}>
          <Bar
            width={width}
            height={dynamicHeight}
            data={data}
            config={config}
            options={options}
          />
        </div>
      </div>
      <div className="flex flex-wrap h-fit ">
        {new_datasets?.map((item, index) => {
          return (
            <div
              key={index}
              className="m-[5px] flex items-center justify-center space-x-[5px] "
            >
              <div
                style={{ backgroundColor: item?.backgroundColor }}
                className={`w-[15px] h-[15px] `}
              ></div>
              <div>{item?.label}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};
