export const FutureHomeExpensePDF = (List) => {
    let sublist = [];
    let mainList = [];
    List?.map((item, index) => {
        item.icon = "house.svg"
      item.order = index + 1;
      sublist.push(item);
      if (sublist?.length === 2 || List?.length === item.order) {
        mainList.push(sublist);
        sublist = [];
      return;
      }
      return;
    });
    return mainList;
  };