import _ from "lodash";
import { calDebtCreditCard, toFloat2 } from "../../utils/useFunctions";
import { useBalanceStore } from "../../_store/balanceStore";
import { key_under_education } from "../../pages/education/functions/options";

export const Short_Term_Debt = (data, index, age) => {
  let allPeriods = [];
  let pay_sum_alls = Array.from({ length: age }).fill(0);

  for (const each_data of data?.short_terms || []) {
    let pay_periods = Array.from({ length: age }).fill(0);
    let period = Math.floor(each_data?.period / 12);
    let month_left = each_data?.period % 12;

    for (let item = 0; item < period; item++) {
      pay_periods[item] = each_data?.pay_permonth * 12;
      pay_sum_alls[item] += each_data?.pay_permonth * 12;
    }
    pay_periods[period] = each_data?.pay_permonth * month_left;
    pay_sum_alls[period] += each_data?.pay_permonth * month_left;

    allPeriods.push({ name: each_data?.creditor_name, pay_periods });
  }

  return { allPeriods, pay_sum_alls };
};

export const Credit_Debt = (data, index, age) => {
  let pay_sum_alls = Array.from({ length: age }).fill(0);
  let cardNames =
    data && data.credit_cards
      ? data.credit_cards.map((item, index) => {
          return {
            name: item && item.creditor_name,
            pay_periods: Array.from({ length: age }).fill(0),
          };
        })
      : [];

  data &&
    data.credit_cards &&
    data.credit_cards.map((item, index) => {
      switch (item && item.type_payment) {
        case "pay_full":
          let foundCard = cardNames.find(
            (item2) => item2 && item2.name === item.creditor_name
          );
          if (foundCard) {
            foundCard.pay_periods[0] = item.pay_peryear;
            pay_sum_alls[0] += item.pay_peryear;
          }
          break;
        case "pay_other":
          let foundCard2 = cardNames.find(
            (item2) => item2 && item2.name === item.creditor_name
          );
          let creditor = calDebtCreditCard(item);

          let chunk = _.chunk(creditor.creditor_items, 12);
          let totalData = chunk.map((item, index) => {
            return item
              .map(
                (item, index) =>
                  item?.interest_26_5_5_6 +
                  item?.interest_25_6_5_7 +
                  item?.interest_6_6_24_6
              )
              .reduce((total, num) => total + num, 0);
          });

          foundCard2.pay_periods.map((item, index) => {
            foundCard2.pay_periods[index] = toFloat2(totalData[index]) || 0;
            pay_sum_alls[index] += toFloat2(totalData[index]) || 0;
          });

          break;
        case "pay_minimum":
          let foundCard3 = cardNames.find(
            (item2) => item2 && item2.name === item.creditor_name
          );

          let creditor2 = calDebtCreditCard(item);

          let chunk2 = _.chunk(creditor2.creditor_items, 12);
          let totalData2 = chunk2.map((item, index) => {
            return item
              .map(
                (item, index) =>
                  item?.interest_26_5_5_6 +
                  item?.interest_25_6_5_7 +
                  item?.interest_6_6_24_6
              )
              .reduce((total, num) => total + num, 0);
          });

          foundCard3.pay_periods.map((item, index) => {
            foundCard3.pay_periods[index] = toFloat2(totalData2[index]) || 0;
            pay_sum_alls[index] += toFloat2(totalData2[index]) || 0;
          });

          // console.log("foundCard3", foundCard3);

          break;
      }
    });

  // return data?.credit_cards;
  // console.log("cardNames", cardNames);
  return { allPeriods: cardNames, pay_sum_alls };
};

export const CalcAll_Expense = (
  tempFutureData,
  balanceData,
  educationData,
  key_under_education
) => {
  let copytempFutureData = tempFutureData;

  let carExpense = tempFutureData?.expenese_car_items;
  let homeExpense = tempFutureData?.expenese_home_items;
  let childExpense = tempFutureData?.expenese_children_items;
  let travelExpense = tempFutureData?.expenese_travel_items;
  let collectionExpense = tempFutureData?.expenese_collection_items;
  let otherExpense = tempFutureData?.expenese_other_items;

  // =======รายได้===========================

  //----------"มรดก"------------
  let incomeLegacyItems = tempFutureData?.income_legacy_items;
  //----------ขายธุรกิจ------------
  let incomeBusinessItems = tempFutureData?.income_business_items;

  //----------รายได้จากอสังหาริม------------
  let incomePropertyItems = tempFutureData?.income_property_items;

  //-------รายได้อื่นๆ----------
  let incomeOtherItems = tempFutureData?.income_other_items;

  // ========หนี้=============================

  // หนี้ดอกลอย
  let debtShortList = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_shortterm_other"
  )?.short_term_others;

  // หนี้ีนอกระบบ
  let debtInformalShortterm = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_informal_shortterm"
  )?.informal_short_terms;

  // เงินกู้ระยะสั้น
  let debtShortTerm = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_shortterm"
  )?.short_terms;

  // บัตรเครดิต
  let debtCreditcard = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_creditcard"
  )?.credit_cards;

  // ========หนี้ระยะยาว=============================

  // หนี้ระยะยาว รถ
  let debtCar = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_car"
  )?.debt_long_cars;

  // หนี้ระยะยาว บ้าน
  let debtPlace = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_place"
  )?.debt_long_places;

  // หนี้ระยะยาว อื่นๆ
  let debtLongOther = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_long_other"
  )?.debt_long_others;

  // คำนวณรายจ่าย
  let newTempData = tempFutureData?.cash_flow_items?.map((itemA, indexA) => {
    let newItem = itemA;

    newItem.outcome_total =
      Number(newItem?.out_come_stable || 0) +
      Number(newItem?.outcome_peryear || 0);

    carExpense?.map((itemB, indexB) => {
      if (itemB?.year > indexA) {
        newItem.outcome_total = itemB?.sum_invest_five + newItem?.outcome_total;
      }
      if (itemB?.year + itemB?.period_year > indexA && itemB?.year <= indexA) {
        newItem.outcome_total =
          itemB?.pay_per_year * 12 + newItem?.outcome_total;
      }
    });

    homeExpense?.map((itemB, indexB) => {
      if (itemB?.year > indexA) {
        newItem.outcome_total = itemB?.sum_invest_five + newItem?.outcome_total;
      }
      if (itemB?.year + itemB?.period_year > indexA && itemB?.year <= indexA) {
        newItem.outcome_total =
          itemB?.pay_per_year * 12 + newItem?.outcome_total;
      }
    });

    childExpense?.map((itemB, indexB) => {
      if (itemB?.ishasEducation) {
        if (itemB?.year > indexA) {
          return (newItem.outcome_total += itemB?.sum_invest_five);
        }

        if (itemB?.year - 1 < indexA &&
          itemB?.year + 19 > indexA) {
          let eduExpense = Math.ceil(
            key_under_education?.[itemB?.education_under]?.default_value *
              Math.pow(1.06, indexA - 1)
          );
          return (newItem.outcome_total += eduExpense);
        }

        return (newItem.outcome_total += 0);
      }

      if (itemB?.year - 1 > indexA) {
        return (newItem.outcome_total += itemB?.sum_invest_five);
      }

      return (newItem.outcome_total += 0);
    });

    travelExpense?.map((itemB, indexB) => {
      if (itemB?.year > indexA) {
        newItem.outcome_total = itemB?.sum_invest_five + newItem?.outcome_total;
      }
    });

    collectionExpense?.map((itemB, indexB) => {
      if (itemB?.year > indexA) {
        newItem.outcome_total = itemB?.sum_invest_five + newItem?.outcome_total;
      }
    });

    otherExpense?.map((itemB, indexB) => {
      if (itemB?.year > indexA) {
        newItem.outcome_total = itemB?.sum_invest_five + newItem?.outcome_total;
      }
    });

    //----------"การศึกษาบุตร"------------

    educationData?.map((itemB, indexB) => {
      if (tempFutureData?.expenese_children_education?.length > 0) {
        newItem.outcome_total += Number(
          itemB?.education_items[indexA]?.sum_under_education || 0
        );

        return;
      }
    });

    // ========หนี้=============================

    // หนี้ดอกลอย
    debtShortList?.map((itemB, indexB) => {
      let year = Math.floor(itemB?.period / 12);
      let lastYearMonth = itemB?.period % 12;

      if (indexA >= Math.ceil(itemB?.period / 12)) {
        newItem.outcome_total += 0;
      }
      if (indexA < year) {
        newItem.outcome_total += itemB?.pay_permonth * 12;
      } else {
        newItem.outcome_total += itemB?.pay_permonth * lastYearMonth;
      }
    });

    // หนี้ีนอกระบบ
    debtInformalShortterm?.map((item2, index2) => {
      let year = Math.floor(item2?.number_of_days / 365);
      let lastYearMonth = item2?.number_of_days % 30;

      if (item2?.period === "Infinity") {
        newItem.outcome_total += item2?.pay_permonth * 12;
      } else {
        if (indexA >= Math.ceil(item2?.number_of_days / 365)) {
          newItem.outcome_total += 0;
        } else {
          if (indexA < year) {
            newItem.outcome_total += item2?.pay_permonth * 365;
          } else {
            newItem.outcome_total += item2?.pay_permonth * lastYearMonth;
          }
        }
      }
    });

    // เงินกู้ระยะสั้น
    debtShortTerm?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (item2?.period === "Infinity") {
        newItem.outcome_total += item2?.pay_permonth * 12;
      } else {
        if (indexA >= Math.ceil(item2?.number_of_days / 365)) {
          newItem.outcome_total += 0;
        } else {
          if (indexA >= Math.ceil(item2?.period / 12)) {
            newItem.outcome_total += 0;
          } else {
            if (indexA < year) {
              newItem.outcome_total += item2?.pay_permonth * 12;
            } else {
              newItem.outcome_total += item2?.pay_permonth * lastYearMonth;
            }
          }
        }
      }
    });

    // บัตรเครดิต
    debtCreditcard?.map((item2, index2) => {
      let period = calDebtCreditCard(item2)?.creditor_items?.length;
      let year = Math.floor(period / 12);
      let lastYearMonth = period % 12;

      if (item2?.type_payment === "pay_full" && indexA === 0) {
        return (newItem.outcome_total += item2?.pay_peryear);
      }

      if (indexA >= Math.ceil(period / 12)) {
        return (newItem.outcome_total += 0);
      }
      if (indexA < year) {
        return (newItem.outcome_total += item2?.pay_permonth * 12);
      } else {
        return (newItem.outcome_total += item2?.pay_permonth * lastYearMonth);
      }
    });

    // // ========หนี้ระยะยาว=============================
    // หนี้ระยะยาว รถ
    debtCar?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (indexA >= Math.ceil(item2?.period / 12)) {
        return (newItem.outcome_total += 0);
      }
      if (indexA < year) {
        return (newItem.outcome_total += item2?.pay_permonth * 12);
      } else {
        return (newItem.outcome_total += item2?.pay_permonth * lastYearMonth);
      }
    });

    // หนี้ระยะยาว บ้าน
    debtPlace?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (indexA >= Math.ceil(item2?.period / 12)) {
        return (newItem.outcome_total += 0);
      }
      if (indexA < year) {
        return (newItem.outcome_total += item2?.pay_permonth * 12);
      } else {
        return (newItem.outcome_total += item2?.pay_permonth * lastYearMonth);
      }
    });

    // หนี้ระยะยาว อื่นๆ
    debtLongOther?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (indexA >= Math.ceil(item2?.period / 12)) {
        return (newItem.outcome_total += 0);
      }
      if (indexA < year) {
        return (newItem.outcome_total += item2?.pay_permonth * 12);
      } else {
        return (newItem.outcome_total += item2?.pay_permonth * lastYearMonth);
      }
    });

    return newItem;
  });

  // คำนวณรายได้
  newTempData?.map((itemA, indexA) => {
    let newItem = itemA;

    if (itemA?.age > tempFutureData?.age_retire) {
      newItem.income_peryear = itemA?.pension_peryear;
    }

    //----------"มรดก"------------
    incomeLegacyItems?.map((itemB, indexB) => {
      if (itemB?.year === indexA) {
        return (newItem.income_peryear += itemB?.amount);
      }
    });

    //----------ขายธุรกิจ------------
    incomeBusinessItems?.map((itemB, indexB) => {
      if (itemB?.year === indexA) {
        return (newItem.income_peryear += itemB?.amount);
      }
    });

    //----------รายได้จากอสังหาริม------------
    incomePropertyItems?.map((itemB, indexB) => {
      if (itemB?.is_rent) {
        if (itemB?.year > indexA) {
          return (newItem.income_peryear += 0);
        }
        if (itemB?.year <= indexA && itemB?.year + itemB?.year_rent > indexA) {
          return (newItem.income_peryear += itemB?.amount_perYear);
        }
        return (newItem.income_peryear += 0);
      }

      if (itemB?.year === indexA) {
        return (newItem.income_peryear += itemB?.amount);
      }
    });

    //-------รายได้อื่นๆ----------
    incomeOtherItems?.map((itemB, indexB) => {
      if (itemB?.year > indexA) {
        return (newItem.income_peryear += 0);
      }
      if (itemB?.year <= indexA && itemB?.year + itemB?.year_rent > indexA) {
        return (newItem.income_peryear += itemB?.amount_perYear);
      }
      return (newItem.income_peryear += 0);
    });

    return newItem;
  });

  // คำนวณคงเหลือ
  newTempData?.map((itemA, indexA) => {
    let newItem = itemA;
    newItem.remain =
      Number(itemA?.income_peryear || 0) - Number(itemA?.outcome_total || 0);

    return newItem;
  });

  // ผลตอบแทนการลงทุน
  // สินทรัพย์ลงทุน

  newTempData?.map((itemA, indexA) => {
    let newItem = itemA;
    if (indexA === 0) {
      newItem.income_investment =
        (tempFutureData?.income_investment *
          tempFutureData?.income_investment_percent) /
        100;
      newItem.remain_accumulate =
        newItem?.remain +
        newItem?.income_investment +
        tempFutureData?.income_investment;

      return newItem;
    }
    // newItem.income_investment = (newTempData[indexA-1]* tempFutureData?.income_investment_percent)/100
    newItem.remain_accumulate =
      newItem?.remain +
      newItem?.income_investment +
      newTempData[indexA - 1]?.remain_accumulate;

    return newItem;
  });

  return newTempData;
};

export const CalcAllChildExpense = (
  childExpense,
  futurePlanData,
  indexFirst,
  education_under,
  choice_value
) => {
  // console.log("childExpense",childExpense)
  // console.log("futurePlanData",futurePlanData)
  // console.log("------------------------------")

  let newTempData = futurePlanData?.cash_flow_items?.map((itemA, indexA) => {
    let newItem = itemA;
    newItem.outcome_total = 0;

    if (education_under === choice_value) {
      if (true) {
        if (childExpense[indexFirst]?.year - 1 > indexA) {
          return (newItem.outcome_total +=
            childExpense[indexFirst]?.sum_invest_five);
        }

        if (
          indexA > childExpense[indexFirst]?.year &&
          indexA < childExpense[indexFirst]?.year + 23
        ) {
          let eduExpense = Math.ceil(
            key_under_education?.[choice_value]?.default_value *
              Math.pow(1.06, indexA - 1)
          );


          return (newItem.outcome_total += eduExpense);
        }

        return (newItem.outcome_total += 0);
      }
    }
  });


  return newTempData?.reduce((total, number) => total + number, 0);
};
