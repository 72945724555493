import { useEffect, useMemo } from "react";

import { ICON_INFO, ICON_NAVIGATE_BALANCE } from "../../../utils/useIcons";
import {
  STYLE_BORDER_YELLOW_PROPOTION,
  STYLE_CARD_BALANCE,
  STYLE_CARD_PROPOTION,
  STYLE_HEADER_SECTION_PROPOTION,
  STYLE_IMG_BALANCE,
  STYLE_TITLE_BALANCE,
  STYLE_TITLE_PROPOTION,
  STYLE_TITLE_WITH_INFO,
  STYLE_VALUE_BALANCE,
} from "../../../utils/useStyle";
import { useBalanceContext } from "../Balance.Main";
import numeral from "numeral";
import {
  DonutChartEmpty,
  DonutChartMain,
} from "../../../components/chart/DonutChartMain";
import { BoxWhite } from "../styles/Card";
import { useBalanceStore } from "../../../_store/balanceStore";

const ViewOutComeProportion = ({ onClick }) => {
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  return (
    <div className={STYLE_CARD_PROPOTION}>
      <div className={STYLE_HEADER_SECTION_PROPOTION}>
        <div>
          <div className={STYLE_TITLE_PROPOTION}>สัดส่วนรายจ่าย</div>
          <div className={STYLE_BORDER_YELLOW_PROPOTION}></div>
        </div>
        <ICON_NAVIGATE_BALANCE onClick={onClick} />
      </div>

      {Number(balanceData?.expenses_propotion) <= 0 ||
      isNaN(balanceData?.expenses_propotion) ? (
        <DonutChartEmpty text={`ค่าใช้จ่าย`} />
      ) : (
        <DonutChartMain
          text={`ค่าใช้จ่าย`}
          value={numeral(
            Number(balanceData?.sum_expenses_fixed || 0) +
              Number(balanceData?.sum_expenses_variable || 0) +
              Number(balanceData?.sum_expenses_invest_saving || 0)
          ).format("0,0")}
          // defalut={getOutcomeBalance > 0 ? 0 : 100}
          data1={Number(balanceData?.sum_expenses_fixed || 0)}
          data2={Number(balanceData?.sum_expenses_variable || 0)}
          data3={Number(balanceData?.sum_expenses_invest_saving || 0)}
        />
      )}

      <BoxWhite
        info
        title="ค่าใช้จ่ายคงที่"
        icon="/images/finance_outcome2.png"
        colortext="text-yellow400"
        value={Number(balanceData?.sum_expenses_fixed||0)}
      />

      <BoxWhite
        info
        title="ค่าใช้จ่ายผันแปร"
        icon="/images/finance_variable2.png"
        colortext="text-red400"
        value={Number(balanceData?.sum_expenses_variable||0)}
      />

      <BoxWhite
        info
        title="ค่าใช้จ่ายเพื่อการออม/ลงทุน"
        icon="/images/finance_invest2.png"
        colortext="text-maingreen"
        value={Number(balanceData?.sum_expenses_invest_saving||0)}
      />
    </div>
  );
};

export default ViewOutComeProportion;
