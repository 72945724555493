import numeral from "numeral";
import React from "react";
import { CardMain } from "../../../components/card/CardMain";
import Container from "../../../components/layout/Container";
import { cn, dateFormatTH } from "../../../utils/useFunctions";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_PADDING,
  STYLE_TEXT_BLUE,
} from "../../../utils/useStyle";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import { PdfHeaderText } from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import { STYLE_PAGE } from "../styles/pdf.style";
import { tax405Percent } from "../../../functions/taxFucntion/premium/businessFunction";

const IncomeProfilePdf = ({
  page,
  taxData = {},
  userData = {},
  allList = [],
  isFirst = false,
}) => {
  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="วางแผนภาษี"
        icon="/images/tax_plan.svg"
        classNameIcon="w-[24px] h-[27px]"
      />

      <Container classnameInner={""}>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <div className={STYLE_PARENT_PADDING}>
            <CardMain
              border={false}
              icon={false}
              marginyellow="mt-0"
              padding=" py-0 "
              panel={
                <div className=" px-3 -mt-3">
                  <div className=" text-xl font-medium pr-3 flex">
                    ข้อมูลส่วนตัวผู้เสียภาษีได้
                  </div>
                </div>
              }
            />
            <CardMain
              border={false}
              icon={false}
              marginyellow="mt-3"
              panel={
                <div className=" px-3 -mt-3">
                  <div className="w-full flex justify-between text-[11px] font-[500]">
                    <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      เลขประจำตัวผู้เสียภาษี
                    </div>
                    <div
                      className={cn(STYLE_TEXT_BLUE, {
                        "w-fit whitespace-nowrap": true,
                      })}
                    >
                      {" "}
                      {`${userData?.tax_id}`}{" "}
                    </div>
                  </div>
                  <div className="w-full flex justify-between text-[11px] font-[500]">
                    <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      ชื่อ - นามสกุล
                    </div>
                    <div
                      className={cn(STYLE_TEXT_BLUE, {
                        "w-fit whitespace-nowrap": true,
                      })}
                    >
                      {" "}
                      {`${userData?.firstname} ${userData?.lastname}`}{" "}
                    </div>
                  </div>
                  <div className="w-full flex justify-between text-[11px] font-[500]">
                    <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      ร้านค้า / กิจการส่วนตัว
                    </div>
                    <div
                      className={cn(STYLE_TEXT_BLUE, {
                        "w-fit whitespace-nowrap": true,
                      })}
                    >
                      {" "}
                      {`${userData?.your_business}`}{" "}
                    </div>
                  </div>
                  <div className="w-full flex justify-between text-[11px] font-[500]">
                    <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      ที่อยู่เว็บไซด์
                    </div>
                    <div
                      className={cn(STYLE_TEXT_BLUE, {
                        "w-fit whitespace-nowrap": true,
                      })}
                    >
                      {" "}
                      {`${userData?.your_website}`}{" "}
                    </div>
                  </div>
                  <div className="w-full flex justify-between text-[11px] font-[500]">
                    <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      วันเกิด
                    </div>
                    <div
                      className={cn(STYLE_TEXT_BLUE, {
                        "w-fit whitespace-nowrap": true,
                      })}
                    >
                      {" "}
                      {`${dateFormatTH(userData?.birthday)}`}{" "}
                    </div>
                  </div>
                  <div className="w-full flex justify-between text-[11px] font-[500]">
                    <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      เลขผู้จ่ายเงินได้
                    </div>
                    <div
                      className={cn(STYLE_TEXT_BLUE, {
                        "w-fit whitespace-nowrap": true,
                      })}
                    >
                      {" "}
                      {`${userData?.tax_id}`}{" "}
                    </div>
                  </div>
                  {userData?.claim_disabled_person && (
                    <div className="w-full flex justify-between text-[11px] font-[500]">
                      <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                        ใช้สิทธิยกเว้นคนพิการ
                      </div>
                    </div>
                  )}
                  {userData?.claim_special_zone && (
                    <div className="w-full flex justify-between text-[11px] font-[500]">
                      <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                        มีที่อยู่ในเขตพัฒนาพิเศษเฉพาะกิจ
                      </div>
                    </div>
                  )}
                  <div className="w-full flex justify-between text-[11px] font-[500]">
                    <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      สถานะ
                    </div>
                    <div
                      className={cn(STYLE_TEXT_BLUE, {
                        "w-fit whitespace-nowrap": true,
                      })}
                    >
                      {" "}
                      {`${userData?.life_status}`}{" "}
                    </div>
                  </div>
                  <div className="w-full flex justify-between text-[11px] font-[500]">
                    <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      สถานภาพ
                    </div>
                    <div
                      className={cn(`${STYLE_TEXT_BLUE} flex flex-col `, {
                        "w-fit whitespace-nowrap": true,
                      })}
                    >
                      {userData?.person_status?.value === "one" && (
                        <div>{`บุคคลธรรมดา`}</div>
                      )}
                      {userData?.person_status?.value === "two" && (
                        <div>{`ผู้ถึงแก่ความตายระหว่างปีภาษี`}</div>
                      )}
                      {userData?.person_status?.value === "three" && (
                        <div>{`กองมรดกที่ยังมิได้แบ่ง`}</div>
                      )}
                      {userData?.person_status?.value === "four" && (
                        <div>{`ห้างหุ้นสามัญที่มิใช่นิติบุคคล`}</div>
                      )}
                      {userData?.person_status?.value === "five" && (
                        <div>{`คณะบุคคลที่มิใช้นิติบุคคล`}</div>
                      )}
                      {userData?.person_status?.value === "six" && (
                        <div>{`วิสาหกิจชุุมชนตาม พ.ร.บ. ส่งเสริม วิสาหกิจชุมชน พ.ศ.2548`}</div>
                      )}
                    </div>
                  </div>
                  <div className="w-full flex justify-between text-[11px] font-[500]">
                    <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      สถานภาพการยื่นแบบ
                    </div>
                    <div
                      className={cn(`${STYLE_TEXT_BLUE} flex flex-col `, {
                        "w-fit whitespace-nowrap": true,
                      })}
                    >
                      {userData?.submission_tax_status?.value ===
                        "type_40(1)_(8)" && <div>{`บุคคลธรรมดา`}</div>}
                      {userData?.submission_tax_status?.value ===
                        "type_40(1)_(2)_single" && (
                        <div>{`มีเงินได้มาตรา 40(1),(2) ประเภทเดียว และแยกยื่นแบบฯ`}</div>
                      )}
                      {userData?.submission_tax_status?.value ===
                        "type_none_income" && <div>{`ไม่มีเงินได้`}</div>}
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </Container>
      <PdfFooter page={page} />
    </div>
  );
};

export default IncomeProfilePdf;
