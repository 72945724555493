import numeral from "numeral";
import React from "react";
import { CardMain } from "../../../components/card/CardMain";
import Container from "../../../components/layout/Container";
import { cn } from "../../../utils/useFunctions";
import {
    STYLE_MARGIN_TOP_PARENT,
    STYLE_PARENT_PADDING,
    STYLE_TEXT_BLUE,
} from "../../../utils/useStyle";
import { numberWithoutComma } from "../../balance/component/VIDrawerDebt.Main";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import { PdfHeaderText } from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import { STYLE_PAGE } from "../styles/pdf.style";

const Income406Pdf = ({
  page,
  taxData = {},
  datalist = [],
  isFirst = false,
  isLast = false,
}) => {
    const TotalExpense = () => {
        let tempTotalExpense = 0;
        tempTotalExpense = taxData?.freeJob
          ?.filter((item) => {
            return item?.calculating_method !== "หักแบบเหมา";
          })
          .reduce((total, num) => {
            return total + Number(num?.Tax_Real || 0);
          }, 0);
        return tempTotalExpense;
      };

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="วางแผนภาษี"
        icon="/images/tax_plan.svg"
        classNameIcon="w-[24px] h-[27px]"
      />

      <Container classnameInner={""}>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <div className={STYLE_PARENT_PADDING}>
            {isFirst && (
              <CardMain
                border={false}
                icon={false}
                marginyellow="mt-0"
                padding=" py-0 "
                panel={
                  <div className=" px-3 -mt-3">
                    <div className=" text-xl font-medium pr-3 flex">
                      ค่าตอบแทนจากการประกอบวิชาชีพอิสระ วิชากฎหมาย
                      การประกอบโรคศิลปะ วิศวกรรม สถาปัตยกรรม การบัญชี
                      ประณีตศิลปกรรม (มาตรา 40(6))
                    </div>
                  </div>
                }
              />
            )}
            {datalist?.map((item, index) => {
              return (
                <CardMain
                  border={false}
                  icon={false}
                  marginyellow="mt-3"
                  panel={
                    <div className=" px-3 -mt-3">
                      <div className="w-fit flex justify-between text-[11px] font-[500]">
                        <div
                          className={` text-base text-mainblue font-semibold w-full mb-1`}
                        >
                          รายการที่
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap  text-base text-mainblue font-semibold": true,
                          })}
                        >
                          {item?.order}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          ประเภทธุรกิจ
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${item?.type_job?.label}`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          เงินได้ทั้งหมด
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${numeral(item?.tax_income)?.format(
                            "0,0"
                          )} บาท`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                          เลขผู้จ่ายเงินได้
                        </div>
                        <div
                          className={cn(STYLE_TEXT_BLUE, {
                            "w-fit whitespace-nowrap": true,
                          })}
                        >
                          {" "}
                          {`${item?.tax_payer_ID}`}{" "}
                        </div>
                      </div>
                      <div className="w-full flex justify-between text-[11px] font-[500]">
                        <div
                          className={`font-semibold text-black mt-2 text-sm`}
                        >
                          การคำนวณค่าใช้จ่าย
                        </div>
                      </div>
                      {item?.calculating_method === "หักแบบเหมา" && (
                        <div className="w-full flex justify-between text-[11px] font-[500]">
                          <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                            {`${item?.calculating_method} ${
                              item?.type_job?.value === " tax_income_medicine"
                                ? "60"
                                : item?.type_job?.value
                                ? "30"
                                : "-"
                            }`}
                          </div>
                          <div
                            className={cn(STYLE_TEXT_BLUE, {
                              "w-fit whitespace-nowrap": true,
                            })}
                          >
                            {" "}
                            {`${numeral(
                              item?.income_for_calculating_all_in
                            )?.format("0,0")} บาท`}{" "}
                          </div>
                        </div>
                      )}
                      {item?.calculating_method === "หักตามจริง" && (
                        <div className="w-full flex justify-between text-[11px] font-[500]">
                          <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                            {item?.calculating_method}
                          </div>
                          <div
                            className={cn(STYLE_TEXT_BLUE, {
                              "w-fit whitespace-nowrap": true,
                            })}
                          >
                            {" "}
                            {`${numeral(item?.Tax_Real)?.format(
                              "0,0"
                            )} บาท`}{" "}
                          </div>
                        </div>
                      )}
                    </div>
                  }
                />
              );
            })}
            {isLast && (
              <CardMain
                border={false}
                icon={false}
                marginyellow="mt-3"
                panel={
                  <div className=" px-3 -mt-3">
                    <div
                      className={` text-base text-black font-semibold w-full mb-1 text-center`}
                    >
                      การคำนวณค่าใช้จ่าย
                    </div>
                    <div className="w-full flex justify-between text-[11px] font-[500]">
                      <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      1. ต้นทุนการให้บริการ
                      </div>
                      <div
                        className={cn(STYLE_TEXT_BLUE, {
                          "w-fit whitespace-nowrap": true,
                        })}
                      >
                        
                        {`${numeral(taxData?.free_job_expense_cost)?.format("0,0")} บาท`}
                      </div>
                    </div>
                    <div className="w-full flex justify-between text-[11px] font-[500]">
                      <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      2.เงินเดือนค่าจ้าง
                      </div>
                      <div
                        className={cn(STYLE_TEXT_BLUE, {
                          "w-fit whitespace-nowrap": true,
                        })}
                      >
                        {`${numeral(taxData?.free_job_expense_salary)?.format("0,0")} บาท`}
                      </div>
                    </div>
                    <div className="w-full flex justify-between text-[11px] font-[500]">
                      <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      3.ค่าใช้จ่ายอื่นๆ
                      </div>
                      <div
                        className={cn(STYLE_TEXT_BLUE, {
                          "w-fit whitespace-nowrap": true,
                        })}
                      >
                        {`${numeral(taxData?.free_job_expense_other)?.format("0,0")} บาท`}
                      </div>
                    </div>
                    <div className="w-full flex justify-between text-[11px] font-[500]">
                      <div className={`${STYLE_TEXT_BLUE} w-full mb-1`}>
                      รวมค่าใช้จ่ายทั้งหมด
                      </div>
                      <div
                        className={cn(STYLE_TEXT_BLUE, {
                          "w-fit whitespace-nowrap": true,
                        })}
                      >
                        {`${numeral(numberWithoutComma(
                              taxData?.free_job_expense_cost || 0
                            ) +
                              numberWithoutComma(
                                taxData?.free_job_expense_salary || 0
                              ) +
                              numberWithoutComma(
                                taxData?.free_job_expense_other || 0
                              ))?.format("0,0")} บาท`}
                      </div>
                    </div>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </Container>
      <PdfFooter page={page} />
    </div>
  );
};

export default Income406Pdf;
