import * as React from "react";

//
import Navbar from "../components/layout/Navbar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import HomeMain from "../pages/home/Home.Main";
import TaxPlanMain from "../pages/tax_plan/Tax_Plan.Main";
import RetirePlanMain from "../pages/retirement_plan/Retirement_Plan.Main";
import Footer from "../components/layout/Footer";
import SummaryTableFinance from "../pages/retirement_plan/Sum_TableFinance";
import ViewSummaryRetirePlan from "../pages/retirement_plan/components/VISummaryRetirePlan";
import ViewSummaryTaxPlan from "../pages/tax_plan/components/VISumTax";
import BalanceMain from "../pages/balance/Balance.Main";
import RiskPlanMain from "../pages/risk_plan/Risk_Plan.Main";
import LegacyPlanMain from "../pages/legacy/Legacy.Main";
import SavingPlanMain from "../pages/saving_plan/Saving_Plan.Main";
import LoginMain from "../pages/login/Login.Main";
import ProfileMain from "../pages/profile/Profile.Main";
import FirstLogin from "../pages/login/Login.FirstLogin";
import ProtectedRoute from "../components/ProtectedRoute";
import FamilyTreeMain from "../pages/family_tree/Family_Tree.Main";
import AllInsuarancMain from "../pages/allInsuarance/AllInsuarance.Main";
import InvestPlanMain from "../pages/invest/InvestPlan.main";
import ReportMain from "../pages/report/report.main";

import ViInfoTaxProfile from "../pages/tax_plan/components/Premium/VIInfoTaxProfile.Main";
import ViewIncome_Salary_Premium from "../pages/tax_plan/components/Premium/VIIncome_Salary.Premium";
import ViewIncomeSalaryPremium from "../pages/tax_plan/components/Premium/VIIncome_Salary.Premium";
import ViewIncomeFreelance from "../pages/tax_plan/components/Premium/VIIncome_Freelance";
import ViewIncomeBusiness from "../pages/tax_plan/components/Premium/VIincome_Business";
import ViewIncomeInvest from "../pages/tax_plan/components/Premium/VIIncome_Invest";
import ViewIncomeHeritage from "../pages/tax_plan/components/Premium/VIIncome_Heritage";
import ViewSensitiveTest from "../pages/retirement_plan/components/VISensitiveTest";
import EducationMain from "../pages/education/Education.Main";
import ViewEduInfo from "../pages/education/components/ViewEduInfo.Main";
import DebtMain from "../pages/debt/Debt.Main";
import ViewDebtPlanForm from "../pages/debt/component/VIDebtPlanForm";
import InsuarancePlanMain from "../pages/insuarance_plan/InsuarancePlan.Main";
import ViewFamilyInsurance from "../pages/insuarance_plan/component/VIFamily_Insurance";
import ViewDisableInsurance from "../pages/insuarance_plan/component/VIDisable_Insurance";
import FutureMain from "../pages/future_plan/Future.Main";
import AboutusMain from "../pages/aboutus/AboutUs.Main";
import { useStrategyStore } from "../_store/strategyRetireStore";
import { useRetireStore } from "../_store/retireStore";
import { useTaxStore } from "../_store/taxplanStore";
import { useSavingStore } from "../_store/savingStore";
import { useUserStore } from "../_store/userStore";
import { useBalanceStore } from "../_store/balanceStore";
import { useRiskStore } from "../_store/riskStore";
import { useEducationStore } from "../_store/educationStore";
import { useInsuranceStore } from "../_store/insuranceStore";
import { useFamilyStore } from "../_store/familyStore";
import { useOpenStore } from "../_store/openStore";
import { useFamilytreeStore } from "../_store/familytreeStore";
import { useDebtStore } from "../_store/debtplanStore";
import { useFuturePlanStore } from "../_store/futurePlanStore";
import Api from "../api/api.main";
import PDFMain from "../pages/pdf/pdf.main";
import classNames from "classnames";
import { useInvestStore } from "../_store/investStore";
import ReportRiskPage from "../pages/report/view/report.v.risk";
import ReportHypothesisPage from "../pages/report/view/report.v.hypothesis";
import ReacteportOverViewPage from "../pages/report/view/report.v.overview";
import ReportBalancePage from "../pages/report/view/report.v.balance";
import ReportIncomePage from "../pages/report/view/report.v.income";
import ReportDebtPage from "../pages/report/view/report.v.debt";
import ReportExpensePage from "../pages/report/view/report.v.expense";
import ReportAssetPage from "../pages/report/view/report.v.asset";
import ReportFinancialPage from "../pages/report/view/report.v.financial";
import ReportInvestPage from "../pages/report/view/report.v.invest";
import ReportRetirePage from "../pages/report/view/report.v.retire";
import ReportTaxPage from "../pages/report/view/report.v.tax";
import ReportDisableInsurancePage from "../pages/report/view/report.v.disableInsurance";
import ReportEducationPage from "../pages/report/view/report.v.education";
import ReportFamilyInsurancePage from "../pages/report/view/report.v.familyInsurance";
import ReportFuturePlanPage from "../pages/report/view/report.v.futureplan";
import ReportDebtPlanPage from "../pages/report/view/report.v.debtplan";
import ReportHeritagePage from "../pages/report/view/report.v.heritage";
import ReportAllInsurancePage from "../pages/report/view/report.v.allInsurance";
import ReportSavingPage from "../pages/report/view/report.v.saving";
import ViManualFormOne from "../pages/legacy/components/VI.ManualFormOne";
import ViManualFormTwo from "../pages/legacy/components/VI.ManualFormTwo";
import ViManualFormThree from "../pages/legacy/components/VI.ManualFormThree";
import ViNormalFormOne from "../pages/legacy/components/VI.NormalOne";
import ViNormalFormTwo from "../pages/legacy/components/VI.NormalTwo";
import ViNormalFormThree from "../pages/legacy/components/VI.NormalThree";

const MainRouter = () => {
  const strategy = useStrategyStore((state) => state.strategy);
  const retireData = useRetireStore((state) => state.retireData);
  const taxData = useTaxStore((state) => state.taxData);
  const savingData = useSavingStore((state) => state.savingData);
  const userData = useUserStore((state) => state.userData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const riskData = useRiskStore((state) => state.riskData);
  const educationData = useEducationStore((state) => state.educationData);
  const insuranceData = useInsuranceStore((state) => state.insuranceData);
  const familyData = useFamilyStore((state) => state);
  const openData = useOpenStore((state) => state);
  const familytreeData = useFamilytreeStore((state) => state.familytreeData);
  const debtData = useDebtStore((state) => state.debtData);
  const investData = useInvestStore((state) => state.investData);
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const [trigger, setTrigger] = React.useState(false);
  const pathname = useLocation().pathname;

  React.useEffect(() => {
    if (
      window?.location.hostname === "https://assurewealth.website/" ||
      window?.location.hostname === "assurewealth.website" ||
      window?.location.hostname === "https://www.assurewealth.website" ||
      window?.location.hostname === "www.assurewealth.website"
    ) {
      window.location.href = "https://assurewealthonline.com/";
    }
  }, []);

  // React.useEffect(() => {
  //   const createTimer = setInterval(() => {
  //     setTrigger(!trigger);
  //   }, 1000 * 15);

  //   return () => {
  //     clearInterval(createTimer);
  //   };
  // }, [trigger]);

  // React.useEffect(() => {
  //   updateStorage();
  //   console.log("update trigger")
  // }, [trigger]);

  React.useEffect(() => {
    const createTimer = setTimeout(()=>{
    },1000*10)
    updateStorage();
    console.log("update trigger");
  }, [
    pathname,
    strategy,
    retireData,
    taxData,
    savingData,
    userData,
    balanceData,
    riskData,
    educationData,
    insuranceData,
    familyData,
    debtData,
    investData,
    futurePlanData,
  ]);

  const updateStorage = async () => {
    try {
      if (!userData || ["/report", "/pdf"].includes(pathname)) return;

      const { data: dataStorage } = await Api.get(
        `/storages/profile/${userData._id}`
      );
      if (!dataStorage?.storage) {
        await Api.post(`/storages`, {
          profileId: userData._id,
          strategy,
          retireData,
          taxData,
          savingData,
          balanceData,
          riskData,
          educationData,
          insuranceData,
          familyData,
          openData,
          familytreeData,
          debtData,
          futurePlanData,
          investData,
        });
      } else {
        await Api.put(`/storages/${dataStorage.storage._id}`, {
          profileId: userData._id,
          strategy,
          retireData,
          taxData,
          savingData,
          balanceData,
        });
        await Api.put(`/storages/${dataStorage.storage._id}`, {
          profileId: userData._id,
          riskData,
          educationData: educationData?.map((item) => {
            return {
              ...item,
              img: "",
            };
          }),
          insuranceData,
          familyData,
          openData,
          familytreeData,
          debtData,
          futurePlanData,
          investData,
        });
      }
    } catch (error) {
      console.log("update storage error", error);
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage:
            window?.location?.pathname === "/" ||
            window?.location?.pathname === "/profile"
              ? ""
              : "url(/images/circle.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition:
            window?.location?.pathname === "/" ||
            window?.location?.pathname === "/profile"
              ? ""
              : "left",
        }}
        className={classNames(
          " font-IBMPlexSansThai flex flex-col justify-between relative min-h-screen mx-auto  ",
          {
            "max-w-2xl": window?.location?.pathname !== "/pdf",
          }
        )}
      >
        <div>
          <Navbar />
          <Routes>
            <Route path="/login" element={<LoginMain />} />
            <Route path="/firstlogin" element={<FirstLogin />} />
            <Route path="/familytree" element={<FamilyTreeMain />} />
            <Route path="/allInsuarance" element={<AllInsuarancMain />} />
            <Route path="/profile" element={<ProfileMain />} />
            <Route path="/pdf" element={<PDFMain />} />
            {/* รู้จักเรา */}
            <Route path="/aboutus" element={<AboutusMain />} />
            {/* ภาษี */}
            <Route path="/" element={<HomeMain />} />
            <Route path="/taxplan" element={<TaxPlanMain />} />
            {/* <Route path="/taxplan" element={<ViewStepOnePremium />} /> */}

            {/* ViewStepOnePremium */}
            <Route path="/taxpayerInfo" element={<ViInfoTaxProfile />} />
            <Route
              path="/taxIncomeSalary"
              element={<ViewIncomeSalaryPremium />}
            />
            <Route
              path="/taxIncomeFreelance"
              element={<ViewIncomeFreelance />}
            />
            <Route path="/taxIncomeBusiness" element={<ViewIncomeBusiness />} />
            <Route path="/taxIncomeInvest" element={<ViewIncomeInvest />} />
            <Route path="/taxIncomeHeritage" element={<ViewIncomeHeritage />} />
            {/* เกษียณ */}
            <Route path="/retireplan" element={<RetirePlanMain />} />
            <Route path="/summaryresult" element={<ViewSummaryRetirePlan />} />
            <Route path="/sensitivetest" element={<ViewSensitiveTest />} />
            {/* <Route path="/summarytax" element={<ViewSummaryTaxPlan />} /> */}
            <Route
              path="/summarytablemovements"
              element={<SummaryTableFinance />}
            />
            {/* งมดุล */}
            <Route path="/balance" element={<BalanceMain />} />
            {/* ความเสี่ยง */}
            <Route path="/riskplan" element={<RiskPlanMain />} />

            {/* มรดก */}
            <Route path="/legacyplan" element={<LegacyPlanMain />} />
            {/* มรดกแบบเขียนเอง 1 */}
            <Route path="/legacy/manualformone" element={<ViManualFormOne />} />
            {/* มรดกแบบเขียนเอง 2 */}
            <Route path="/legacy/manualformtwo" element={<ViManualFormTwo />} />
            {/* มรดกแบบเขียนเอง 3 */}
            <Route
              path="/legacy/manualformthree"
              element={<ViManualFormThree />}
            />
            {/* มรดกแบบธรรมดา 1  */}
            <Route path="/legacy/normalformone" element={<ViNormalFormOne />} />
            {/* มรดกแบบธรรมดา 2 */}
            <Route path="/legacy/normalformtwo" element={<ViNormalFormTwo />} />
            {/* มรดกแบบธรรมดา 3 */}
            <Route
              path="/legacy/normalformthree"
              element={<ViNormalFormThree />}
            />

            {/* ออม */}
            <Route path="/savingsplan" element={<SavingPlanMain />} />
            {/* ลงทุน */}
            <Route path="/investplan" element={<InvestPlanMain />} />
            {/* การศึกษาบุตร */}
            <Route path="/education" element={<EducationMain />} />
            <Route path="/education/info" element={<ViewEduInfo />} />
            {/* จัดการหนี้สิน */}
            <Route path="/debtplan" element={<DebtMain />} />
            <Route path="/debtplan/form" element={<ViewDebtPlanForm />} />
            {/* แผนประกัน */}
            <Route path="/insuranceplan" element={<InsuarancePlanMain />} />
            <Route
              path="/insuranceplan/family"
              element={<ViewFamilyInsurance />}
            />
            <Route
              path="/insuranceplan/disabled"
              element={<ViewDisableInsurance />}
            />
            {/* แผนอนาคต  */}
            <Route path="/futureplan" element={<FutureMain />} />
            {/* รายงาน */}
            <Route path="/report" element={<ReportMain />} />
            {/* รายงานภาพรวม */}
            <Route
              path="/report/overview"
              element={<ReacteportOverViewPage />}
            />
            {/* รายงานสมมติฐาน */}
            <Route
              path="/report/hypothesis"
              element={<ReportHypothesisPage />}
            />
            {/* รายงานรายได้ */}
            <Route path="/report/income" element={<ReportIncomePage />} />
            {/* รายงานหนี้สิน */}
            <Route path="/report/debt" element={<ReportDebtPage />} />
            {/* รายงานรายจ่าย */}
            <Route path="/report/expense" element={<ReportExpensePage />} />
            {/* รายงานทรัพย์สิน */}
            <Route path="/report/asset" element={<ReportAssetPage />} />
            {/* รายงานงบดุล */}
            <Route path="/report/balance" element={<ReportBalancePage />} />
            {/* รายงานอัตราส่วนการเงิน */}
            <Route path="/report/financial" element={<ReportFinancialPage />} />
            {/* รายงานบริหารความเสี่ยง */}
            <Route path="/report/riskplan" element={<ReportRiskPage />} />
            {/* รายงานสัดส่วนการลงทุน */}
            <Route path="/report/investment" element={<ReportInvestPage />} />
            {/* รายงานแผนเกษียณ */}
            <Route path="/report/retirement" element={<ReportRetirePage />} />
            {/* รายงานภาษี */}
            <Route path="/report/tax" element={<ReportTaxPage />} />
            {/* รายงานทุพพลภาพ */}
            <Route
              path="/report/disableInsurance"
              element={<ReportDisableInsurancePage />}
            />
            {/* รายงานทุนแผนประกันครอบครัว */}
            <Route
              path="/report/familyInsurance"
              element={<ReportFamilyInsurancePage />}
            />

            {/* รายงานการศึกษาบุตร */}
            <Route path="/report/education" element={<ReportEducationPage />} />
            {/* รายงานแผนอนาคต */}
            <Route
              path="/report/futureplan"
              element={<ReportFuturePlanPage />}
            />
            {/* รายงานวางแผนหนี้สิน */}
            <Route path="/report/debtplan" element={<ReportDebtPlanPage />} />

            {/* รายงานแผนมรดก */}
            <Route path="/report/heritage" element={<ReportHeritagePage />} />
            {/* รายงานรวมแผนประกัน */}
            <Route
              path="/report/allInsurance"
              element={<ReportAllInsurancePage />}
            />

            {/* รายงานแผนการออม */}
            <Route path="/report/savingplan" element={<ReportSavingPage />} />
          </Routes>
        </div>
        {window?.location?.pathname !== "/pdf" && <Footer />}
      </div>
    </React.Fragment>
  );
};
export default MainRouter;
