import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Checkbox,
  Input,
  Slider,
} from "@material-tailwind/react";
import { MTButton } from "../../../components/button/MTButton";
import { CardMain } from "../../../components/card/CardMain";
import {
  STYLE_INPUT,
  STYLE_INPUT_SLIDER,
  STYLE_SPACE_Y,
} from "../../../utils/useStyle";
import { ICON_INFO } from "../../../utils/useIcons";
import CheckBoxMain from "../../../components/checkbox/CheckboxMain";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { SliderSingle } from "../../../components/slider/SliderSingle";
import ErrorMain from "../../../components/error/Error.Main";
import { NumericFormat } from "react-number-format";
import { InfoDrawer } from "../../../components/drawer/InfoDrawer";
import { PopupMain } from "../../../components/popup/PopupMain";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";

export const PensionInfo = ({ openPensionInfo, setOpenPensionInfo }) => {
  return (
    <InfoDrawer
      open={openPensionInfo}
      head="บำนาญ (ต่อเดือน)"
      text={
        <div>
          <div className=" mb-2 ">
            <div className=""> คุณจะได้รับบำนาญก็ต่อเมื่อ </div>
            <div>1.คุณต้องได้รับสิทธิบำนาญตามเกณฑ์ราชการ</div>
            <div>2.คุณชำระประกันบำนาญครบตามสัญญาแล้ว</div>
          </div>
          เงินตอบแทนความชอบที่ได้รับราชการมา
          ซึ่งจ่ายเป็นรายเดือนจนกว่าจะถึงแก่กรรม หรือหมดสิทธิ โดย
          <li> 1.เป็นข้อราชการตามกฎหมาย</li>
          <li>2.รับเงินเดือนจากเงินงบประมาณรายจ่ายประเภทงบบุคลากร</li>
          <li> 3.มีเวลารับราชการ 10 ปีขึ้นไป</li>
        </div>
      }
      onClose={() => {
        setOpenPensionInfo(false);
      }}
    />
  );
};

export const InvestmentInfo = ({
  openInvestmentInfo,
  setOpenInvestmentInfo,
}) => {
  return (
    <InfoDrawer
      open={openInvestmentInfo}
      head="สินทรัพย์ลงทุน"
      text={
        <div>
          สินทรัพย์ลงทุน คือ สินทรัพย์ที่ก่อให้เกิดผลตอบแทน
          มีความงอกเงยต่อความมั่งคั่งของคุณ เช่น
          <li>พันธบัตร / หุ้นกู้</li>
          <li>หุ้นบุริมสิทธิ</li>
          <li>หุ้นสามัญ</li>
          <li>กองทุนรวมตลาดหุ้น</li>
          <li>กองทุนรวมหุุ้น</li>
          <li>กองทุนรวมเพื่อการเลี้ยงชีพ</li>
        </div>
      }
      onClose={() => {
        setOpenInvestmentInfo(false);
      }}
    />
  );
};

export const BeforeRetireInfo = ({
  openBeforeRetireInfo,
  setOpenBeforeRetireInfo,
}) => {
  return (
    <InfoDrawer
      open={openBeforeRetireInfo}
      head="ผลตอบแทนจากการลงทุนก่อนเกษียณต่อปี (%)"
      text={
        " ผู้ลงทุนในกองทุนรวมจะได้รับผลตอบแทนใน 2 ลักษณะ ได้แก่ กำไรจากการขายหน่วยลงทุนและเงินปันผลของกองทุนรวม เงินปันผลหมายถึงส่วนแบ่งกำไรของกองทุนนั้นๆ (ในกรณีเป็นกองทุนรวมที่มีนโยบายจ่ายเงินปันผล) ถ้าเป็นบุคคลธรรมดารับเงินปันผลจะถูกหักภาษี 10 % หรือนำมารวมคำนวณภาษีปลายปีในอัตราก้าวหน้า "
      }
      onClose={() => {
        setOpenBeforeRetireInfo(false);
      }}
    />
  );
};

export const AfterRetireInfo = ({
  openAfterRetireInfo,
  setOpenAfterRetireInfo,
}) => {
  return (
    <InfoDrawer
      open={openAfterRetireInfo}
      head="ผลตอบแทนจากการลงทุนหลังเกษียณต่อปี (%)"
      text={
        " ผู้ลงทุนในกองทุนรวมจะได้รับผลตอบแทนใน 2 ลักษณะ ได้แก่ กำไรจากการขายหน่วยลงทุนและเงินปันผลของกองทุนรวม เงินปันผลหมายถึงส่วนแบ่งกำไรของกองทุนนั้นๆ (ในกรณีเป็นกองทุนรวมที่มีนโยบายจ่ายเงินปันผล) ถ้าเป็นบุคคลธรรมดารับเงินปันผลจะถูกหักภาษี 10 % หรือนำมารวมคำนวณภาษีปลายปีในอัตราก้าวหน้า "
      }
      onClose={() => {
        setOpenAfterRetireInfo(false);
      }}
    />
  );
};

const ViewInvestment = ({
  index,
  formRetire,
  setFormRetire,
  error,
  setError,
  setOpenPension,
  openPension,
  openPensionInfo,
  setOpenPensionInfo,
  openInvestmentInfo,
  setOpenInvestmentInfo,
  openBeforeRetireInfo,
  setOpenBeforeRetireInfo,
  openAfterRetireInfo,
  setOpenAfterRetireInfo,
  openPopupPension,
  setOpenPopupPension,
}) => {
  const [open, setOpen] = useState();
  const [value, setValue] = useState(false);

  const onChangePension = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (isNaN(e.target.value)) return;
    // if (!openPension) return;
    else
      setFormRetire({
        ...formRetire,
        pension: output,
      });
  };

  const onChangeInvestmentAssets = (e) => {
    // if (isNaN(e.target.value)) return;
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    setFormRetire({
      ...formRetire,
      investment_assets: output,
    });
  };

  const onChangeBeforeRetire = (e) => {
    // if (isNaN(e.target.value)) return;

    const tempValue = e.target.value ? Number(e.target.value) : null;

    if (tempValue > formRetire?.investment_bf_retirement_max) {
      return;
    }
    {
      setError({
        ...error,
        investment_bf_retirement: true,
      });
    }

    {
      // Clear the error if the value is within the allowed range
      setError({
        ...error,
        investment_bf_retirement: false,
      });
    }

    // Set tempValue to the state
    setFormRetire({
      ...formRetire,
      investment_bf_retirement: tempValue,
      strategy_assets_generate_per_year: tempValue,
    });
  };

  const onChangeBeforeRetireMax = (e) => {
    if (isNaN(e.target.value)) return;
    else {
      setError({
        ...error,
        investment_bf_retirement_max: false,
      });
    }
    const value = e.target.value ? Number(e.target.value) : null;

    setFormRetire({
      ...formRetire,
      investment_bf_retirement: value,
      investment_bf_retirement_max: value,
    });
  };

  const onChangeAfterRetire = (e) => {
    if (isNaN(e.target.value)) return;

    const tempValue = e.target.value ? Number(e.target.value) : null;

    if (tempValue > formRetire?.investment_af_retirement_max) {
      return;
    }

    {
      // Handle error or set an error flag
      setError({
        ...error,
        investment_af_retirement: true,
      });
    }
    {
      // Clear the error if the value is within the allowed range
      setError({
        ...error,
        investment_af_retirement: false,
      });
    }

    // Set tempValue to the state
    setFormRetire({
      ...formRetire,
      investment_af_retirement: tempValue,
      strategy_investment_af_retirement: tempValue,
    });
  };

  const onChangeAfterRetireMax = (e) => {
    if (isNaN(e.target.value)) return;
    else {
      setError({
        ...error,
        investment_af_retirement_max: false,
      });
    }
    const value = e.target.value ? Number(e.target.value) : null;
    setFormRetire({
      ...formRetire,
      investment_af_retirement: value,
      investment_af_retirement_max: value,
    });
  };

  const clearvalue = () => {
    setFormRetire((prevFormRetire) => ({
      ...prevFormRetire,
      pension: "",
    }));
  };


  return (
    <div className={STYLE_SPACE_Y}>
      <CardMain
        check={true}
        onClick={() => {
          setOpenPensionInfo(true);
        }}
        openCheckbox={formRetire?.open_pension}
        onChangeCheckbox={() => {
          if (formRetire?.open_pension) {
            setOpenPension(false);
            setFormRetire({
              ...formRetire,
              open_pension: false,
            });
            return clearvalue();
          }
          setOpenPension(true);
          setOpenPopupPension(true);
          setFormRetire({
            ...formRetire,
            open_pension: true,
          });
        }}
        icon={true}
        title="บำนาญ (ต่อเดือน)"
        margin="mt-[6px]"
        panel={
          <div className=" px-[14px] mt-0">
           <NumericFormat
              disabled={!formRetire?.open_pension}
              // type="number"
              maxLength={11}
              className={STYLE_INPUT}
              placeholder="ถ้ามีเงินบำนาญกรุณาติ๊กช่องว่าง"
              value={formRetire?.pension}
              onChange={onChangePension}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
            />
          </div>
        }
      />
      <PopupMain
        onClick={() => {
          setOpenPopupPension(false);
        }}
        open={openPopupPension}
        title="บำนาญ (ต่อเดือน)"
        text={
          <div className=" text-left text-sm">
            <div className=" mb-2  font-medium">
              <div className=" text-center"> คุณจะได้รับบำนาญก็ต่อเมื่อ </div>
              <div>1.คุณต้องได้รับสิทธิบำนาญตามเกณฑ์ราชการ</div>
              <div>2.คุณชำระประกันบำนาญครบตามสัญญาแล้ว</div>
            </div>
            เงินตอบแทนความชอบที่ได้รับราชการมา
            ซึ่งจ่ายเป็นรายเดือนจนกว่าจะถึงแก่กรรม หรือหมดสิทธิ โดย
            <li>1.เป็นข้อราชการตามกฎหมาย</li>
            <li>2.รับเงินเดือนจากเงินงบประมาณรายจ่ายประเภทงบบุคลากร</li>
            <li>3.มีเวลารับราชการ 10 ปีขึ้นไป</li>
          </div>
        }
        img="/images/pention.png"
      />
      <CardMain
        onClick={() => {
          setOpenInvestmentInfo(true);
        }}
        icon={true}
        title="สินทรัพย์ลงทุน"
        margin="mt-[6px]"
        panel={
          <div className=" px-[14px] mt-0">
           <NumericFormat
              // type="number"
              maxLength={11}
              className={STYLE_INPUT}
              placeholder="20,000"
              value={formRetire?.investment_assets}
              onChange={onChangeInvestmentAssets}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
            />
          </div>
        }
      />
      <CardMain
        onClick={() => {
          setOpenBeforeRetireInfo(true);
        }}
        icon={false}
        title="ผลตอบแทนจากการลงทุนก่อนเกษียณต่อปี (%)"
        panel={
          <div className=" px-4">
            <div className=" flex justify-center">
              {/* <input
                  
                onWheelCapture={(e) => {
                  e.target.blur();
                }}
              
              /> */}

             <NumericFormat
                // type="number"
                min={1}
                maxLength={11}
                placeholder="0"
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className={STYLE_INPUT_SLIDER}
                value={formRetire?.investment_bf_retirement}
                onChange={onChangeBeforeRetire}
              />
            </div>
            <SliderSingle
              placeholder="0"
              valuemin={1}
              valuemax={formRetire?.investment_bf_retirement_max}
              value={formRetire?.investment_bf_retirement}
              onChange={onChangeBeforeRetire}
              onChnageMax={onChangeBeforeRetireMax}
            />
            {error?.investment_bf_retirement && (
              <ErrorMain errortext="กรุณากรอกผลตอบแทนจากการลงทุนก่อนเกษียณต่อปี ถ้าไม่มีกรุณากรอก 0" />
            )}
            {error?.investment_bf_retirement_max && (
              <ErrorMain errortext="กรุณากรอกผลตอบแทนจากการลงทุนก่อนเกษียณสูงสุดต่อปี" />
            )}
          </div>
        }
      />
      <CardMain
        onClick={() => {
          setOpenAfterRetireInfo(true);
        }}
        icon={false}
        title="ผลตอบแทนจากการลงทุนหลังเกษียณต่อปี (%) "
        panel={
          <div className=" px-4">
            <div className=" flex justify-center">
              <input
                placeholder="0"
                className={STYLE_INPUT_SLIDER}
                value={formRetire?.investment_af_retirement}
                onChange={onChangeAfterRetire}
                min={1}
              />
            </div>
            <SliderSingle
              placeholder="0"
              valuemin={1}
              valuemax={formRetire?.investment_af_retirement_max}
              value={formRetire?.investment_af_retirement}
              onChange={onChangeAfterRetire}
              onChnageMax={onChangeAfterRetireMax}
            />
            {error?.investment_af_retirement && (
              <ErrorMain errortext="กรุณากรอกผลตอบแทนจากการลงทุนหลังเกษียณต่อปี ถ้าไม่มีกรุณากรอก 0" />
            )}
            {error?.investment_af_retirement_max && (
              <ErrorMain errortext="กรุณากรอกผลตอบแทนจากการลงทุนหลังเกษียณสูงสุดต่อปี" />
            )}
          </div>
        }
      />
    </div>
  );
};

export default ViewInvestment;
