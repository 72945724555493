import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../components/header/Header";
import Container from "../../components/layout/Container";
import { NavigatePath } from "../../functions/Navigate.Goback";
import { STYLE_MARGIN_TOP_PARENT } from "../../utils/useStyle";
import { ImageMain } from "../../components/img/Img.Main";
import { ButtonCounter } from "../../components/button/MTButton";
import { CardKids } from "./styles/CardMain";
import { useState } from "react";
import { useEducationStore } from "../../_store/educationStore";
import { v4 as uuid } from "uuid";
import { PopupMain } from "../../components/popup/PopupMain";

const EducationMain = () => {
  const navigate = useNavigate();
  const educationData = useEducationStore((state) => state.educationData);
  const setEducationData = useEducationStore((state) => state.setEducationData);
  const [openPopup, setOpenPopup] = useState(false);

  // console.log("educationData", educationData);

  // เพิ่มบุตร
  const onClickNextEducation = () => {
    const id = uuid();
    const tempEducationData = educationData ? [...educationData] : [];
    tempEducationData.push({
      name: "",
      year: "",
      age: "",
      img: "",
      id,
      isEdit: true,
      current_education: "",
      target_education: "",
      under_education: "",
      education_items: [],
      sum_total_education: 0,
      sum_total_education_accumulate: 0,
      sum_total_invest: 0,
      sum_total_save: 0,
      no: tempEducationData.length + 1,
    });
    setEducationData(tempEducationData);
  };

  // ลบบุตร

  const onClickDeleteEducationItem = (id) => (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setOpenPopup(true);
  };

  const onClickConfirmDeleteEducationItem = (id) => (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    const tempEducationData = educationData.filter((item) => item.id !== id);
    setEducationData(tempEducationData);
    setOpenPopup(false);
  };

  // ไปหน้าแก้ไขข้อมูลบุตร
  const onClickNavigateEducationInfo = (id) => (e) => {
    NavigatePath(navigate, `/education/info?id=${id}`);
  };

  return (
    <div>
      <Container>
        <div
          style={{
            backgroundImage: `url(/images/circle.png)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: " left ",
          }}
          className={` mb-10`}
        >
          <div className={`${STYLE_MARGIN_TOP_PARENT} `}>
            <HeaderTitle
              title="วางแผนการศึกษาบุตร"
              isGoback
              onClickGoBack={() => {
                NavigatePath(navigate, "/");
              }}
            />
            <ImageMain
              src="../images/edu_1.png"
              className="w-[151px] h-[122px] mx-auto mt-5"
            />
            <div className=" px-6 mt-[18px] space-y-[18px]">
              <div className="  bg-gray50 rounded-full px-4 py-[6px] flex justify-between items-center h-[42px]  ">
                <div className=" text-sm text-black font-medium">
                  จำนวนบุตรทั้งหมด (คน)
                </div>
                <ButtonCounter
                  bg="bg-white"
                  value={educationData?.length}
                  handleNext={onClickNextEducation}
                  // isMinus
                  // handleNext={handleCreditCardNext?.bind(
                  //   this,
                  //   index
                  // )}
                />
              </div>
              {educationData.length > 0 &&
                educationData.map((item, index) => {
                  // console.log("item", item);
                  return (
                    <div className="  w-full  ">
                      <CardKids
                        key={index}
                        onClick={onClickNavigateEducationInfo(item?.id)}
                        onClickDelete={onClickDeleteEducationItem(item?.id)}
                        name={item?.name}
                        year={item?.year}
                        age={item?.age}
                        img={item?.img}
                        no={item?.no}
                      />

                      <PopupMain
                        title={"ยืนยันการลบข้อมูล"}
                        text={
                          <div className=" text-base">
                            คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                          </div>
                        }
                        button2={true}
                        textButton={"ยืนยัน"}
                        textButton2={"ยกเลิก"}
                        open={openPopup}
                        onClick={onClickConfirmDeleteEducationItem(item?.id)}
                        onClickSecond={() => {
                          setOpenPopup(false);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EducationMain;
