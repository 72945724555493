import { calDebtCreditCard } from "../../utils/useFunctions";

export const OutcomeReliableCalAfter = (retireData, balanceData) => {
  let tempOutcome = [];

  // ========หนี้=============================

  // หนี้ดอกลอย
  let debtShortList = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_shortterm_other"
  )?.short_term_others;

  // หนี้ีนอกระบบ
  let debtInformalShortterm = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_informal_shortterm"
  )?.informal_short_terms;

  // เงินกู้ระยะสั้น
  let debtShortTerm = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_shortterm"
  )?.short_terms;

  // บัตรเครดิต
  let debtCreditcard = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_creditcard"
  )?.credit_cards;

  // ========หนี้ระยะยาว=============================

  // หนี้ระยะยาว รถ
  let debtCar = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_car"
  )?.debt_long_cars;

  // หนี้ระยะยาว บ้าน
  let debtPlace = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_place"
  )?.debt_long_places;

  // หนี้ระยะยาว อื่นๆ
  let debtLongOther = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_long_other"
  )?.debt_long_others;

  retireData?.af_plan_age?.map((item, indexA) => {
    tempOutcome.push(
      (retireData?.out_come - retireData?.strategy_out_come) * 12
    );

    // ========หนี้=============================

    // หนี้ดอกลอย
    debtShortList?.map((itemB, indexB) => {
      let year = Math.floor(itemB?.period / 12);
      let lastYearMonth = itemB?.period % 12;

      if (indexA >= Math.ceil(itemB?.period / 12)) {
        tempOutcome[indexA] += 0;
      }
      if (indexA < year) {
        tempOutcome[indexA] += itemB?.pay_permonth * 12;
      } else {
        tempOutcome[indexA] += itemB?.pay_permonth * lastYearMonth;
      }
    });

    // หนี้ีนอกระบบ
    debtInformalShortterm?.map((item2, index2) => {
      let year = Math.floor(item2?.number_of_days / 365);
      let lastYearMonth = item2?.number_of_days % 30;

      if (item2?.period === "Infinity") {
        tempOutcome[indexA] += item2?.pay_permonth * 12;
      } else {
        if (indexA >= Math.ceil(item2?.number_of_days / 365)) {
          tempOutcome[indexA] += 0;
        } else {
          if (indexA < year) {
            tempOutcome[indexA] += item2?.pay_permonth * 365;
          } else {
            tempOutcome[indexA] += item2?.pay_permonth * lastYearMonth;
          }
        }
      }
    });

    // เงินกู้ระยะสั้น
    debtShortTerm?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (item2?.period === "Infinity") {
        tempOutcome[indexA] += item2?.pay_permonth * 12;
      } else {
        if (indexA >= Math.ceil(item2?.number_of_days / 365)) {
          tempOutcome[indexA] += 0;
        } else {
          if (indexA >= Math.ceil(item2?.period / 12)) {
            tempOutcome[indexA] += 0;
          } else {
            if (indexA < year) {
              tempOutcome[indexA] += item2?.pay_permonth * 12;
            } else {
              tempOutcome[indexA] += item2?.pay_permonth * lastYearMonth;
            }
          }
        }
      }
    });

    // บัตรเครดิต
    debtCreditcard?.map((item2, index2) => {
      let period = calDebtCreditCard(item2)?.creditor_items?.length;
      let year = Math.floor(period / 12);
      let lastYearMonth = period % 12;

      if (item2?.type_payment === "pay_full" && indexA === 0) {
        return (tempOutcome[indexA] += item2?.pay_peryear);
      }

      if (indexA >= Math.ceil(period / 12)) {
        return (tempOutcome[indexA] += 0);
      }
      if (indexA < year) {
        return (tempOutcome[indexA] += item2?.pay_permonth * 12);
      } else {
        return (tempOutcome[indexA] += item2?.pay_permonth * lastYearMonth);
      }
    });

    // ========หนี้ระยะยาว=============================
    // หนี้ระยะยาว รถ
    debtCar?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (indexA >= Math.ceil(item2?.period / 12)) {
        return (tempOutcome[indexA] += 0);
      }
      if (indexA < year) {
        return (tempOutcome[indexA] += item2?.pay_permonth * 12);
      } else {
        return (tempOutcome[indexA] += item2?.pay_permonth * lastYearMonth);
      }
    });

    // หนี้ระยะยาว บ้าน
    debtPlace?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (indexA >= Math.ceil(item2?.period / 12)) {
        return (tempOutcome[indexA] += 0);
      }
      if (indexA < year) {
        return (tempOutcome[indexA] += item2?.pay_permonth * 12);
      } else {
        return (tempOutcome[indexA] += item2?.pay_permonth * lastYearMonth);
      }
    });

    // หนี้ระยะยาว อื่นๆ
    debtLongOther?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (indexA >= Math.ceil(item2?.period / 12)) {
        return (tempOutcome[indexA] += 0);
      }
      if (indexA < year) {
        return (tempOutcome[indexA] += item2?.pay_permonth * 12);
      } else {
        return (tempOutcome[indexA] += item2?.pay_permonth * lastYearMonth);
      }
    });
  });

  return tempOutcome;
};

export const OutcomeUreliableCalAfter = (retireData, balanceData) => {
  let tempOutcome = [];

  // ========หนี้=============================

  // หนี้ดอกลอย
  let debtShortList = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_shortterm_other"
  )?.short_term_others;

  // หนี้ีนอกระบบ
  let debtInformalShortterm = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_informal_shortterm"
  )?.informal_short_terms;

  // เงินกู้ระยะสั้น
  let debtShortTerm = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_shortterm"
  )?.short_terms;

  // บัตรเครดิต
  let debtCreditcard = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_creditcard"
  )?.credit_cards;

  // ========หนี้ระยะยาว=============================

  // หนี้ระยะยาว รถ
  let debtCar = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_car"
  )?.debt_long_cars;

  // หนี้ระยะยาว บ้าน
  let debtPlace = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_place"
  )?.debt_long_places;

  // หนี้ระยะยาว อื่นๆ
  let debtLongOther = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_long_other"
  )?.debt_long_others;

  Array?.from({
    length:
      Number(retireData?.strategy_age_dead || retireData?.age_dead) -
      Number(retireData?.age_current || 0) +
      1,
  })?.map((item, indexA) => {
    let calOutcome =
      Number(retireData?.out_come_variable || 0) *
      Math.pow(1 + retireData?.out_come_peryear / 100, indexA);
    if (indexA === 0) {
      calOutcome = Number(retireData?.out_come_variable || 0);
    }
    // tempOutcome.push(calOutcome * 12);
    tempOutcome.push(retireData?.out_come * 12);

    // ========หนี้=============================

    // หนี้ดอกลอย
    debtShortList?.map((itemB, indexB) => {
      let year = Math.floor(itemB?.period / 12);
      let lastYearMonth = itemB?.period % 12;

      if (indexA >= Math.ceil(itemB?.period / 12)) {
        tempOutcome[indexA] += 0;
      }
      if (indexA < year) {
        tempOutcome[indexA] += itemB?.pay_permonth * 12;
      } else {
        tempOutcome[indexA] += itemB?.pay_permonth * lastYearMonth;
      }
    });

    // หนี้ีนอกระบบ
    debtInformalShortterm?.map((item2, index2) => {
      let year = Math.floor(item2?.number_of_days / 365);
      let lastYearMonth = item2?.number_of_days % 30;

      if (item2?.period === "Infinity") {
        tempOutcome[indexA] += item2?.pay_permonth * 12;
      } else {
        if (indexA >= Math.ceil(item2?.number_of_days / 365)) {
          tempOutcome[indexA] += 0;
        } else {
          if (indexA < year) {
            tempOutcome[indexA] += item2?.pay_permonth * 365;
          } else {
            tempOutcome[indexA] += item2?.pay_permonth * lastYearMonth;
          }
        }
      }
    });

    // เงินกู้ระยะสั้น
    debtShortTerm?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (item2?.period === "Infinity") {
        tempOutcome[indexA] += item2?.pay_permonth * 12;
      } else {
        if (indexA >= Math.ceil(item2?.number_of_days / 365)) {
          tempOutcome[indexA] += 0;
        } else {
          if (indexA >= Math.ceil(item2?.period / 12)) {
            tempOutcome[indexA] += 0;
          } else {
            if (indexA < year) {
              tempOutcome[indexA] += item2?.pay_permonth * 12;
            } else {
              tempOutcome[indexA] += item2?.pay_permonth * lastYearMonth;
            }
          }
        }
      }
    });

    // บัตรเครดิต
    debtCreditcard?.map((item2, index2) => {
      let period = calDebtCreditCard(item2)?.creditor_items?.length;
      let year = Math.floor(period / 12);
      let lastYearMonth = period % 12;

      if (item2?.type_payment === "pay_full" && indexA === 0) {
        return tempOutcome[indexA] += item2?.pay_peryear
      }

      if (indexA >= Math.ceil(period / 12)) {
        return tempOutcome[indexA] += 0
      }
      if (indexA < year) {
        return tempOutcome[indexA] += item2?.pay_permonth * 12
      } else {
        return tempOutcome[indexA] += item2?.pay_permonth * lastYearMonth
      }
    });

    // ========หนี้ระยะยาว=============================
    // หนี้ระยะยาว รถ
    debtCar?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (indexA >= Math.ceil(item2?.period / 12)) {
        return tempOutcome[indexA] += 0
      }
      if (indexA < year) {
        return (tempOutcome[indexA] += item2?.pay_permonth * 12);
      } else {
        return (tempOutcome[indexA] += item2?.pay_permonth * lastYearMonth);
      }
    });

    // หนี้ระยะยาว บ้าน
    debtPlace?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (indexA >= Math.ceil(item2?.period / 12)) {
        return (tempOutcome[indexA] += 0);
      }
      if (indexA < year) {
        return (tempOutcome[indexA] += item2?.pay_permonth * 12);
      } else {
        return (tempOutcome[indexA] += item2?.pay_permonth * lastYearMonth);
      }
    });

    // หนี้ระยะยาว อื่นๆ
    debtLongOther?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (indexA >= Math.ceil(item2?.period / 12)) {
        return (tempOutcome[indexA] += 0);
      }
      if (indexA < year) {
        return (tempOutcome[indexA] += item2?.pay_permonth * 12);
      } else {
        return (tempOutcome[indexA] += item2?.pay_permonth * lastYearMonth);
      }
    });

  });


  return tempOutcome;
};

export const InvestmentReturnCalAfter = (retireData) => {
  let tempOutcomeReliable = OutcomeUreliableCalAfter(retireData);
  let tempIncome = retireData?.af_plan_income;
  let tempOutcome = [];
  let valueInvest = {};

  Array?.from(
    {
      length:
        Number(retireData?.strategy_age_dead || retireData?.age_dead) -
        Number(retireData?.age_current || 0) +
        1,
    },
    () => 0
  )?.map((item, index) => {
    const valueInvest = {
      return_invest: 0,
      asset_invest: 0,
    };

    // console.log("retireData",retireData)

    if (index === 0) {
      valueInvest.return_invest =
        (Number(retireData?.investment_assets || 0) +
          Number(retireData?.strategy_assets_generate || 0)) *
        (Number(retireData?.investment_af_retirement || 0) / 100);

      valueInvest.asset_invest =
        Number(tempIncome[index] || 0) -
        Number(OutcomeUreliableCalAfter(retireData)[index] || 0) +
        Number(retireData?.investment_assets || 0) +
        Number(retireData?.strategy_assets_generate || 0) +
        Number(valueInvest.return_invest || 0);

        console.log("after OutcomeUreliableCalAfter",OutcomeUreliableCalAfter(retireData)[index])
    
    } else {
      if (item <= retireData?.strategy_age_retire) {
        valueInvest.return_invest =
          Number(tempOutcome[index - 1]?.asset_invest || 0) *
          (Number(retireData?.investment_af_retirement || 0) / 100);

        valueInvest.asset_invest =
          Number(tempIncome[index] || 0) -
          Number(OutcomeUreliableCalAfter(retireData)[index] || 0) +
          Number(valueInvest?.return_invest || 0) +
          Number(tempOutcome[index - 1]?.asset_invest || 0);
      } else {
        valueInvest.return_invest =
          Number(tempOutcome[index - 1]?.asset_invest || 0) *
          (Number(retireData?.investment_af_retirement || 0) / 100);

        valueInvest.asset_invest =
          Number(tempIncome[index] || 0) -
          Number(OutcomeUreliableCalAfter(retireData)[index] || 0) +
          Number(valueInvest?.return_invest || 0) +
          Number(tempOutcome[index - 1]?.asset_invest || 0);
      }
    }

    return tempOutcome.push(valueInvest);
  });

  return tempOutcome;
};

export const DebtRetireAfter = (
  tempFutureData,
  balanceData,
  educationData,
  key_under_education
) => {
  let copytempFutureData = tempFutureData;

  let carExpense = tempFutureData?.expenese_car_items;
  let homeExpense = tempFutureData?.expenese_home_items;
  let childExpense = tempFutureData?.expenese_children_items;
  let travelExpense = tempFutureData?.expenese_travel_items;
  let collectionExpense = tempFutureData?.expenese_collection_items;
  let otherExpense = tempFutureData?.expenese_other_items;

  // =======รายได้===========================

  //----------"มรดก"------------
  let incomeLegacyItems = tempFutureData?.income_legacy_items;
  //----------ขายธุรกิจ------------
  let incomeBusinessItems = tempFutureData?.income_business_items;

  //----------รายได้จากอสังหาริม------------
  let incomePropertyItems = tempFutureData?.income_property_items;

  //-------รายได้อื่นๆ----------
  let incomeOtherItems = tempFutureData?.income_other_items;

  // ========หนี้=============================

  // หนี้ดอกลอย
  let debtShortList = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_shortterm_other"
  )?.short_term_others;

  // หนี้ีนอกระบบ
  let debtInformalShortterm = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_informal_shortterm"
  )?.informal_short_terms;

  // เงินกู้ระยะสั้น
  let debtShortTerm = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_shortterm"
  )?.short_terms;

  // บัตรเครดิต
  let debtCreditcard = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_creditcard"
  )?.credit_cards;

  // ========หนี้ระยะยาว=============================

  // หนี้ระยะยาว รถ
  let debtCar = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_car"
  )?.debt_long_cars;

  // หนี้ระยะยาว บ้าน
  let debtPlace = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_place"
  )?.debt_long_places;

  // หนี้ระยะยาว อื่นๆ
  let debtLongOther = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_long_other"
  )?.debt_long_others;

  // คำนวณรายจ่าย
  let newTempData = tempFutureData?.cash_flow_items?.map((itemA, indexA) => {
    let newItem = itemA;

    newItem.outcome_total =
      Number(newItem?.out_come_stable || 0) +
      Number(newItem?.outcome_peryear || 0);

    carExpense?.map((itemB, indexB) => {
      if (itemB?.year > indexA) {
        newItem.outcome_total = itemB?.sum_invest_five + newItem?.outcome_total;
      }
      if (itemB?.year + itemB?.period_year > indexA && itemB?.year <= indexA) {
        newItem.outcome_total =
          itemB?.pay_per_year * 12 + newItem?.outcome_total;
      }
    });

    homeExpense?.map((itemB, indexB) => {
      if (itemB?.year > indexA) {
        newItem.outcome_total = itemB?.sum_invest_five + newItem?.outcome_total;
      }
      if (itemB?.year + itemB?.period_year > indexA && itemB?.year <= indexA) {
        newItem.outcome_total =
          itemB?.pay_per_year * 12 + newItem?.outcome_total;
      }
    });

    childExpense?.map((itemB, indexB) => {
      if (itemB?.ishasEducation) {
        if (itemB?.year > indexA) {
          return (newItem.outcome_total += itemB?.sum_invest_five);
        }

        // if (indexA > itemB?.year + 1 && indexA < itemB?.year + 21) {
        if (itemB?.year - 1 < indexA) {
          let eduExpense = Math.ceil(
            key_under_education?.[itemB?.education_under]?.default_value *
              Math.pow(1.06, indexA - 1)
          );
          return (newItem.outcome_total += eduExpense);
        }

        return (newItem.outcome_total += 0);
      }

      if (itemB?.year - 1 > indexA) {
        return (newItem.outcome_total += itemB?.sum_invest_five);
      }

      return (newItem.outcome_total += 0);
    });

    travelExpense?.map((itemB, indexB) => {
      if (itemB?.year > indexA) {
        newItem.outcome_total = itemB?.sum_invest_five + newItem?.outcome_total;
      }
    });

    collectionExpense?.map((itemB, indexB) => {
      if (itemB?.year > indexA) {
        newItem.outcome_total = itemB?.sum_invest_five + newItem?.outcome_total;
      }
    });

    otherExpense?.map((itemB, indexB) => {
      if (itemB?.year > indexA) {
        newItem.outcome_total = itemB?.sum_invest_five + newItem?.outcome_total;
      }
    });

    //----------"การศึกษาบุตร"------------

    educationData?.map((itemB, indexB) => {
      if (tempFutureData?.expenese_children_education?.length > 0) {
        newItem.outcome_total += Number(
          itemB?.education_items[indexA]?.sum_under_education || 0
        );

        return;
      }
    });

    // ========หนี้=============================

    // หนี้ดอกลอย
    debtShortList?.map((itemB, indexB) => {
      let year = Math.floor(itemB?.period / 12);
      let lastYearMonth = itemB?.period % 12;

      if (indexA >= Math.ceil(itemB?.period / 12)) {
        newItem.outcome_total += 0;
      }
      if (indexA < year) {
        newItem.outcome_total += itemB?.pay_permonth * 12;
      } else {
        newItem.outcome_total += itemB?.pay_permonth * lastYearMonth;
      }
    });

    // หนี้ีนอกระบบ
    debtInformalShortterm?.map((item2, index2) => {
      let year = Math.floor(item2?.number_of_days / 365);
      let lastYearMonth = item2?.number_of_days % 30;

      if (item2?.period === "Infinity") {
        newItem.outcome_total += item2?.pay_permonth * 12;
      } else {
        if (indexA >= Math.ceil(item2?.number_of_days / 365)) {
          newItem.outcome_total += 0;
        } else {
          if (indexA < year) {
            newItem.outcome_total += item2?.pay_permonth * 365;
          } else {
            newItem.outcome_total += item2?.pay_permonth * lastYearMonth;
          }
        }
      }
    });

    // เงินกู้ระยะสั้น
    debtShortTerm?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (item2?.period === "Infinity") {
        newItem.outcome_total += item2?.pay_permonth * 12;
      } else {
        if (indexA >= Math.ceil(item2?.number_of_days / 365)) {
          newItem.outcome_total += 0;
        } else {
          if (indexA >= Math.ceil(item2?.period / 12)) {
            newItem.outcome_total += 0;
          } else {
            if (indexA < year) {
              newItem.outcome_total += item2?.pay_permonth * 12;
            } else {
              newItem.outcome_total += item2?.pay_permonth * lastYearMonth;
            }
          }
        }
      }
    });

    // บัตรเครดิต
    debtCreditcard?.map((item2, index2) => {
      let period = calDebtCreditCard(item2)?.creditor_items?.length;
      let year = Math.floor(period / 12);
      let lastYearMonth = period % 12;

      if (item2?.type_payment === "pay_full" && indexA === 0) {
        return (newItem.outcome_total += item2?.pay_peryear);
      }

      if (indexA >= Math.ceil(period / 12)) {
        return (newItem.outcome_total += 0);
      }
      if (indexA < year) {
        return (newItem.outcome_total += item2?.pay_permonth * 12);
      } else {
        return (newItem.outcome_total += item2?.pay_permonth * lastYearMonth);
      }
    });

    // ========หนี้ระยะยาว=============================

    // หนี้ระยะยาว รถ
    debtCar?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (indexA >= Math.ceil(item2?.period / 12)) {
        return (newItem.outcome_total += 0);
      }
      if (indexA < year) {
        return (newItem.outcome_total += item2?.pay_permonth * 12);
      } else {
        return (newItem.outcome_total += item2?.pay_permonth * lastYearMonth);
      }
    });

    // หนี้ระยะยาว บ้าน
    debtPlace?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (indexA >= Math.ceil(item2?.period / 12)) {
        return (newItem.outcome_total += 0);
      }
      if (indexA < year) {
        return (newItem.outcome_total += item2?.pay_permonth * 12);
      } else {
        return (newItem.outcome_total += item2?.pay_permonth * lastYearMonth);
      }
    });

    // หนี้ระยะยาว อื่นๆ
    debtLongOther?.map((item2, index2) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (indexA >= Math.ceil(item2?.period / 12)) {
        return (newItem.outcome_total += 0);
      }
      if (indexA < year) {
        return (newItem.outcome_total += item2?.pay_permonth * 12);
      } else {
        return (newItem.outcome_total += item2?.pay_permonth * lastYearMonth);
      }
    });

    return newItem;
  });

  // คำนวณรายได้
  newTempData?.map((itemA, indexA) => {
    let newItem = itemA;
    // newItem.income_peryear = 0

    if (itemA?.age > tempFutureData?.age_retire) {
      newItem.income_peryear = itemA?.pension_peryear;
    }

    //----------"มรดก"------------
    incomeLegacyItems?.map((itemB, indexB) => {
      if (itemB?.year === indexA) {
        return (newItem.income_peryear += itemB?.amount);
      }
    });

    //----------ขายธุรกิจ------------
    incomeBusinessItems?.map((itemB, indexB) => {
      if (itemB?.year === indexA) {
        return (newItem.income_peryear += itemB?.amount);
      }
    });

    //----------รายได้จากอสังหาริม------------
    incomePropertyItems?.map((itemB, indexB) => {
      if (itemB?.is_rent) {
        if (itemB?.year > indexA) {
          return (newItem.income_peryear += 0);
        }
        if (itemB?.year <= indexA && itemB?.year + itemB?.year_rent > indexA) {
          return (newItem.income_peryear += itemB?.amount_perYear);
        }
        return (newItem.income_peryear += 0);
      }

      if (itemB?.year === indexA) {
        return (newItem.income_peryear += itemB?.amount);
      }
    });

    //-------รายได้อื่นๆ----------
    incomeOtherItems?.map((itemB, indexB) => {
      if (itemB?.year > indexA) {
        return (newItem.income_peryear += 0);
      }
      if (itemB?.year <= indexA && itemB?.year + itemB?.year_rent > indexA) {
        return (newItem.income_peryear += itemB?.amount_perYear);
      }
      return (newItem.income_peryear += 0);
    });

    return newItem;
  });

  // คำนวณคงเหลือ
  newTempData?.map((itemA, indexA) => {
    let newItem = itemA;
    newItem.remain =
      Number(itemA?.income_peryear || 0) - Number(itemA?.outcome_total || 0);

    return newItem;
  });

  // ผลตอบแทนการลงทุน
  // สินทรัพย์ลงทุน

  newTempData?.map((itemA, indexA) => {
    let newItem = itemA;
    if (indexA === 0) {
      newItem.income_investment =
        (tempFutureData?.income_investment *
          tempFutureData?.income_investment_percent) /
        100;
      newItem.remain_accumulate =
        newItem?.remain +
        newItem?.income_investment +
        tempFutureData?.income_investment;

      return newItem;
    }
    // newItem.income_investment = (newTempData[indexA-1]* tempFutureData?.income_investment_percent)/100
    newItem.remain_accumulate =
      newItem?.remain +
      newItem?.income_investment +
      newTempData[indexA - 1]?.remain_accumulate;

    return newItem;
  });

  return newTempData;
};
