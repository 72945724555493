import numeral from "numeral";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { MTButton } from "../../../components/button/MTButton";
import { CardMain, CardProduct } from "../../../components/card/CardMain";
import { FooterAfterSum } from "../../../components/FooterAfterSum/FooterAfterSum";
import { defaultValue } from "../../../functions/main";
import { STYLE_PADDING_MAIN } from "../../../utils/useStyle";
import CustomHorizontalBarChart from "../../pdf/component/pdf.c.familyChart";
import { first_reload } from "../functions/firstLoad";
import { StatusCard } from "../styles/Card";
import ViewTableFamily from "./VITableFamily.Main";

const ViewFamily_Final = ({ setFinal, setTab }) => {
  const navigate = useNavigate();
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  // console.log("insuranceData", insuranceData);
  // console.log("educationData", educationData);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  const getStatus = () => {
    if (insuranceData.sum_insurance_more === 0) {
      return "normal";
    } else if (insuranceData.sum_insurance_more < 0) {
      return "toomuch";
    }
    return "more";
  };

  return (
    <div id="first_reload">
      <div className={`${STYLE_PADDING_MAIN} my-3 space-y-5 `}>
        <CardMain
          title={
            <div className=" text-mainblue font-semibold ">
              ผลลัพธ์วางแผนทุนประกันคุ้มครองค่าใช้จ่าย
            </div>
          }
          panel={
            <div className=" px-3  space-y-5 font-medium">
              <div>
                <div>ทุนประกันขั้นต่ำที่ต้องการให้ครอบครัวอยู่รอด 5 ปี</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(
                    defaultValue(insuranceData.sum_family_five_year)
                  ).format("0,0")}{" "}
                  บาท
                </div>
              </div>

              <div>
                <div>
                  ทุนประกันที่ควรมีเพื่อคุ้มครองครอบครัว{" "}
                  {insuranceData?.family_year_survive} ปี
                </div>
                <div className="text-sm">
                  (ยังไม่รวมหนี้สินและทุนการศึกษาบุตร)
                </div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {/* {numeral(insuranceData?.sum_family_remain)?.format("0,0")} บาท */}
                  {numeral(insuranceData?.sum_family)?.format("0,0")} บาท
                </div>
              </div>
              {/* <div>
                <div>ทุนประกันสำหรับหนี้สิน</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(0)?.format("0,0")} บาท
                </div>
              </div> */}

              {insuranceData?.asset_is_debt === "Y" && (
                <div className="flex flex-col border border-gray-400 rounded p-3">
                  <div className="  underline">ทุนประกันที่ต้องมีเพิ่มเติม</div>
                  <div className="mb-4">
                    <div>หนี้สินทั้งหมดของคุณ</div>
                    <div className=" text-maincyan font-semibold text-2xl">
                      {numeral(Number(insuranceData.sum_family_debt||0)).format("0,0")} บาท
                    </div>
                  </div>
                  <div>
                    <div className=" mb-2">ทุนประกันเพื่อการศึกษาบุตร</div>
                    <ViewTableFamily />
                  </div>
                  <div className="ml-auto text-maincyan font-semibold text-md">
                    รวมทั้งสิ้น{" "}
                    {numeral(
                      Number(insuranceData.sum_family_debt||0) +
                        Number(insuranceData.sum_insurance_education||0)
                    ).format("0,0")}{" "}
                    บาท
                  </div>
                </div>
              )}

              {/* ทุนประกันแนะนำ */}
              <div className="flex flex-col border border-gray-400 rounded p-3">
                <div className="  underline">ทุนประกันแนะนำ</div>
                <div className="ml-auto text-maincyan font-semibold text-md">
                  {numeral(
                    Number(insuranceData?.sum_family||0) +
                    Number(insuranceData.sum_family_debt||0) +
                    Number(insuranceData.sum_insurance_education||0)
                  ).format("0,0")}{" "}
                  บาท
                </div>
              </div>

              {/* <div>
                <div>ทุนประกันสุทธิ</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(insuranceData.sum_insurance_net)?.format("0,0")} บาท
                </div>
              </div> */}

              <div className="flex flex-col border border-gray-400 rounded p-3">
                <div className="  underline">
                  ทุนประกันและมูลค่าสินทรัพย์ที่มีอยู่แล้ว
                </div>
                <div>
                  <div>ทุนประกันที่คุณมี ณ ปัจจุบัน</div>
                  <div className=" text-maincyan font-semibold text-2xl">
                    {numeral(insuranceData?.sum_insurance_current)?.format(
                      "0,0"
                    )}{" "}
                    บาท
                  </div>
                </div>
                <div>
                  <div>
                    มูลค่าสินทรัพย์ (ยกเว้นสินทรัพย์ส่วนตัว เช่น
                    บ้านที่อยู่อาศัย รถยนต์ที่ใช้ส่วนตัว)
                  </div>
                  <div className=" text-maincyan font-semibold text-2xl">
                    {numeral(insuranceData?.sum_asset_current)?.format("0,0")}{" "}
                    บาท
                  </div>
                </div>
                <div className="ml-auto text-maincyan font-semibold text-md">
                  รวมทั้งสิ้น{" "}
                  {numeral(
                    insuranceData.sum_insurance_current +
                      insuranceData.sum_asset_current
                  ).format("0,0")}{" "}
                  บาท
                </div>
              </div>

              <div className="flex flex-col border border-gray-400 rounded p-3">
                <StatusCard
                  status={getStatus()}
                  value={insuranceData.sum_insurance_more}
                />
                {/* <div className="underline text-center">ทุนประกันแนะนำ</div>
                <div className="my-4 text-maincyan font-semibold text-2xl text-center">
                  {numeral(insuranceData?.sum_insurance_more)?.format("0,0")}{" "}
                  บาท
                </div> */}
                <div className="text-center">
                  (ทุนประกันเพื่อคุ้มครองครอบครัว{" "}
                  {insuranceData?.family_year_survive} ปี + คุ้มครองภาระหนี้สิน
                  + คุ้มครองการศึกษาบุตร) - (ทุนประกันที่มีอยู่ +
                  มูลค่าสินทรัพย์ลงทุนและสภาพคล่อง)
                </div>
                <div className="my-2 text-center">
                  ({numeral(insuranceData.sum_family).format("0,0")} +{" "}
                  {numeral(insuranceData.sum_family_debt).format("0,0")} +{" "}
                  {numeral(insuranceData.sum_insurance_education).format("0,0")}
                  ) - (
                  {numeral(insuranceData.sum_insurance_current).format("0,0")} +{" "}
                  {numeral(insuranceData.sum_asset_current).format("0,0")}){" "}
                  = {numeral(insuranceData.sum_insurance_more).format("0,0")}
                </div>
                <div className="w-[300px] flex justify-center mx-auto">
                  <CustomHorizontalBarChart
                    width={120}
                    yourData={[
                      insuranceData.sum_insurance_current +
                        insuranceData.sum_asset_current,
                      0,
                    ]}
                    recomenedData={[
                      0,
                      insuranceData?.sum_family +
                        insuranceData.sum_family_debt +
                        insuranceData.sum_insurance_education,
                    ]}
                    barThickness={40}
                  />
                </div>
              </div>

              {/* <StatusCard
                status={getStatus()}
                value={insuranceData.sum_insurance_more}
              /> */}
            </div>
          }
        />
        <MTButton
          onClick={() => {
            setFinal(false);
            setTab(2);
          }}
          text="ย้อนกลับ"
          color="bg-white"
          border="border border-maincyan"
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          width="w-full"
        />
        <CardProduct />
        <FooterAfterSum />
      </div>
    </div>
  );
};

export default ViewFamily_Final;
