import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import NavigateGoBack from "../../../functions/Navigate.Goback";
import {
  STYLE_CARD_BG,
  STYLE_FLEX_STATUS,
  STYLE_HEAD_STRAGADY,
  STYLE_MARGIN_TOP_PARENT,
} from "../../../utils/useStyle";
import { ImageMain } from "../../../components/img/Img.Main";
import { useRetireStore } from "../../../_store/retireStore";
import LineChart2Main from "../../../components/chart/LineChart2Main";
import LineChartMain from "../../../components/chart/LineChartMain";
import { dataAgeAF } from "../functions/functionRetriementPlan";
import {
  ICON_CHECK,
  ICON_CLOSE,
  ICON_STAR,
  ICON_WARNING,
} from "../../../utils/useIcons";
import { useIsMobile } from "../../../utils/useIsMobile";
import { FooterAfterSum } from "../../../components/FooterAfterSum/FooterAfterSum";
import { useEffect, useMemo, useState } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { MTButton } from "../../../components/button/MTButton";
import {
  lastResultInvestmentAssetsAFSensitive,
  loopInvestmentAssetsAFSensitive,
} from "../functions/functionRetrementSensitivePlan";
import numeral from "numeral";
import { Checkbox } from "@material-tailwind/react";
import { InvestmentReturnCalAfter } from "../../../functions/retirement/retirementFunctionAfter";
import { InvestmentReturnCal } from "../../../functions/retirement/retirementFunction";
import { calPMTFVA } from "../../education/functions/function";

const convertYearToMonth = (year) => {
  return year * 12;
};

const ViewSensitiveTest = ({
  strategyChange,
  lastResult,
  retireResult,
  remain,
  // afterDiffYear,
  // sumAfterRetireInvest,
}) => {
  const retireData = useRetireStore((state) => state.retireData);
  const setRetireData = useRetireStore((state) => state.setRetireData);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [retireSensitiveData, setRetireSensitiveData] = useState([]);

  const afterDiffYear =
    retireData?.strategy_age_retire - retireData?.age_current;

  const dataPlanSensitive = retireSensitiveData?.map((item) => {
    return item?.results;
  });

  const getInvestSumAF = InvestmentReturnCalAfter(retireData)?.map(
    (af_investment_assets, index) => {
      return {
        result: af_investment_assets?.asset_invest,
        age: retireData?.af_plan_age[index],
      };
    }
  );

  const getremainAF = getInvestSumAF?.filter((thisRemain, i) => {
    if (thisRemain?.result <= 0) return { ...thisRemain };
  })?.[0];

  const lastResultInvest = lastResultInvestmentAssetsAFSensitive();

  const retirementAge = retireData?.age_retire;
  const remainAF = Number(getremainAF?.age) - 1;

  const getInvestSum = InvestmentReturnCal(retireData)?.map(
    (bf_investment_assets, index) => {
      return {
        result: bf_investment_assets?.asset_invest,
        age: retireData?.bf_plan_age[index],
      };
    }
  );

  const lastResultAF = getInvestSumAF[getInvestSumAF?.length - 1];
  const retireBF = getInvestSum.find(
    (result) => result.age === retirementAge
  )?.result;

  const sumAfterRetireInvest = useMemo(() => {
    const convertedMonth = convertYearToMonth(afterDiffYear);
    const sumPMT = calPMTFVA(
      lastResultAF?.result < 0
        ? Math.abs(lastResultAF?.result)
        : lastResultAF?.result,
      retireData?.investment_af_retirement / 100 / 12,
      convertedMonth
    );
    return sumPMT;
  }, [retireData]);

  // หาอายุสุดท้าย
  const getAgeSensitive = retireSensitiveData?.filter((item) => {
    if (item?.results > 0) return item;
  });
  const ageSensitive = getAgeSensitive?.[getAgeSensitive?.length - 1]?.age;

  useEffect(() => {
    setRetireSensitiveData(loopInvestmentAssetsAFSensitive());
  }, [retireData]);

  const onChangeCheckImagine = (e) => {
    setRetireData({ ...retireData, [e.target.name]: e.target.checked });
  };

  // console.log("dataPlanSensitive", dataPlanSensitive);

  return (
    <div id="first_reload">
      <Container>
        <HeaderTitle
          title="ทดสอบความอ่อนไหว"
          isGoback
          onClickGoBack={() => {
            setRetireSensitiveData([]);
            NavigateGoBack(navigate);
          }}
        />
        <div className={`${STYLE_MARGIN_TOP_PARENT} mb-10 px-6  `}>
          <div className=" mb-[18px]">
            {/*---------- ผลลัพธ์ ----------*/}
            {lastResultInvest > 0 ? (
              <div className=" text-center text-black text-md flex flex-col gap-[9px]">
                <ImageMain
                  src="/images/goodface.svg"
                  className="w-18 h-18 mx-auto"
                />
                <div>
                  <div>ยินดีด้วย...</div>
                  ท่านสามารถใช้ชีวิตหลังเกษียณได้ตามที่ท่านตั้งใจไว้
                </div>
              </div>
            ) : (
              <div className=" text-center text-black text-md flex flex-col gap-[9px]">
                <ImageMain
                  src="/images/badface.svg"
                  className="w-18 h-18 mx-auto"
                />
                <div>
                  ไม่นะ..เมื่อท่านอายุ {retireData?.age_dead} ปี
                  <div>เงินของท่านจะหมดลง ก่อนสิ้นอายุขัย</div>
                </div>
              </div>
            )}
          </div>

          {/*---- ความเคลื่อนไหวทางการเงิน ----*/}
          <div className={`${STYLE_CARD_BG} mb-[18px]`}>
            <div className=" flex flex-col gap-2 mb-[8px] ">
              <div className={STYLE_HEAD_STRAGADY}>
                ความเคลื่อนไหวทางการเงิน
              </div>
              <div className={`border-b border-mainyellow w-[295px] `}></div>
            </div>
            <div className="px-4">
              <LineChartMain
                labels={dataAgeAF()}
                dataPlan={dataPlanSensitive}
                head={`สินทรัพย์เพื่อการลงทุนสุทธิ`}
              />
            </div>
            <div className=" mt-[26px] mb-6 space-y-2.5 px-2">
              {retireData?.strategy_sum ? (
                <>
                  {lastResultAF?.result > 0 ? (
                    <>
                      <div className=" space-y-[10px] mb-3 mt-5">
                        <div className={STYLE_FLEX_STATUS}>
                          <ICON_CHECK className=" w-6 text-maincyan " />
                          <div className=" w-full text-sm flex-none">
                            <div className=" flex flex-wrap font-medium w-full h-fit ">
                              เริ่มวางแผนเกษียณตอนอายุ {retireData?.age_current}{" "}
                              ปี ประมาณการณ์เมื่อเกษียณอายุตอน{" "}
                              {retireData?.age_retire} ปี จะมีสินทรัพย์ลงทุนรวม
                            </div>
                            <div className="font-medium ">
                              {" "}
                              <span className="text-mainblue">
                                {numeral(retireResult)?.format("0,0.00")}
                              </span>{" "}
                              บาท
                            </div>
                          </div>
                        </div>
                        <div className={STYLE_FLEX_STATUS}>
                          <ICON_WARNING className=" w-6 text-mainyellow" />
                          <div className="  text-sm flex-none">
                            สามารถใช้จนได้ถึงอายุ{" "}
                            <span className="font-medium">
                              {retireData?.strategy_age_dead
                                ? retireData?.strategy_age_dead
                                : retireData?.age_dead}{" "}
                              ปี
                            </span>{" "}
                            แต่อย่าประมาทนะ
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className=" mt-[26px] mb-6 space-y-2.5">
                      <div className={STYLE_FLEX_STATUS}>
                        <ICON_CHECK className=" w-6 text-maincyan " />
                        <div className="  text-sm flex-none">
                          คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ{" "}
                          <span className="font-medium">
                            {retireData?.age_current} ปี
                          </span>
                        </div>
                      </div>
                      <div className={STYLE_FLEX_STATUS}>
                        <ICON_WARNING className=" w-6 text-mainyellow" />
                        <div className="  text-sm flex-none">
                          สามารถใช้จนได้ถึงอายุ
                          <span className=" font-medium"> {remainAF} ปี </span>
                        </div>
                      </div>
                      <div className={STYLE_FLEX_STATUS}>
                        <ICON_CLOSE className=" w-6 text-mainred" />
                        {isMobile ? (
                          <div className="  text-sm flex-none">
                            <div>
                              ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                              <span className=" font-medium">
                                {retireData?.age_dead} ปี
                              </span>{" "}
                            </div>
                            ยังขาดอยู่ทั้งหมด{" "}
                            <span className=" font-medium text-mainblue">
                              {lastResultAF?.result < 0
                                ? numeral(lastResultAF?.result * -1)?.format(
                                    "0,0"
                                  )
                                : numeral(lastResultAF?.result)?.format("0,0")}
                              {""} บาท
                            </span>
                          </div>
                        ) : (
                          <div className="  text-sm flex-none">
                            ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                            <span className=" font-medium">
                              {retireData?.age_dead} ปี
                            </span>{" "}
                            ยังขาดอยู่ทั้งหมด{" "}
                            <span className=" font-medium text-mainblue">
                              {lastResultAF?.result < 0
                                ? numeral(lastResultAF?.result * -1)?.format(
                                    "0,0"
                                  )
                                : numeral(lastResultAF?.result)?.format("0,0")}
                              {""} บาท
                            </span>
                          </div>
                        )}
                      </div>
                      <div className={STYLE_FLEX_STATUS}>
                        <ICON_STAR className=" w-6 text-maingreen flex-none " />
                        <div className="  text-sm ">
                          ควรวางแผนลงทุนผลตอบแทน{" "}
                          <span className=" font-semibold">
                            {" "}
                            {Math.abs(
                              retireData?.investment_af_retirement
                            )} %{" "}
                          </span>
                          เป็นระยะเวลา{" "}
                          <span className=" font-semibold">
                            {Math.abs(afterDiffYear)} ปี
                          </span>{" "}
                          เดือนละ{" "}
                          <span className=" font-semibold text-mainblue">
                            {numeral(Math.abs(sumAfterRetireInvest))?.format(
                              "0,0"
                            )}{" "}
                            บาท
                          </span>
                        </div>
                      </div>

                      <div className=" text-center font-semibold text-lg text-maincyan ">
                        เพื่อให้แผนเกษียณเป็นจริง
                        <div className=" flex justify-center w-full">
                          <div>มาปรับกลยุทธ์อีกครั้งกันเถอะ</div>
                          <ImageMain
                            src="../images/goodthumb.png"
                            className=" h-8 w-8 "
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className=" space-y-[10px] mb-3 mt-5">
                  {lastResultAF?.result > 0 ? (
                    <>
                      <div className={STYLE_FLEX_STATUS}>
                        <ICON_CHECK className=" w-6 text-maincyan " />
                        <div className="  text-sm flex-none">
                          คาดการณ์สินทรัพย์เพื่อการลงทุน
                          <div>
                            ณ วันเกษียณอายุ{" "}
                            <span className="font-medium">
                              {retireData?.age_retire} ปี
                            </span>{" "}
                            จำนวน{" "}
                            <span className=" font-medium text-mainblue">
                              {numeral(retireBF)?.format("0,0.00")}
                              {/* {lastResult} บาท */}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={STYLE_FLEX_STATUS}>
                        <ICON_WARNING className=" w-6 text-mainyellow" />
                        <div className="  text-sm flex-none">
                          สามารถใช้จนได้ถึงอายุ{" "}
                          <span className="font-medium">
                            {retireData?.age_dead} ปี{" "}
                          </span>
                          แต่อย่าประมาทนะ
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={STYLE_FLEX_STATUS}>
                        <ICON_WARNING className=" w-6 text-mainyellow" />
                        <div className="  text-sm flex-none">
                          สามารถใช้จนได้ถึงอายุ
                          <span className=" font-medium"> {remainAF} ปี </span>
                        </div>
                      </div>
                      <div className={STYLE_FLEX_STATUS}>
                        <ICON_CLOSE className=" w-6 text-mainred" />
                        <div className="  text-sm flex-none">
                          <div>
                            ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                            <span className=" font-medium">
                              {retireData?.age_dead} ปี
                            </span>{" "}
                          </div>
                          ยังขาดอยู่ทั้งหมด{" "}
                          <span className=" font-medium text-mainblue">
                            {lastResultAF?.result < 0
                              ? numeral(lastResultAF?.result * -1)?.format(
                                  "0,0"
                                )
                              : numeral(lastResultAF?.result)?.format(
                                  "0,0"
                                )}{" "}
                            บาท
                          </span>
                        </div>
                      </div>
                      <div className={STYLE_FLEX_STATUS}>
                        <div className="  text-sm flex-wrap">
                          ควรวางแผนลงทุนผลตอบแทน{" "}
                          {Math.abs(retireData?.investment_af_retirement)} %
                          เป็นระยะเวลา {Math.abs(afterDiffYear)} ปี เดือนละ{" "}
                          {numeral(Math.abs(sumAfterRetireInvest)).format(
                            "0,0"
                          )}{" "}
                          บาท และเพื่อให้แผนเกษียณเป็นจริงมาปรับกลยุทธ์กันเถอะ
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              <MTButton
                width="w-full"
                onClick={() => navigate("/summarytablemovements?sensitive")}
                text="ดูรายละเอียด"
                color="border-maincyan bg-white"
                colortext="text-maincyan group-hover:text-white font-medium"
                hovercolor="hover:bg-maincyan group "
                variant="outlined"
              />
            </div>
          </div>

          {/*---------- อธิบายทดสอบความอ่อนไหว ----------*/}
          <div className={`${STYLE_CARD_BG} mb-[18px]`}>
            <div className=" flex flex-col gap-2 mb-[8px] ">
              <div className={STYLE_HEAD_STRAGADY}>สถานการณ์สมมติ</div>
              <div className={`border-b border-mainyellow w-[295px] `}></div>
            </div>
            <div className=" mt-[26px] mb-6 space-y-2.5 px-2">
              <div className="flex space-x-3">
                <Checkbox
                  size={"sm"}
                  containerProps={{
                    className: "p-1",
                  }}
                  ripple="false"
                  color="cyan"
                  name="check_imagine_one"
                  checked={retireData?.check_imagine_one}
                  onChange={onChangeCheckImagine}
                />
                <div className={STYLE_FLEX_STATUS}>
                  <ImageMain
                    src="/images/assetdown.svg"
                    className="w-6 h-6 flex-none"
                  />
                  <div className="  text-sm flex-none">
                    สถานการณ์ที่ 1
                    <div className="  text-sm ">
                      เศรษฐกิจถดถอย ผลตอบแทนการลงทุน
                      <div>
                        หลังเกษียณ เหลือเพียง{" "}
                        <span className=" font-semibold text-red300">1 %</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex space-x-3">
                <Checkbox
                  size={"sm"}
                  containerProps={{
                    className: "p-1",
                  }}
                  color="cyan"
                  name="check_imagine_two"
                  checked={retireData?.check_imagine_two}
                  onChange={onChangeCheckImagine}
                />
                <div className={STYLE_FLEX_STATUS}>
                  <ImageMain
                    src="/images/heart_medical.svg"
                    className="w-6 h-6 flex-none"
                  />
                  <div className="  text-sm ">
                    สถานการณ์ที่ 2
                    <div className="  text-sm ">
                      เจ็บป่วยโรคร้ายแรงมีค่ารักษาพยาบาล
                      <div>
                        1 ล้านบาท ตอนอายุ{" "}
                        <span className=" font-semibold text-mainorange">
                          {retireData?.age_current + 5}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex space-x-3">
                <Checkbox
                  size={"sm"}
                  containerProps={{
                    className: "p-1",
                  }}
                  color="cyan"
                  name="check_imagine_three"
                  checked={retireData?.check_imagine_three}
                  onChange={onChangeCheckImagine}
                />
                <div className={STYLE_FLEX_STATUS}>
                  <ImageMain
                    src="/images/moneybag.svg"
                    className="w-6 h-6 flex-none"
                  />
                  <div className="  text-sm ">
                    สถานการณ์ที่ 3
                    <div className="  text-sm ">
                      ค้ำประกันให้ผู้อื่น เกิดหนี้ 5 ล้านบาท{" "}
                      <div>
                        ตอนอายุ{" "}
                        <span className=" font-semibold text-maingreen">
                          {retireData?.age_current + 10}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*--------- รอด / ไม่รอด ----------*/}
          {lastResultInvest > 0 ? (
            <div className=" flex flex-col justify-center mt-[18px]">
              <div className=" flex  justify-center items-center gap-[3px]">
                <ImageMain src="../images/taxicon3.png" className="w-8 h-8" />
                <div className=" text-mainblue font-semibold text-xl">
                  ยอดเยี่ยม
                </div>
              </div>
              <div className=" text-mainblue text-center text-sm mt-2">
                ถ้าเกิดสถานการณ์เหล่านี้ขึ้นจริง
                <div> การปรับกลยุทธ์เกษียณของคุณเป็นแผนที่ยอดเยี่ยม</div>
                <div>แต่ก็อย่าประมาทล่ะ</div>
              </div>
            </div>
          ) : (
            <div className=" flex flex-col justify-center mt-[18px]">
              <div className=" flex  justify-center items-center gap-[3px]">
                <ImageMain src="../images/badface_2.png" className="w-8 h-8" />
                <div className=" text-mainblue font-semibold text-xl">
                  ยังไม่ปลอดภัย
                </div>
              </div>
              <div className=" text-mainblue text-center text-sm mt-2">
                ถ้าเกิดสถานการณ์เหล่านี้ขึ้นจริง
                <div>การปรับกลยุทธ์เกษียณของคุณยังไม่ปลอดภัยเท่าที่ควร</div>
              </div>
            </div>
          )}

          <div className=" my-[18px]">
            <MTButton
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
              width="w-full"
              onClick={() => {
                setRetireSensitiveData([]);
                NavigateGoBack(navigate);
              }}
            />
          </div>

          <FooterAfterSum />
        </div>
      </Container>
    </div>
  );
};

export default ViewSensitiveTest;
