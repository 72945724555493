import numeral from "numeral";
import { useMemo } from "react";
import { useTaxStore } from "../../../_store/taxplanStore";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import { cn } from "../../../utils/useFunctions";
import { ICON_BAHT, ICON_GRAPH } from "../../../utils/useIcons";
import { STYLE_CARD_BG } from "../../../utils/useStyle";
import { calculateTax, checkSum } from "../../tax_plan/functions";
import { PdfCard, PdfCardWhite } from "../component/pdf.c.card";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import { PdfHeaderText } from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_BORDER_TABLE,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_11,
} from "../styles/pdf.style";
import {
  TabThreeExpenseCalculation,
  TabThreeSumIncomeCalculation,
} from "../../../functions/taxFucntion/premium/tab3function";
import {
  ExpenstDetail,
  IncomeTab4Calculation,
  sum408RecieveMoney,
  sum408property,
} from "../../../functions/taxFucntion/premium/tab4function";
import { DeductionSumPDF, ExceptionSumPDF } from "../../../functions/pdfGenerateFunction/pdfFunction";

const taxDataList = [
  {
    row: "1",
    tax_progressive_rate: "0 - 150,000",
    income_cal_tax: "",
    tax_rate: "ยกเว้น",
    tax_amount: "",
    max: 150000,
    rate: 0,
  },
  {
    row: "2",
    tax_progressive_rate: "เกิน 150,000 - 300,000",
    income_cal_tax: "",
    tax_rate: "5%",
    tax_amount: "",
    max: 150000,
    rate: 0.05,
  },
  {
    row: "3",
    tax_progressive_rate: "เกิน 300,000 - 500,000",
    income_cal_tax: "",
    tax_rate: "10%",
    tax_amount: "",
    rate: 0.1,
    max: 200000,
  },
  {
    row: "4",
    tax_progressive_rate: "เกิน 500,000 - 750,000",
    income_cal_tax: "",
    tax_rate: "15%",
    tax_amount: "",
    rate: 0.15,
    max: 250000,
  },
  {
    row: "5",
    tax_progressive_rate: "เกิน 750,000 - 1,000,000",
    income_cal_tax: "",
    tax_rate: "20%",
    tax_amount: "",
    rate: 0.2,
    max: 250000,
  },
  {
    row: "6",
    tax_progressive_rate: "เกิน 1,000,000 - 2,000,000",
    income_cal_tax: "",
    tax_rate: "25%",
    tax_amount: "",
    rate: 0.25,
    max: 1000000,
  },
  {
    row: "7",
    tax_progressive_rate: "เกิน 2,000,000 - 5,000,000",
    income_cal_tax: "",
    tax_rate: "30%",
    tax_amount: "",
    rate: 0.3,
    max: 3000000,
  },
  {
    row: "8",
    tax_progressive_rate: "เกิน 5,000,000",
    income_cal_tax: "",
    tax_rate: "35%",
    tax_amount: "",
    rate: 0.35,
    max: Infinity,
  },
];

function IncomeResultPDF({ page, taxData, userData }) {

  const calculateTaxPDF = () => {
    // const netIncome = totalIncome;
    // sumTotal
    const netIncome = sumTotal;

    let newTaxList = [];
    let remain = netIncome;
    for (const thisTax of taxDataList) {
      let thisItem = { ...thisTax };
      if (thisTax.max === Infinity && remain > 0) {
        thisItem.income_cal_tax = remain;
      } else if (remain > thisTax.max) {
        thisItem.income_cal_tax = thisTax.max;
      } else {
        thisItem.income_cal_tax = remain > 0 ? remain : 0;
      }

      if (thisItem.income_cal_tax > 0) {
        thisItem.tax_amount = thisItem.income_cal_tax * thisItem.rate;
      }
      remain -= thisItem.income_cal_tax;
      newTaxList.push(thisItem);
    }
    return newTaxList;
  };

  const sumTotal = useMemo(() => {
    if (userData?.is_premium) {
      return (
        IncomeTab4Calculation(taxData) -
        (ExceptionSumPDF(taxData) || 0) -
        ExpenstDetail(taxData)?.reduce((total,num)=>{ return total+Number(num?.value)},0) -
        (DeductionSumPDF(taxData) || 0)
      );
    }
    return (
      taxData.tax_income -
      (ExceptionSumPDF(taxData) || 0) -
      (taxData?.deduction_expenses || 0) -
      (DeductionSumPDF(taxData) || 0)
    );
  }, [taxData]);

  const displaySumBudget = () => {
    let sum = 0;
    sum =
      Number(taxData?.tax_plan_insurance_life) +
      Number(taxData?.tax_plan_insurance_health) +
      Number(taxData?.tax_plan_insurance_annuity) +
      Number(taxData?.tax_fund_provident) +
      Number(taxData?.tax_fund_nsf) +
      Number(taxData?.tax_plan_rmf) +
      Number(taxData?.tax_plan_ssf) +
      Number(taxData?.tax_plan_political_donate) +
      Number(taxData?.tax_fund_esg);
    return Number(sum);
  };

  const getCaltax = () => {
    let result = 0;
    result =
      taxData?.tax_income -
      taxData?.deduction_expenses -
      taxData?.deduct_summary;

    if (userData?.is_premium) {
      result =
        TabThreeSumIncomeCalculation(taxData) -
        TabThreeExpenseCalculation(taxData) -
        taxData?.deduct_summary +
        sum408property(taxData) +
        sum408RecieveMoney(taxData);
    }

    return calculateTax(result);
  };

  const displaySumAfterTaxPaid = () => {
    let result = 0;
    result =
      Number(taxData?.tax_income) -
      Number(taxData?.deduction_expenses) -
      Number(taxData?.deduct_summary_af);

    if (userData?.is_premium) {
      result =
        TabThreeSumIncomeCalculation(taxData) -
        TabThreeExpenseCalculation(taxData) -
        Number(taxData?.deduct_summary || 0) -
        Number(displaySumBudget() || 0);
    }

    return calculateTax(result);
  };

  const displayTaxPaid = useMemo(
    () => calculateTax(taxData?.tax_income),
    [taxData?.tax_income]
  );

  const displaySumTax = useMemo(
    () => checkSum(getCaltax(), taxData?.after_tax_paid, 0, 0),
    [getCaltax(), taxData?.after_tax_paid, 0, 0]
  );

  const checkPercent = () => {
    let result = 0;
    result = ((getCaltax() - taxData?.after_tax_paid) / getCaltax()) * 100;
    if (isNaN(result)) {
      return 0;
    }
    return Number(result)?.toFixed(2);
  };

  const secondItems = useMemo(() => {
    const newSecondItems = [
      {
        label: "รายได้",
        value: taxData?.tax_income,
      },
      {
        label: "ค่าใช้จ่าย",
        value: taxData?.deduction_expenses,
      },
      {
        label: "ค่าลดหย่อน",
        value: taxData?.deduct_summary_af,
      },
      {
        label: "เงินได้สุทธิ",
        value: taxData?.net_income,
      },
    ];
    return newSecondItems;
  }, [taxData]);

  const headers = [
    {
      label: "คิดภาษีแบบอัตราก้าวหน้า",
      className: "w-[150px]",
    },
    {
      label: "เงินได้ที่ใช้คิดภาษี",
      className: "w-[150px]",
    },
    {
      label: "อัตราภาษี",
      className: "w-[100px]",
    },
    {
      label: "ค่าภาษี",
      className: "w-[100px]",
    },
  ];

  

  const rows = useMemo(() => {
    let newRows = [
      {
        key: "0_to_150000",
        value: 150000,
        tax_progrssive_rate: "0 - 150,000",
        tax_calculate: "150,000",
        tax_rate: "ยกเว้น",
        tax_value: "ยกเว้น",
      },
      {
        key: "150001_to_300000",
        value: 150000,
        tax_progrssive_rate: "150,001 - 300,000",
        tax_calculate: "0",
        tax_rate: 5,
        tax_value: "",
      },
      {
        key: "300000_to_750000",
        value: 250000,
        tax_progrssive_rate: "300,000 - 750,000",
        tax_calculate: "0",
        tax_rate: 10,
        tax_value: "",
      },
      {
        key: "750001_to_1000000",
        value: 250000,
        tax_progrssive_rate: "750,001 - 1,000,000",
        tax_calculate: "0",
        tax_rate: 15,
        tax_value: "",
      },
      {
        key: "1000001_to_2000000",
        value: 1000000,
        tax_progrssive_rate: "1,000,001 - 2,000,000",
        tax_calculate: "0",
        tax_rate: 20,
        tax_value: "",
      },
      {
        key: "2000001_to_5000000",
        value: 3000000,
        tax_progrssive_rate: "2,000,001 - 5,000,000",
        tax_calculate: "0",
        tax_rate: 25,
        tax_value: "",
      },
      {
        key: "5000001",
        value: 0,
        tax_progrssive_rate: "เกิน 5,000,000",
        tax_calculate: "0",
        tax_rate: 35,
        tax_value: "",
      },
    ];

    let prev_total = 0;
    for (const newRow of newRows) {
      if (newRow.key === "0_to_150000") {
        prev_total += newRow.value;
        continue;
      }

      let cal_new_remain = (sumTotal < 0 ? 0 : sumTotal) - prev_total;
      if (cal_new_remain > newRow.value) {
        cal_new_remain = newRow.value;
      }

      const findPrev = newRows.find((row) => row.key === newRow.key);
      if (findPrev) {
        findPrev.tax_calculate = cal_new_remain;
        findPrev.tax_value = cal_new_remain * (newRow.tax_rate / 100);
      }
      prev_total += cal_new_remain;
    }
    return newRows;
  }, [taxData]);



  const totalTax = useMemo(() => {
    let total = rows.reduce((total, row) => {
      if (row.tax_value === "ยกเว้น") {
        return total;
      }
      return total + row.tax_value;
    }, 0);
    return total;
  }, [rows]);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="วางแผนภาษี"
        icon="/images/tax_plan.svg"
        classNameIcon="w-[24px] h-[27px]"
      />
      {/* หลังวางแผน */}
      <PdfCard className={"flex flex-col space-y-3"}>
        {/* FIRST */}
        <PdfCardWhite className={cn("px-3 flex")}>
          <div className=" mt-10 flex flex-col">
            <HalfChartMain
              text={false}
              value={`0`}
              beforevalue={getCaltax()}
              aftervalue={taxData?.after_tax_paid}
              maxvalue={displayTaxPaid}
              chartWidth="w-[170px]"
              fontSize="text-[15px]"
              mtText="-mt-16"
            />
            <div className=" flex justify-center gap-5 w-full -mt-16">
              <div
                className={cn(
                  STYLE_CARD_BG,
                  "py-1 px-2 flex items-center space-x-2 text-[11px] text-mainblue w-[80px] justify-center"
                )}
              >
                <ICON_GRAPH className="w-3 h-3 text-maincyan " />
                <span className=" flex-none"> {checkPercent()} %</span>
              </div>
              <div
                className={cn(
                  STYLE_CARD_BG,
                  "py-1 px-2 flex items-center space-x-2 text-[11px] text-mainblue w-[80px] justify-center"
                )}
              >
                <ICON_BAHT className="w-3 h-3 text-maincyan " />
                {numeral(displaySumTax).format("0,0")}
              </div>
            </div>
          </div>

          <div
            className={cn(
              "flex flex-col w-full mt-4 space-y-1 ml-2",
              STYLE_PDF_TEXT_MEDIUM_12
            )}
          >
            <div className="flex justify-between">
              <div>ก่อนวางแผน</div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_13, "text-[#AA1616]")}>
                {String(getCaltax()) === "Infinity"
                  ? "0"
                  : numeral(getCaltax())?.format("0,0")}{" "}
                บาท
              </div>
            </div>

            <div className="flex justify-between">
              <div>หลังวางแผน</div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_13, "text-[#16AA37]")}>
                {String(displaySumAfterTaxPaid()) === "Infinity"
                  ? "0"
                  : numeral(displaySumAfterTaxPaid())?.format("0,0")}{" "}
                บาท
              </div>
            </div>

            <div className="flex justify-between">
              <div>ภาษีหัก ณ ที่จ่าย</div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_13, "text-[#18488A]")}>
                {numeral(taxData?.tax_withholding)?.format("0,0")} บาท
              </div>
            </div>

            <div className="flex justify-center space-x-1">
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12, "text-[#00A9A0]")}>
                {Number(taxData?.final_tax || 0) +
                  sum408property(taxData) +
                  sum408RecieveMoney(taxData) +
                  Number(taxData?.tax_QuitJob?.moreTax || 0) >
                0
                  ? "ขอคืนได้อีก"
                  : "ภาษีที่ต้องจ่าย"}
              </div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12, "text-[#FF7A00]")}>
                {numeral(
                  Number(taxData?.final_tax || 0) +
                    Number(sum408property(taxData) || 0) +
                    Number(sum408RecieveMoney(taxData) || 0) +
                    Number(taxData?.tax_QuitJob?.moreTax || 0)
                )?.format("0,0")}{" "}
                บาท
              </div>
            </div>
          </div>
        </PdfCardWhite>

        {/* SECOND */}
        <PdfCardWhite className={cn("px-3 flex flex-col")}>
          <div className="flex items-center space-x-1">
            <div>
              <img src="/images/outcome_money.svg" />
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>เงินได้สุทธิ</div>
          </div>

          <div className={cn("flex justify-between mt-1")}>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>รายได้</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {`${
                userData?.is_premium
                  ? numeral(IncomeTab4Calculation(taxData))?.format("0,0")
                  : numeral(taxData?.tax_income)?.format("0,0")
              } บาท`}
            </div>
          </div>
          <div className={cn("flex justify-between mt-1")}>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>ยกเว้น</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {`${numeral(ExceptionSumPDF(taxData))?.format("0,0")} บาท`}
            </div>
          </div>
          <div className={cn("flex justify-between mt-1")}>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>ค่าใช้จ่าย</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {`${
                userData?.is_premium
                  ? numeral(
                      ExpenstDetail(taxData)?.reduce((total, num) => {
                        return total + Number(num.value);
                      }, 0)
                    )?.format("0,0")
                  : numeral(taxData?.deduction_expenses || 0)?.format("0,0")
              } บาท`}
            </div>
          </div>

          <div className={cn("flex justify-between mt-1")}>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>ค่าลดหย่อน</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {`${numeral(DeductionSumPDF(taxData) || 0)?.format("0,0")} บาท`}
            </div>
          </div>

          <div className={cn("flex justify-between mt-1")}>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>เงินได้สุทธิ</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
            {`${userData?.is_premium
                    ? numeral(
                        IncomeTab4Calculation(taxData) -
                          Number(ExceptionSumPDF(taxData) || 0) -
                          Number(taxData?.deduction_expenses || 0) -
                          Number(DeductionSumPDF(taxData) || 0) <
                          0
                          ? 0
                          : IncomeTab4Calculation(taxData) -
                              Number(ExceptionSumPDF(taxData) || 0) -
                              Number(taxData?.deduction_expenses || 0) -
                              Number(DeductionSumPDF(taxData))
                      )?.format("0,0")
                    : numeral(
                        Number(taxData?.tax_income || 0) -
                          Number(ExceptionSumPDF(taxData) || 0) -
                          Number(taxData?.deduction_expenses || 0) -
                          Number(DeductionSumPDF(taxData) || 0) <
                          0
                          ? 0
                          : Number(taxData?.tax_income || 0) -
                              Number(ExceptionSumPDF(taxData) || 0) -
                              Number(taxData?.deduction_expenses || 0) -
                              Number(DeductionSumPDF(taxData))
                      )?.format("0,0")} บาท`}
            </div>
          </div>

          <div className={cn("flex justify-center")}>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
              รายได้ - ยกเว้น - หักค่าใช้จ่าย - หักค่าลดหย่อน =
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_11, "ml-1")}>
              เงินได้สุทธิ
            </div>
          </div>

          <div
            className={cn(
              "flex justify-center",
              STYLE_PDF_TEXT_NORMAL_11,
              "text-maingray"
            )}
          >
            ({numeral(userData?.is_premium? IncomeTab4Calculation(taxData):taxData.tax_income)?.format("0,0")} -{" "}
            {numeral(ExceptionSumPDF(taxData) || 0)?.format("0,0")} -{" "}
            {numeral(userData?.is_premium?ExpenstDetail(taxData)?.reduce((total,num)=>{ return total+Number(num?.value)},0):taxData?.deduction_expenses)?.format("0,0")} -{" "}
            {numeral(DeductionSumPDF(taxData) || 0)?.format("0,0")} ={" "}
            {numeral(sumTotal < 0 ? 0 : sumTotal)?.format("0,0")})
          </div>
        </PdfCardWhite>
      </PdfCard>

      {/* TABLE */}
      <table className="mt-2 border-collapse w-full">
        <thead>
          <tr>
            {headers.map((header, index) => {
              return (
                <th
                  key={index}
                  className={cn(
                    STYLE_PDF_BORDER_TABLE,
                    STYLE_PDF_TEXT_MEDIUM_BLUE_11,
                    header.className
                  )}
                >
                  {header.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-center">
          {calculateTaxPDF().map((row, index) => {
            return (
              <tr key={index}>
                <td
                  className={cn(
                    STYLE_PDF_BORDER_TABLE,
                    STYLE_PDF_TEXT_NORMAL_11,
                    "text-maingray"
                  )}
                >
                  {row?.tax_progressive_rate}
                </td>
                <td
                  className={cn(
                    STYLE_PDF_BORDER_TABLE,
                    STYLE_PDF_TEXT_NORMAL_11,
                    "text-maingray"
                  )}
                >
                  {numeral(row?.income_cal_tax).format("0,0")}
                </td>
                <td
                  className={cn(
                    STYLE_PDF_BORDER_TABLE,
                    STYLE_PDF_TEXT_NORMAL_11,
                    "text-mainblue"
                  )}
                >
                  {row?.tax_rate}
                </td>
                <td
                  className={cn(
                    STYLE_PDF_BORDER_TABLE,
                    STYLE_PDF_TEXT_NORMAL_11,
                    "text-maingray"
                  )}
                >
                  {numeral(row?.tax_amount).format("0,0")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className={cn("flex justify-center items-center space-x-1 mt-2")}>
        <div
          className={cn("flex justify-center", STYLE_PDF_TEXT_MEDIUM_BLUE_12)}
        >
          รวมภาษีที่ต้องชำระ
        </div>
        <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
          {numeral(totalTax).format("0,0")} บาท
        </div>
      </div>
      <PdfFooter page={page} />
    </div>
  );
}

export default IncomeResultPDF;
