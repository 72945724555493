import { useLocation, useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../../components/header/Header";
import Container from "../../../../components/layout/Container";
import {
  STYLE_LABEL_RADIO_TAX_PROFILE,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_BUTTON_TWO,
  STYLE_PARENT_PADDING,
  STYLE_SPACE_INPUT,
  STYLE_SPACE_TAX_PROFILE,
  STYLE_TEXT_BLUE,
} from "../../../../utils/useStyle";
import NavigateGoBack, {
  NavigatePath,
} from "../../../../functions/Navigate.Goback";
import {
  InputMain,
  InputWithLabel,
} from "../../../../components/input/InputMain";
import { useUserStore } from "../../../../_store/userStore";
import { dateFormatTH } from "../../../../utils/useFunctions";
import { ICON_CALENDAR } from "../../../../utils/useIcons";
import { CheckBoxSecond } from "../../../../components/checkbox/CheckboxSecond";
import DropDownMain from "../../../../components/dropdown/DropDownMain";
import { MTButton } from "../../../../components/button/MTButton";
import { useEffect, useState } from "react";
import { useTaxStore } from "../../../../_store/taxplanStore";
import { useStrategyStore } from "../../../../_store/strategyRetireStore";
import { useRetireStore } from "../../../../_store/retireStore";
import { useSavingStore } from "../../../../_store/savingStore";
import { useBalanceStore } from "../../../../_store/balanceStore";
import { useRiskStore } from "../../../../_store/riskStore";
import { useEducationStore } from "../../../../_store/educationStore";
import { useInsuranceStore } from "../../../../_store/insuranceStore";
import { useFamilyStore } from "../../../../_store/familyStore";
import { useOpenStore } from "../../../../_store/openStore";
import { useFamilytreeStore } from "../../../../_store/familytreeStore";
import { useDebtStore } from "../../../../_store/debtplanStore";
import { useInvestStore } from "../../../../_store/investStore";
import { useFuturePlanStore } from "../../../../_store/futurePlanStore";
import api from "../../../../api/api.main";

const ViInfoTaxProfile = () => {
  const navigate = useNavigate();
  const userData = useUserStore((state) => state.userData);
  const setUserData = useUserStore((state) => state.setUserData);
  const taxData = useTaxStore((state) => state.taxData);
  const setTaxData = useTaxStore((state) => state.setTaxData);

  const strategy = useStrategyStore((state) => state.strategy);
  const retireData = useRetireStore((state) => state.retireData);
  const savingData = useSavingStore((state) => state.savingData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const riskData = useRiskStore((state) => state.riskData);
  const educationData = useEducationStore((state) => state.educationData);
  const insuranceData = useInsuranceStore((state) => state.insuranceData);
  const familyData = useFamilyStore((state) => state);
  const openData = useOpenStore((state) => state);
  const familytreeData = useFamilytreeStore((state) => state.familytreeData);
  const debtData = useDebtStore((state) => state.debtData);
  const investData = useInvestStore((state) => state.investData);
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const pathname = useLocation().pathname;

  const [formProfile, setFormProfile] = useState({
    ...userData,
  });

  useEffect( ()=>{

    loadData()
    
  },[])

  const loadData = async () => {
    try {
      const { data: dataStorage } = await api.get(
        `/storages/profile/${userData._id}`
      );

      setFormProfile({...dataStorage?.storage?.userData,...userData})

    } catch (error) {
      console.log("error: ",error)
    }
  }

  // console.log("formProfile",formProfile)

  const updateStorage = async () => {
    try {
      if (!userData || ["/report", "/pdf"].includes(pathname)) return;

      const { data: dataStorage } = await api.get(
        `/storages/profile/${userData._id}`
      );


      if (!dataStorage?.storage) {
        await api.post(`/storages`, {
          profileId: userData._id,
          strategy,
          retireData,
          taxData,
          savingData,
          balanceData,
          riskData,
          educationData,
          insuranceData,
          familyData,
          openData,
          familytreeData,
          debtData,
          futurePlanData,
          investData,
          userData
        });
      } else {
        await api.put(`/storages/${dataStorage.storage._id}`, {
          profileId: userData._id,
          strategy,
          retireData,
          taxData,
          savingData,
          balanceData,
          userData
        });
        await api.put(`/storages/${dataStorage.storage._id}`, {
          profileId: userData._id,
          riskData,
          educationData: educationData?.map((item) => {
            return {
              ...item,
              img: "",
            };
          }),
          insuranceData,
          familyData,
          openData,
          familytreeData,
          debtData,
          futurePlanData,
          investData,
          userData
        });
      }
    } catch (error) {
      console.log("update storage error", error);
    }
  };

  // ร้านค้า / กิจการส่วนตัว (ถ้ามี)
  const onChangeYourBusiness = (e) => {
    let value = e.target.value;
    return setFormProfile({ ...formProfile, your_business: value });
  };

  // ที่อยู่เว็บไซด์
  const onChangeYourWebsite = (e) => {
    let value = e.target.value;
    return setFormProfile({ ...formProfile, your_website: value });
  };

  // ใช้สิทธิยกเว้นคนพิการ
  const [claimSisabledPerson, setClaimDisabledPerson] = useState(false);
  const onChangDisabledPersonClaim = (e) => {
    let value = e.target.value;
    setClaimDisabledPerson(!claimSisabledPerson);
    return setFormProfile({
      ...formProfile,
      claim_disabled_person: !claimSisabledPerson,
    });
  };

  // มีที่อยู่ในเขตพัฒนาพิเศษเฉพาะกิจ
  const [claimSpecialZone, setClaimSpecialZone] = useState(false);
  const onChangeClaimSpecialZone = (e) => {
    let value = e.target.value;
    setClaimSpecialZone(!claimSpecialZone);
    return setFormProfile({
      ...formProfile,
      claim_special_zone: !claimSpecialZone,
    });
  };

  // สถานภาพ
  const onChangePersonStatus = (e) => {
    let value = e.target.value;
    if (value === "one") {
      setFormProfile({
        ...formProfile,
        person_status: { value: value, label: "บุคคลธรรมดา" },
      });
    }
    if (value === "two") {
      setFormProfile({
        ...formProfile,
        person_status: { value: value, label: "ผู้ถึงแก่ความตายระหว่างปีภาษี" },
      });
    }
    if (value === "three") {
      setFormProfile({
        ...formProfile,
        person_status: { value: value, label: "กองมรดกที่ยังมิได้แบ่ง" },
      });
    }
    if (value === "four") {
      setFormProfile({
        ...formProfile,
        person_status: {
          value: value,
          label: "ห้างหุ้นสามัญที่มิใช่นิติบุคคล",
        },
      });
    }
    if (value === "five") {
      setFormProfile({
        ...formProfile,
        person_status: {
          value: value,
          label: "คณะบุคคลที่มิใช้นิติบุคคล",
        },
      });
    }
    if (value === "six") {
      setFormProfile({
        ...formProfile,
        person_status: {
          value: value,
          label: "วิสาหกิจชุุมชนตาม พ.ร.บ. ส่งเสริม วิสาหกิจชุมชน พ.ศ.2548",
        },
      });
    }
  };

  // สถานภาพการยื่นแบบ
  const onChangeSubmissionTaxStatus = (e) => {
    let value = e.target.value;
    if (value === "type_40(1)_(8)") {
      setFormProfile({
        ...formProfile,
        submission_tax_status: {
          value: value,
          label: "มีรายได้มาตรา 40(1)-(8)",
        },
      });
    }
    if (value === "type_40(1)_(2)_single") {
      setFormProfile({
        ...formProfile,
        submission_tax_status: {
          value: value,
          label: "มีเงินได้มาตรา 40(1),(2) ประเภทเดียว และแยกยื่นแบบฯ",
        },
      });
    }
    if (value === "type_none_income") {
      setFormProfile({
        ...formProfile,
        submission_tax_status: {
          value: value,
          label: "ไม่มีเงินได้",
        },
      });
    }
  };

  useEffect(()=>{
    setUserData({ ...formProfile });
  },[formProfile])

  //---------- Submit ---------//
  const onClickSubmit = async () => {
    try {
      await updateStorage()
      NavigateGoBack(navigate);
    } catch (error) {
      console.log("Tax player profile error : ", error);
    
    }
    
  };

  return (
    <Container>
      <div className={STYLE_MARGIN_TOP_PARENT}>
        <HeaderTitle
          title="ข้อมูลส่วนตัวผู้เสียภาษี"
          isGoback
          onClickGoBack={() => {
            NavigateGoBack(navigate);
          }}
        />
        <div className={STYLE_PARENT_PADDING}>
          {/* ----------- เลขประจำตัวผู้เสียภาษี ----------- */}
          <div className={STYLE_SPACE_INPUT}>
            <div className={STYLE_TEXT_BLUE}>เลขประจำตัวผู้เสียภาษี</div>
            <div className="px-[14px] w-full border border-gray-200 rounded-full h-10 flex items-center bg-gray30 text-mainblue ">
              {formProfile?.tax_id}
            </div>
          </div>

          {/* ----------- ชื่อ - นามสกุล ----------- */}
          <div className={STYLE_SPACE_INPUT}>
            <div className={STYLE_TEXT_BLUE}>ชื่อ - นามสกุล</div>
            <div className="px-[14px] w-full border border-gray-200 rounded-full h-10 flex items-center bg-gray30 text-mainblue ">
              {formProfile?.firstname} {formProfile?.lastname}
            </div>
          </div>

          {/* ---------- ร้านค้า / กิจการส่วนตัว (ถ้ามี) ---------- */}
          <InputWithLabel
            className={`focus:outline-none  focus:border-maincyan `}
            label={`ร้านค้า / กิจการส่วนตัว (ถ้ามี)`}
            placeholder="ระบุ"
            value={formProfile?.your_business}
            onChange={onChangeYourBusiness}
          />

          {/* ---------- ที่อยู่เว็บไซด์ ---------- */}
          <InputWithLabel
            className={`focus:outline-none  focus:border-maincyan `}
            label={`ที่อยู่เว็บไซด์`}
            placeholder="ระบุ"
            value={formProfile?.your_website}
            onChange={onChangeYourWebsite}
          />

          <div>
            <div className={STYLE_TEXT_BLUE}>วันเกิด</div>
            <div
              className={`px-[14px] w-full border border-gray-200 rounded-full h-10 flex items-center bg-gray30 text-black`}
            >
              {dateFormatTH(formProfile?.birthday)}
              <ICON_CALENDAR className={` w-6 h-6 text-maingray ml-auto`} />
            </div>
          </div>

          {/* ----------- ใช้สิทธิยกเว้นคนพิการ ----------- */}
          <div className={STYLE_SPACE_TAX_PROFILE}>
            <CheckBoxSecond
              isLeft={true}
              name={"claim_disabled_person"}
              label_title={
                <div className="text-base ml-1 mt-1">
                  ใช้สิทธิยกเว้นคนพิการ
                </div>
              }
              value={"claim_disabled_person"}
              onClick={onChangDisabledPersonClaim}
              checked={formProfile?.claim_disabled_person}
            />
          </div>

          {/* ----------- มีที่อยู่ในเขตพัฒนาพิเศษเฉพาะกิจ ----------- */}
          <div className={STYLE_SPACE_TAX_PROFILE}>
            <CheckBoxSecond
              isLeft={true}
              name={"claim_special_zone"}
              label_title={
                <div className="text-base ml-1 mt-1">
                  มีที่อยู่ในเขตพัฒนาพิเศษเฉพาะกิจ
                </div>
              }
              value={"claim_special_zone"}
              onClick={onChangeClaimSpecialZone}
              checked={formProfile?.claim_special_zone}
            />
          </div>

          {/* -----------   สถานะ   ----------- */}
          <div className={STYLE_SPACE_INPUT}>
            <div className={STYLE_TEXT_BLUE}>สถานะ</div>
            <div className="px-[14px] w-full border border-gray-200 rounded-full h-10 flex items-center bg-gray30  ">
              {formProfile?.life_status}
            </div>
          </div>

          {/* -----------   สถานภาพ   ----------- */}
          <div>
            <div className={STYLE_TEXT_BLUE}>สถานภาพ</div>
            <div
              onChange={onChangePersonStatus}
              className="flex flex-col gap-1"
            >
              <CheckBoxSecond
                isLeft={true}
                name={"person_status"}
                label_title={
                  <div className={STYLE_LABEL_RADIO_TAX_PROFILE}>
                    1.บุคคลธรรมดา
                  </div>
                }
                value={"one"}
                checked={
                  formProfile?.person_status?.value === "one" ? true : false
                }
              />
              <CheckBoxSecond
                isLeft={true}
                name={"person_status"}
                label_title={
                  <div className={STYLE_LABEL_RADIO_TAX_PROFILE}>
                    2.ผู้ถึงแก่ความตายระหว่างปีภาษี
                  </div>
                }
                value={"two"}
                checked={
                  formProfile?.person_status?.value === "two" ? true : false
                }
              />
              <CheckBoxSecond
                isLeft={true}
                name={"person_status"}
                label_title={
                  <div className={STYLE_LABEL_RADIO_TAX_PROFILE}>
                    3.กองมรดกที่ยังมิได้แบ่ง
                  </div>
                }
                value={"three"}
                checked={
                  formProfile?.person_status?.value === "three" ? true : false
                }
              />
              <CheckBoxSecond
                isLeft={true}
                name={"person_status"}
                label_title={
                  <div className={STYLE_LABEL_RADIO_TAX_PROFILE}>
                    4.ห้างหุ้นสามัญที่มิใช่นิติบุคคล
                  </div>
                }
                value={"four"}
                checked={
                  formProfile?.person_status?.value === "four" ? true : false
                }
              />
              <CheckBoxSecond
                isLeft={true}
                name={"person_status"}
                label_title={
                  <div className={STYLE_LABEL_RADIO_TAX_PROFILE}>
                    5.คณะบุคคลที่มิใช้นิติบุคคล
                  </div>
                }
                value={"five"}
                checked={
                  formProfile?.person_status?.value === "five" ? true : false
                }
              />
              <CheckBoxSecond
                isLeft={true}
                name={"person_status"}
                label_title={
                  <div className={`${STYLE_LABEL_RADIO_TAX_PROFILE} mt-5`}>
                    6.วิสาหกิจชุุมชนตาม พ.ร.บ. ส่งเสริม วิสาหกิจชุมชน พ.ศ.2548
                  </div>
                }
                value={"six"}
                checked={
                  formProfile?.person_status?.value === "six" ? true : false
                }
              />
            </div>
          </div>

          {/* -----------   สถานภาพการยื่นแบบ   ----------- */}
          <div className=" pt-5 space-y-[1px]">
            <div className={STYLE_TEXT_BLUE}>สถานภาพการยื่นแบบ</div>
            <div
              onChange={onChangeSubmissionTaxStatus}
              className="flex flex-col gap-1"
            >
              <CheckBoxSecond
                isLeft={true}
                name={"สถานภาพ"}
                label_title={
                  <div className={STYLE_LABEL_RADIO_TAX_PROFILE}>
                    1.มีรายได้มาตรา 40(1)-(8)
                  </div>
                }
                value={"type_40(1)_(8)"}
                checked={
                  formProfile?.submission_tax_status?.value === "type_40(1)_(8)"
                    ? true
                    : false
                }
              />
              <CheckBoxSecond
                isLeft={true}
                name={"สถานภาพ"}
                label_title={
                  <div className={`${STYLE_LABEL_RADIO_TAX_PROFILE} mt-2`}>
                    2.มีเงินได้มาตรา 40(1),(2) ประเภทเดียว และแยกยื่นแบบฯ
                  </div>
                }
                value={"type_40(1)_(2)_single"}
                checked={
                  formProfile?.submission_tax_status?.value ===
                  "type_40(1)_(2)_single"
                    ? true
                    : false
                }
              />
              <CheckBoxSecond
                isLeft={true}
                name={"สถานภาพ"}
                label_title={
                  <div className={STYLE_LABEL_RADIO_TAX_PROFILE}>
                    3.ไม่มีเงินได้
                  </div>
                }
                value={"type_none_income"}
                checked={
                  formProfile?.submission_tax_status?.value ===
                  "type_none_income"
                    ? true
                    : false
                }
              />
            </div>
          </div>

          <div className={STYLE_PARENT_BUTTON_TWO}>
            <MTButton
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
              width="w-full"
              onClick={() => {
                NavigateGoBack(navigate);
              }}
            />
            <MTButton
              text={"บันทึก"}
              color="bg-maincyan"
              width="w-full"
              onClick={onClickSubmit}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ViInfoTaxProfile;
