import { Drawer, Step, Stepper } from "@material-tailwind/react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MTButton } from "../../components/button/MTButton";
import Container from "../../components/layout/Container";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_TAB,
  STYLE_TAB_PANEL_2,
} from "../../utils/useStyle";
import {
  Child,
  Disabilities,
  Disable,
  DrawerMain,
  Education,
  Father,
  FundCompensationMoney,
  FundESG,
  FundGorBorKhor,
  FundNSF,
  FundPrivateSchoolTeacher,
  FundProvident,
  FundRMF,
  FundSSF,
  FundSocial,
  HomeLoan,
  InsuaranceHealth,
  InsuranceAnnuity,
  InsuranceLife,
  Mother,
  OtherDonate,
  Parents,
  PayerIncome,
  Political,
  Prenatal,
  Special,
  Spouse,
  generateSummary,
} from "./components/VIDeductionDrawerMain";
import ViewStepOneTaxPlan from "./components/VIStep1Tax_Tab";
import ViewStepTwoTaxPlan from "./components/VIStep2Tax_Tab";
import ViewStepThreeTaxPlan from "./components/VIStep3Tax_Tab";

import { useBalanceStore } from "../../_store/balanceStore";
import { useFamilyStore } from "../../_store/familyStore";
import { useTaxStore } from "../../_store/taxplanStore";
import { useUserStore } from "../../_store/userStore";
import { PopupMain } from "../../components/popup/PopupMain";
import { NavigatePath } from "../../functions/Navigate.Goback";
import ViewStepFourTaxPlan from "./components/VIStep4Tax_Tab";
import {
  ViewDetailsTaxDeduct,
  ViewDetailsTaxDeductFirst,
  ViewDetailsTaxExemption,
  ViewDetailsTaxExpense,
  ViewDetailsTaxIncome,
  ViewDetailsTaxNetIcome,
} from "./components/ViDetailsTax.Main";
import { formatDeduction } from "./functions";
import { C } from "react-flaticons";
import numeral from "numeral";

const { HeaderTitle } = require("../../components/header/Header");
const TaxPlanContext = createContext();
export const useTaxPlanContext = () => useContext(TaxPlanContext);

const checkError = (form) => {
  const tax_income =
    form?.tax_income === "" ||
    form?.tax_income === 0 ||
    form?.tax_income === null;

  const tax_plan_insurance_life = form?.tax_plan_insurance_life > 100000;

  const tax_plan_insurance_health =
    Number(form?.tax_plan_insurance_health) + Number(form?.insurance_health) >
    25000;

  const sum_all_insurance =
    Number(form?.tax_plan_insurance_health) +
      Number(form?.insurance_health) +
      Number(form?.insurance_life) +
      Number(form?.tax_plan_insurance_life) >
    100000;

  const tax_plan_insurance_annuity =
    form?.tax_plan_insurance_annuity + Number(form?.insurance_annuity) > 200000;

  const tax_fund_provident =
    form?.tax_fund_provident + Number(form?.fund_provident) >
    form?.tax_income * 0.15;

  const tax_fund_nsf = form?.tax_fund_nsf + Number(form?.fund_nsf) > 13200;

  const tax_fund_esg = form?.tax_fund_esg + Number(form?.fund_esg) > 100000;

  const tax_plan_rmf =
    form?.tax_plan_rmf + Number(form?.fund_provident_rmf_value) > 200000;

  const tax_plan_ssf =
    form?.tax_plan_ssf + Number(form?.fund_provident_ssf_value) > 200000;

  const tax_plan_political_donate =
    form?.tax_plan_political_donate + Number(form?.political_donate) > 10000;

  return {
    tax_income,
    // tax_plan_insurance_life,
    // tax_plan_insurance_health,
    // sum_all_insurance,
    // tax_plan_insurance_annuity,
    // tax_fund_provident,
    // tax_fund_nsf,
    // tax_fund_esg,
    // tax_plan_rmf,
    // tax_plan_ssf,
    // tax_plan_political_donate,
  };
};

const TaxPlanMain = (index) => {
  // Tax Data Store
  const taxData = useTaxStore((state) => state.taxData);
  const setTaxData = useTaxStore((state) => state.setTaxData);
  const clearTaxData = useTaxStore((state) => state.clearTaxData);
  const userData = useUserStore((state) => state.userData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  const [form, setForm] = useState(() => {
    //-- ตัวแปลที่ต้องหาจาก premium --//
    return {
      tax_income: "",
      tax_paid: "",
      after_tax_paid: 0,

      //ภาษีหัก ณ ที่จ่าย
      tax_withholding: 0,
      //ยอดรวมภาษีสุดท้าย (ภาษีที่ต้องจ่าย ลบด้วย ภาษีหัก ณ ที่จ่าย)
      final_tax: 0,
      net_income: 0,

      deduction_expenses: 100000,
      deduct_spouse: "",
      deduct_spouse_prev: "",
      deduct_child: "",
      deduct_child_prev: "",
      deduct_mother: "",
      deduct_father: "",
      deduct_disable_people: "",
      deduct_disabilities_people: "",
      deduct_insurance_premiums_parents: "",
      deduct_insurance_premiums_parents_prev: "",
      deduct_summary: "",
      deduct_summary_af: "",
      insurance_life: "",
      insurance_life_prev: "",
      insurance_health: "",
      insurance_health_prev: "",
      insurance_life_health: "",
      insurance_annuity: "",
      insurance_annuity_prev: "",
      fund_provident: "",
      fund_provident_prev: "",
      fund_nsf: "",
      fund_nsf_prev: "",
      fund_provident_rmf_value: "",
      fund_provident_rmf_value_prev: "",
      fund_provident_ssf_value: "",
      fund_provident_ssf_value_prev: "",
      fund_GorBorKhor_value: "",
      fund_GorBorKhor_value_prev: "",
      fund_PrivateSchoolTeacher_value: "",
      fund_PrivateSchoolTeacher_value_prev: "",
      fund_esg: "",
      fund_esg_prev: "",
      home_loan: "",
      home_loan_prev: "",
      fund_social_security: "",
      fund_social_security_prev: "",
      prenatal_care_cost: "",
      prenatal_care_cost_prev: "",
      political_donate: "",
      political_donate_prev: "",

      special_discount_gov: "",
      special_discount_gov_prev: "",
      special_discount_gov_name: "",
      sum_deduction: "",
      tax_plan_insurance_life: "",
      tax_plan_insurance_health: "",
      tax_plan_rmf: "",
      tax_plan_donate: "",
      tax_plan_insurance_annuity: "",
      tax_plan_ssf: "",
      tax_plan_political_donate: "",
      tax_fund_provident: "",
      tax_fund_nsf: "",
      tax_fund_esg: "",
      tax_fund_GorBorKhor: "",
      tax_fund_PrivateSchoolTeacher: "",

      // ส่วนลดภาษีเงินบริจาคการศึกษา
      education_donate: "",
      education_donate_prev: "",
      raw_education_donate: "",
      deduct_summary_except_education_donate: "",

      // ส่วนลดภาษีเงินบริจาคอื่น ๆ
      other_donate: "",
      other_donate_prev: "",
      deduct_summary_except_other_donate: "",
    };
  });

  const [formAfter, setFormAfter] = useState({});

  // const [ishasIncome, setIshasIncome] = useState(true);
  const ishasIncome = useFamilyStore((state) => state.ishasIncome);
  const setIshasIncome = useFamilyStore((state) => state.setIshasIncome);
  // const [isMarriage, setIsMarriage] = useState(true);
  const isMarriage = useFamilyStore((state) => state.isMarriage);
  const setIsMarriage = useFamilyStore((state) => state.setIsMarriage);
  // const [isSpouseDisable, setIsSpouseDisable] = useState(true);
  const isSpouseDisable = useFamilyStore((state) => state.isSpouseDisable);
  const setIsSpouseDisable = useFamilyStore(
    (state) => state.setIsSpouseDisable
  );

  // const [ishasChild, setIshasChild] = useState(false);
  const ishasChild = useFamilyStore((state) => state.ishasChild);
  const setIshasChild = useFamilyStore((state) => state.setIshasChild);
  // const [chlidList, setChildList] = useState([]);
  const chlidList = useFamilyStore((state) => state.chlidList);
  const setChildList = useFamilyStore((state) => state.setChildList);

  const [ishasIncomeMother, setIshasIncomeMother] = useState(true);
  const [ishasIncomeFather, setIshasIncomeFather] = useState(true);

  const [ishasIncomeDisable, setIshasIncomeDisable] = useState(true);
  const [ishasIncomeDisabilities, setIshasIncomeDisabilities] = useState(true);

  const [error, setError] = useState({
    tax_income: false,
  });
  const [openDrawer, setOpenDrawer] = useState();
  const [openInfo, setOpenInfo] = useState("");
  const [openIncomeInfo, setOpenIncomeInfo] = useState(false);
  const [openReduceInfo, setOpenReduceInfo] = useState(false);
  const [openDeductInfo, setOpenDeductInfo] = useState(false);
  const [openTaxPlanInfo, setOpenTaxPlanInfo] = useState(false);

  //เปิดpopup error หลังจากคำนวณภาษี
  const [openTaxAfterError, setOpenTaxAfterError] = useState(false);
  const [openTaxAfterError2, setOpenTaxAfterError2] = useState(false);
  const [openTaxAfterError3, setOpenTaxAfterError3] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);

  const [deduction, setDeduction] = useState([
    {
      title: "ผู้มีเงินได้",
      amount: 60000,
      amount_prev: 0,
    },
  ]);

  const taxplanContextValue = {
    deduction,
    setDeduction,
  };

  const checkSum5values = (form) => {
    let sum = 0;
    sum =
      Number(form?.tax_plan_insurance_annuity) +
      Number(form?.insurance_annuity) +
      Number(form?.tax_fund_provident) +
      Number(form?.fund_provident) +
      Number(form?.tax_fund_nsf) +
      Number(form?.fund_nsf) +
      Number(form?.tax_plan_rmf) +
      Number(form?.fund_provident_rmf_value) +
      Number(form?.tax_plan_ssf) +
      Number(form?.fund_provident_ssf_value) +
      Number(form?.fund_GorBorKhor_value) +
      Number(form?.fund_PrivateSchoolTeacher_value);
    return Number(sum);
  };

  const checkSumTab3 = (form) => {
    let sum = 0;
    sum =
      Number(form?.tax_plan_insurance_annuity || 0) +
      Number(form?.tab3_annuity || 0) +
      Number(form?.tax_fund_provident || 0) +
      Number(form?.fund_provident || 0) +
      Number(form?.tax_fund_nsf || 0) +
      Number(form?.fund_nsf || 0) +
      Number(form?.tax_plan_rmf || 0) +
      Number(form?.fund_provident_rmf_value || 0) +
      Number(form?.tax_plan_ssf || 0) +
      Number(form?.fund_provident_ssf_value || 0) +
      Number(form?.fund_GorBorKhor_value || 0) +
      Number(form?.fund_PrivateSchoolTeacher_value || 0);

    let usage = 0;
    usage =
      Number(form?.tax_plan_ssf || 0) +
      Number(form?.tax_plan_rmf || 0) +
      Number(form?.tax_plan_insurance_annuity || 0);

    let InsuranceAnnuity =
      Number(form?.insurance_annuity || 0) <= 100000
        ? 0
        : Number(form?.insurance_annuity || 0) - 100000;

    let remainMoney =
      500000 -
      (Number(InsuranceAnnuity || 0) +
        // Number(form?.insurance_annuity || 0)+
        Number(form?.fund_provident || 0) +
        Number(form?.fund_nsf || 0) +
        Number(form?.fund_provident_rmf_value || 0) +
        Number(form?.fund_provident_ssf_value || 0) +
        Number(form?.fund_GorBorKhor_value || 0) +
        Number(form?.fund_PrivateSchoolTeacher_value || 0));

    return { sum: Number(sum), usage, remainMoney };
  };

  const getBeforeDeduct = useMemo(() => {
    let sum = 0;
    deduction?.map((row) => {
      sum += Number(row?.amount);
    });
    return sum;
  });

  const displaySumDeduction = () => {
    let sum = 0;
    sum =
      Number(getBeforeDeduct) +
      Number(form?.tax_plan_insurance_life) +
      Number(form?.tax_plan_insurance_health) +
      Number(form?.tax_plan_insurance_annuity) +
      Number(form?.tax_fund_provident) +
      Number(form?.tax_fund_nsf) +
      Number(form?.tax_plan_rmf) +
      Number(form?.tax_plan_ssf) +
      Number(form?.tax_plan_political_donate) +
      Number(form?.tax_fund_esg) +
      Number(form?.tax_fund_GorBorKhor) +
      Number(form?.tax_fund_PrivateSchoolTeacher);

    return Number(sum);
  };

  //======================================== useEffect Set Form ======================================== //
  useEffect(() => {
    let newForm = { ...form, ...taxData };

    if (userData) {
      const { result, sumResult, sumResultExceptEducationDonate } =
        generateSummary(newForm);
      newForm = {
        ...newForm,
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      };
      setDeduction(result);
    }

    setForm((prev) => {
      return {
        ...prev,
        ...newForm,
      };
    });
  }, [taxData, userData]);

  // useEffect(() => {

  //   setTaxData((prev) => {
  //     return {
  //       ...prev,
  //       ...form,
  //     };
  //   });
  // }, [form]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && chlidList?.length > 0) {
      let result = chlidList.reduce((sum, row) => {
        return row?.amount + sum;
      });
      setDeduction((prev) => {
        return [
          ...prev,
          {
            title: "ลูก",
            amount: result,
          },
        ];
      });
    }
    return () => {
      ignore = true;
    };
  }, [chlidList]);

  // console.log("checkSumTab3(form).remainMoney",checkSumTab3(form))
  // console.log("taxData", taxData);
  // console.log("form", form);
  // console.log("=====================================");

  return (
    <TaxPlanContext.Provider value={taxplanContextValue}>
      <Container>
        <PopupMain
          open={openTaxAfterError}
          onClick={() => {
            setOpenTaxAfterError(false);
          }}
          text={
            <div className=" font-medium">
              "กรุณากรอกจำนวนเงินให้พอดีกับที่ซื้อเพิ่มได้อีก"
            </div>
          }
        />

        <PopupMain
          open={openTaxAfterError2}
          onClick={() => {
            setOpenTaxAfterError2(false);
          }}
          text={
            <div className=" font-medium">
              "กองทุนสำรองเลี้ยงชีพ, เงินสะสมกองทุนออมแห่งชาติ,
              ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF),
              กองทุนรวมเพื่อการออม(SSF), กองทุนสงเคราะห์ครูโรงเรียนเอกชน,
              กองทุนบำเหน็จบำนาญข้าราชการ เมื่อรวมกันแล้วต้องไม่เกิน 5000,000
              บาท"
            </div>
          }
        />
        {openTaxAfterError3 && (
          <PopupMain
            open={openTaxAfterError3}
            onClick={() => {
              setOpenTaxAfterError3(false);
            }}
            text={
              <div className=" font-medium">
                กรุณาพิจารณาเบี้ยประกันชีวิตแบบบำนาญ SSF และ RMF ใหม่
                ให้ไม่เกินสิทธิคงเหลือของค่าลดหย่อนที่ใช้สิทธิรวมกันในขั้นที่ 2
                คือรวมกันห้ามเกิน{" "}
                {`${numeral(checkSumTab3(form).remainMoney)?.format("0,0")}`}{" "}
                บาท "
              </div>
            }
          />
        )}
        {tab === "income" ? (
          <ViewDetailsTaxIncome setTab={setTab} taxData={taxData} />
        ) : tab === "expense" ? (
          <ViewDetailsTaxExpense setTab={setTab} taxData={taxData} />
        ) : tab === "deduct" ? (
          <ViewDetailsTaxDeduct setTab={setTab} form={form} />
        ) : tab === "netincome" ? (
          <ViewDetailsTaxNetIcome setTab={setTab} form={form} userData={userData} />
        ) : tab === "exception" ? (
          <ViewDetailsTaxExemption setTab={setTab} form={form} />
        ) : tab === "firstDeduction" ? (
          <ViewDetailsTaxDeductFirst
            form={form}
            setTab={setTab}
            section={"second"}
          />
        ) : (
          <div className={`${STYLE_MARGIN_TOP_PARENT} `}>
            <HeaderTitle
              title="วางแผนภาษี"
              isGoback
              onClickGoBack={() => {
                NavigatePath(navigate, "/");
              }}
            />

            {/* ------ หัวข้อ Step ------ */}
            <div className=" px-8">
              <Stepper
                activeStep={tab}
                lineClassName="bg-gray-300"
                activeLineClassName="bg-maincyan"
                className={`${STYLE_TAB} `}
                isLastStep={(value) => setIsLastStep(value)}
                isFirstStep={(value) => setIsFirstStep(value)}
              >
                <Step
                  className=" !bg-maincyan text-lg"
                  activeClassName="!bg-maincyan "
                  onClick={() => setTab(0)}
                >
                  <div className=" mt-0.5">1</div>
                </Step>
                <Step
                  onClick={() => setTab(1)}
                  className={`${tab === 0 ? "!bg-gray300" : "!bg-maincyan"}`}
                  activeClassName="!bg-maincyan"
                >
                  <div className=" mt-0.5">2</div>
                </Step>

                <Step
                  onClick={() => setTab(2)}
                  className={`${
                    tab === 1 || tab === 0 ? "!bg-gray300" : "!bg-maincyan"
                  }`}
                  activeClassName="!bg-maincyan"
                >
                  <div className=" mt-0.5">3</div>
                </Step>

                <Step
                  className="!bg-gray-300"
                  activeClassName="!bg-maincyan"
                  onClick={() => setTab(3)}
                >
                  <div className=" mt-0.5">4</div>
                </Step>
              </Stepper>
            </div>

            {/*-------------------- Step 1 --------------------*/}
            {tab === 0 && (
              <div className={STYLE_TAB_PANEL_2}>
                <ViewStepOneTaxPlan
                  openIncomeInfo={openIncomeInfo}
                  setOpenIncomeInfo={setOpenIncomeInfo}
                  form={form}
                  setForm={setForm}
                  error={error}
                  setError={setError}
                  onClick={() => {
                    setTab(2);
                  }}
                  userData={userData}
                />
              </div>
            )}

            {/*-------------------- Step 2 --------------------*/}
            {tab === 1 && (
              <div className={STYLE_TAB_PANEL_2}>
                <ViewStepTwoTaxPlan
                  form={form}
                  setForm={setForm}
                  openInfo={openInfo}
                  setOpenInfo={setOpenInfo}
                  openReduceInfo={openReduceInfo}
                  setOpenReduceInfo={setOpenReduceInfo}
                  openDeductInfo={openDeductInfo}
                  setOpenDeductInfo={setOpenDeductInfo}
                  setTab={setTab}
                  userData={userData}
                  error={error}
                  setError={setError}
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                />
              </div>
            )}

            {/*-------------------- Step 3 --------------------*/}
            {tab === 2 && (
              <div className={STYLE_TAB_PANEL_2}>
                <ViewStepThreeTaxPlan
                  form={form}
                  setForm={setForm}
                  formAfter={formAfter}
                  setFormAfter={setFormAfter}
                  error={error}
                  setError={setError}
                  openTaxPlanInfo={openTaxPlanInfo}
                  setOpenTaxPlanInfo={setOpenTaxPlanInfo}
                  userData={userData}
                />
              </div>
            )}

            {/*-------------------- Step 4 --------------------*/}
            {tab === 3 && (
              <div className={STYLE_TAB_PANEL_2}>
                <ViewStepFourTaxPlan
                  tab={tab}
                  setTab={setTab}
                  form={form}
                  setForm={setForm}
                  error={error}
                  setError={setError}
                  openTaxPlanInfo={openTaxPlanInfo}
                  setOpenTaxPlanInfo={setOpenTaxPlanInfo}
                  userData={userData}
                />
              </div>
            )}

            {/*-------------------- ปุ่ม ถัดไป, ย้อนกลับ --------------------*/}

            <div className=" flex justify-center gap-4 mb-10 px-5">
              {tab === 0 ? (
                <>
                  <MTButton
                    loading={loading}
                    text={"ถัดไป"}
                    color="bg-maincyan"
                    onClick={(e) => {
                      e?.preventDefault();
                      const formerror = checkError(form);
                      const isformerror = Object.values(formerror).some(
                        (item) => item === true
                      );
                      if (!userData?.is_premium) {
                        setError(formerror);
                      }
                      if (isformerror) {
                        // if (false) {
                        setTab((prev) => prev + 1);
                        return;
                      } else {
                        const result = formatDeduction(form);
                        setDeduction(result);
                        setForm((prev) => ({
                          ...prev,
                          deduct_summary: 60000,
                          deduct_summary_except_education_donate:
                            prev?.deduct_summary_except_education_donate
                              ? prev?.deduct_summary_except_education_donate
                              : 60000,
                          deduct_summary_except_other_donate:
                            prev?.deduct_summary_except_other_donate
                              ? prev?.deduct_summary_except_other_donate
                              : 60000,
                        }));
                        setTab((prev) => prev + 1);
                        // tab > 3 && setTab(0);
                        // navigate("/summarytax");
                      }
                    }}
                  />
                </>
              ) : tab === 2 && checkSum5values() > 500000 ? (
                ""
              ) : (
                <>
                  <MTButton
                    loading={loading}
                    text="ย้อนกลับ"
                    color="bg-gray55"
                    width={tab === 3 ? "w-7/12" : "w-full"}
                    onClick={() => {
                      setTab((prev) => prev - 1);
                    }}
                  />
                  {tab === 3 ? (
                    ""
                  ) : (
                    <MTButton
                      loading={loading}
                      text={"ถัดไป"}
                      color="bg-maincyan"
                      type={"button"}
                      width="w-full"
                      onClick={(e) => {
                        if (tab === 2) {
                          const formerror = checkError(form);
                          const newFormError = {
                            ...error,
                            sum_Fund: error?.sum_after_Fund
                              ? error?.sum_after_Fund
                              : false,
                          };

                          const isformerror = Object.values(newFormError).some(
                            (item) => item === true
                          );

                          setError(newFormError);

                          if (checkSumTab3(form).sum > 500000) {
                            console.log("sum error");
                            console.log(
                              "checkSumTab3(form)",
                              checkSumTab3(form)
                            );
                            setOpenTaxAfterError3(true);
                            return;
                          }

                          if (
                            newFormError?.sum_after_Fund === true ||
                            newFormError?.sum_Fund === true
                          ) {
                            setOpenTaxAfterError2(true);
                            return;
                          }

                          if (isformerror) {
                            setOpenTaxAfterError(true);
                            return;
                          } else {
                            e?.stopPropagation();
                            e?.preventDefault();
                            setTaxData({
                              ...form,
                              deduct_summary_af: displaySumDeduction(),
                            });
                            setTab((prev) => prev + 1);
                            // navigate("/summarytax");
                          }
                        } else {
                          e?.stopPropagation();
                          e?.preventDefault();
                          setTaxData(form);
                          setTab((prev) => prev + 1);
                        }
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </Container>
      {/* {------ รายละเอียดภาษี ------} */}
      {/* {tab === "income" && <ViewDetailsTaxIncome setTab={setTab} />} */}
      {/*------------------------------ Drawer ตัว (i) วางแผนภาษี ------------------------------*/}
      {openTaxPlanInfo && (
        <Drawer
          zindex={100}
          placement="bottom"
          open={openTaxPlanInfo}
          className=" rounded-t-[40px] !max-h-[70vh] overflow-y-scroll "
        >
          <div
            className={` w-[90px] h-[3px] rounded-full mx-auto bg-[#E0DFDF] mb-7 mt-5`}
          ></div>
          <img
            src="/images/img_popup.png"
            className="animate-bounce mx-auto w-64 h-auto"
            alt=""
          />
          <div className=" h-fit md:h-full relative ">
            <div className=" px-10">
              <div className=" text-[28px] font-IBMPlexSansThai font-semibold text-mainblue">
                วางแผนภาษี
              </div>
              <div className=" mt-2 text-maingray font-normal font-IBMPlexSansThaileading-relaxed">
                การเตรียมการเพื่อเสียภาษีให้ถูกต้อง ครบถ้วน
                ในฐานะพลเมืองดีและใช้สิทธิประโยชน์ทางภาษีต่าง ๆ
                ที่กฎหมายกำหนดไว้ไปใช้ลดหย่อนภาษีเงินได้ประจำปี
                เพื่อบรรเทาภาระภาษีให้น้อยลง ไม่ต้องเสียภาษีมากจนเกินไป
                รวมถึงไม่ต้องชำระภาษีเพิ่มหรือเสียเบี้ยปรับโดยใช่เหตุ
                และยิ่งถ้าเราวางแผนภาษีได้เป็นอย่างดี
                ก็จะเป็นการเพิ่มพูนความมั่งคั่งได้เป็นอย่างดีด้วย
              </div>
            </div>
            <div className=" flex justify-center pt-5">
              {" "}
              <MTButton
                text="ปิด"
                onClick={() => {
                  setOpenTaxPlanInfo(false);
                }}
              />
            </div>
            <img
              src="/images/bgblue.png"
              className=" absolute -bottom-[85%] -z-10 w-full md:h-full h-fit "
              alt=""
            />
          </div>
        </Drawer>
      )}
      {/*------------------------------ Drawer วางแผนภาษี ------------------------------*/}
      {openDrawer && (
        <DrawerMain
          form={form}
          setForm={setForm}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          setTaxData={setTaxData}
          setOpen={() => {
            setOpenInfo(openInfo);
            setOpenDrawer(openDrawer);
          }}
        />
      )}
      {openInfo === 1 && (
        <PayerIncome
          index={index}
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 2 && (
        <Spouse
          isSpouseDisable={isSpouseDisable}
          setIsSpouseDisable={setIsSpouseDisable}
          ishasIncome={ishasIncome}
          setIshasIncome={setIshasIncome}
          isMarriage={isMarriage}
          setIsMarriage={setIsMarriage}
          index={index}
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}{" "}
      {openInfo === 3 && (
        <Child
          index={index}
          openInfo={openInfo}
          ishasChild={ishasChild}
          setIshasChild={setIshasChild}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 4 && (
        <Mother
          ishasIncomeMother={ishasIncomeMother}
          setIshasIncomeMother={setIshasIncomeMother}
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 5 && (
        <Father
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          ishasIncomeFather={ishasIncomeFather}
          setIshasIncomeFather={setIshasIncomeFather}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 6 && (
        <Disable
          ishasIncomeDisable={ishasIncomeDisable}
          setIshasIncomeDisable={setIshasIncomeDisable}
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          setForm={setForm}
          form={form}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 7 && (
        <Disabilities
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          ishasIncomeDisabilities={ishasIncomeDisabilities}
          setIshasIncomeDisabilities={setIshasIncomeDisabilities}
          setForm={setForm}
          form={form}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 8 && (
        <Parents
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          setForm={setForm}
          form={form}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 9 && (
        <InsuranceLife
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 10 && (
        <InsuaranceHealth
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 11 && (
        <InsuranceAnnuity
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
          userData={userData}
        />
      )}
      {openInfo === 12 && (
        <FundProvident
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 13 && (
        <FundNSF
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 14 && (
        <FundESG
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 15 && (
        <FundRMF
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 16 && (
        <FundSSF
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 17 && (
        <HomeLoan
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 18 && (
        <FundSocial
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 19 && (
        <Prenatal
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 20 && (
        <Political
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 21 && (
        <Education
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 22 && (
        <OtherDonate
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 23 && (
        <Special
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 24 && (
        <FundGorBorKhor
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 25 && (
        <FundPrivateSchoolTeacher
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInfo === 26 && (
        <FundCompensationMoney
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          form={form}
          setForm={setForm}
          setOpenDrawer={setOpenDrawer}
        />
      )}
    </TaxPlanContext.Provider>
  );
};
export default TaxPlanMain;
