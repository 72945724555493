import { result } from "lodash";

export const strategyNamelist = {
  strategy_out_come: "ลดรายจ่ายผันแปร",
  strategy_income: "เพิ่มรายได้",
  strategy_passive_income_retire: "หา Passive Income ยามเกษียณ (เดือนละ)",
  strategy_assets_generate: "แปลงสินทรัพย์ให้มีผลตอบแทน",
  strategy_assets_generate_per_year: "อัตราผลตอบแทนคาดหวัง",
  strategy_salary: "เงินเดือนใหม่",
  strategy_salary_peryear: "อัตราการเพิ่มเงินเดือนใหม่ (%)",
  strategy_control_rate_outcome:
    "ปรับพฤติกรรมและควบคุมอัตราการเพิ่มขึ้นของค่าใช้จ่ายผันแปร",
  strategy_age_retire: "ปรับเวลาเกษียณ",
  strategy_age_retire_reduce: "ลดเวลาเกษียณ",
  strategy_age_dead: "พิจารณาอายุขัย",
  strategy_investment_bf_retirement: "อัตราผลตอบแทนก่อนเกษียณ (%)",
  // strategy_investment_bf_retirement_max: "",
  strategy_investment_af_retirement: "อัตราผลตอบแทนหลังเกษียณ (%)",
  // strategy_investment_af_retirement_max: "",
};

export const formatSTG = (retireData, isSubmit = false) => {
  let result = [];
  for (const key in strategyNamelist) {
    // console.log("key", key);
    // console.log("retireData", retireData?.[key]);

    if (Number(retireData?.[key]) > 0) {
      result.push({
        title: strategyNamelist[key],
        amount: retireData?.[key],
        key,
      });
    }
  }

  return result;
};

export const avgWeight = (beforeValue, asset, stgAsset, newValue) => {
  let sum = 0;
  sum =
    (Number(beforeValue) * Number(asset) +
      Number(stgAsset) * Number(newValue)) /
    (Number(asset) + Number(stgAsset));
  return Number(sum?.toFixed(1));
};

export const mapingSTG = (
  result,
  {
    openOutcome,
    openPassive,
    openIncome,
    openAssetGen,
    openSalary,
    openInvestment,
    openControlRate,
    openRetire,
    openDead,
  }
) => {
  const newResults = [];

  for (const resultItem of result) {
    //ลดรายจ่าย
    if (
      resultItem?.key === "strategy_out_come" &&
      openOutcome &&
      resultItem?.amount > 0
    ) {
      newResults.push(resultItem);
    }
    //passiveincome
    if (
      resultItem?.key === "strategy_passive_income_retire" &&
      openPassive &&
      resultItem?.amount > 0
    ) {
      newResults.push(resultItem);
    }
    //เพิ่มรายได้
    if (
      resultItem?.key === "strategy_income" &&
      openIncome &&
      resultItem?.amount > 0
    ) {
      newResults.push(resultItem);
    }
    //แปลงสินทรัพย์ให้มีผลตอบแทน
    if (
      resultItem?.key === "strategy_assets_generate" &&
      openAssetGen &&
      resultItem?.amount > 0
    ) {
      newResults.push(resultItem);
    }
    if (
      resultItem?.key === "strategy_assets_generate_per_year" &&
      openAssetGen &&
      resultItem?.amount > 0
    ) {
      newResults.push(resultItem);
    }
    //พัฒนาเงินเดือน
    if (
      resultItem?.key === "strategy_salary" &&
      openSalary &&
      resultItem?.amount > 0
    ) {
      newResults.push(resultItem);
    }
    if (
      resultItem?.key === "strategy_salary_peryear" &&
      openSalary &&
      resultItem?.amount > 0
    ) {
      newResults.push(resultItem);
    }

    // การจัดการลงทุน ก่อน หลัง เกษียณ
    if (
      resultItem?.key === "strategy_investment_bf_retirement" &&
      openInvestment &&
      resultItem?.amount > 0
    ) {
      newResults.push(resultItem);
    }
    if (
      resultItem?.key === "strategy_investment_af_retirement" &&
      openInvestment &&
      resultItem?.amount > 0
    ) {
      newResults.push(resultItem);
    }
    //ปรับค่าใช้จ่าย
    if (
      resultItem?.key === "strategy_control_rate_outcome" &&
      openControlRate &&
      resultItem?.amount > 0
    ) {
      newResults.push(resultItem);
    }
    // ขยายเวลาเกษียณ
    if (
      resultItem?.key === "strategy_age_retire" &&
      openRetire &&
      resultItem?.amount > 0
    ) {
      newResults.push(resultItem);
    }

    //ลดเวลาเกษียณ

    //พิจารณาอายุขัย
    if (
      resultItem?.key === "strategy_age_dead" &&
      openDead &&
      resultItem?.amount > 0
    ) {
      newResults.push(resultItem);
    }
  }

  return newResults;
};
