import { calDebtCreditCard } from "../../utils/useFunctions";

export const findDebtShorttermOther = (balanceData, tempFutureData) => {
  let title = [];
  let value = [];

  let debt_short_list = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_shortterm_other"
  )?.short_term_others;

  debt_short_list?.map((item2, index2) => {
    let tempvalue = [];
    title.push(item2?.creditor_name);

    const calcPeriodInterest = (index) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (index >= Math.ceil(item2?.period / 12)) {
        return 0;
      }
      if (index < year) {
        return 12;
      } else {
        return lastYearMonth;
      }
    };

    tempFutureData?.cash_flow_items?.map((cash_flow_items, index3) => {
      return item2?.period === "Infinity"
        ? tempvalue.push(-Math.abs(item2?.pay_permonth * 12))
        : tempvalue.push(
            -Math.abs(item2?.pay_permonth * calcPeriodInterest(index3))
          );
    });

    value.push(tempvalue);
  });

  return { title, value };
};

export const findDebtInformalShortterm = (balanceData, tempFutureData) => {
  let title = [];
  let value = [];

  let debt_short_list = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_informal_shortterm"
  )?.informal_short_terms;

  debt_short_list?.map((item2, index2) => {
    let tempvalue = [];
    title.push(item2?.creditor_name);

    const calcPeriodInterest = (index) => {
      let year = Math.floor(item2?.number_of_days / 365);
      let lastYearMonth = item2?.number_of_days % 30;

      if (index >= Math.ceil(item2?.number_of_days / 365)) {
        return 0;
      }
      if (index < year) {
        return 365;
      } else {
        return lastYearMonth;
      }
    };

    tempFutureData?.cash_flow_items?.map((cash_flow_items, index3) => {
      return item2?.period === "Infinity"
        ? tempvalue.push(-Math.abs(item2?.pay_permonth * 12))
        : tempvalue.push(
            -Math.abs(item2?.pay_permonth * calcPeriodInterest(index3))
          );
    });

    value.push(tempvalue);
  });

  return { title, value };
};

export const findDebtShortterm = (balanceData, tempFutureData) => {
  let title = [];
  let value = [];

  let debt_short_list = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_shortterm"
  )?.short_terms;

  debt_short_list?.map((item2, index2) => {
    let tempvalue = [];
    title.push(item2?.creditor_name);

    const calcPeriodInterest = (index) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (index >= Math.ceil(item2?.period / 12)) {
        return 0;
      }
      if (index < year) {
        return 12;
      } else {
        return lastYearMonth;
      }
    };

    tempFutureData?.cash_flow_items?.map((cash_flow_items, index3) => {
      return item2?.period === "Infinity"
        ? tempvalue.push(-Math.abs(item2?.pay_permonth * 12))
        : tempvalue.push(
            -Math.abs(item2?.pay_permonth * calcPeriodInterest(index3))
          );
    });

    value.push(tempvalue);
  });

  return { title, value };
};

export const findDebtCreditcard = (balanceData, tempFutureData) => {
  let title = [];
  let value = [];

  let debt_short_list = balanceData?.debt_short_list?.find(
    (item) => item?.options_debt_short === "debt_creditcard"
  )?.credit_cards;

  debt_short_list?.map((item2, index2) => {
    let tempvalue = [];
    title.push(item2?.creditor_name);

    const calcPeriodInterest = (index) => {
      let period =
        calDebtCreditCard(item2)?.creditor_items?.length;
      let year = Math.floor(period / 12);
      let lastYearMonth = period % 12;

      if (index >= Math.ceil(period / 12)) {
        return 0;
      }
      if (index < year) {
        return 12;
      } else {
        return lastYearMonth;
      }
    };

    tempFutureData?.cash_flow_items?.map((cash_flow_items, index3) => {
      return item2?.period === "Infinity"
        ? tempvalue.push(-Math.abs(item2?.pay_permonth * 12))
        : tempvalue.push(
            -Math.abs(item2?.pay_permonth * calcPeriodInterest(index3))
          );
    });

    value.push(tempvalue);
  });

  return { title, value };
};

export const findLongDebtCar = (balanceData, tempFutureData) => {
  let title = [];
  let value = [];

  let debt_long_list = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_car"
  )?.debt_long_cars;

  debt_long_list?.map((item2, index2) => {
    let tempvalue = [];
    title.push(item2?.title_car);

    const calcPeriodInterest = (index) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (index >= Math.ceil(item2?.period / 12)) {
        return 0;
      }
      if (index < year) {
        return 12;
      } else {
        return lastYearMonth;
      }
    };

    tempFutureData?.cash_flow_items?.map((cash_flow_items, index3) => {
      return item2?.period === "Infinity"
        ? tempvalue.push(-Math.abs(item2?.pay_permonth * 12))
        : tempvalue.push(
            -Math.abs(item2?.pay_permonth * calcPeriodInterest(index3))
          );
    });

    value.push(tempvalue);
  });

  return { title, value };
};

export const findLongDebtPlace = (balanceData, tempFutureData) => {
  let title = [];
  let value = [];

  let debt_long_list = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_place"
  )?.debt_long_places;

  debt_long_list?.map((item2, index2) => {
    let tempvalue = [];
    title.push(item2?.address_place);

    const calcPeriodInterest = (index) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (index >= Math.ceil(item2?.period / 12)) {
        return 0;
      }
      if (index < year) {
        return 12;
      } else {
        return lastYearMonth;
      }
    };

    tempFutureData?.cash_flow_items?.map((cash_flow_items, index3) => {
      return item2?.period === "Infinity"
        ? tempvalue.push(-Math.abs(item2?.pay_permonth * 12))
        : tempvalue.push(
            -Math.abs(item2?.pay_permonth * calcPeriodInterest(index3))
          );
    });

    value.push(tempvalue);
  });

  return { title, value };
};

export const findLongDebtOther = (balanceData, tempFutureData) => {
  let title = [];
  let value = [];

  let debt_long_list = balanceData?.debt_long_list?.find(
    (item) => item?.options_debt_long === "debt_long_other"
  )?.debt_long_others;

  debt_long_list?.map((item2, index2) => {
    let tempvalue = [];
    title.push(item2?.title);

    const calcPeriodInterest = (index) => {
      let year = Math.floor(item2?.period / 12);
      let lastYearMonth = item2?.period % 12;

      if (index >= Math.ceil(item2?.period / 12)) {
        return 0;
      }
      if (index < year) {
        return 12;
      } else {
        return lastYearMonth;
      }
    };

    tempFutureData?.cash_flow_items?.map((cash_flow_items, index3) => {
      return item2?.period === "Infinity"
        ? tempvalue.push(-Math.abs(item2?.pay_permonth * 12))
        : tempvalue.push(
            -Math.abs(item2?.pay_permonth * calcPeriodInterest(index3))
          );
    });

    value.push(tempvalue);
  });

  return { title, value };
};