import { Drawer, Slider } from "@material-tailwind/react";
import { MTButton } from "../../../components/button/MTButton";
import { CardHeadgray, CardMain } from "../../../components/card/CardMain";
import {
  STYLE_INPUT,
  STYLE_INPUT_ERROR,
  STYLE_INPUT_SLIDER,
  STYLE_SPACE_Y,
} from "../../../utils/useStyle";
import { ICON_NAVIGATE_RIGHT } from "../../../utils/useIcons";
import { SliderSingle } from "../../../components/slider/SliderSingle";
import { useEffect, useState } from "react";
import numeral from "numeral";
import ErrorMain from "../../../components/error/Error.Main";
import { NumericFormat } from "react-number-format";
import { InfoDrawer } from "../../../components/drawer/InfoDrawer";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";
import { cn } from "../../../utils/useFunctions";
import { ViewTableDebt } from "../../debt/component/VIDebtTable";

export const OutcomeInfo = ({ openCurrent, setOpenCurrent }) => {
  return (
    <InfoDrawer
      open={openCurrent}
      onClose={() => setOpenCurrent(false)}
      head={"รายจ่ายปัจจุบัน (บาท/เดือน)"}
      text={
        " รายจ่ายปัจจุบันต่อเดือน งบประมาณการใช้จ่ายต่อเดือน หรือ เงินเดือนที่หักลบค่าใช้จ่ายประจำ โดยคิดเฉลี่ยเป็นต่อเดือน โดยเฉลี่ยอยู่ที่ 80% ของรายได้ "
      }
    />
  );
};
export const OutcomePerYear = ({ openOutCome, setOpenOutCome }) => {
  return (
    <InfoDrawer
      open={openOutCome}
      onClose={() => setOpenOutCome(false)}
      head={"รายจ่ายที่เพิ่มขึ้นต่อปี (%)"}
      text={
        " รายจ่ายปัจจุบันต่อเดือน งบประมาณการใช้จ่ายต่อปี หรือ เงินเดือนที่หักลบค่าใช้จ่ายประจำ โดยคิดเฉลี่ยเป็นต่อปี โดยเฉลี่ยอยู่ที่ 3-5% "
      }
    />
  );
};

const ViewIncomeOutcome = ({
  onClickCurrentExpenses,
  onClickOutComeperYear,
  formRetire,
  userData,
  balanceData,
  setFormRetire,
  error,
  setError,
  setOpenCurrent,
  setOpenOutCome,
}) => {
  const onChangeOutCome = (field) => (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // const defaultValue = Number(formRetire?.salary) / 2;
    // if (isNaN(e.target.value)) return;
    else {
      setError({
        ...error,
        [field]: false,
      });
    }

    setFormRetire({
      ...formRetire,
      [field]: output,
    });
  };

  const onChangeOutComePerYear = (e) => {
    if (isNaN(e.target.value)) return;

    const tempValue = e.target.value ? Number(e.target.value) : null;

    if (tempValue > Number(formRetire?.out_come_peryear_max)) return;

    // Set tempValue to the state
    setFormRetire({
      ...formRetire,
      out_come_peryear: tempValue,
      strategy_control_rate_outcome: tempValue,
    });
  };

  const onChangeOutComePerYearMax = (e) => {
    if (isNaN(e.target.value)) return;
    if (e.target.value > 300) return;
    else {
      setError({
        ...error,
        out_come_peryear_max: false,
      });
    }
    const value = e.target.value ? Number(e.target.value) : null;

    setFormRetire({
      ...formRetire,
      out_come_peryear: value,
      out_come_peryear_max: value,
    });
  };

  const getResult = () => {
    let result = 0;
    result = formRetire?.salary - formRetire?.out_come;
    return Number(result);
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setFormRetire({
        ...formRetire,
        outcome_remain: getResult(),
      });
    }
  }, [formRetire?.out_come]);

  return (
    <div className={STYLE_SPACE_Y}>
      {userData?.is_premium ? (
        //============================premium========================================
        <>
          <CardHeadgray
            title={"รายจ่ายคงที่/เดือน (ไม่รวมเงินผ่อนชำระหนี้สินคงค้างรายงวด)"}
            text_color="text-mainblue font-semibold text-xl"
            panel={
              <div className=" py-5 flex justify-center items-center space-x-1">
                <NumericFormat
                  placeholder="0"
                  className={cn(STYLE_INPUT_SLIDER, "w-32")}
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  value={Number(formRetire?.out_come || 0)}
                  onChange={onChangeOutCome("out_come")}
                />
                <div>บาท</div>
              </div>
            }
          />
           <CardHeadgray
            title={"หนี้สินคงค้าง"}
            text_color="text-mainblue font-semibold text-xl"
            panel={
              <ViewTableDebt
              debtShortList={balanceData?.debt_short_list}
              debtLongList={balanceData?.debt_long_list}
            />
            }
          />
         
          <CardHeadgray
            title={" รายจ่ายแปรผัน/เดือน"}
            text_color="text-mainblue font-semibold text-xl"
            panel={
              <div className=" py-5 flex justify-center items-center space-x-1">
                <NumericFormat
                  placeholder="0"
                  className={cn(STYLE_INPUT_SLIDER, "w-32")}
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  value={Number(formRetire?.out_come_variable || 0)}
                  onChange={onChangeOutCome("out_come_variable")}
                />
                <div>บาท</div>
              </div>
            }
          />
          <CardHeadgray
            title={" อัตรารายจ่ายฝันแปรที่เพิ่มขึ้นต่อปี (%) "}
            text_color="text-mainblue font-semibold text-xl"
            panel={
              <div className=" py-5 flex justify-center items-center space-x-1">
                <NumericFormat
                  placeholder="0"
                  className={cn(STYLE_INPUT_SLIDER, "w-32")}
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  value={Number(formRetire?.out_come_peryear || 0)}
                  onChange={onChangeOutComePerYear}
                />
                <div>%</div>
              </div>
            }
          />
        </>
      ) : (
        //============================Normal========================================
        <>
          <CardMain
            onClick={() => {
              setOpenCurrent(true);
            }}
            icon={false}
            title="รายจ่ายปัจจุบัน (บาท/ต่อเดือน)"
            panel={
              <div className=" px-4">
                {formRetire?.salary ? (
                  <div>
                    <div className=" flex justify-center ">
                      <NumericFormat
                        maxLength={11}
                        className={`${STYLE_INPUT_SLIDER} w-28`}
                        value={Number(formRetire?.out_come || 0)}
                        onChange={onChangeOutCome("out_come")}
                        placeholder="5,000"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                      />
                    </div>
                    <div className=" flex gap-[5px] items-center mt-3  ">
                      <div className=" test w-full">
                        <input
                          type="range"
                          min={0}
                          max={formRetire?.salary}
                          value={formRetire?.out_come}
                          onChange={onChangeOutCome("out_come")}
                          step={500}
                        />
                      </div>
                    </div>
                    <div className=" flex justify-between mt-2">
                      <div className=" text-bluegray50 font-semibold text-xl font-IBMPlexSansThai">
                        {0}
                      </div>
                      <div className=" text-bluegray50 font-semibold text-sm font-IBMPlexSansThai">
                        {numeral(formRetire?.salary)?.format("0,0")} บาท
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="flex gap-[5px] items-center ">
                        <div className=" bg-mainyellow  w-[18px] h-[10px] rounded-sm "></div>
                        <div className=" text-sm font-semibold text-mainblue">
                          รายจ่าย :{" "}
                          {numeral(formRetire?.out_come)?.format("0,0")} บาท
                        </div>
                      </div>

                      {getResult() > 0 ? (
                        <div className="flex gap-[5px] items-center ">
                          <div className=" bg-maingreen  w-[18px] h-[10px] rounded-sm "></div>
                          <div
                            className={`text-sm font-semibold  text-mainblue`}
                          >
                            คงเหลือ : {numeral(getResult())?.format("0,0")} บาท
                          </div>
                        </div>
                      ) : (
                        <div className="flex gap-[5px] items-center ">
                          <div className=" bg-mainred  w-[18px] h-[10px] rounded-sm "></div>
                          <div
                            className={`text-sm font-semibold  text-mainred`}
                          >
                            คงเหลือ : {numeral(getResult())?.format("0,0")} บาท
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className=" px-2">
                    <NumericFormat
                      maxLength={11}
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                      className={
                        error?.out_come ? STYLE_INPUT_ERROR : STYLE_INPUT
                      }
                      placeholder="10,000"
                      value={formRetire?.out_come}
                      onChange={onChangeOutCome}
                    />
                    {error?.out_come && (
                      <ErrorMain errortext="กรุณากรอกรายจ่ายปัจจุบัน" />
                    )}
                  </div>
                )}
              </div>
            }
          />
          <CardMain
            onClick={() => {
              setOpenOutCome(true);
            }}
            icon={false}
            title="รายจ่ายที่เพิ่มขึ้นต่อปี (%)"
            margin=""
            mb="mb-4"
            panel={
              <div className=" px-4">
                <div className=" flex justify-center">
                  <NumericFormat
                    type="number"
                    maxLength={11}
                    placeholder="0"
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={STYLE_INPUT_SLIDER}
                    value={formRetire?.out_come_peryear}
                    onChange={onChangeOutComePerYear}
                  />
                </div>
                <SliderSingle
                  valuemax={formRetire?.out_come_peryear_max}
                  value={formRetire?.out_come_peryear}
                  onChange={onChangeOutComePerYear}
                  onChnageMax={onChangeOutComePerYearMax}
                />
                {error?.out_come_peryear_max && (
                  <ErrorMain errortext="กรุณากรอกรายจ่ายที่เพิ่มขึ้นต่อปี" />
                )}
              </div>
            }
          />
        </>
      )}
    </div>
  );
};

export default ViewIncomeOutcome;
