import { createContext, useContext, useEffect, useMemo, useState } from "react";
import Container from "../../components/layout/Container";
import {
  STYLE_ACTIVE_STEPPER,
  STYLE_BG_CIRCLE,
  STYLE_INACTIVE_STEPPER,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_STEPPER,
  STYLE_TAB,
  STYLE_TAB_ACTIVE,
  STYLE_TAB_HEADER_CLASSNAME,
  STYLE_TAB_HEDER_PROPS,
  STYLE_TAB_INACTIVE,
  STYLE_TAB_PANEL,
  STYLE_TAB_PARENT,
} from "../../utils/useStyle";
import {
  Drawer,
  Step,
  Stepper,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";

import ViewProfile, {
  AgeInfo,
  DrawerSalary,
  SalaryPerYearInfo,
} from "./components/VIProfile_Tab.js";
import ViewIncomeOutcome, {
  DrawerIncome,
  OutcomeInfo,
  OutcomePerYear,
} from "./components/VIIncome_Tab";
import ViewInvestment, {
  AfterRetireInfo,
  BeforeRetireInfo,
  InvestmentInfo,
  PensionInfo,
} from "./components/VIInvestment_Tab";

import { MTButton } from "../../components/button/MTButton";

import { useNavigate } from "react-router-dom";
import { useRetireStore } from "../../_store/retireStore.js";
import NavigateGoBack, {
  NavigatePath,
} from "../../functions/Navigate.Goback.js";
import classNames from "classnames";
import { ICON_CHECK } from "../../utils/useIcons.js";
import { FooterAfterSum } from "../../components/FooterAfterSum/FooterAfterSum.js";
import { useSettingStore } from "../../_store/settingStore.js";
import { useUserStore } from "../../_store/userStore.js";
import { useBalanceStore } from "../../_store/balanceStore.js";

const { HeaderTitle } = require("../../components/header/Header");
const RetireContext = createContext();
export const useRetireContext = () => useContext(RetireContext);

const checkError = (formRetire) => {
  const salary =
    formRetire?.salary === "" ||
    // formRetire?.salary === 0 ||
    formRetire?.salary === null;

  const salary_peryear =
    formRetire?.salary_peryear === "" || formRetire?.salary_peryear === null;

  const salary_peryear_max =
    formRetire?.salary_peryear_max === "" ||
    formRetire?.salary_peryear_max === 0 ||
    formRetire?.salary_peryear_max > 20 ||
    formRetire?.salary_peryear_max === null ||
    formRetire?.salary_peryear > formRetire?.salary_peryear_max;

  const out_come =
    formRetire?.out_come === "" ||
    // formRetire?.out_come === 0 ||
    formRetire?.out_come === null;

  const out_come_peryear =
    formRetire?.out_come_peryear === "" ||
    formRetire?.out_come_peryear === 0 ||
    formRetire?.out_come_peryear === null;

  const out_come_peryear_max =
    formRetire?.out_come_peryear_max === "" ||
    formRetire?.out_come_peryear_max === 0 ||
    formRetire?.out_come_peryear_max > 300 ||
    formRetire?.out_come_peryear_max === null ||
    formRetire?.out_come_peryear > formRetire?.out_come_peryear_max;

  const investment_bf_retirement =
    formRetire?.investment_bf_retirement === "" ||
    // formRetire?.investment_bf_retirement === 0 ||
    formRetire?.investment_bf_retirement === null;

  const investment_bf_retirement_max =
    formRetire?.investment_bf_retirement_max === "" ||
    formRetire?.investment_bf_retirement_max === 0 ||
    formRetire?.investment_bf_retirement_max > 300 ||
    formRetire?.investment_bf_retirement_max === null ||
    formRetire?.investment_bf_retirement >
      formRetire?.investment_bf_retirement_max;

  const investment_af_retirement =
    formRetire?.investment_af_retirement === "" ||
    // formRetire?.investment_af_retirement === 0 ||
    formRetire?.investment_af_retirement === null;

  const investment_af_retirement_max =
    formRetire?.investment_af_retirement_max === "" ||
    formRetire?.investment_af_retirement_max === 0 ||
    formRetire?.investment_af_retirement_max > 300 ||
    formRetire?.investment_af_retirement_max === null ||
    formRetire?.investment_af_retirement >
      formRetire?.investment_af_retirement_max;

  return {
    salary,
    salary_peryear,
    salary_peryear_max,
    out_come,
    out_come_peryear,
    out_come_peryear_max,
    investment_bf_retirement,
    investment_bf_retirement_max,
    investment_af_retirement,
    investment_af_retirement_max,
  };
};

const RetirePlanMain = () => {
  const userData = useUserStore((state) => state.userData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const settingData = useSettingStore((state) => state.settingData);
  const retireData = useRetireStore((state) => state.retireData);
  const setRetireData = useRetireStore((state) => state.setRetireData);
  const [formRetire, setFormRetire] = useState({
    age_current: settingData?.retire?.age_current ?? 25,
    age_retire: settingData?.retire?.age_retire ?? 60,
    age_dead: settingData?.retire?.age_dead ?? 80,
    age_dead_max: "",
    salary: "",
    salary_peryear: 5,
    salary_peryear_max: retireData?.salary_peryear_max ?? 20,
    out_come: 0,
    out_come_max: "",
    out_come_peryear: settingData?.retire?.expenses_per_year_rate ?? 5,
    out_come_peryear_max: 20,
    pension: "",
    investment_assets: "",
    investment_bf_retirement: 4,
    investment_bf_retirement_max: 20,
    investment_af_retirement: settingData?.retire?.expenses_after_retire ?? 3,
    investment_af_retirement_max: 20,
    bf_plan_year: "",
    bf_plan_age: "",
    bf_plan_income: [],
    bf_plan_outcome: [],
    bf_plan_remain: [],
    bf_return_investment: [],
    bf_investment_assets: [],
    af_plan_year: "",
    af_plan_age: "",
    af_plan_income: [],
    af_plan_outcome: [],
    af_plan_remain: [],
    af_return_investment: [],
    af_investment_assets: [],

    strategy_sum: "",
    strategy_out_come: "",
    strategy_income: "",
    strategy_passive_income_retire: "",
    strategy_assets_generate: "",
    strategy_assets_generate_per_year: 4,
    strategy_salary: "",
    strategy_salary_peryear: 5,
    strategy_salary_peryear_max: 20,
    strategy_control_rate_outcome: 5,
    strategy_control_rate_outcome_max: 20,
    strategy_age_retire: 60,

    strategy_age_dead: 80,
    strategy_investment_bf_retirement: 4,
    strategy_investment_bf_retirement_max: 20,
    strategy_investment_af_retirement: 3,
    strategy_investment_af_retirement_max: 20,

    check_imagine_one: true,
    check_imagine_two: true,
    check_imagine_three: true,

    sum_before_retire_invest: 0,
    sum_after_retire_invest: 0,
  });

  const [error, setError] = useState({
    salary: false,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);

  const [openSalary, setOpenSalary] = useState();
  const [openSalaryPerYear, setOpenSalaryPerYear] = useState();
  const [openCurrent, setOpenCurrent] = useState(false);
  const [openOutCome, setOpenOutCome] = useState(false);
  const [openPension, setOpenPension] = useState();
  const [openPopupPension, setOpenPopupPension] = useState(false);

  //info
  const [openAgeInfo, setOpenAgeInfo] = useState(false);
  const [openSalaryInfo, setOpenSalaryInfo] = useState(false);
  const [openPensionInfo, setOpenPensionInfo] = useState(false);
  const [openInvestmentInfo, setOpenInvestmentInfo] = useState(false);
  const [openBeforeRetireInfo, setOpenBeforeRetireInfo] = useState(false);
  const [openAfterRetireInfo, setOpenAfterRetireInfo] = useState(false);

  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const labelhead = [
    {
      label: "ข้อมูลทั่วไป",
      value: 1,
    },
    {
      label: "รายรับ-จ่าย",
      value: 2,
    },
    {
      label: "ผลตอบแทน",
      value: 3,
    },
  ];

  useEffect(() => {
    setFormRetire({
      ...formRetire,
      ...retireData,
    });
  }, [retireData]);


  return (
    <RetireContext.Provider value={{ formRetire, setFormRetire }}>
      <div>
        <Container
          style={{
            backgroundImage: `url(/images/circle_right.png)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right top",
          }}
          className={` ${STYLE_BG_CIRCLE} `}
        >
          <div className={`${STYLE_MARGIN_TOP_PARENT}  `}>
            <HeaderTitle
              title="วางแผนเกษียณ"
              isGoback
              onClickGoBack={() => {
                NavigatePath(navigate, "/");
                // NavigateGoBack(navigate);
              }}
            />

            <div className=" px-8">
              <Stepper
                activeStep={tab}
                lineClassName="bg-gray-300"
                activeLineClassName="bg-maincyan"
                className={`${STYLE_TAB} `}
                isLastStep={(value) => setIsLastStep(value)}
                isFirstStep={(value) => setIsFirstStep(value)}
              >
                {/*---- ข้อมูลทั่วไป ----*/}
                <Step
                  color="blue"
                  className=" !bg-maincyan"
                  activeClassName="!bg-maincyan "
                  onClick={() => setTab(0)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=" w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 21C5 18.7909 8.13401 17 12 17C15.866 17 19 18.7909 19 21M17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8Z"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div
                    className={classNames(
                      tab === 0 ? STYLE_ACTIVE_STEPPER : STYLE_INACTIVE_STEPPER,
                      `${STYLE_PARENT_STEPPER} `
                    )}
                  >
                    ข้อมูลทั่วไป
                  </div>
                </Step>
                {/*---- รายรับ - จ่าย ----*/}
                <Step
                  onClick={() => setTab(1)}
                  className={`${tab === 0 ? "!bg-gray300" : "!bg-maincyan"}`}
                  activeClassName="!bg-maincyan"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 14 15"
                    fill="none"
                  >
                    <path
                      d="M7 11.02V12.03M7 6.01V6.95M6 10.56C6.12202 10.7048 6.27559 10.8198 6.44893 10.8961C6.62227 10.9723 6.81078 11.0079 7 11C7.28921 11.0197 7.57479 10.9265 7.79671 10.74C8.01864 10.5536 8.15961 10.2883 8.19 10C8.15961 9.71172 8.01864 9.44645 7.79671 9.25996C7.57479 9.07347 7.28921 8.98029 7 9C6.71079 9.01972 6.42521 8.92653 6.20329 8.74004C5.98136 8.55355 5.84039 8.28828 5.81 8C5.83795 7.71071 5.97824 7.44392 6.20075 7.25694C6.42325 7.06996 6.71022 6.97771 7 7C7.18589 6.98863 7.37198 7.0186 7.54489 7.08777C7.71781 7.15693 7.87324 7.26357 8 7.4M7 14.49C10.5 14.49 13 13.25 13 10.49C13 7.49 11.5 5.49 8.5 3.99L9.68 2.47C9.73995 2.37027 9.77246 2.25647 9.77425 2.14012C9.77604 2.02377 9.74704 1.90902 9.69018 1.8075C9.63333 1.70597 9.55065 1.62128 9.45051 1.56201C9.35038 1.50274 9.23636 1.471 9.12 1.47H4.88C4.76364 1.471 4.64962 1.50274 4.54949 1.56201C4.44935 1.62128 4.36667 1.70597 4.30982 1.8075C4.25296 1.90902 4.22396 2.02377 4.22575 2.14012C4.22754 2.25647 4.26005 2.37027 4.32 2.47L5.5 4C2.5 5.51 1 7.51 1 10.51C1 13.25 3.5 14.49 7 14.49Z"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div
                    // className={`absolute -bottom-[30px] w-max text-center font-IBMPlexSansThai `}
                    className={classNames(
                      tab === 1 ? STYLE_ACTIVE_STEPPER : STYLE_INACTIVE_STEPPER,
                      STYLE_PARENT_STEPPER
                    )}
                  >
                    รายรับ - จ่าย
                  </div>
                </Step>
                {/*---- ผลตอบแทน----*/}
                <Step
                  // className="!bg-gray-300"
                  activeClassName="!bg-maincyan"
                  onClick={() => setTab(2)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 20H3V5M21 7L13 14L9 10L3 15"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div
                    // className={`absolute -bottom-[30px] w-max text-center font-IBMPlexSansThai `}
                    className={classNames(
                      tab === 2 ? STYLE_ACTIVE_STEPPER : STYLE_INACTIVE_STEPPER,
                      `${STYLE_PARENT_STEPPER} `
                    )}
                  >
                    ผลตอบแทน
                  </div>
                </Step>
              </Stepper>
            </div>

            {tab === 0 && (
              <div className={STYLE_TAB_PANEL}>
                <ViewProfile
                  error={error}
                  setError={setError}
                  formRetire={formRetire}
                  setFormRetire={setFormRetire}
                  openSalary={openSalary}
                  setOpenSalary={setOpenSalary}
                  openAgeInfo={openAgeInfo}
                  setOpenAgeInfo={setOpenAgeInfo}
                  openSalaryInfo={openSalaryInfo}
                  setOpenSalaryInfo={setOpenSalaryInfo}
                />
              </div>
            )}

            {tab === 1 && (
              <div className={STYLE_TAB_PANEL}>
                <ViewIncomeOutcome
                  error={error}
                  setError={setError}
                  formRetire={formRetire}
                  setFormRetire={setFormRetire}
                  openCurrent={openCurrent}
                  setOpenCurrent={setOpenCurrent}
                  openOutCome={openOutCome}
                  setOpenOutCome={setOpenOutCome}
                  userData={userData}
                  balanceData={balanceData}
                />
              </div>
            )}

            {tab === 2 && (
              <div className={STYLE_TAB_PANEL}>
                <ViewInvestment
                  openPension={openPension}
                  setOpenPension={setOpenPension}
                  error={error}
                  setError={setError}
                  formRetire={formRetire}
                  setFormRetire={setFormRetire}
                  openPensionInfo={openPensionInfo}
                  setOpenPensionInfo={setOpenPensionInfo}
                  openInvestmentInfo={openInvestmentInfo}
                  setOpenInvestmentInfo={setOpenInvestmentInfo}
                  openBeforeRetireInfo={openBeforeRetireInfo}
                  setOpenBeforeRetireInfo={setOpenBeforeRetireInfo}
                  openAfterRetireInfo={openAfterRetireInfo}
                  setOpenAfterRetireInfo={setOpenAfterRetireInfo}
                  openPopupPension={openPopupPension}
                  setOpenPopupPension={setOpenPopupPension}
                />
              </div>
            )}

            <div className=" flex justify-center gap-4 mb-10">
              {tab === 0 ? (
                <>
                  <MTButton
                    loading={loading}
                    text={"ถัดไป"}
                    color="bg-maincyan"
                    onClick={() => {
                      const formerror = checkError(formRetire);
                      const isformerror = Object.values(formerror).some(
                        (item) => item === true
                      );
                      setError(formerror);
                      if (isformerror) {
                        return;
                      }
                      setTab((prev) => prev + 1);
                      tab > 2 && setTab(0);
                      // navigate("/summarytax");
                    }}
                  />
                </>
              ) : (
                <>
                  <MTButton
                    loading={loading}
                    text="ย้อนกลับ"
                    color="bg-gray55"
                    onClick={() => {
                      const temp = { ...formRetire };
                      setRetireData(temp);
                      setTab((prev) => prev - 1);
                    }}
                  />
                  <MTButton
                    loading={loading}
                    text={"ถัดไป"}
                    color="bg-maincyan"
                    onClick={() => {
                      if (tab === 2) {
                        const formerror = checkError(formRetire);
                        const isformerror = Object.values(formerror).some(
                          (item) => item === true
                        );
                        setError(formerror);
                        if (isformerror) {
                          return;
                        } else setRetireData(formRetire);

                        navigate("/summaryresult");
                      } else {
                        if(userData?.is_premium){
                          if((formRetire?.out_come+formRetire?.out_come_variable) > formRetire?.salary){
                            alert("กรุณากรอกรายจ่ายคงที่และรายจ่ายแปรผันให้พอดีกับรายรับ")
                            return;
                          }
                        }

                        const formerror = checkError(formRetire);
                        const isformerror = Object.values(formerror).some(
                          (item) => item === true
                        );
                        setError(formerror);
                        if (isformerror) {
                          return;
                        } else setTab((prev) => prev + 1);
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </Container>

        {openSalary && (
          <DrawerSalary openSalary={openSalary} setOpenSalary={setOpenSalary} />
        )}

        {openAgeInfo && (
          <AgeInfo openAgeInfo={openAgeInfo} setOpenAgeInfo={setOpenAgeInfo} />
        )}

        {openSalaryInfo && (
          <SalaryPerYearInfo
            openSalaryInfo={openSalaryInfo}
            setOpenSalaryInfo={setOpenSalaryInfo}
          />
        )}

        {openSalaryPerYear && (
          <DrawerSalary
            openSalary={openSalaryPerYear}
            setOpenSalary={setOpenSalaryPerYear}
          />
        )}
        {openCurrent && (
          <OutcomeInfo
            openCurrent={openCurrent}
            setOpenCurrent={setOpenCurrent}
          />
        )}
        {openOutCome && (
          <OutcomePerYear
            openOutCome={openOutCome}
            setOpenOutCome={setOpenOutCome}
          />
        )}
        {openPensionInfo && (
          <PensionInfo
            openPensionInfo={openPensionInfo}
            setOpenPensionInfo={setOpenPensionInfo}
          />
        )}
        {openInvestmentInfo && (
          <InvestmentInfo
            openInvestmentInfo={openInvestmentInfo}
            setOpenInvestmentInfo={setOpenInvestmentInfo}
          />
        )}
        {openBeforeRetireInfo && (
          <BeforeRetireInfo
            openBeforeRetireInfo={openBeforeRetireInfo}
            setOpenBeforeRetireInfo={setOpenBeforeRetireInfo}
          />
        )}
        {openAfterRetireInfo && (
          <AfterRetireInfo
            openAfterRetireInfo={openAfterRetireInfo}
            setOpenAfterRetireInfo={setOpenAfterRetireInfo}
          />
        )}
      </div>
    </RetireContext.Provider>
  );
};
export default RetirePlanMain;
