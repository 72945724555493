import { useNavigate } from "react-router-dom";
import Container from "../../components/layout/Container";
import { useEffect, useState } from "react";
import { first_reload } from "../insuarance_plan/functions/firstLoad";
import { HeaderTitle } from "../../components/header/Header";
import { NavigatePath } from "../../functions/Navigate.Goback";
import { useRetireStore } from "../../_store/retireStore";
import {
  STYLE_BG_CIRCLE,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PADDING_MAIN,
  STYLE_SPACE_GRAY_MENU,
} from "../../utils/useStyle";
import { CardHeadgray } from "../../components/card/CardMain";
import numeral from "numeral";
import { ViewTableDebt } from "../debt/component/VIDebtTable";
import { useBalanceStore } from "../../_store/balanceStore";
import { MTButton } from "../../components/button/MTButton";
import FutureProfile from "./components/VIFutureProfile";
import FuturePlan from "./components/VIFuture_Plan";
import { numberWithoutComma } from "../balance/component/VIDrawerDebt.Main";
import _ from "lodash";
import { useFuturePlanStore } from "../../_store/futurePlanStore";

const FutureMain = () => {
  const navigate = useNavigate();
  const retireData = useRetireStore((state) => state.retireData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const setFuturePlanData = useFuturePlanStore(
    (state) => state.setFuturePlanData
  );
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const [isPlan, setIsPlan] = useState(false);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  // console.log(retireData, "retireData");

  const onChangeForm =
    (fleld, type = "number") =>
    (e) => {
      let value = e.target.value;
      if (type === "number") {
        value = numberWithoutComma(value);
      }

      if (_.isNaN(value)) {
        value = "";
      }

      setFuturePlanData({
        ...futurePlanData,
        [fleld]: value,
      });
    };

  return (
    <Container
      style={{
        backgroundImage: `url(/images/circle_right.png)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right top",
      }}
      className={` ${STYLE_BG_CIRCLE} `}
    >
      <div id="first_reload">
        <div className={`${STYLE_MARGIN_TOP_PARENT} mb-10 `}>
          <HeaderTitle
            title="วางแผนอนาคต"
            isGoback
            onClickGoBack={() => {
              if (isPlan && tab === 0) {
                setIsPlan(false);
              } else {
                setTab(0);
              }
            }}
          />

          {!isPlan ? (
            <FutureProfile
              age_current={retireData?.age_current}
              age_retire={retireData?.age_retire}
              age_dead={retireData?.age_dead}
              salary={retireData?.salary}
              salary_peryear={retireData?.salary_peryear}
              out_come={retireData?.out_come}
              investment_assets={retireData?.investment_assets}
              debt_short_list={balanceData?.debt_short_list}
              debt_long_list={balanceData?.debt_long_list}
              onClick={() => {
                setIsPlan(true);
                setTab(0)
              }}
              onChangeForm={onChangeForm}
            />
          ) : (
            <FuturePlan
              isPlan={isPlan}
              setIsPlan={setIsPlan}
              setTab={setTab}
              tab={tab}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default FutureMain;
