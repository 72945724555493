import { useState } from "react";
import { PopupMain } from "../../../components/popup/PopupMain";
import numeral from "numeral";
import { STYLE_PADDING_BALANCE_DRAWER } from "../../../utils/useStyle";
import { IncomeAfterCal } from "../../../functions/taxFucntion/taxHeirachy";
import { cn } from "../../../utils/useFunctions";
import {
  STYLE_PAGE,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
} from "../styles/pdf.style";
import PdfHeader from "../component/pdf.c.header";
import { PdfHeaderText } from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import { PdfCard, PdfCardGray, PdfCardWhite } from "../component/pdf.c.card";
import PdfFooter from "../component/pdf.c.footer";

const TaxDeductionPDF = ({
  page,
  total,
  totalException,
  total_name = "รวม",
  head_1 = "รายการ",
  head_2 = "จำนวนเงินที่ได้รับ (บาท)",
  title="ค่าลดหย่อนก่อนวางแผน",
  positionHead = "text-left ",
  subHead = false,
  subhead_name,
  positiontitle = "text-left",
  positionnvalue_1 = "text-right",
  positionnvalue_2 = "text-right",
  displayPart = ["Deduction", "Exception"],
  etc,
  etc_text,
  cols = 3,
  data = [],
  ExceptionLists = [],
  form = {},
}) => {
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);
  const [openPopUpDonate, setOpenPopUpDonate] = useState(false);
  const [openPopUpAnualty, setOpenPopUpAnualty] = useState(false);

  let sumDeduction = 0;

  const DonateMain = ({ amount = 0, incomeAfter = 0, limit = 0 }) => {
    return (
      <>
        <PopupMain
          img={null}
          onClick={() => {
            setOpenInfoPopUp(false);
          }}
          open={openInfoPopUp}
          text={
            <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
              <div className="font-medium">
                {"เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ"}
              </div>
              <div className="font-normal">
                {`หักลดหย่อนได้ 2 เท่าของจำนวนเงินที่จ่ายไปจริง (${numeral(
                  amount
                ).format("0,0")})
                  แต่ไม่เกินร้อยละ 10 ของ เงินได้หลังหักค่าใช้จ่ายและค่าลดหย่อน
                  (${numeral(incomeAfter).format(
                    "0,0"
                  )}) เป็นจำนวนเงินที่ได้รับสิทธิ ${numeral(limit).format(
                  "0,0"
                )} บาท`}
              </div>
            </div>
          }
        />
      </>
    );
  };

  const DonateOther = ({ amount = 0, incomeAfter = 0, limit = 0 }) => {
    return (
      <>
        <PopupMain
          img={null}
          onClick={() => {
            setOpenPopUpDonate(false);
          }}
          open={openPopUpDonate}
          text={
            <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
              <div className="font-medium">{"เงินบริจาคอื่น ๆ"}</div>
              <div className="font-normal">
                {` หักลดหย่อนได้ตามจำนวนเงินที่จ่ายไปจริง (${numeral(
                  amount
                ).format("0,0")})
                  แต่ไม่เกินร้อยละ 10
                  ของเงินได้หลังหักค่าใช้จ่ายและค่าลดหย่อนหลังจากเงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่นๆ
                  (${numeral(incomeAfter).format(
                    "0,0"
                  )}) เป็นจำนวนเงินที่ได้รับสิทธิ ${numeral(limit).format(
                  "0,0"
                )}  บาท`}
              </div>
            </div>
          }
        />
      </>
    );
  };

  const AnualtyPopup = () => {
    return (
      <>
        <PopupMain
          img={null}
          onClick={() => {
            setOpenPopUpAnualty(false);
          }}
          open={openPopUpAnualty}
          text={
            <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
              <div className="font-normal">
                {` ประกันบำนาญให้ใช้สิทธิลดหย่อนเบี้ยประกันชีวิตก่อน หากเบี้ยประกันชีวิตยังไม่เกิน 100,000 บาท ให้นำเบี้ยประกันแบบบำนาญไปหักให้เต็มจำนวน 100,000 บาท `}
              </div>
            </div>
          }
        />
      </>
    );
  };

  return (
    <>
      <div className={cn(STYLE_PAGE)}>
        <PdfHeader />
        <PdfLine />
        <PdfHeaderText
          title="วางแผนภาษี"
          icon="/images/tax_plan.svg"
          classNameIcon="w-[24px] h-[27px]"
        />
        {/* วางแผนภาษี */}
        <PdfCard className={"flex flex-col space-y-3"} title={title}>
          {/* ก่อนวางแผน ครอบครัว */}
          <PdfCardWhite className={cn("px-3")}>
            {displayPart.includes("Deduction") && (
              <>
                <div
                  className={` grid grid-cols-${cols} mb-2.5 p-2.5 gap-[5px] rounded-[10px] text-mainblue text-sm font-semibold  bg-blueopacity mt-2`}
                >
                  {cols === 2 ? (
                    ""
                  ) : (
                    <div className={""}>{"รายละเอียดค่าลดหย่อน"}</div>
                  )}
                  <div
                    className={`${positionHead} ${
                      cols === 2 ? "" : "text-right"
                    } `}
                  >
                    {head_1}
                  </div>
                  <div className="text-right font-medium">{head_2}</div>
                </div>
                {subHead ? (
                  <div className="  px-2.5 text-base font-semibold mb-1">
                    {subhead_name}
                  </div>
                ) : (
                  ""
                )}
                {data
                  .filter((item) => {
                    return item?.value > 0 || item?.value2 > 0;
                  })
                  .map((item, index) => {
                    if (ExceptionLists.includes(item?.title)) {
                      return;
                    }

                    if (item?.title === "ประกันชีวิตแบบบำนาญ") {
                      return (
                        <>
                          <AnualtyPopup />
                          <div
                            key={index}
                            className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                          >
                            <div
                              className={`${positiontitle}  font-semibold flex `}
                            >
                              <div>{item?.title}</div>
                            </div>

                            <div
                              className={`${positionnvalue_1} text-base font-semibold`}
                            >
                              {numeral(item?.value).format("0,0")}
                            </div>
                            {cols === 2 ? (
                              ""
                            ) : (
                              <div
                                className={`${positionnvalue_2} text-base font-semibold`}
                              >
                                {numeral(item?.value2).format("0,0")}
                              </div>
                            )}
                          </div>
                        </>
                      );
                    }

                    if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
                      return (
                        <div
                          key={index}
                          className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                        >
                          <div className={`${positiontitle}  font-semibold  `}>
                            {item?.title}
                          </div>

                          <div
                            className={`${positionnvalue_1} text-base font-semibold`}
                          >
                            {numeral(item?.value).format("0,0")}
                          </div>
                          {cols === 2 ? (
                            ""
                          ) : (
                            <div
                              className={`${positionnvalue_2} text-base font-semibold`}
                            >
                              {item?.value2 >= 10000
                                ? numeral(10000).format("0,0")
                                : numeral(item?.value2).format("0,0")}
                            </div>
                          )}
                        </div>
                      );
                    }

                    if (
                      item?.title ===
                      "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ"
                    ) {
                      let CalLimit = () => {
                        let tempcalLimit =
                          IncomeAfterCal(
                            form?.tax_income,
                            form?.deduction_expenses,
                            sumDeduction
                          ) * 0.1;
                        if (tempcalLimit < 0) {
                          return 0;
                        }
                        return tempcalLimit;
                      };

                      return (
                        <>
                          <DonateMain
                            amount={item?.value}
                            incomeAfter={
                              IncomeAfterCal(
                                form?.tax_income,
                                form?.deduction_expenses,
                                sumDeduction
                              ) >= 0
                                ? IncomeAfterCal(
                                    form?.tax_income,
                                    form?.deduction_expenses,
                                    sumDeduction
                                  )
                                : 0
                            }
                            limit={
                              item?.value * 2 > CalLimit()
                                ? CalLimit()
                                : item?.value * 2
                            }
                          />
                          <div
                            key={index}
                            className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                          >
                            <div
                              className={`${positiontitle}  font-semibold flex `}
                            >
                              <div>{item?.title}</div>
                            </div>

                            <div
                              className={`${positionnvalue_1} text-base font-semibold`}
                            >
                              {numeral(item?.value).format("0,0")}
                            </div>
                            {cols === 2 ? (
                              ""
                            ) : (
                              <div
                                className={`${positionnvalue_2} text-base font-semibold`}
                              >
                                {numeral(item?.value2).format("0,0")}
                              </div>
                            )}
                          </div>
                        </>
                      );
                    }

                    if (item?.title === "เงินบริจาคอื่น ๆ") {
                      let calLimit = () => {
                        let tempcalLimit =
                          IncomeAfterCal(
                            form?.tax_income,
                            form?.deduction_expenses,
                            sumDeduction
                          ) -
                          data.find(
                            (item) =>
                              item?.title ===
                              "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ"
                          )?.value2;
                        if (tempcalLimit < 0) {
                          return 0;
                        }
                        return tempcalLimit;
                      };

                      return (
                        <>
                          <DonateOther
                            amount={item?.value}
                            incomeAfter={calLimit()}
                            limit={
                              calLimit() * 0.1 < item?.value2
                                ? calLimit() * 0.1
                                : item?.value2
                            }
                          />
                          <div
                            key={index}
                            className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                          >
                            <div
                              className={`${positiontitle}  font-semibold flex `}
                            >
                              <div>{item?.title}</div>
                            </div>

                            <div
                              className={`${positionnvalue_1} text-base font-semibold`}
                            >
                              {numeral(item?.value).format("0,0")}
                            </div>
                            {cols === 2 ? (
                              ""
                            ) : (
                              <div
                                className={`${positionnvalue_2} text-base font-semibold`}
                              >
                                {numeral(item?.value2).format("0,0")}
                              </div>
                            )}
                          </div>
                        </>
                      );
                    }

                    sumDeduction += Number(item?.value2);

                    return (
                      <div
                        key={index}
                        className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                      >
                        <div className={`${positiontitle}  font-semibold  `}>
                          {item?.title}
                        </div>

                        <div
                          className={`${positionnvalue_1} text-base font-semibold`}
                        >
                          {item?.value === ""
                            ? ""
                            : numeral(item?.value).format("0,0")}
                        </div>
                        {cols === 2 ? (
                          ""
                        ) : (
                          <div
                            className={`${positionnvalue_2} text-base font-semibold`}
                          >
                            {numeral(item?.value2).format("0,0")}
                          </div>
                        )}
                      </div>
                    );
                  })}
                {(data
                  .filter((item) => {
                    return item?.value > 0 || item?.value2 > 0;
                  })
                  .map((item) => item?.title)
                  .includes("ประกันชีวิตแบบบำนาญ") ||
                  data
                    .filter((item) => {
                      return item?.value > 0 || item?.value2 > 0;
                    })
                    .map((item) => item?.title)
                    .includes("ประกันชีวิต")) && (
                  <div className="w-full text-sm ">
                    *ประกันบำนาญให้ใช้สิทธิลดหย่อนเบี้ยประกันชีวิตก่อน
                    หากเบี้ยประกันชีวิตยังไม่เกิน 100,000 บาท
                    ให้นำเบี้ยประกันแบบบำนาญไปหักให้เต็มจำนวน 100,000 บาท
                  </div>
                )}
                <div className=" grid grid-cols-2 gap-[5px] items-center text-sm px-2.5 mt-10 font-medium">
                  <div>{total_name}</div>
                  <div className=" text-right text-lg font-bold text-mainblue">
                    {`${numeral(total).format('0,0')} บาท`} 
                  </div>
                </div>
              </>
            )}
          </PdfCardWhite>

          {/* ก่อนวางแผน ประกัน */}
          <PdfCardWhite className={cn("px-3")}>
            {displayPart.includes("Exception") && (
              <>
                <div
                  className={` grid grid-cols-${cols} mb-2.5 p-2.5 gap-[5px] mt-[30px] rounded-[10px] text-mainblue text-sm font-semibold  bg-blueopacity `}
                >
                  {cols === 2 ? (
                    ""
                  ) : (
                    <div className={""}>
                      {"รายละเอียดเงินได้ที่ได้รับการยกเว้น"}
                    </div>
                  )}
                  <div
                    className={`${positionHead} ${
                      cols === 2 ? "" : "text-right"
                    } `}
                  >
                    {head_1}
                  </div>
                  <div className="text-right font-medium">{head_2}</div>
                </div>
                {data.map((item, index) => {
                  if (
                    ExceptionLists.includes(item?.title) &&
                    item?.value !== 0 &&
                    item?.value !== undefined
                  ) {
                    return (
                      <div
                        key={index}
                        className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                      >
                        <div className={`${positiontitle}  font-semibold  `}>
                          {item?.title}
                        </div>

                        <div
                          className={`${positionnvalue_1} text-base font-semibold`}
                        >
                          {item?.value === ""
                            ? ""
                            : numeral(item?.value).format("0,0")}
                        </div>
                        {cols === 2 ? (
                          ""
                        ) : (
                          <div
                            className={`${positionnvalue_2} text-base font-semibold`}
                          >
                            {numeral(item?.value2).format("0,0")}
                          </div>
                        )}
                      </div>
                    );
                  }

                  if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
                    return (
                      <div
                        key={index}
                        className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                      >
                        <div className={`${positiontitle}  font-semibold  `}>
                          {item?.title}
                        </div>

                        <div
                          className={`${positionnvalue_1} text-base font-semibold`}
                        >
                          {numeral(item?.value).format("0,0")}
                        </div>
                        {cols === 2 ? (
                          ""
                        ) : (
                          <div
                            className={`${positionnvalue_2} text-base font-semibold`}
                          >
                            {item?.value2 >= 10000
                              ? numeral(item?.value2 - 10000).format("0,0")
                              : numeral(item?.value2).format("0,0")}
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
                <div className=" grid grid-cols-2 gap-[5px] items-center text-sm px-2.5 mt-10 font-medium">
                  <div>{total_name}</div>
                  <div className=" text-right text-lg font-bold text-mainblue">
                    {`${numeral(totalException).format('0,0')} บาท`} 
                  </div>
                </div>
              </>
            )}
          </PdfCardWhite>
        </PdfCard>
        <PdfFooter page={page} />
      </div>
    </>
  );
};

export default TaxDeductionPDF;
