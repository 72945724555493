import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { cn } from "../../utils/useFunctions";
import fontSarabunTH from "./font/fontSarabunTH";
import {
  STYLE_PAGE,
  STYLE_PAGE_LANDING,
  STYLE_PDF_CONTENT,
} from "./styles/pdf.style";
import PageLanding from "./view/pdf.v.pageLanding";
import PdfPageBalananceOne from "./view/pdf.v.pageBalanceOne";
import PdfPageBalananceTwo from "./view/pdf.v.pageBalanceTwo";
import PdfPageBalananceThree from "./view/pdf.v.pageBalanceThree";
import PdfPageBalananceFour from "./view/pdf.v.pageBalanceFour";
import PdfPageBalananceSix from "./view/pdf.v.pageBalanceSix";
import PdfPageBalananceSeven from "./view/pdf.v.pageBalanceSeven";
import PdfPageFinacialOne from "./view/pdf.v.pageFinacialOne";
import PdfPageFinacialTwo from "./view/pdf.v.pageFinalcialTwo";
import PdfPageFinacialLanding from "./view/pdf.v.pageFinalcialLanding";
import PdfPageRiskOne from "./view/pdf.v.pageRiskOne";
import PdfPageFamilyOne from "./view/pdf.v.pageFamilyOne";
import PdfPageFamilyTwo from "./view/pdf.v.pageFamilyTwo";
import PdfPageFamilyThree from "./view/pdf.v.pageFamilyThree";
import PdfPageInvestOne from "./view/pdf.v.pageInvestOne";
import PdfPageSavingOne from "./view/pdf.v.pageSavingOne";
import PdfPageFutureOne from "./view/pdf.v.pageFutureOne";
import PdfPageFutureTwo from "./view/pdf.v.pageFutureTwo";
import PdfPageFutureThree from "./view/pdf.v.pageFutureThree";
import PdfPageFutureFour from "./view/pdf.v.pageFutureFour";
import PdfPageDebtOne from "./view/pdf.v.pageDebtOne";
import PdfPageDebtTwo from "./view/pdf.v.pageDebtTwo";
import PdfPageDebtThree from "./view/pdf.v.pageDebtThree";
import PdfPageTaxOne from "./view/pdf.v.pageTaxOne";
import PdfPageTaxTwo from "./view/pdf.v.pageTaxTwo";
import PdfPageTaxSix from "./view/pdf.v.pageTaxSix";
import PdfPageEducationOne from "./view/pdf.v.pageEducation";
import PdfPageRetireOne from "./view/pdf.v.pageRetireOne";
import PdfPageRetireTwo from "./view/pdf.v.pageRetireTwo";
import PdfPageRetireThree from "./view/pdf.v.pageRetireThree";
import { useEducationStore } from "../../_store/educationStore";
import PdfPageOverviewOne from "./view/pdf.v.pageOverviewOne";
import PdfPageOverviewTwo from "./view/pdf.v.pageOverviewTwo";
import PdfPageOverviewThree from "./view/pdf.v.pageOverviewThree";
import PdfPageOVIncomeOne from "./view/pdf.v.pageOVIncomeOne";
import PdfPageOVExpenseOne from "./view/pdf.v.pageOVExpenseOne";
import PdfPageOVDebtOne from "./view/pdf.v.pageOVDebtOne";
import PdfPageOVPropertyOne from "./view/pdf.v.pageOVPropertyOne";
import PdfPageAssumeOne from "./view/pdf.v.pageAssumeOne";
import PdfPageAppendix from "./view/pdf.v.pageAppendix";
import PdfPageAppendixEducationOne from "./view/pdf.v.pageAppendixEducationOne";
import PdfPageInvestTwo from "./view/pdf.v.pageInvestTwo";
import { useInvestStore } from "../../_store/investStore";
import { useBalanceStore } from "../../_store/balanceStore";
import _ from "lodash";
import PdfPageBalananceFive from "./view/pdf.v.pageBalanceFive";
import PdfPageInvestThree from "./view/pdf.v.pageInvestThree";
import PdfPageInvestFour from "./view/pdf.v.pageInvestFour";
import PdfPageInvestFive from "./view/pdf.v.pageInvestFive";
import PdfPageDebtOneSum from "./view/pdf.v.pageDebtOneSum";
import PdfPageDebtFour from "./view/pdf.v.pageDebtFour";
import PdfPageTaxThree from "./view/pdf.v.pageTaxThree";
import { useUserStore } from "../../_store/userStore";
import SalaryIncomePdf from "./premiumTax/salary.Income.pdf";
import { useTaxStore } from "../../_store/taxplanStore";
import QuitJobIncomePdf from "./premiumTax/quitjob.result.pdf";
import IncomeProfilePdf from "./premiumTax/incomeProfile.pdf";
import Income402Pdf from "./premiumTax/income402.pdf";
import { DeductionSumPDF, ExceptionSumPDF, Income403PerPage, Income404PerPage, Income404StockPerPage, Income405PerPage, Income406PerPage, Income407PerPage, Income408PerPage, TaxHeirachyPDF } from "../../functions/pdfGenerateFunction/pdfFunction";
import Income406Pdf from "./premiumTax/income406.pdf";
import Income403Pdf from "./premiumTax/income403.pdf";
import Income405Pdf from "./premiumTax/income405.pdf";
import Income407Pdf from "./premiumTax/income407.pdf";
import Income408Pdf from "./premiumTax/income408.pdf";
import Income404Pdf from "./premiumTax/income404.pdf";
import Income408StockPdf from "./premiumTax/income404Stock.pdf";
import IncomeTaxNotIncludePdf from "./premiumTax/incomeTaxNotInclude.pdf";
import IncomeRMFPdf from "./premiumTax/incomeRMF.pdf";
import IncomeSSFPdf from "./premiumTax/incomeSSF.pdf";
import TaxDeductionPDF from "./premiumTax/taxDeduction.pdf";
import { ExceptionLists } from "../../functions/taxFucntion/taxHeirachy";
import IncomeResultPDF from "./premiumTax/incomeResult.pdf";
import { FutureHomeExpensePDF } from "../../functions/pdfGenerateFunction/pdfFutureFunction";
import { useFuturePlanStore } from "../../_store/futurePlanStore";
import ExpenseFuturePDF from "./premiumFuture/expenseFuture.pdf";

const PDFRender = () => {
  const educationData = useEducationStore((state) => state.educationData);
  const balanceData = useBalanceStore((state) => state?.balanceData);
  const userData = useUserStore((state) => state.userData);
  const taxData = useTaxStore((state) => state.taxData);
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);


  const totalTaxDeduct = TaxHeirachyPDF(taxData)
    .filter((item) => {
      if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
        return item;
      }
      if (!ExceptionLists.includes(item?.title)) {
        return item;
      }
    })
    .reduce((sum, item) => {
      if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
        if (item?.value2 >= 10000) {
          return sum + 10000;
        } else {
          return sum + item?.value2;
        }
      }

      return sum + item?.value2;
    }, 0);

    const totalException = TaxHeirachyPDF(taxData)
    .filter((item) => {
      if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
        return item;
      }
      if (ExceptionLists.includes(item?.title)) {
        return item;
      }
    })
    .reduce((sum, item) => {
      if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
        if (item?.value2 >= 10000) {
          return sum + Number(item?.value2 || 0) - 10000;
        } else {
          return sum + Number(item?.value2 || 0);
        }
      }
      return sum + Number(item?.value2 || 0);
    }, 0);


  const getPageAfterEducation = (page) => {
    if (educationData?.length === 0) return page - 1;
    return page + educationData.length + 1;
  };

  const getPageAfterInvestTwo = (page) => {
    const filterPropertyInvest = balanceData?.property_invest_list?.filter(
      (item) => item?.options_property_invest_category
    );
    const groupInvest = _.groupBy(
      filterPropertyInvest,
      "options_property_invest_category"
    );
    const keys = Object.keys(groupInvest);
    if (keys?.length === 0) return page - 1;
    return page + keys?.length - 1;
  };

  const getPageAfterDebtOneSum = (page) => {
    const creditShortItems =
      balanceData?.debt_short_list?.filter(
        (item) => item?.options_debt_short === "debt_creditcard"
      ) || [];
    const shortTermShortItems =
      balanceData?.debt_short_list?.filter(
        (item) => item?.options_debt_short === "debt_shortterm"
      ) || [];
    const informalShortItems =
      balanceData?.debt_short_list?.filter(
        (item) => item?.options_debt_short === "debt_informal_shortterm"
      ) || [];
    const otherShortItems =
      balanceData?.debt_short_list?.filter(
        (item) => item?.options_debt_short === "debt_shortterm_other"
      ) || [];
    const carLongItems =
      balanceData?.debt_long_list?.filter(
        (item) => item?.options_debt_long === "debt_car"
      ) || [];
    const placeLongItems =
      balanceData?.debt_long_list?.filter(
        (item) => item?.options_debt_long === "debt_place"
      ) || [];
    const otherLongItems =
      balanceData?.debt_long_list?.filter(
        (item) => item?.options_debt_long === "debt_long_other"
      ) || [];
    const newChunkDebtItems = _.chunk(
      [
        ...creditShortItems,
        ...shortTermShortItems,
        ...informalShortItems,
        ...otherShortItems,
        ...carLongItems,
        ...placeLongItems,
        ...otherLongItems,
      ],
      2
    );
    return page + newChunkDebtItems.length - 1;
  };


  return (
    <div
      className={cn(
        " h-screen overflow-auto",
        "absolute top-0 left-[-150%] -z-20 max-w-xs"
      )}
    >
      <div id="pdf-content" className={cn(STYLE_PDF_CONTENT)}>
        {/* <PageLanding /> */}

        {/* Overview */}
        {/* <PdfPageOverviewOne page={1} />
        <PdfPageOverviewTwo page={2} />
        <PdfPageOverviewThree page={3} /> */}

        {/* ASSUME */}
        {/* <PdfPageAssumeOne page={4} /> */}

        {/* Overview Income */}
        {/* <PdfPageOVIncomeOne page={5} /> */}

        {/* Overview Expense */}
        {/* <PdfPageOVExpenseOne page={6} /> */}

        {/* Overview Property */}
        {/* <PdfPageOVPropertyOne page={7} /> */}

        {/* Overview Debt  */}
        {/* <PdfPageOVDebtOne page={8} /> */}

        {/* งบดุล */}
        {/* <PdfPageBalananceOne page={9} />
        <PdfPageBalananceTwo page={10} />
        <PdfPageBalananceThree page={11} />
        <PdfPageBalananceFour page={12} />
        <PdfPageBalananceFive page={13} />
        <PdfPageBalananceSix page={14} />
        <PdfPageBalananceSeven page={15} /> */}

        {/* อัตราส่วนทางการเงิน */}
        {/* <PdfPageFinacialOne page={16} />
        <PdfPageFinacialTwo page={17} />
        <PdfPageFinacialLanding page={18} /> */}

        {/* แผนบริหารจัดการความเสี่ยง */}
        {/* <PdfPageRiskOne page={19} /> */}

        {/* แผนทุนประกันครอบครัว */}
        {/* <PdfPageFamilyOne page={20} />
        <PdfPageFamilyTwo page={21} />
        <PdfPageFamilyThree page={22} /> */}

        {/* แผนเกษียณ */}
        {/* <PdfPageRetireOne page={23} />
        <PdfPageRetireTwo page={24} />
        <PdfPageRetireThree page={25} /> */}

        {/* วางแผนภาษี */}
        {/* {userData?.is_premium ? (
          <>
            <SalaryIncomePdf page={26} userData={userData} taxData={taxData} />
            {taxData?.tax_QuitJob?.isQuitJob &&
            <QuitJobIncomePdf page={26} userData={userData} taxData={taxData} />
            }
          </>
        ) : (
          <PdfPageTaxOne page={26} userData={userData} />
        )} */}
        {/* <PdfPageTaxTwo page={27} />
        <PdfPageTaxThree page={28} />
        <PdfPageTaxSix page={29} /> */}

        {/* สัดส่วนการลงทุน */}
        {/* <PdfPageInvestOne page={30} />
        <PdfPageInvestTwo page={31} />
        <PdfPageInvestThree page={getPageAfterInvestTwo(32)} />
        <PdfPageInvestFour page={getPageAfterInvestTwo(33)} />
        <PdfPageInvestFive page={getPageAfterInvestTwo(34)} /> */}

        {/* แผนการศึกษาบุตร */}
        {/* <PdfPageEducationOne page={getPageAfterInvestTwo(35)} /> */}

        {/* แผนการออม */}
        {/* <PdfPageSavingOne
          page={getPageAfterEducation(getPageAfterInvestTwo(36))}
        /> */}

        {/* แผนอนาคต */}
        {/* <PdfPageFutureOne
          page={getPageAfterEducation(getPageAfterInvestTwo(37))}
        />
        <PdfPageFutureTwo
          page={getPageAfterEducation(getPageAfterInvestTwo(38))}
        />
        <PdfPageFutureThree
          page={getPageAfterEducation(getPageAfterInvestTwo(39))}
        />
        <PdfPageFutureFour
          page={getPageAfterEducation(getPageAfterInvestTwo(40))}
        /> */}

        {/* แผนจัดการหนี้สิน */}
        {/* <PdfPageDebtOne
          page={getPageAfterEducation(getPageAfterInvestTwo(41))}
        />
        <PdfPageDebtOneSum
          page={getPageAfterEducation(getPageAfterInvestTwo(42))}
        />
        <PdfPageDebtTwo
          page={getPageAfterDebtOneSum(
            getPageAfterEducation(getPageAfterInvestTwo(43))
          )}
        />
        <PdfPageDebtThree
          page={getPageAfterDebtOneSum(
            getPageAfterEducation(getPageAfterInvestTwo(44))
          )}
        />
        <PdfPageDebtFour
          page={getPageAfterDebtOneSum(
            getPageAfterEducation(getPageAfterInvestTwo(45))
          )}
        /> */}

        {/* ภาคผนวก */}
        {/* <PdfPageAppendix
          page={getPageAfterEducation(getPageAfterInvestTwo(46))}
        /> */}

        {/* ตารางการศึกษา */}
        {/* <PdfPageAppendixEducationOne
          page={getPageAfterEducation(getPageAfterInvestTwo(47))}
        /> */}

<PageLanding />

        <PdfPageOverviewOne page={1} />
        <PdfPageOverviewTwo page={2} />
        <PdfPageOverviewThree page={3} />

        <PdfPageAssumeOne page={4} />

        <PdfPageOVIncomeOne page={5} />

        <PdfPageOVExpenseOne page={6} />

        <PdfPageOVPropertyOne page={7} />

        <PdfPageOVDebtOne page={8} />

        <PdfPageBalananceOne page={9} />
        <PdfPageBalananceTwo page={10} />
        <PdfPageBalananceThree page={11} />
        <PdfPageBalananceFour page={12} />
        <PdfPageBalananceFive page={13} />
        <PdfPageBalananceSix page={14} />
        <PdfPageBalananceSeven page={15} />

        <PdfPageFinacialOne page={16} />
        <PdfPageFinacialTwo page={17} />
        <PdfPageFinacialLanding page={18} />

        <PdfPageRiskOne page={19} />

        <PdfPageFamilyOne page={20} />
        <PdfPageFamilyTwo page={21} />
        <PdfPageFamilyThree page={22} />

        <PdfPageRetireOne page={23} />
        <PdfPageRetireTwo page={24} />
        <PdfPageRetireThree page={25} />

        {userData?.is_premium ? (
          <>
            <IncomeProfilePdf
              page={26}
              taxData={taxData?.RMF_Profit}
              userData={userData}
            />
            <SalaryIncomePdf page={26} userData={userData} taxData={taxData} />
            {taxData?.tax_QuitJob?.isQuitJob && (
              <QuitJobIncomePdf
                page={26}
                userData={userData}
                taxData={taxData}
              />
            )}
            <Income402Pdf page={26} userData={userData} taxData={taxData} />
            {Income406PerPage(taxData?.freeJob)?.map((item, index) => {
              return (
                <Income406Pdf
                  page={26}
                  isFirst={index === 0}
                  isLast={
                    Income406PerPage(taxData?.freeJob)?.length === index + 1
                  }
                  userData={userData}
                  taxData={taxData}
                  datalist={item}
                />
              );
            })}
            {Income403PerPage(taxData?.tax_403).map((item, index) => {
              return (
                <Income403Pdf
                  page={26}
                  isFirst={index === 0}
                  isLast={
                    Income403PerPage(taxData?.tax_403)?.length === index + 1
                  }
                  userData={userData}
                  taxData={taxData}
                  datalist={item}
                />
              );
            })}
            {Income405PerPage(taxData?.tax_405)?.map((item, index) => {
              return (
                <Income405Pdf
                  page={26}
                  isFirst={index === 0}
                  isLast={
                    Income405PerPage(taxData?.tax_405)?.length === index + 1
                  }
                  userData={userData}
                  taxData={taxData}
                  datalist={item}
                />
              );
            })}
            {Income407PerPage(taxData?.tax_407)?.map((item, index) => {
              return (
                <Income407Pdf
                  page={26}
                  isFirst={index === 0}
                  isLast={
                    Income407PerPage(taxData?.tax_407)?.length === index + 1
                  }
                  userData={userData}
                  taxData={taxData}
                  datalist={item}
                />
              );
            })}
            {Income408PerPage(taxData?.tax_408)?.map((item, index) => {
              return (
                <Income408Pdf
                  page={26}
                  isFirst={index === 0}
                  isLast={
                    Income408PerPage(taxData?.tax_408)?.length === index + 1
                  }
                  userData={userData}
                  taxData={taxData}
                  datalist={item}
                />
              );
            })}
            {Income404PerPage(taxData?.tax_404)?.map((item, index) => {
              return (
                <Income404Pdf
                  page={26}
                  isFirst={index === 0}
                  isLast={false}
                  userData={userData}
                  taxData={taxData}
                  datalist={item}
                />
              );
            })}
            {Income404StockPerPage(taxData?.tax_404_stock)?.map(
              (item, index) => {
                return (
                  <Income408StockPdf
                    page={26}
                    isFirst={index === 0}
                    isLast={
                      Income408PerPage(taxData?.tax_404_stock)?.length ===
                      index + 1
                    }
                    userData={userData}
                    taxData={taxData}
                    datalist={item}
                    allList={taxData?.tax_404_stock}
                  />
                );
              }
            )}
            {taxData?.income_not_include_tax > 0 && (
              <IncomeTaxNotIncludePdf page={26} taxData={taxData} />
            )}
            <IncomeRMFPdf page={26} taxData={taxData?.RMF_Profit} />
            <IncomeSSFPdf page={26} taxData={taxData?.SSF_Profit} />

            <TaxDeductionPDF
              page={27}
              data={TaxHeirachyPDF(taxData).sort((a, b) => a.sort - b.sort)}
              total={totalTaxDeduct}
              totalException={totalException}
            />

            <TaxDeductionPDF
              page={27}
              title={"ค่าลดหย่อนหลังวางแผน"}
              data={TaxHeirachyPDF(taxData, () => {}, "four").sort(
                (a, b) => a.sort - b.sort
              )}
              total={DeductionSumPDF(taxData)}
              totalException={ExceptionSumPDF(taxData)}
            />

            {/* <PdfPageTaxTwo page={27} />
            <PdfPageTaxThree page={28} /> */}
            <IncomeResultPDF page={29} taxData={taxData} userData={userData} />

            <PdfPageInvestOne page={30} />
            <PdfPageInvestTwo page={31} />
            <PdfPageInvestThree page={32} />
            <PdfPageInvestFour page={33} />
            <PdfPageInvestFive page={34} />

            <PdfPageEducationOne page={35} />

            <PdfPageSavingOne page={36} />

            {/* อนาคตPremium */}
            {FutureHomeExpensePDF(futurePlanData?.expenese_home_items)?.map(
              (item, index) => {
                return (
                  <ExpenseFuturePDF
                    page={26}
                    isFirst={index === 0}
                    isLast={
                      FutureHomeExpensePDF(futurePlanData?.expenese_home_items)?.length === index + 1
                    }
                    userData={userData}
                    taxData={taxData}
                    datalist={item}
                  />
                );
              }
            )}
            <PdfPageFutureTwo page={38} />
            <PdfPageFutureThree page={39} />
            <PdfPageFutureFour page={40} />

            <PdfPageDebtOne page={41} />
            <PdfPageDebtOneSum page={42} />
            <PdfPageDebtTwo page={43} />
            <PdfPageDebtThree page={44} />
            <PdfPageDebtFour page={45} />

            <PdfPageAppendix page={46} />

            <PdfPageAppendixEducationOne page={47} />
          </>
        ) : (
          <>
            <PdfPageTaxOne page={26} userData={userData} />
            <PdfPageTaxTwo page={27} />
            <PdfPageTaxThree page={28} />
            <PdfPageTaxSix page={29} />

            <PdfPageInvestOne page={30} />
            <PdfPageInvestTwo page={31} />
            <PdfPageInvestThree page={32} />
            <PdfPageInvestFour page={33} />
            <PdfPageInvestFive page={34} />

            <PdfPageEducationOne page={35} />

            <PdfPageSavingOne page={36} />

            <PdfPageFutureOne page={37} />
            <PdfPageFutureTwo page={38} />
            <PdfPageFutureThree page={39} />
            <PdfPageFutureFour page={40} />

            <PdfPageDebtOne page={41} />
            <PdfPageDebtOneSum page={42} />
            <PdfPageDebtTwo page={43} />
            <PdfPageDebtThree page={44} />
            <PdfPageDebtFour page={45} />

            <PdfPageAppendix page={46} />

            <PdfPageAppendixEducationOne page={47} />
          </>
        )}
      </div>
    </div>
  );
};

export default PDFRender;
