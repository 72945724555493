import React from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import numeral from "numeral";
import { OPTIONS_TYPE_CAR, OPTIONS_TYPE_PLACE } from "../functions";

const ListInsurance = ({ InsuranceData = {}, showTotal = true }) => {
  
    return (
      <div className="mb-1.5">
        <div className=" border-b border-gray60 pb-3 mb-3"></div>
        <div className="flex flex-col items-center gap-2.5">
          <div className="flex justify-between p-2 bg-secoundblue rounded-[10px] w-full text-sm text-mainblue font-medium">
            <div>กรมธรรม์เลขที่</div>
            <div>มูลค่าเวนคืน</div>
          </div>
          <>
              <div className="w-full pb-2.5 border-b border-gray60">
                  <div className="flex flex-col gap-1.5">
                    {InsuranceData?.expenses_insuarant_lifes?.map((item, index) => {
                        return (
                            <div className="flex flex-col">
                            <div
                              key={index}
                              className="flex justify-between items-center text-xs font-normal"
                            >
                              <div>
                                {item?.insurance_policy_number}{" "}
                              </div>
                              <div className="text-mainblue">
                                {numeral(item?.insurance_policy_cash_surrender).format("0,0")} บาท
                              </div>
                            </div>
                          </div>
                        );
                        
                    })}
                  </div>
                </div>
            </>
        </div>
      </div>
    );
}

export default ListInsurance