import { Checkbox } from "@material-tailwind/react";
import { HeadtitleCard } from "../../../components/header/Header";
import { RadioMain } from "../../../components/radio/Radio.Main";
import { STYLE_CARD_BG } from "../../../utils/useStyle";

export const CardTest = ({
  question = "question",
  choice1 = "choice1",
  choice2 = "choice2",
  choice3 = "choice3",
  choice4 = "choice4",
  name = "name",
  name1 = "name1",
  name2 = "name2",
  name3 = "name3",
  name4 = "name4",
  value1 = "value1",
  value2 = "value2",
  value3 = "value3",
  value4 = "value4",
  onChange1,
  onChange2,
  onChange3,
  onChange4,
  checked1,
  checked2,
  checked3,
  checked4,
  cantwochoice = false,
  sub = false,
}) => {
  return (
    <div className=" mt-2.5">
      <div className=" text-black font-medium mb-2.5">{question}</div>
      {sub && (
        <div className="text-red-500 text-sm -mt-1 mb-2.5 font-medium">
          {sub}
        </div>
      )}

      {/*---- เลือกได้มากกว่า 2 ข้อ ----*/}
      {cantwochoice ? (
        <div className="flex flex-col gap-2.5">
          {/* Choice 1 */}
          <label>
            <div className=" bg-white pl-2 pr-5 py-1 rounded-[10px]">
              {/* <RadioMain
                label={
                  <div className=" text-mainblue font-medium">{choice1}</div>
                }
                name={name1}
                value={value1}
                onClick={onChange1}
                checked={checked1}
              /> */}
              <Checkbox
                name={name1}
                value={value1}
                onClick={onChange1}
                size={"sm"}
                className="p-0"
                color="cyan"
                checked={checked1}
                label={
                  <div className=" text-mainblue font-medium">{choice1} </div>
                }
                ripple={false}
              />
            </div>
          </label>
          {/* Choice 2 */}
          <label>
            <div className=" bg-white pl-2 pr-5 py-1 rounded-[10px]">
              {/* <RadioMain
                label={
                  <div className=" text-mainblue font-medium">{choice2}</div>
                }
                name={name2}
                value={value2}
                onClick={onChange2}
                checked={checked2}
              /> */}
              <Checkbox
                name={name2}
                value={value2}
                onClick={onChange2}
                checked={checked2}
                size={"sm"}
                className="p-0"
                color="cyan"
                label={
                  <div className=" text-mainblue font-medium">{choice2}</div>
                }
                ripple={false}
              />
            </div>
          </label>
          {/* Choice 3 */}
          <label>
            <div className=" bg-white pl-2 pr-5 py-1 rounded-[10px]">
              {/* <RadioMain
                label={
                  <div className=" text-mainblue font-medium">{choice3}</div>
                }
                name={name3}
                value={value3}
                onClick={onChange3}
                checked={checked3}
              /> */}
              <Checkbox
                label={
                  <div className=" text-mainblue font-medium">{choice3}</div>
                }
                name={name3}
                value={value3}
                onClick={onChange3}
                checked={checked3}
                size={"sm"}
                className="p-0"
                color="cyan"
                ripple={false}
              />
            </div>
          </label>
          {/* Choice 4 */}
          <label>
            <div className=" bg-white pl-2 pr-5 py-1 rounded-[10px]">
              {/* <RadioMain
                label={
                  <div className=" text-mainblue font-medium">{choice4}</div>
                }
                name={name4}
                value={value4}
                onClick={onChange4}
                checked={checked4}
              /> */}

              <Checkbox
                label={
                  <div className=" text-mainblue font-medium">{choice4}</div>
                }
                name={name4}
                value={value4}
                onClick={onChange4}
                checked={checked4}
                size={"sm"}
                className="p-0"
                color="cyan"
                ripple={false}
              />
            </div>
          </label>
        </div>
      ) : (
        <div className="flex flex-col gap-2.5">
          {/*---- เลือกได้ 1 ข้อ ----*/}
          {/* Choice 1 */}
          <label>
            <div className=" bg-white pl-2 pr-5 py-1 rounded-[10px]">
              <RadioMain
                label={
                  <div className=" text-mainblue font-medium">{choice1}</div>
                }
                name={name}
                value={value1}
                onChange={onChange1}
                checked={checked1}
                disabledDesc={true}
              />
            </div>
          </label>
          {/* Choice 2 */}
          <label>
            <div className=" bg-white pl-2 pr-5 py-1 rounded-[10px]">
              <RadioMain
                label={
                  <div className=" text-mainblue font-medium">{choice2}</div>
                }
                name={name}
                value={value2}
                onChange={onChange2}
                checked={checked2}
                disabledDesc={true}
              />
            </div>
          </label>
          {/* Choice 3 */}
          <label>
            <div className=" bg-white pl-2 pr-5 py-1 rounded-[10px]">
              <RadioMain
                label={
                  <div className=" text-mainblue font-medium">{choice3}</div>
                }
                name={name}
                value={value3}
                onChange={onChange3}
                checked={checked3}
                disabledDesc={true}
              />
            </div>
          </label>
          {/* Choice 4 */}
          <label>
            <div className=" bg-white pl-2 pr-5 py-1 rounded-[10px]">
              <RadioMain
                label={
                  <div className=" text-mainblue font-medium">{choice4}</div>
                }
                name={name}
                value={value4}
                onChange={onChange4}
                checked={checked4}
                disabledDesc={true}
              />
            </div>
          </label>
        </div>
      )}
    </div>
  );
};

export const CardResult = ({
  title = "title",
  panel = "panel",
  icon = "icon",
  border = "border",
  mb = "mb",
  margin = "margin",

  onClick = () => {},
}) => {
  return (
    <div className={STYLE_CARD_BG}>
      <HeadtitleCard
        stylehead="text-mainblue font-semibold"
        width=" w-10/12"
        title={title}
        onClick={onClick}
        icon={icon}
        border={border}
        mb={mb}
      />
      <div className={`px-4`}>{panel}</div>
    </div>
  );
};
