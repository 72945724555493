import React, { useEffect, useMemo, useState } from "react";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import numeral from "numeral";
import { CalcAll_Expense } from "../../../functions/futurePlan/futurePlanFunction";
import { useBalanceStore } from "../../../_store/balanceStore";
import { calDebtCreditCard } from "../../../utils/useFunctions";
import { useEducationStore } from "../../../_store/educationStore";
import { key_under_education } from "../../education/functions/options";

const TABLE_HEADER_STYLE =
  " px-[16px] pt-[14px] pb-[6px] break-keep whitespace-nowrap text-[#18488A] text-base font-semibold font-IBMPlexSansThai";

const FutureResultTable = ({ tab = 0, displaySumary }) => {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const educationData = useEducationStore((state) => state.educationData);

  // const [tempFutureData, setFuteureDate] = useState(futurePlanData);

  const tempFutureData = useMemo(() => {
    let copyFuturePlanData = futurePlanData;
    let calExpense = CalcAll_Expense(
      futurePlanData,
      balanceData,
      educationData,
      key_under_education
    );

    copyFuturePlanData = { ...copyFuturePlanData, cash_flow_items: calExpense };
    return copyFuturePlanData;
  }, []);

  const merge_keys = useMemo(() => {
    let income_keys = tempFutureData.cash_flow_keys?.filter((cash_flow_key) => {
      return cash_flow_key.includes("income");
    });
    let expenese_keys = tempFutureData.cash_flow_keys?.filter(
      (cash_flow_key) => {
        return cash_flow_key.includes("expenese");
      }
    );

    let merge_income_keys = [
      ...(income_keys || []),
      "income_peryear",
      "remain_accumulate",
      "pension_peryear",
    ];
    let merge_expenese_keys = [
      ...(expenese_keys || []),
      "outcome_peryear",
      "out_come_stable",
    ];
    // let merge_keys = [...merge_income_keys, ...merge_expenese_keys];
    let merge_keys = [
      "income_peryear",
      "outcome_total",
      "remain",
      "income_investment",
      "remain_accumulate",
      "outcome_peryear",
      "out_come_stable",
      "debt_short_list",
      "debt_creditcard",
      "debt_shortterm",
      "debt_informal_shortterm",
      "debt_shortterm_other",
      "debt_car",
      "debt_place",
      "debt_long_other",
      "expenese_other_items",
      "expenese_car_items",
      "expenese_home_items",
      "expenese_children_items",
      "expenese_travel_items",
      "expenese_collection_items",
      "expenese_children_education",
      "income_other_items",
      "income_business_items",
      "income_property_items",
      "income_legacy_items",
    ];

    return { merge_keys, merge_expenese_keys, merge_income_keys };
  }, [futurePlanData]);

  const merge_label_key = {
    expenese_home_items: "บ้าน",
    expenese_car_items: "ซื้อรถ",
    expenese_children_items: "ลูก",
    expenese_travel_items: "ท่องเที่ยว",
    expenese_collection_items: "ของสะสม",
    expenese_other_items: "รายจ่ายอื่นๆ",
    income_legacy_items: "รายได้จากมรดก",
    income_business_items: "ขายธุรกิจ",
    income_property_items: "รายได้จากอสังหาริม",
    income_other_items: "รายได้อื่นๆ",
    income_peryear: "รายได้",
    remain_accumulate: "สินทรัพย์ลงทุน",
    outcome_peryear: "จ่ายผันแปร",
    out_come_stable: "จ่ายคงที่ (ไม่รวมหนี้)",
    outcome_total: "รวมรายจ่ายต่อปี ",
    pension_peryear: "บำนาญต่อปี",
    remain: "เงินคงเหลือ",
    income_investment: "ผลตอบแทนการลงทุน ",
    debt_shortterm_other: "หนี้ดอกลอย",
    debt_informal_shortterm: "หนี้นอกระบบ",
    debt_shortterm: "หนี้ระยะสั้น",
    debt_creditcard: "หนี้บัตรเครดิต",
    expenese_children_education: "การศึกษาบุตร",
    debt_car: "ยานพาหนะ",
    debt_place: "ที่อยู่อาศัย",
    debt_long_other: "หนี้ระยะยาวอื่นๆ",
  };


  return (
    <div className="w-fit max-w-full ">
      <div className=" flex w-fit max-w-full min-h-[300px] rounded-[20px] overflow-hidden ">
        <div className="flex flex-col">
          <div className={TABLE_HEADER_STYLE + " bg-[#F4F2F3] "}> อายุ </div>
          {tempFutureData?.cash_flow_items?.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  TABLE_HEADER_STYLE +
                  " text-center h-[44px] bg-[#16AA37]/[0.2] border-b-[1px] border-b-[#16AA37] "
                }
              >
                {item.age}
              </div>
            );
          })}
        </div>
        <div className="flex overflow-x-scroll ">
          {merge_keys?.merge_keys?.length > 0 &&
            merge_keys?.merge_keys?.map((item, index) => {
              //----------ซื้อรถ------------
              if (item === "expenese_car_items") {
                return tempFutureData?.expenese_car_items?.map(
                  (itemA, indexA) => {
                    const valuePeriod = (index) => {
                      if (itemA?.year > index) {
                        return itemA?.sum_invest_five;
                      }
                      if (itemA?.year + itemA?.period_year > index) {
                        return itemA?.pay_per_year * 12;
                      }
                      return 0;
                    };

                    return (
                      <div key={index} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {/* ซื้อรถ */}
                          {itemA?.title}
                        </div>
                        {tempFutureData?.cash_flow_items.map(
                          (cash_flow_items, index2) => {
                            return (
                              <div
                                key={index2}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(valuePeriod(index2))?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                );
              }

              //----------รายจ่าย ซื้อบ้าน------------
              if (item === "expenese_home_items") {
                return tempFutureData?.expenese_home_items?.map(
                  (itemA, indexA) => {
                    // console.log("index",index)
                    const valuePeriod = (index) => {
                      if (itemA?.year > index) {
                        return itemA?.sum_invest_five;
                      }
                      if (itemA?.year + itemA?.period_year > index) {
                        return itemA?.pay_per_year * 12;
                      }
                      return 0;
                    };

                    return (
                      <div key={index} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {itemA?.title}
                        </div>
                        {tempFutureData?.cash_flow_items.map(
                          (cash_flow_items, index2) => {
                            return (
                              <div
                                key={index2}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(valuePeriod(index2))?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                );
              }

              //----------รายจ่าย ลูก------------
              if (item === "expenese_children_items") {
                return tempFutureData?.expenese_children_items?.map(
                  (itemA, indexA) => {
                    const valuePeriod = (index) => {
                      if (itemA?.ishasEducation) {
                        if (itemA?.year > index) {
                          return itemA?.sum_invest_five;
                        }

                        if (
                          itemA?.year - 1 < index &&
                          itemA?.year + 19 > index
                        ) {
                          let eduExpense = Math.ceil(
                            key_under_education?.[itemA?.education_under]
                              ?.default_value * Math.pow(1.06, index - 1)
                          );
                          return eduExpense;
                        }

                        return 0;
                      }

                      if (itemA?.year - 1 > index) {
                        return itemA?.sum_invest_five;
                      }

                      return 0;
                    };

                    return (
                      <div key={index} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {/* ซื้อรถ */}
                          {itemA?.title}
                        </div>
                        {tempFutureData?.cash_flow_items.map(
                          (cash_flow_items, index2) => {
                            return (
                              <div
                                key={index2}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(valuePeriod(index2))?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                );
              }

              //----------รายจ่าย ท่องเที่ยว------------
              if (item === "expenese_travel_items") {
                return tempFutureData?.expenese_travel_items?.map(
                  (itemA, indexA) => {
                    const valuePeriod = (index) => {
                      if (itemA?.year > index) {
                        return itemA?.sum_invest_five;
                      }

                      return 0;
                    };

                    return (
                      <div key={index} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {/* ซื้อรถ */}
                          {itemA?.title}
                        </div>
                        {tempFutureData?.cash_flow_items.map(
                          (cash_flow_items, index2) => {
                            return (
                              <div
                                key={index2}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(valuePeriod(index2))?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                );
              }

              //----------รายจ่าย ของสะสม------------
              if (item === "expenese_collection_items") {
                return tempFutureData?.expenese_collection_items?.map(
                  (itemA, indexA) => {
                    const valuePeriod = (index) => {
                      if (itemA?.year > index) {
                        return itemA?.sum_invest_five;
                      }

                      return 0;
                    };

                    return (
                      <div key={index} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {/* ซื้อรถ */}
                          {itemA?.title}
                        </div>
                        {tempFutureData?.cash_flow_items.map(
                          (cash_flow_items, index2) => {
                            return (
                              <div
                                key={index2}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(valuePeriod(index2))?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                );
              }

              //----------รายจ่าย อื่นๆ------------
              if (item === "expenese_other_items") {
                return tempFutureData?.expenese_other_items?.map(
                  (itemA, indexA) => {
                    const valuePeriod = (index) => {
                      if (itemA?.year > index) {
                        return itemA?.sum_invest_five;
                      }

                      return 0;
                    };

                    return (
                      <div key={index} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {/* ซื้อรถ */}
                          {itemA?.title}
                        </div>
                        {tempFutureData?.cash_flow_items.map(
                          (cash_flow_items, index2) => {
                            return (
                              <div
                                key={index2}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(valuePeriod(index2))?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                );
              }

              //----------"การศึกษาบุตร"------------
              if (
                item === "expenese_children_education" &&
                futurePlanData?.expenese_children_education?.length > 0
              ) {
                return educationData?.map((item2, index) => {
                  return (
                    <div key={index} className="flex flex-col">
                      <div
                        className={
                          TABLE_HEADER_STYLE +
                          " text-center h-[44px] bg-[#F4F2F3] "
                        }
                      >
                        {item2?.name ? item2?.name : merge_label_key[item]}
                      </div>
                      {tempFutureData?.cash_flow_items?.map(
                        (cash_flow_items, index2) => {
                          return (
                            <div
                              key={index2}
                              className={
                                TABLE_HEADER_STYLE +
                                " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                              }
                            >
                              {numeral(
                                item2?.education_items[index2]
                                  ?.sum_under_education
                              )?.format("0,0.00")}
                            </div>
                          );
                        }
                      )}
                    </div>
                  );
                });
              }

              // ======== รายได้ =============================

              //-------รายได้อื่นๆ----------
              if (item === "income_other_items") {
                return tempFutureData?.income_other_items?.map(
                  (item2, index) => {
                    const PeriodIncome = (indexTemp) => {
                      if (item2?.year > indexTemp) {
                        return 0;
                      }
                      if (
                        item2?.year <= indexTemp &&
                        item2?.year + item2?.year_rent > indexTemp
                      ) {
                        return item2?.amount_perYear;
                      }
                      return 0;
                    };
                    return (
                      <div key={index} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {item2?.title ? item2?.title : merge_label_key[item]}
                        </div>
                        {tempFutureData?.cash_flow_items.map(
                          (cash_flow_items, index2) => {
                            return (
                              <div
                                key={index2}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(PeriodIncome(index2))?.format(
                                  "0,0.00"
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                );
              }

              //----------รายได้จากอสังหาริม------------
              if (item === "income_property_items") {
                return tempFutureData?.income_property_items?.map(
                  (item2, index) => {
                    const PeriodIncome = (indexTemp) => {
                      if (item2?.is_rent) {
                        if (item2?.year > indexTemp) {
                          return 0;
                        }
                        if (
                          item2?.year <= indexTemp &&
                          item2?.year + item2?.year_rent > indexTemp
                        ) {
                          return item2?.amount_perYear;
                        }
                        return 0;
                      }

                      return indexTemp === item2?.year ? item2?.amount : 0;
                    };
                    return (
                      <div key={index} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {item2?.title ? item2?.title : merge_label_key[item]}
                        </div>
                        {tempFutureData?.cash_flow_items.map(
                          (cash_flow_items, index2) => {
                            return (
                              <div
                                key={index2}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(PeriodIncome(index2))?.format(
                                  "0,0.00"
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                );
              }

              //----------ขายธุรกิจ------------
              if (item === "income_business_items") {
                // console.log("merge_label_key",merge_label_key[item])
                return tempFutureData?.income_business_items?.map(
                  (item2, index) => {
                    return (
                      <div key={index} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {item2?.title ? item2?.title : merge_label_key[item]}
                        </div>
                        {tempFutureData?.cash_flow_items.map(
                          (cash_flow_items, index2) => {
                            return (
                              <div
                                key={index2}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(
                                  index2 === item2?.year ? item2?.amount : 0
                                )?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                );
              }

              //----------"มรดก"------------
              if (item === "income_legacy_items") {
                return tempFutureData?.income_legacy_items?.map(
                  (item2, index) => {
                    return (
                      <div key={index} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {item2?.title ? item2?.title : merge_label_key[item]}
                        </div>
                        {tempFutureData?.cash_flow_items.map(
                          (cash_flow_items, index2) => {
                            return (
                              <div
                                key={index2}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(
                                  index2 === item2?.year ? item2?.amount : 0
                                )?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                );
              }

              // ========หนี้=============================

              // หนี้ดอกลอย
              if (item === "debt_shortterm_other") {
                return balanceData?.debt_short_list
                  ?.find(
                    (item) =>
                      item?.options_debt_short === "debt_shortterm_other"
                  )
                  ?.short_term_others?.map((item2, index2) => {
                    const calcPeriodInterest = (index) => {
                      let year = Math.floor(item2?.period / 12);
                      let lastYearMonth = item2?.period % 12;

                      if (index >= Math.ceil(item2?.period / 12)) {
                        return 0;
                      }
                      if (index < year) {
                        return 12;
                      } else {
                        return lastYearMonth;
                      }
                    };
                    return (
                      <div key={index2} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {item2?.creditor_name
                            ? item2?.creditor_name
                            : merge_label_key[item]}
                        </div>
                        {tempFutureData?.cash_flow_items?.map(
                          (cash_flow_items, index3) => {
                            return (
                              <div
                                key={index3}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(
                                  item2?.period === "Infinity"
                                    ? item2?.pay_permonth * 12
                                    : item2?.pay_permonth *
                                        calcPeriodInterest(index3)
                                )?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  });
              }

              // หนี้ีนอกระบบ
              if (item === "debt_informal_shortterm") {
                return balanceData?.debt_short_list
                  ?.find(
                    (item) =>
                      item?.options_debt_short === "debt_informal_shortterm"
                  )
                  ?.informal_short_terms?.map((item2, index2) => {
                    const calcPeriodInterest = (index) => {
                      let year = Math.floor(item2?.number_of_days / 365);
                      let lastYearMonth = item2?.number_of_days % 30;

                      if (index >= Math.ceil(item2?.number_of_days / 365)) {
                        return 0;
                      }
                      if (index < year) {
                        return 365;
                      } else {
                        return lastYearMonth;
                      }
                    };
                    return (
                      <div key={index2} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {item2?.creditor_name
                            ? item2?.creditor_name
                            : merge_label_key[item]}
                        </div>
                        {tempFutureData?.cash_flow_items?.map(
                          (cash_flow_items, index3) => {
                            return (
                              <div
                                key={index3}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(
                                  item2?.period === "Infinity"
                                    ? item2?.pay_permonth * 12
                                    : item2?.pay_permonth *
                                        calcPeriodInterest(index3)
                                )?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  });
              }

              // เงินกู้ระยะสั้น
              if (item === "debt_shortterm") {
                return balanceData?.debt_short_list
                  ?.find(
                    (item) => item?.options_debt_short === "debt_shortterm"
                  )
                  ?.short_terms?.map((item2, index2) => {
                    const calcPeriodInterest = (index) => {
                      let year = Math.floor(item2?.period / 12);
                      let lastYearMonth = item2?.period % 12;

                      if (index >= Math.ceil(item2?.period / 12)) {
                        return 0;
                      }
                      if (index < year) {
                        return 12;
                      } else {
                        return lastYearMonth;
                      }
                    };
                    return (
                      <div key={index2} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {item2?.creditor_name
                            ? item2?.creditor_name
                            : merge_label_key[item]}
                        </div>
                        {tempFutureData?.cash_flow_items?.map(
                          (cash_flow_items, index3) => {
                            return (
                              <div
                                key={index3}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(
                                  item2?.period === "Infinity"
                                    ? item2?.pay_permonth * 12
                                    : item2?.pay_permonth *
                                        calcPeriodInterest(index3)
                                )?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  });
              }

              // บัตรเครดิต
              if (item === "debt_creditcard") {
                return balanceData?.debt_short_list
                  ?.find(
                    (item) => item?.options_debt_short === "debt_creditcard"
                  )
                  ?.credit_cards?.map((item2, index2) => {
                    const calcPeriodInterest = (index) => {
                      let period =
                        calDebtCreditCard(item2)?.creditor_items?.length;
                      let year = Math.floor(period / 12);
                      let lastYearMonth = period % 12;

                      if (index >= Math.ceil(period / 12)) {
                        return 0;
                      }
                      if (index < year) {
                        return 12;
                      } else {
                        return lastYearMonth;
                      }
                    };
                    return (
                      <div key={index2} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {item2?.creditor_name
                            ? item2?.creditor_name
                            : merge_label_key[item]}
                        </div>
                        {tempFutureData?.cash_flow_items?.map(
                          (cash_flow_items, index3) => {
                            // console.log("item2?.creditor_name",calcPeriodInterest(index3))

                            return (
                              <div
                                key={index3}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(
                                  item2?.type_payment === "pay_full" &&
                                    index3 === 0
                                    ? item2?.pay_peryear
                                    : item2?.pay_permonth *
                                        calcPeriodInterest(index3)
                                )?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  });
              }

              // ========หนี้ระยะยาว=============================
              // หนี้ระยะยาว รถ
              if (item === "debt_car") {
                return balanceData?.debt_long_list
                  ?.find((item) => item?.options_debt_long === "debt_car")
                  ?.debt_long_cars?.map((item2, index2) => {
                    const calcPeriodInterest = (index) => {
                      let year = Math.floor(item2?.period / 12);
                      let lastYearMonth = item2?.period % 12;

                      if (index >= Math.ceil(item2?.period / 12)) {
                        return 0;
                      }
                      if (index < year) {
                        return 12;
                      } else {
                        return lastYearMonth;
                      }
                    };
                    return (
                      <div key={index2} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {item2?.title_car
                            ? item2?.title_car
                            : merge_label_key[item]}
                        </div>
                        {tempFutureData?.cash_flow_items?.map(
                          (cash_flow_items, index3) => {
                            return (
                              <div
                                key={index3}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(
                                  item2?.period === "Infinity"
                                    ? item2?.pay_permonth * 12
                                    : item2?.pay_permonth *
                                        calcPeriodInterest(index3)
                                )?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  });
              }

              // หนี้ระยะยาว บ้าน
              if (item === "debt_place") {
                return balanceData?.debt_long_list
                  ?.find((item) => item?.options_debt_long === "debt_place")
                  ?.debt_long_places?.map((item2, index2) => {
                    const calcPeriodInterest = (index) => {
                      let year = Math.floor(item2?.period / 12);
                      let lastYearMonth = item2?.period % 12;

                      if (index >= Math.ceil(item2?.period / 12)) {
                        return 0;
                      }
                      if (index < year) {
                        return 12;
                      } else {
                        return lastYearMonth;
                      }
                    };
                    return (
                      <div key={index2} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {item2?.address_place
                            ? item2?.address_place
                            : merge_label_key[item]}
                        </div>
                        {tempFutureData?.cash_flow_items?.map(
                          (cash_flow_items, index3) => {
                            return (
                              <div
                                key={index3}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(
                                  item2?.period === "Infinity"
                                    ? item2?.pay_permonth * 12
                                    : item2?.pay_permonth *
                                        calcPeriodInterest(index3)
                                )?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  });
              }

              // หนี้ระยะยาว อื่นๆ
              if (item === "debt_long_other") {
                return balanceData?.debt_long_list
                  ?.find(
                    (item) => item?.options_debt_long === "debt_long_other"
                  )
                  ?.debt_long_others?.map((item2, index2) => {
                    const calcPeriodInterest = (index) => {
                      let year = Math.floor(item2?.period / 12);
                      let lastYearMonth = item2?.period % 12;

                      if (index >= Math.ceil(item2?.period / 12)) {
                        return 0;
                      }
                      if (index < year) {
                        return 12;
                      } else {
                        return lastYearMonth;
                      }
                    };
                    return (
                      <div key={index2} className="flex flex-col">
                        <div
                          className={
                            TABLE_HEADER_STYLE +
                            " text-center h-[44px] bg-[#F4F2F3] "
                          }
                        >
                          {item2?.title ? item2?.title : merge_label_key[item]}
                        </div>
                        {tempFutureData?.cash_flow_items?.map(
                          (cash_flow_items, index3) => {
                            return (
                              <div
                                key={index3}
                                className={
                                  TABLE_HEADER_STYLE +
                                  " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                                }
                              >
                                {numeral(
                                  item2?.period === "Infinity"
                                    ? item2?.pay_permonth * 12
                                    : item2?.pay_permonth *
                                        calcPeriodInterest(index3)
                                )?.format("0,0.00")}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  });
              }

              if (
                merge_label_key[item] !== undefined &&
                item !== "expenese_children_education"
              ) {
                return (
                  <div key={index} className="flex flex-col">
                    <div
                      className={
                        TABLE_HEADER_STYLE +
                        " text-center h-[44px] bg-[#F4F2F3] "
                      }
                    >
                      {merge_label_key[item]}
                    </div>
                    {tempFutureData?.cash_flow_items?.map(
                      (cash_flow_items, index2) => {
                        return (
                          <div
                            key={index2}
                            className={
                              TABLE_HEADER_STYLE +
                              " text-center h-[44px] bg-white border-[#F4F2F3] border-b-[1px] "
                            }
                          >
                            {item === "income_peryear" &&
                              numeral(cash_flow_items?.income_peryear)?.format(
                                "0,0.00"
                              )}
                            {item === "income_investment" &&
                              numeral(
                                cash_flow_items?.income_investment
                              )?.format("0,0.00")}
                            {item === "out_come_stable" &&
                              numeral(cash_flow_items?.out_come_stable)?.format(
                                "0,0.00"
                              )}
                            {item === "outcome_peryear" &&
                              numeral(cash_flow_items?.outcome_peryear)?.format(
                                "0,0.00"
                              )}
                            {item === "outcome_total" &&
                              numeral(cash_flow_items?.outcome_total)?.format(
                                "0,0.00"
                              )}
                            {item === "remain" &&
                              numeral(cash_flow_items?.remain)?.format(
                                "0,0.00"
                              )}
                            {item === "remain_accumulate" &&
                              numeral(
                                cash_flow_items?.remain_accumulate
                              )?.format("0,0.00")}
                            {item === "sum_income_total" &&
                              numeral(
                                cash_flow_items?.sum_income_total
                              )?.format("0,0.00")}
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default FutureResultTable;
