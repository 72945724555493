import { STYLE_INPUT_SLIDER_MAX } from "../../utils/useStyle";

export const SliderSingle = ({
  valuemax,
  valuemin="0",
  value,
  onChange,
  onChnageMax,
  year = false,
  width = "w-9",
  bath,
  showInputMax = true,
}) => {
  return (
    <div className=" flex gap-[5px] items-center ">
      <div className=" text-bluegray50 font-semibold text-xl font-IBMPlexSansThai">
        1
      </div>
      <input
        id="typeinp"
        type="range"
        defaultValue={1}
        min={valuemin}
        max={valuemax}
        value={value ? value : 1}
        onChange={onChange}
        className="progress "
      />
      <div className="flex gap-1 items-center ">
        {showInputMax && (
          <input
            type="number"
            onWheelCapture={(e) => {
              e.target.blur();
            }}
            className={`${STYLE_INPUT_SLIDER_MAX} ${width}`}
            placeholder="1"
            value={valuemax}
            onChange={onChnageMax}
            inputMode="decimal"
            pattern="[0-9],*"
          />
        )}
        <div className="text-bluegray50 font-semibold text-sm">
          {year ? "ปี" : bath ? "บาท" : "%"}
        </div>
      </div>
    </div>
  );
};
