/* eslint-disable react-hooks/exhaustive-deps */
import numeral from "numeral";
import { useRetireStore } from "../../../_store/retireStore";
import { CardStrategy } from "../../../components/card/CardMain";
import LineChartMain from "../../../components/chart/LineChartMain";
import {
  ICON_CHECK,
  ICON_CLOSE,
  ICON_RLOCNG_RIGHT,
  ICON_STAR,
  ICON_WARNING,
} from "../../../utils/useIcons";
import {
  STYLE_CARD_STRAGADY,
  STYLE_FLEX_STATUS,
  STYLE_HEAD_STRAGADY,
} from "../../../utils/useStyle";
import { useEffect, useMemo, useState } from "react";
import { useRetireContext } from "./VISummaryRetirePlan";
import LineChartMainAfter from "../../../components/chart/LineChartAfter";
import { MTButton } from "../../../components/button/MTButton";
import { useNavigate } from "react-router";
import { useIsMobile } from "../../../utils/useIsMobile";
import { avgWeight } from "../functions/functionMain";
import { ImageMain } from "../../../components/img/Img.Main";

const ViewAfterRetirePlan_Tab = ({
  isPass,
  strategy,
  setStrategy,
  afterDiffYear,
  sumAfterRetireInvest,
  labelsaf,
  dataaf,
  showPaire,
  strategyChange,
  lastResult,
  remain,
  retireResult,
  onClickSTG,
  pmt_value,
}) => {
  const retireData = useRetireStore((state) => state.retireData);

  const keyUnit = [
    "strategy_out_come",
    "strategy_income",
    "strategy_passive_income_retire",
    "strategy_salary",
    "strategy_assets_generate",
  ];

  const keyUnitPercent = [
    "strategy_assets_generate",
    "strategy_assets_generate_per_year",
    "strategy_salary_peryear",
    "strategy_control_rate_outcome",
    "strategy_investment_bf_retirement",
    "strategy_investment_af_retirement",
  ];

  const getFormattedValue = (row) => {
    if (row?.key === "strategy_out_come") {
      return numeral(retireData?.out_come_variable)?.format("0,0");
    } else if (row?.key === "strategy_income") {
      return numeral(retireData?.salary)?.format("0,0");
    } else if (row?.key === "strategy_passive_income_retire") {
      return numeral(retireData?.pension)?.format("0,0");
    } else if (row?.key === "strategy_assets_generate") {
      return numeral(retireData?.investment_assets)?.format("0,0");
    } else if (row?.key === "strategy_assets_generate_per_year") {
      return numeral(retireData?.investment_bf_retirement)?.format("0,0");
    } else if (row?.key === "strategy_salary") {
      return numeral(retireData?.salary)?.format("0,0");
    } else if (row?.key === "strategy_salary_peryear") {
      return numeral(retireData?.salary_peryear)?.format("0,0");
    } else if (row?.key === "strategy_control_rate_outcome") {
      return numeral(retireData?.out_come_peryear)?.format("0,0");
    } else if (row?.key === "strategy_age_retire") {
      return retireData?.age_retire;
    } else if (row?.key === "strategy_age_dead") {
      return retireData?.age_dead;
    } else if (row?.key === "strategy_investment_bf_retirement") {
      return retireData?.investment_bf_retirement;
    } else if (row?.key === "strategy_investment_af_retirement") {
      return retireData?.investment_af_retirement;
    }
    return 0;
  };

  const weightValue = useMemo(
    // let avgWeight = (3 * 10000 + 6000 * 9) / (10000 + 6000);
    () =>
      avgWeight(
        Number(retireData?.investment_bf_retirement),
        Number(retireData?.investment_assets),
        Number(retireData?.strategy_assets_generate),
        Number(retireData?.strategy_assets_generate_per_year)
      ),
    [
      Number(retireData?.investment_bf_retirement),
      Number(retireData?.investment_assets),
      Number(retireData?.strategy_assets_generate),
      Number(retireData?.strategy_assets_generate_per_year),
    ]
  );

  // console.log("weightValue", weightValue);

  const getFormattedValueAfter = (row) => {
    if (row?.key === "strategy_out_come") {
      return numeral(
        retireData?.out_come_variable - retireData?.strategy_out_come
      )?.format("0,0");
    } else if (row?.key === "strategy_income") {
      return numeral(retireData?.salary + retireData?.strategy_income)?.format(
        "0,0"
      );
    } else if (row?.key === "strategy_passive_income_retire") {
      return numeral(
        retireData?.pension + retireData?.strategy_passive_income_retire
      )?.format("0,0");
    } else if (row?.key === "strategy_assets_generate") {
      return numeral(
        retireData?.strategy_assets_generate + retireData?.investment_assets
      )?.format("0,0");
    } else if (row?.key === "strategy_assets_generate_per_year") {
      // return weightValue;
      return numeral(retireData?.strategy_assets_generate_per_year)?.format(
        "0,0"
      );
    } else if (row?.key === "strategy_salary") {
      return numeral(retireData?.strategy_salary)?.format("0,0");
    } else if (row?.key === "strategy_salary_peryear") {
      return numeral(retireData?.strategy_salary_peryear)?.format("0,0");
    } else if (row?.key === "strategy_control_rate_outcome") {
      return numeral(retireData?.strategy_control_rate_outcome)?.format("0,0");
    } else if (row?.key === "strategy_age_retire") {
      return retireData?.strategy_age_retire;
    } else if (row?.key === "strategy_age_dead") {
      return retireData?.strategy_age_dead;
    } else if (row?.key === "strategy_investment_bf_retirement") {
      return retireData?.strategy_investment_bf_retirement;
    } else if (row?.key === "strategy_investment_af_retirement") {
      return retireData?.strategy_investment_af_retirement;
    }
    return 0;
  };

  const getIcon = (row) => {
    if (row?.key === "strategy_out_come") {
      return "../images/stg_1.png";
    } else if (row?.key === "strategy_income") {
      return "../images/saving_expenses.png";
    } else if (row?.key === "strategy_passive_income_retire") {
      return "../images/stg_2.png";
    } else if (row?.key === "strategy_assets_generate") {
      return "../images/saving_save.png";
    } else if (row?.key === "strategy_assets_generate_per_year") {
      return "../images/saving_save.png";
    } else if (row?.key === "strategy_salary") {
      return "../images/stg_3.png";
    } else if (row?.key === "strategy_salary_peryear") {
      return "../images/stg_3.png";
    } else if (row?.key === "strategy_control_rate_outcome") {
      return "../images/stg_1.png";
    } else if (row?.key === "strategy_age_retire") {
      return "../images/stg_4.png";
    } else if (row?.key === "strategy_age_dead") {
      return "../images/stg_5.png";
    } else if (row?.key === "strategy_investment_bf_retirement") {
      return "../images/stg_4.png";
    } else if (row?.key === "strategy_investment_af_retirement") {
      return "../images/stg_4.png";
    }
  };

  const getETC = (row) => {
    if (row?.key === "strategy_assets_generate_per_year") {
      return (
        <div className=" text-xs w-full border-t border-gray-300 text-maingray">
          <div className=" mt-3 mb-1">
            *อัตราผลตอบแทนที่ใช้คำนวณ {weightValue}
          </div>
          <div>
            ** (สินทรัพย์มีไว้ลงทุน x อัตราผลตอบแทนก่อนเกษียณ) +
            (แปลงสินทรัพย์ให้มีผลตอบแทน x อัตราผลตอบแทนที่คาดหวัง) /
            (สินทรัพย์มีไว้ลงทุน + แปลงสินทรัพย์ให้มีผลตอบแทน){" "}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (window !== undefined) {
      window.scrollTo(0, 0);
    }
  }, []);

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <div>
      <div className=" flex flex-col gap-2 mb-[8px] mt-4">
        <div className={STYLE_HEAD_STRAGADY}>ความเคลื่อนไหวทางการเงิน</div>
        <div className={`border-b border-mainyellow w-[295px] -mx-4 `}></div>
      </div>
      {showPaire ? (
        <div className=" px-4">
          <LineChartMain
            labels={labelsaf}
            dataPlan={dataaf}
            head={`สินทรัพย์เพื่อการลงทุนสุทธิ`}
          />
        </div>
      ) : (
        ""
      )}

      {strategyChange ? (
        <>
          {lastResult?.result > 0 ? (
            <>
              <div className=" space-y-[10px] mb-3 mt-5">
                <div className={STYLE_FLEX_STATUS}>
                  <ICON_CHECK className=" w-6 text-maincyan " />
                  <div className="  text-sm flex-none">
                    <div className="font-medium">
                      เริ่มวางแผนเกษียณตอนอายุ {retireData?.age_current} ปี
                      ประมาณการณ์เมื่อเกษียณอายุตอน {retireData?.age_retire} ปี
                    </div>
                    <div className="font-medium ">
                      จำนวน <span className="text-mainblue">{numeral(retireResult)?.format("0,0.00")}</span> บาท
                    </div>
                  </div>
                </div>
                <div className={STYLE_FLEX_STATUS}>
                  <ICON_WARNING className=" w-6 text-mainyellow" />
                  <div className="  text-sm flex-none">
                    สามารถใช้จนได้ถึงอายุ{" "}
                    <span className="font-medium">
                      {retireData?.strategy_age_dead
                        ? retireData?.strategy_age_dead
                        : retireData?.age_dead}{" "}
                      ปี
                    </span>{" "}
                    แต่อย่าประมาทนะ
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className=" mt-[26px] mb-6 space-y-2.5">
              <div className={STYLE_FLEX_STATUS}>
                <ICON_CHECK className=" w-6 text-maincyan " />
                <div className="  text-sm flex-none">
                  คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ{" "}
                  <span className="font-medium">
                    {retireData?.age_current} ปี
                  </span>
                </div>
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_WARNING className=" w-6 text-mainyellow" />
                <div className="  text-sm flex-none">
                  สามารถใช้จนได้ถึงอายุ
                  <span className=" font-medium"> {remain} ปี </span>
                </div>
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_CLOSE className=" w-6 text-mainred" />
                {isMobile ? (
                  <div className="  text-sm flex-none">
                    <div>
                      ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                      <span className=" font-medium">
                        {retireData?.age_dead} ปี
                      </span>{" "}
                    </div>
                    ยังขาดอยู่ทั้งหมด{" "}
                    <span className=" font-medium text-mainblue">
                      {lastResult?.results < 0
                        ? numeral(lastResult?.results * -1)?.format("0,0")
                        : numeral(lastResult?.results)?.format("0,0")}
                      {""} บาท
                    </span>
                  </div>
                ) : (
                  <div className="  text-sm flex-none">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                    <span className=" font-medium">
                      {retireData?.age_dead} ปี
                    </span>{" "}
                    ยังขาดอยู่ทั้งหมด{" "}
                    <span className=" font-medium text-mainblue">
                      {lastResult?.result < 0
                        ? numeral(lastResult?.result * -1)?.format("0,0")
                        : numeral(lastResult?.result)?.format("0,0")}
                      {""} บาท
                    </span>
                  </div>
                )}
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_STAR className=" w-6 text-maingreen flex-none " />
                <div className="  text-sm ">
                  ควรวางแผนลงทุนผลตอบแทน{" "}
                  <span className=" font-semibold">
                    {" "}
                    {Math.abs(retireData?.investment_af_retirement)} %{" "}
                  </span>
                  เป็นระยะเวลา{" "}
                  <span className=" font-semibold">
                    {Math.abs(afterDiffYear)} ปี
                  </span>{" "}
                  เดือนละ{" "}
                  <span className=" font-semibold text-mainblue">
                    {numeral(Math.abs(sumAfterRetireInvest))?.format("0,0")} บาท
                  </span>
                </div>
              </div>

              <div className=" text-center font-semibold text-lg text-maincyan ">
                เพื่อให้แผนเกษียณเป็นจริง
                <div className=" flex justify-center w-full">
                  <div>มาปรับกลยุทธ์อีกครั้งกันเถอะ</div>
                  <ImageMain
                    src="../images/goodthumb.png"
                    className=" h-8 w-8 "
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className=" space-y-[10px] mb-3 mt-5">
          {lastResult?.result > 0 ? (
            <>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_CHECK className=" w-6 text-maincyan " />
                <div className="  text-sm flex-none">
                  คาดการณ์สินทรัพย์เพื่อการลงทุน
                  <div>
                    ณ วันเกษียณอายุ{" "}
                    <span className="font-medium">
                      {retireData?.age_retire} ปี
                    </span>{" "}
                    จำนวน{" "}
                    <span className=" font-medium text-mainblue">
                      {numeral(retireResult)?.format("0,0.00")}
                      {/* {lastResult} บาท */}
                    </span>
                  </div>
                </div>
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_WARNING className=" w-6 text-mainyellow" />
                <div className="  text-sm flex-none">
                  สามารถใช้จนได้ถึงอายุ{" "}
                  <span className="font-medium">
                    {retireData?.age_dead} ปี{" "}
                  </span>
                  แต่อย่าประมาทนะ
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_WARNING className=" w-6 text-mainyellow" />
                <div className="  text-sm flex-none">
                  สามารถใช้จนได้ถึงอายุ
                  <span className=" font-medium"> {remain} ปี </span>
                </div>
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <ICON_CLOSE className=" w-6 text-mainred" />
                <div className="  text-sm flex-none">
                  <div>
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                    <span className=" font-medium">
                      {retireData?.age_dead} ปี
                    </span>{" "}
                  </div>
                  ยังขาดอยู่ทั้งหมด{" "}
                  <span className=" font-medium text-mainblue">
                    {lastResult?.result < 0
                      ? numeral(lastResult?.result * -1)?.format("0,0")
                      : numeral(lastResult?.result)?.format("0,0")}{" "}
                    บาท
                  </span>
                </div>
              </div>
              <div className={STYLE_FLEX_STATUS}>
                <div className="  text-sm flex-wrap">
                  ควรวางแผนลงทุนผลตอบแทน{" "}
                  {Math.abs(retireData?.investment_af_retirement)} %
                  เป็นระยะเวลา {Math.abs(afterDiffYear)} ปี เดือนละ{" "}
                  {numeral(Math.abs(sumAfterRetireInvest)).format("0,0")} บาท
                  และเพื่อให้แผนเกษียณเป็นจริงมาปรับกลยุทธ์กันเถอะ
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <MTButton
        onClick={onClickSTG}
        text="ปรับกลยุทธ์"
        color="bg-maincyan "
        colortext="text-white font-medium"
        width="w-full"
      />
      {/* <MTButton
        width="w-full"
        onClick={() => navigate("/summarytablemovements?after")}
        text="ดูรายละเอียด"
        color="border-maincyan bg-white"
        colortext="text-maincyan group-hover:text-white font-medium"
        hovercolor="hover:bg-maincyan group "
        variant="outlined"
      /> */}
      <div className=" flex flex-col gap-2 mb-6 mt-4">
        <div className={STYLE_HEAD_STRAGADY}>กลยุทธ์ที่ใช้ปรับในครั้งนี้</div>
        <div className={`border-b border-mainyellow w-[295px] -mx-4 `}></div>
      </div>

      <div className=" space-y-[11px] mb-10">
        {strategy?.map((row, index) => {
          return (
            <CardStrategy
              icon={getIcon(row)}
              key={index}
              unit={
                keyUnit?.includes(row?.key)
                  ? "บาท"
                  : keyUnitPercent?.includes(row?.key)
                  ? "%"
                  : "ปี"
              }
              title={row?.title}
              value1={getFormattedValue(row)}
              value2={getFormattedValueAfter(row)}
              etc={getETC(row)}
            />
          );
        })}
      </div>
    </div>
  );
};
export default ViewAfterRetirePlan_Tab;
