import { cn, mapStatus } from "../../../utils/useFunctions";
import { PdfCard, PdfCardWhite } from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_11,
} from "../styles/pdf.style";
import { useBalanceStore } from "../../../_store/balanceStore";
import numeral from "numeral";
import { useMemo } from "react";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import _ from "lodash";
import {
  ICON_BAHT,
  ICON_CHECK,
  ICON_CLOSE,
  ICON_GRAPH,
  ICON_SAVING,
  ICON_STAR,
  ICON_WARNING,
} from "../../../utils/useIcons";
import { STYLE_CARD_BG } from "../../../utils/useStyle";
import PdfFooter from "../component/pdf.c.footer";
import LineChartMain from "../../../components/chart/LineChartMain";
import { useRetireStore } from "../../../_store/retireStore";
import {
  dataAge,
  dataAgeAF,
  loopInvestmentAssets,
  loopInvestmentAssetsAF,
} from "../../retirement_plan/functions/functionRetriementPlan";
import { calPMT, calPMTFVA } from "../../education/functions/function";
import { calculateTax, checkSum } from "../../tax_plan/functions";
import { useTaxStore } from "../../../_store/taxplanStore";
import { useStrategyStore } from "../../../_store/strategyRetireStore";
import { avgWeight } from "../../retirement_plan/functions/functionMain";

function PdfPageRetireTwo({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);
  const retireData = useRetireStore((state) => state.retireData);
  const taxData = useTaxStore((state) => state.taxData);
  const strategy = useStrategyStore((state) => state.strategy);

  const getCaltax = () => {
    let result = 0;
    result =
      taxData?.tax_income -
      taxData?.deduction_expenses -
      taxData?.deduct_summary;
    return calculateTax(result);
  };

  const displaySumAfterTaxPaid = () => {
    let result = 0;
    result =
      Number(taxData?.tax_income) -
      Number(taxData?.deduction_expenses) -
      Number(taxData?.deduct_summary_af);

    return calculateTax(result);
  };

  const displayTaxPaid = useMemo(
    () => calculateTax(taxData?.tax_income),
    [taxData?.tax_income]
  );

  const displaySumTax = useMemo(
    () => checkSum(getCaltax(), taxData?.after_tax_paid, 0, 0),
    [getCaltax(), taxData?.after_tax_paid, 0, 0]
  );

  const checkPercent = () => {
    let result = 0;
    result = ((getCaltax() - taxData?.after_tax_paid) / getCaltax()) * 100;
    if (isNaN(result)) {
      return 0;
    }
    return Number(result)?.toFixed(2);
  };

  const dataPlanBf = retireData?.bf_investment_assets?.map(
    (bf_investment_assets) => {
      return bf_investment_assets?.results;
    }
  );

  // สภาพคล่อง
  const valueProperty = useMemo(() => {
    let sum = 0;
    const expenses_permonth = balanceData?.expenses_propotion / 12;
    if (balanceData?.property_propotion) {
      sum =
        expenses_permonth > 0
          ? balanceData?.property_propotion / expenses_permonth
          : 0;
    }
    return Math.round(sum);
  }, [balanceData]);
  const getPropertyStatus = () => {
    if (!balanceData?.property_propotion) return;

    if (valueProperty < 6) {
      return "bad";
    } else if (valueProperty === 6) {
      return "good";
    } else {
      return "chance";
    }
  };

  // หนี้สินต่อทรัพย์สิน
  const valueDebttoTotalAsset = useMemo(() => {
    let sum = 0;
    if (balanceData?.debt_propotion) {
      sum =
        balanceData?.property_propotion > 0
          ? (balanceData?.debt_propotion / balanceData?.property_propotion) *
            100
          : 0;
    }
    return sum;
  }, [balanceData]);
  const getDebttoTotalAssetStatus = () => {
    if (!balanceData?.debt_propotion) return;

    if (valueDebttoTotalAsset > 50) {
      return "bad";
    }
    return "good";
  };

  // ชำระหนี้ต่อรายได้
  const valueDebttoSalary = useMemo(() => {
    let sum = 0;
    const debtPermonth =
      balanceData?.sum_debt_short_permonth +
      balanceData?.sum_debt_long_permonth;
    const salaryPermonth = balanceData?.salary_propotion
      ? balanceData?.salary_propotion / 12
      : 0;
    sum = salaryPermonth > 0 ? (debtPermonth / salaryPermonth) * 100 : 0;
    return sum;
  }, [balanceData]);
  const getDebttoSalaryStatus = () => {
    if (valueDebttoSalary > 50) {
      return "bad";
    }
    return "good";
  };

  // การออม
  const valueConservation = useMemo(() => {
    let sum = 0;
    if (balanceData?.sum_expenses_invest_saving) {
      sum = balanceData?.sum_expenses_invest_saving;
    }
    const finalResult =
      sum > 0 ? (sum / balanceData?.salary_propotion) * 100 : 0;
    return finalResult;
  }, [balanceData]);
  const getConservationStatus = () => {
    if (!balanceData?.sum_expenses_invest_saving) return;

    if (valueConservation < 10) {
      return "bad";
    }
    return "good";
  };

  // การลงทุน
  const valueInvest = useMemo(() => {
    let sum = 0;
    if (balanceData?.sum_property_invest) {
      sum =
        balanceData?.net_wealth > 0
          ? (balanceData?.sum_property_invest / balanceData?.net_wealth) * 100
          : 0;
    }
    return sum;
  }, [balanceData]);
  const getInvestStatus = () => {
    if (!balanceData?.sum_property_invest) return;

    if (valueInvest > 50) {
      return "good";
    }
    return "bad";
  };

  // วางแผนเกษียณ
  const getInvestSum = loopInvestmentAssets();
  const lastResultBF = getInvestSum[getInvestSum?.length - 1];
  const retirementAge = retireData?.age_retire;
  const retireBF = getInvestSum.find(
    (result) => result.age === retirementAge
  )?.results;
  const getremainBF = getInvestSum?.filter((thisRemain, i) => {
    if (thisRemain?.results <= 0) return { ...thisRemain };
  })?.[0];
  const remainBF = getremainBF?.age - 1;
  const beforeDiffYear = retireData?.age_retire - retireData?.age_current;
  const sumBeforeRetireInvest = useMemo(() => {
    const convertedMonth = beforeDiffYear * 12;
    const sumPMT = calPMTFVA(
      // retireData?.investment_bf_retirement / 100 / 12,
      // convertedMonth,
      // -lastResultBF?.results * -1
      lastResultBF?.results < 0
        ? Math.abs(lastResultBF?.results)
        : lastResultBF?.results,
      retireData?.investment_bf_retirement / 100 / 12,
      convertedMonth
    );
    return sumPMT;
  }, [retireData]);
  const strategyChange = retireData?.strategy_sum ? true : false;

  const dataPlanAF = retireData?.af_investment_assets?.map(
    (af_investment_assets) => {
      return af_investment_assets?.results;
    }
  );

  const getInvestSumAF = loopInvestmentAssetsAF();
  const lastResultAF = getInvestSumAF[getInvestSumAF?.length - 1];
  const getremainAF = getInvestSumAF?.filter((thisRemain, i) => {
    if (thisRemain?.results <= 0) return { ...thisRemain };
  })?.[0];
  const remainAF = Number(getremainAF?.age) - 1;
  const afterDiffYear =
    retireData?.strategy_age_retire - retireData?.age_current;

  const convertYearToMonth = (year) => {
    return year * 12;
  };

  const sumAfterRetireInvest = useMemo(() => {
    const convertedMonth = convertYearToMonth(afterDiffYear);
    const sumPMT = calPMTFVA(
      // retireData?.strategy_investment_bf_retirement / 100 / 12,
      // convertedMonth,
      // -lastResultAF?.results * -1
      lastResultAF?.results < 0
        ? Math.abs(lastResultAF?.results)
        : lastResultAF?.results,
      retireData?.investment_bf_retirement / 100 / 12,
      convertedMonth
    );
    return sumPMT;
  }, [retireData]);

  const getDetailBF = () => {
    if (strategyChange) {
      if (lastResultBF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan" />,
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">
                    {/* คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ */}
                    เริ่มวางแผนเกษียณตอนอายุ{" "}
                  </div>
                  <div
                    className={cn("text-[10px] font-semibold flex space-x-1")}
                  >
                    <div className="">
                      {_.isNaN(retireData?.age_current)
                        ? "-"
                        : retireData?.age_current}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                  </div>
                </div>
              );
            },
          },
          {
            icon: (
              <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />
            ),
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {retireData?.strategy_age_dead
                        ? retireData?.strategy_age_dead
                        : retireData?.age_dead || "-"}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
            // title: "สามารถใช้จนได้ถึงอายุ",
            // value: retireData?.strategy_age_dead
            //   ? retireData?.strategy_age_dead
            //   : retireData?.age_dead,
            // unit: "ปี แต่อย่าประมาทนะ",
          },
        ];
      }
      return [
        {
          icon: (
            <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan flex-none" />
          ),
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-[10px] ">
                  {/* คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ{" "} */}
                  เริ่มวางแผนเกษียณตอนอายุ{""}
                </div>
                <div className={cn("text-[10px] flex space-x-1")}>
                  <div className="font-semibold">
                    {_.isNaN(retireData?.age_current)
                      ? "-"
                      : retireData?.age_current}{" "}
                    ปี
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex flex-col">
                <div className="flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(remainBF) ? "-" : remainBF} ปี
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-[14px] h-[14px] text-maincyan" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                  </div>
                  <div className={cn("text-[10px] font-semibold")}>
                    {_.isNaN(retireData?.age_dead) ? "-" : retireData?.age_dead}{" "}
                    ปี
                  </div>
                </div>
                <div>
                  <div className="text-[10px] ">
                    ยังขาดอยู่ทั้งหมด{" "}
                    <span className=" font-medium text-black">
                      {lastResultBF?.results < 0
                        ? numeral(lastResultBF?.results * -1)?.format("0,0")
                        : numeral(lastResultBF?.results)?.format("0,0")}
                      {""} บาท
                    </span>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-[14px] h-[14px] text-maingreen" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">ควรวางแผนลงทุนผลตอบแทน </div>
                  <div className={cn("text-[10px] font-semibold")}>
                    {_.isNaN(retireData?.investment_bf_retirement)
                      ? ""
                      : retireData?.investment_bf_retirement}{" "}
                    %
                  </div>
                </div>
                <div className="text-[10px] flex space-x-1">
                  <div>เป็นระยะเวลา</div>
                  <span className=" font-semibold">
                    {_.isNaN(beforeDiffYear) ? "-" : beforeDiffYear} ปี
                  </span>{" "}
                  <div>เดือนละ</div>
                  <span className=" font-semibold text-mainblue">
                    {numeral(sumBeforeRetireInvest)?.format("0,0")} บาท
                  </span>
                </div>
              </div>
            );
          },
        },
      ];
    } else {
      if (lastResultBF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan" />,
            render: () => {
              return (
                <div className="text-[10px] flex flex-col gap-1">
                  <div className=" ">
                    คาดการณ์สินทรัพย์เพื่อการลงทุน ณ วันเกษียณอายุ
                  </div>
                  <div className="flex">
                    <span className="font-medium mr-1">
                      {_.isNaN(retireData?.age_retire)
                        ? "-"
                        : retireData?.age_retire}{" "}
                      ปี
                    </span>
                    <div className="flex space-x-1">
                      <div>จำนวน</div>
                      <span className=" font-medium text-mainblue">
                        {numeral(retireBF)?.format("0,0.00")}
                      </span>
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            icon: (
              <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />
            ),
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                <div className={cn("text-[10px] flex space-x-1")}>
                  <div className="font-semibold">
                    {" "}
                    {_.isNaN(remainBF) ? "-" : remainBF} ปี
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-[14px] h-[14px] text-mainred" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1 flex-wrap ">
                  <div className="text-[10px] ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ
                  </div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}{" "}
                      ปี
                    </div>
                  </div>
                </div>
                <div className="flex gap-1 text-[10px]">
                  <div className="  text-[10px]">ยังขาดอยู่ทั้งหมด</div>
                  <div
                    className={cn("text-[10px] font-semibold text-black")}
                  >
                    {_.isNaN(lastResultBF?.results)
                      ? "-"
                      : numeral(
                          lastResultBF?.results < 0
                            ? lastResultBF?.results * -1
                            : lastResultBF?.results
                        ).format("0,0")}{" "}
                    บาท
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-[14px] h-[14px] text-maincyan" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">ควรวางแผนลงทุนผลตอบแทน</div>
                </div>
                <div className={cn("text-[10px] font-semibold")}>
                  {retireData?.investment_bf_retirement} %
                  <span className=" font-normal"> เป็นระยะเวลา </span>
                  <span className="font-semibold">
                    {_.isNaN(beforeDiffYear) ? "-" : beforeDiffYear} ปี{" "}
                  </span>
                  <span className="font-normal">เดือนละ </span>
                  <span className="text-mainblue">
                    {_.isNaN(sumBeforeRetireInvest)
                      ? "-"
                      : numeral(sumBeforeRetireInvest)?.format("0,0")}
                  </span>
                </div>
              </div>
            );
          },
        },
      ];
    }
  };

  const getDetailAF = () => {
    if (strategyChange) {
      if (lastResultAF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan" />,
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">
                    {/* คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ */}
                    เริ่มวางแผนเกษียณตอนอายุ{" "}
                  </div>
                  <div
                    className={cn("text-[10px] font-semibold flex space-x-1")}
                  >
                    <div className="">
                      {_.isNaN(retireData?.age_current)
                        ? "-"
                        : retireData?.age_current}{" "}
                      ปี
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            icon: (
              <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />
            ),
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {retireData?.strategy_age_dead
                        ? retireData?.strategy_age_dead
                        : retireData?.age_dead || "-"}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: (
            <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan flex-none" />
          ),
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-[10px] ">
                  {/* คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ{" "} */}
                  เริ่มวางแผนเกษียณตอนอายุ{" "}
                </div>
                <div className={cn("text-[10px] flex space-x-1")}>
                  <div className="font-semibold">
                    {_.isNaN(retireData?.age_current)
                      ? "-"
                      : retireData?.age_current}{" "}
                    ปี
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex flex-col">
                <div className="flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(remainAF) ? "-" : remainAF} ปี
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-[14px] h-[14px] text-maincyan" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ{" "}
                  </div>
                  <div className={cn("text-[10px] font-semibold")}>
                    {_.isNaN(retireData?.age_dead) ? "-" : retireData?.age_dead}{" "}
                    ปี
                  </div>
                </div>
                <div>
                  <div className="text-[10px] ">
                    ยังขาดอยู่ทั้งหมด{" "}
                    <span className=" font-medium text-black">
                      {lastResultAF?.results < 0
                        ? numeral(lastResultAF?.results * -1)?.format("0,0")
                        : numeral(lastResultAF?.results)?.format("0,0")}
                      {""} บาท
                    </span>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-[14px] h-[14px] text-maingreen" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">ควรวางแผนลงทุนผลตอบแทน </div>
                  <div className={cn("text-[10px] font-semibold")}>
                    {_.isNaN(retireData?.investment_af_retirement)
                      ? ""
                      : retireData?.investment_af_retirement}{" "}
                    %
                  </div>
                </div>
                <div className="text-[10px] flex space-x-1">
                  <div>เป็นระยะเวลา</div>
                  <span className=" font-semibold">
                    {_.isNaN(afterDiffYear) ? "-" : afterDiffYear} ปี
                  </span>{" "}
                  <div>เดือนละ</div>
                  <span className=" font-semibold text-mainblue">
                    {numeral(sumAfterRetireInvest)?.format("0,0")} บาท
                  </span>
                </div>
              </div>
            );
          },
        },
      ];
    } else {
      if (lastResultAF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-[14px] h-[14px] text-maincyan" />,
            render: () => {
              return (
                <div className="text-[10px] flex flex-col gap-1">
                  <div className=" ">
                    คาดการณ์สินทรัพย์เพื่อการลงทุน ณ วันเกษียณอายุ
                  </div>
                  <div className="flex">
                    <span className="font-medium mr-1">
                      {_.isNaN(retireData?.age_retire)
                        ? "-"
                        : retireData?.age_retire}{" "}
                      ปี
                    </span>
                    <div className="flex space-x-1">
                      <div>จำนวน</div>
                      <span className=" font-medium text-mainblue">
                        {numeral(retireBF)?.format("0,0.00")}
                      </span>
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            icon: (
              <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />
            ),
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: <ICON_WARNING className=" w-[14px] h-[14px] text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-[10px] ">สามารถใช้จนได้ถึงอายุ</div>
                <div className={cn("text-[10px] flex space-x-1")}>
                  <div className="font-semibold">
                    {" "}
                    {_.isNaN(remainAF) ? "-" : remainAF} ปี
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-[14px] h-[14px] text-mainred" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1 flex-wrap ">
                  <div className="text-[10px] ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ
                  </div>
                  <div className={cn("text-[10px] flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}{" "}
                      ปี
                    </div>
                  </div>
                </div>
                <div className="flex gap-1 text-[10px]">
                  <div className="  text-[10px]">ยังขาดอยู่ทั้งหมด</div>
                  <div
                    className={cn("text-[10px] font-semibold text-black")}
                  >
                    {_.isNaN(lastResultAF?.results)
                      ? "-"
                      : numeral(
                          lastResultAF?.results < 0
                            ? lastResultAF?.results * -1
                            : lastResultAF?.results
                        ).format("0,0")}{" "}
                    บาท
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-[14px] h-[14px] text-maincyan" />,
          render: () => {
            return (
              <div className="flex flex-col">
                <div className=" flex gap-1">
                  <div className="text-[10px] ">ควรวางแผนลงทุนผลตอบแทน</div>
                </div>
                <div className={cn("text-[10px] font-semibold")}>
                  {retireData?.investment_af_retirement} %
                  <span className=" font-normal"> เป็นระยะเวลา </span>
                  <span className="font-semibold">
                    {_.isNaN(afterDiffYear) ? "-" : afterDiffYear} ปี{" "}
                  </span>
                  <span className="font-normal">เดือนละ </span>
                  <span className="text-mainblue">
                    {_.isNaN(sumAfterRetireInvest)
                      ? "-"
                      : numeral(sumAfterRetireInvest)?.format("0,0")}
                  </span>
                </div>
              </div>
            );
          },
        },
      ];
    }
  };

  let checkAF = lastResultAF?.results;
  let checkBF = lastResultBF?.results;

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="วางแผนเกษียณ" icon="/images/grandparents.svg" />
      {/* ก่อนวางแผน */}
      <PdfCard title={"ก่อนวางแผน"}>
        <PdfCardWhite className={"flex flex-col px-3"}>
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
            ความเคลื่อนไหวทางการเงิน 
          </div>
          <div className="flex space-x-2">
            <div className={cn("w-[230px]")}>
              <LineChartMain
                axisSize={6}
                labels={dataAge()}
                dataPlan={dataPlanBf}
                fontSize="text-[10px]"
                head={``}
                // height={250}
                width={230}
                classNameBath={"md:top-[10%]"}
                classNameAge={"right-[-4%] md:right-[0%]"}
              />
            </div>

            <div className="flex flex-col">
              {checkBF > 0 ? (
                <div
                  className={cn(
                    "w-full text-center text-black items-center text-md flex flex-col gap-[9px]",
                    STYLE_PDF_TEXT_NORMAL_11
                  )}
                >
                  <img src="/images/goodface.svg" className="w-6 h-6 mx-auto" />
                  <div>
                    <div>ยินดีด้วย...</div>
                    ท่านสามารถใช้ชีวิตหลังเกษียณได้ตามที่ท่านตั้งใจไว้
                  </div>
                </div>
              ) : (
                <div
                  className={cn(
                    "w-full text-center text-black text-md flex items-center flex-col gap-[9px]",
                    STYLE_PDF_TEXT_NORMAL_11
                  )}
                >
                  <img src="/images/badface.svg" className="w-6 h-6 mx-auto" />
                  <div>
                    ไม่นะ..เมื่อท่านอายุ {retireData?.age_dead} ปี
                    <div>เงินของท่านจะหมดลง ก่อนสิ้นอายุขัย</div>
                  </div>
                </div>
              )}
              <div className="mt-2 space-y-1">
                {getDetailBF().map((item, index) => {
                  return (
                    <div key={index} className="flex gap-2.5">
                      <div>{item?.icon}</div>
                      {item?.render && item?.render()}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </PdfCardWhite>
      </PdfCard>

      {/* หลังวางแผน */}
      <PdfCard title={"หลังวางแผน"}>
        <PdfCardWhite className={"flex flex-col px-3"}>
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
            ความเคลื่อนไหวทางการเงิน  
          </div>
          <div className="flex space-x-2">
            <div className={cn("w-[230px]")}>
              <LineChartMain
                axisSize={6}
                labels={dataAgeAF()}
                dataPlan={dataPlanAF}
                fontSize="text-[10px]"
                head={``}
                // height={250}
                width={230}
                classNameBath={"md:top-[10%]"}
                classNameAge={"right-[-4%] md:right-[0%]"}
              />
            </div>

            <div className="flex flex-col">
              {checkAF > 0 ? (
                <div
                  className={cn(
                    "w-full text-center text-black items-center text-md flex flex-col gap-[9px]",
                    STYLE_PDF_TEXT_NORMAL_11
                  )}
                >
                  <img src="/images/goodface.svg" className="w-6 h-6 mx-auto" />
                  <div>
                    <div>ยินดีด้วย...</div>
                    ท่านสามารถใช้ชีวิตหลังเกษียณได้ตามที่ท่านตั้งใจไว้
                  </div>
                </div>
              ) : (
                <div
                  className={cn(
                    "w-full text-center text-black text-md flex items-center flex-col gap-[9px]",
                    STYLE_PDF_TEXT_NORMAL_11
                  )}
                >
                  <img src="/images/badface.svg" className="w-6 h-6 mx-auto" />
                  <div>
                    ไม่นะ..เมื่อท่านอายุ {retireData?.age_dead} ปี
                    <div>เงินของท่านจะหมดลง ก่อนสิ้นอายุขัย</div>
                  </div>
                </div>
              )}
              <div className="mt-2 space-y-1">
                {getDetailAF().map((item, index) => {
                  return (
                    <div key={index} className="flex gap-2.5">
                      <div>{item?.icon}</div>
                      {item?.render && item?.render()}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageRetireTwo;
