import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Progress,
} from "@material-tailwind/react";
import _ from "lodash";
import numeral from "numeral";
import React, { useEffect, useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useTaxStore } from "../../../_store/taxplanStore";
import { CardMain } from "../../../components/card/CardMain";
import { InfoDrawer } from "../../../components/drawer/InfoDrawer";
import TaxHeirachy from "../../../functions/taxFucntion/taxHeirachy";
import { cn } from "../../../utils/useFunctions";
import { ICON_ARROW_UP_DOWN } from "../../../utils/useIcons";
import {
  STYLE_ACCORDION_BODY_TAX,
  STYLE_ACCORDION_BODY_TAX_2,
  STYLE_ACCORDION_BODY_TAX_3,
  STYLE_ACCORDION_SUBTITLE,
  STYLE_AF_ACCORDION,
  STYLE_BF_ACCORDION,
  STYLE_BORDER_YELLOW_AC,
  STYLE_CARD_BG,
  STYLE_DOK_JAN,
  STYLE_HEAD_AC,
  STYLE_HEAD_GRAY,
  STYLE_INPUT_INSET,
  STYLE_INPUT_INSET_ERROR,
  STYLE_NUMBER_PLAN_BAR,
  STYLE_PARENT_HEAD_AC,
} from "../../../utils/useStyle";
import { useTaxPlanContext } from "../Tax_Plan.Main";
import {
  calculateTax,
  calculateTaxProperty,
  getPercent,
  getcalPaid,
  getcalSum,
} from "../functions";
import { CardConclusionTax } from "../styles/Card/CardConclusionTax";
import {
  TabThreeExpenseCalculation,
  TabThreeSumIncomeCalculation,
  TabThreeWithHoldingTax,
} from "../../../functions/taxFucntion/premium/tab3function";
import { IncomeTab4Calculation } from "../../../functions/taxFucntion/premium/tab4function";

const numberWithoutComma = (value) => {
  let result = parseInt(value?.replace(/,/g, ""), 10);
  return result;
};

const ErrorPrivilege = () => {
  return (
    <div className=" text-center text-red-500 font-medium mb-1 text-xs">
      ใช้สิทธิ์เต็มแล้ว ไม่จำเป็นต้องซื้อเพิ่ม
    </div>
  );
};

export const TaxDrawer = ({ openTaxPlanInfo, setOpenTaxPlanInfo }) => {
  return (
    <InfoDrawer
      head={"วางแผนภาษี"}
      text={
        "  การเตรียมการเพื่อเสียภาษีให้ถูกต้อง ครบถ้วน ในฐานะพลเมืองดีและใช้สิทธิประโยชน์ทางภาษีต่าง ๆ ที่กฎหมายกำหนดไว้ไปใช้ลดหย่อนภาษีเงินได้ประจำปี เพื่อบรรเทาภาระภาษีให้น้อยลง ไม่ต้องเสียภาษีมากจนเกินไป รวมถึงไม่ต้องชำระภาษีเพิ่มหรือเสียเบี้ยปรับโดยใช่เหตุ และยิ่งถ้าเราวางแผนภาษีได้เป็นอย่างดี ก็จะเป็นการเพิ่มพูนความมั่งคั่งได้เป็นอย่างดีด้วย "
      }
      open={openTaxPlanInfo}
      onClose={() => {
        setOpenTaxPlanInfo(false);
      }}
    />
  );
};

const ViewStepThreeTaxPlan = ({
  onClick,
  setForm,
  form,
  formAfter,
  setFormAfter,
  error,
  setError,
  openTaxPlanInfo,
  setOpenTaxPlanInfo,
  userData,
}) => {
  const taxData = useTaxStore((state) => state.taxData);
  const setTaxData = useTaxStore((state) => state.setTaxData);

  const [open, setOpen] = useState(1);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const { deduction, setDeduction } = useTaxPlanContext();
  const [cloneInsurance, setCloneInsurance] = useState({
    insurance_life: 0,
    insurance_annuity: 0,
  });

  const [sum7value, setSum7value] = useState(() => {
    let sum = 0;
    let InsuranceAnnuity =
      Number(form?.insurance_annuity || 0) <= 100000
        ? 0
        : Number(form?.insurance_annuity || 0) - 100000;
    sum =
      Number(InsuranceAnnuity) +
      // Number(form?.insurance_annuity || 0)+
      Number(form?.fund_provident || 0) +
      Number(form?.fund_nsf || 0) +
      Number(form?.fund_provident_rmf_value || 0) +
      Number(form?.fund_provident_ssf_value || 0) +
      Number(form?.fund_GorBorKhor_value || 0) +
      Number(form?.fund_PrivateSchoolTeacher_value || 0);

    return 500000 - sum;
  });

  const [sevenValueMax, setSevenValueMax] = useState({
    SSF_sum: 0,
    Insurance_Annuity_sum: 0,
    RMF_sum: 0,
  });

  // เช็คประกันบำนาญ
  useEffect(() => {
    let tempForm = { ...form };
    let diffLifeInsurance = 0;
    let diffAnnuityInsurance = 0;
    let LifeInsuranceDiff =
      100000 -
      _.get(form, "insurance_health", 0) -
      _.get(form, "insurance_life", 0);

    tempForm.insurance_life = Number(form.insurance_life || 0);

    if (Number(tempForm.insurance_life || 0) <= 100000) {
      diffAnnuityInsurance = 100000 - Number(tempForm.insurance_life || 0);
    } else {
      diffAnnuityInsurance = 0;
    }

    if (Number(form.insurance_annuity || 0) - diffAnnuityInsurance < 0) {
      diffLifeInsurance = Number(form.insurance_annuity || 0);
    } else {
      diffLifeInsurance = diffAnnuityInsurance;
    }

    tempForm.insurance_annuity =
      Number(form.insurance_annuity || 0) - diffLifeInsurance;

    if (form.insurance_life >= 100000) {
      tempForm.insurance_life = Number(form?.insurance_life || 0);
    } else {
      tempForm.insurance_life =
        Number(form?.insurance_life || 0) + diffLifeInsurance;
      if (
        _.get(form, "insurance_life", 0) === 0 &&
        _.get(form, "insurance_health", 0) >= 0
      ) {
        let HealthValue =
          _.get(form, "insurance_health", 0) >= 25000
            ? 25000
            : _.get(form, "insurance_health", 0);
        let maxInsuranceLife = 100000 - HealthValue;

        tempForm.insurance_life =
          tempForm.insurance_life > maxInsuranceLife
            ? maxInsuranceLife
            : tempForm.insurance_life;
      }
    }

    if (form.insurance_annuity < LifeInsuranceDiff) {
      LifeInsuranceDiff = 100000;
    }

    setCloneInsurance({
      insurance_life: tempForm.insurance_life,
      insurance_annuity: tempForm.insurance_annuity,
      diffLifeInsurance: diffLifeInsurance,
      LifeInsuranceDiff: LifeInsuranceDiff,
    });
  }, []);

  useEffect(() => {
    let tempSSFSum = () => {
      let tempIncome =
        form?.tax_income * 0.3 > 200000 ? 200000 : form?.tax_income * 0.3;

      if (userData?.is_premium) {
        tempIncome =
          IncomeTab4Calculation(taxData) * 0.3 > 200000
            ? 200000
            : IncomeTab4Calculation(taxData) * 0.3;
      }

      return Number(tempIncome) - Number(form?.fund_provident_ssf_value) < 0
        ? 0
        : Number(tempIncome) - Number(form?.fund_provident_ssf_value);
    };

    let sumInsuranceAnnuity = () => {
      let tempIncome =
        form?.tax_income * 0.15 >= 200000 ? 200000 : form?.tax_income * 0.15;

      if (userData?.is_premium) {
        tempIncome =
          IncomeTab4Calculation(taxData) * 0.15 >= 200000
            ? 200000
            : IncomeTab4Calculation(taxData) * 0.15;
      }

      return tempIncome - cloneInsurance?.insurance_annuity < 0
        ? 0
        : tempIncome - cloneInsurance?.insurance_annuity;
    };

    let sumRMF = () => {
      let tempIncome =
        form?.tax_income * 0.3 > 500000 ? 500000 : form?.tax_income * 0.3;

      if (userData?.is_premium) {
        tempIncome =
          IncomeTab4Calculation(taxData) * 0.3 > 500000
            ? 500000
            : IncomeTab4Calculation(taxData) * 0.3;
      }

      return tempIncome - form?.fund_provident_rmf_value < 0
        ? 0
        : tempIncome - form?.fund_provident_rmf_value;
    };

    setSevenValueMax({
      SSF_sum: tempSSFSum(),
      Insurance_Annuity_sum: sumInsuranceAnnuity(),
      RMF_sum: sumRMF(),
    });
  }, [form]);

  useEffect(() => {
    let tempSum =
      form?.fund_provident_ssf_value +
      form?.tax_plan_ssf +
      form?.fund_provident_rmf_value +
      form?.tax_plan_rmf +
      cloneInsurance?.insurance_annuity +
      form?.tax_plan_insurance_annuity;
    setForm({
      ...form,
      tab3_annuity: cloneInsurance?.insurance_annuity,
      tab3sum: tempSum,
    });
  }, [
    form?.tax_plan_ssf,
    form?.tax_plan_rmf,
    form?.tax_plan_insurance_annuity,
  ]);

  const donateCap = userData?.is_premium
    ? IncomeTab4Calculation(taxData) -
      form?.deduction_expenses -
      TaxHeirachy(form)
        ?.filter((item) => {
          return (
            item?.title !==
              "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ" &&
            item?.title !== "เงินบริจาคอื่น ๆ"
          );
        })
        .reduce((sum, item) => {
          return sum + Number(_.get(item, "value2", 0));
        }, 0)
    : form?.tax_income -
      form?.deduction_expenses -
      TaxHeirachy(form)
        ?.filter((item) => {
          return (
            item?.title !==
              "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ" &&
            item?.title !== "เงินบริจาคอื่น ๆ"
          );
        })
        .reduce((sum, item) => {
          return sum + Number(_.get(item, "value2", 0));
        }, 0);

  const donateCapEtc = userData?.is_premium
    ? IncomeTab4Calculation(taxData) -
      form?.deduction_expenses -
      TaxHeirachy(form)
        ?.filter((item) => {
          return item?.title !== "เงินบริจาคอื่น ๆ";
        })
        .reduce((sum, item) => {
          return sum + _.get(item, "value2", 0);
        }, 0)
    : form?.tax_income -
      form?.deduction_expenses -
      TaxHeirachy(form)
        ?.filter((item) => {
          return item?.title !== "เงินบริจาคอื่น ๆ";
        })
        .reduce((sum, item) => {
          return sum + _.get(item, "value2", 0);
        }, 0);

  const InsuranceHealthBoughtAlready = (form) => {
    let tempInsuranceHealth = form?.insurance_health;
    if (cloneInsurance?.insurance_life < 100000) {
      tempInsuranceHealth = 100000 - cloneInsurance?.insurance_life;
    }
    return tempInsuranceHealth < form?.insurance_health
      ? tempInsuranceHealth
      : form?.insurance_health;
  };

  //เงินคงเหลือประกันชีวิต
  const InsuranceLifeRemain = (form) => {
    let tempHealth =
      form?.insurance_health > 25000 ? 25000 : form?.insurance_health;
    let tempValue =
      100000 -
      (Number(
        (form?.insurance_life || 0) >= 100000
          ? 100000
          : form?.insurance_life || 0
      ) +
        Number(
          (form?.insurance_health || 0) >= 25000
            ? 25000
            : form?.insurance_health || 0
        ) +
        Number(form?.tax_plan_insurance_life || 0));

    if (
      Number(form?.insurance_life) + Number(form?.insurance_health) <=
      100000
    ) {
      // console.log("1")
      tempValue = 100000 - tempHealth;
      // tempValue = 75000 - form?.insurance_life;
    }
    return tempValue - cloneInsurance?.insurance_life < 0
      ? 0
      : tempValue - cloneInsurance?.insurance_life;
  };

  //เงินคงเหลือประกันสุขภาพ
  const InsuranceHealthRemain = (form) => {
    let tempValue = 0;
    let tempExcessLife =
      InsuranceLifeRemain(form) -
      (25000 - Number(form?.insurance_health)) -
      _.get(form, "tax_plan_insurance_life", 0);
    tempExcessLife = tempExcessLife > 0 ? 0 : tempExcessLife;

    if (
      Number(form?.insurance_health) + Number(cloneInsurance?.insurance_life) <
      100000
    ) {
      tempValue = 25000 - Number(form?.insurance_health) + tempExcessLife;
    }

    return tempValue < 0 ? 0 : tempValue;
  };

  //เงินคงเหลือประกันชีวิตแบบบำนาญ
  const InsuranceAnnuityRemain = (form) => {
    let tempValue = 0;
    let tempIncome = form?.tax_income;

    if (userData?.is_premium) {
      tempIncome = TabThreeSumIncomeCalculation(taxData);
    }

    if (tempIncome * 0.15 >= 200000) {
      tempValue =
        sevenValueMax?.Insurance_Annuity_sum -
        (100000 - cloneInsurance?.LifeInsuranceDiff);
    }

    if (tempIncome * 0.15 < 200000) {
      tempValue = tempIncome * 0.15 - cloneInsurance?.insurance_annuity;
    }

    return tempValue < 0 ? 0 : tempValue;
  };

  // เงินบริจาคสนับสนุนการศึกษา / สถานพยาบาล / สภากาชาดไทย / อื่นๆ
  const SupportEducationRemain = (form) => {
    return (donateCap - Number(form?.education_donate || 0)) * 0.1;
  };

  const OtherDonateRemain = (form) => {
    return (
      (donateCapEtc -
        Number(form?.other_donate || 0) -
        _.get(form, "tax_plan_education_donate", 0) * 2) *
      0.1
    );
  };

  const displaySumBudget = () => {
    let sum = 0;
    sum =
      Number(form?.tax_plan_insurance_life || 0) +
      Number(form?.tax_plan_insurance_health || 0) +
      Number(form?.tax_plan_insurance_annuity || 0) +
      Number(form?.tax_fund_provident || 0) +
      // Number(form?.tax_fund_nsf || 0) +
      Number(form?.tax_plan_rmf || 0) +
      Number(form?.tax_plan_ssf || 0) +
      Number(form?.tax_plan_political_donate || 0) +
      Number(form?.tax_fund_esg || 0) +
      // Number(form?.tax_fund_GorBorKhor || 0) +
      // Number(form?.tax_fund_PrivateSchoolTeacher || 0) +
      Number(form?.tax_plan_education_donate || 0) +
      Number(form?.tax_plan_other_donate || 0);

    return sum;
  };

  const displayDeducExpenses = useMemo(() => {
    let deduction_expenses = 0;
    if (form?.tax_income / 2 < 100000)
      deduction_expenses = form?.tax_income / 2;
    else return 100000;
  }, [form?.deduction_expenses]);

  const displayTaxPaid = useMemo(() => {
    let result = form.tax_income;
    if (userData?.is_premium) {
      result = TabThreeSumIncomeCalculation(taxData);
    }
    return calculateTax(result);
  }, [form?.tax_income]);

  // Sum ค่าลดหย่อนก่อนวางแผน
  const getBeforeDeduct = useMemo(() => {
    let sum = 0;
    deduction?.map((row) => {
      sum += Number(row?.amount || 0);
    });
    return sum;
  }, []);

  // mark

  // Sum ค่าลดหย่อนทั้งหมด
  const displaySumDeduction = () => {
    let sum = 0;
    sum =
      Number(getBeforeDeduct || 0) +
      Number(form?.tax_plan_insurance_life || 0) +
      Number(form?.tax_plan_insurance_health || 0) +
      Number(form?.tax_plan_insurance_annuity || 0) +
      Number(form?.tax_fund_provident || 0) +
      Number(form?.tax_fund_nsf || 0) +
      Number(form?.tax_plan_rmf || 0) +
      Number(form?.tax_plan_ssf || 0) +
      Number(form?.tax_plan_political_donate || 0) +
      Number(form?.tax_fund_esg || 0);
    // +
    // Number(form?.tax_fund_GorBorKhor || 0) +
    // Number(form?.tax_fund_PrivateSchoolTeacher || 0);
    return sum;
  };

  const getCaltax = () => {
    let result = 0;
    let expense =
      Number(form?.tax_income || 0) / 2 < 100000
        ? Number(form?.tax_income || 0) / 2
        : 100000;
    result =
      Number(form?.tax_income || 0) -
      Number(expense || 0) -
      Number(getBeforeDeduct || 0);

    if (userData?.is_premium) {
      result =
        TabThreeSumIncomeCalculation(taxData) -
        TabThreeExpenseCalculation(taxData) -
        Number(getBeforeDeduct || 0);
    }

    return calculateTax(result);
  };

  // Sum ภาษีหลังวางแผน
  const displaySumAfterTaxPaid = () => {
    let result = 0;
    result =
      Number(form?.tax_income || 0) -
      Number(form?.deduction_expenses || 0) -
      Number(form?.deduct_summary || 0) -
      Number(displaySumBudget() || 0);
    if (userData?.is_premium) {
      result =
        TabThreeSumIncomeCalculation(taxData) -
        TabThreeExpenseCalculation(taxData) -
        Number(form?.deduct_summary || 0) -
        Number(displaySumBudget() || 0);
    }
    return calculateTax(result);
  };

  // เงินได้สุทธิ
  const getNetIncome = () => {
    let sum = 0;
    sum =
      Number(form?.tax_income) -
      Number(form?.deduction_expenses) -
      Number(form?.deduct_summary);
    return sum;
  };

  const getSumAllInsurance = useMemo(
    () => getcalSum(form?.tax_plan_insurance_life, form?.insurance_life),
    [form?.tax_plan_insurance_life, form?.insurance_life]
  );

  // Sum ประกันชีวิต กับ ประกันสุขภาพ
  const getSumAllInsuranceHelth = () => {
    let sum = 0;
    sum =
      Number(form?.tax_plan_insurance_life) +
      Number(form?.insurance_life) +
      (Number(form?.tax_plan_insurance_health) +
        Number(form?.insurance_health) >
      25000
        ? 25000
        : Number(form?.tax_plan_insurance_health) +
          Number(form?.insurance_health));
    return sum;
  };

  const getSumAnnuity = () => {
    let result = 0;
    result = form?.tax_plan_insurance_annuity + form?.insurance_annuity;
    return result;
  };

  const getSumProvident = () => {
    let result = 0;
    result = Number(form?.tax_fund_provident) + Number(form?.fund_provident);
    return Number(result);
  };

  const getSumSSF = () => {
    let result = 0;
    result = form?.tax_plan_ssf + form?.fund_provident_ssf_value;
    return Number(result);
  };

  const getSumRMF = useMemo(
    () => getcalSum(form?.tax_plan_rmf, form?.fund_provident_rmf_value),
    [form?.tax_plan_rmf, form?.fund_provident_rmf_value]
  );
  const getSumESG = useMemo(
    () => getcalSum(form?.tax_fund_esg, form?.fund_esg),
    [form?.tax_fund_esg, form?.fund_esg]
  );

  const getSumFundProvident = () => {
    let results = 0;
    results = Number(form?.tax_fund_provident) + Number(form?.fund_provident);
    return Number(results);
  };

  const RemainInsuaLife = useMemo(() => {
    return getSumAllInsurance - 100000 < 0 ? 0 : getSumAllInsurance - 100000;
  }, [getSumAllInsurance, 100000]);

  const RemianFund = useMemo(
    () =>
      getcalPaid(
        form?.tax_plan_political_donate + form?.political_donate,
        10000
      ),
    [form?.tax_plan_political_donate + form?.political_donate, 10000]
  );
  const getDonate = () => {
    let sum = 0;
    sum = form?.tax_plan_political_donate + form?.political_donate;
    return numeral(sum)?.format("0,0");
  };

  // Find 15 percent of tax_incom
  const taxIncome15pc =
    form?.tax_income * 0.15 > 500000 ? 500000 : form?.tax_income * 0.15;

  const RemainProvident = useMemo(() => {
    return getcalPaid(getSumFundProvident(), taxIncome15pc);
  }, [getSumFundProvident(), taxIncome15pc]);

  const SSFMax = (form) => {
    let tempIncome =
      form?.tax_income * 0.3 > 200000 ? 200000 : form?.tax_income * 0.3;
    // let tempMax = 0
    // if (sum7value >= (tempIncome - form?.fund_provident_ssf_value)) {
    //   tempMax = tempIncome - form?.fund_provident_ssf_value;
    // }else{
    //   tempMax = sum7value ;
    // }
    // return tempIncome;
    return sevenValueMax?.SSF_sum;
  };

  const RemainRMF = () => {
    let results = 0;
    results =
      RMFMax(form) - (form?.tax_plan_rmf + form?.fund_provident_rmf_value);
    return Number(results);
  };

  const RemainESG = () => {
    let results = 0;
    results = 100000 - (form?.tax_fund_esg + form?.fund_esg);
    return Number(results);
  };

  const RemainSSF = () => {
    let results = 0;
    results =
      SSFMax(form) - (form?.tax_plan_ssf + form?.fund_provident_ssf_value);
    return Number(results);
  };

  const RemainPrivateSchoolTeacher = () => {
    let results = 0;
    results =
      500000 -
      (form?.tax_fund_PrivateSchoolTeacher +
        form?.fund_PrivateSchoolTeacher_value);
    return Number(results);
  };

  const getPercentBar = useMemo(
    () => getPercent(getCaltax(), displayTaxPaid),
    [getCaltax(), displayTaxPaid]
  );

  const getPercentBarAfter = useMemo(
    () => getPercent(displaySumAfterTaxPaid(), displayTaxPaid),
    [displaySumAfterTaxPaid(), displayTaxPaid]
  );

  const getPercentInsuaranceLife = useMemo(
    () =>
      getPercent(form?.tax_plan_insurance_life + form?.insurance_life, 100000),
    [form?.tax_plan_insurance_life + form?.insurance_life, 100000]
  );

  let valuehealth = () => {
    let sum = 0;
    sum =
      Number(form?.tax_plan_insurance_health) + Number(form?.insurance_health);

    return Number(sum);
  };

  const getPercentBarHealth = useMemo(
    () => getPercent(Number(valuehealth()), 25000),
    [valuehealth, 25000]
  );

  const getPercentDonate = useMemo(
    () =>
      getPercent(
        form?.tax_plan_political_donate + form?.political_donate,
        10000
      ),
    [form?.tax_plan_political_donate, form?.political_donate, 10000]
  );

  const getPercentBarAnnutity = useMemo(() => {
    if (form?.tax_income * 0.15 >= 200000) {
      return getPercent(
        form.tax_plan_insurance_annuity + form?.insurance_annuity,
        200000
      );
    }

    if (form?.tax_income * 0.15 < 200000) {
      return getPercent(
        form.tax_plan_insurance_annuity + form?.insurance_annuity,
        form?.tax_income * 0.15
      );
    }
  }, [form?.tax_plan_insurance_annuity, 200000, form?.tax_income]);

  const getPercentBarProvident = useMemo(
    () =>
      getPercent(form.tax_fund_provident + form?.fund_provident, taxIncome15pc),
    [form?.tax_fund_provident, taxIncome15pc]
  );

  const RMFRemain = (form) => {
    let tempValue =
      Number(form?.tax_income) * 0.3 > 500000
        ? 500000
        : Number(form?.tax_income) * 0.3;
    return (
      tempValue -
      form?.fund_provident_rmf_value -
      SSFMax(form) -
      form?.fund_provident_ssf_value
    );
  };

  const RMFMax = (form) => {
    let tempIncome =
      form?.tax_income * 0.3 > 500000 ? 500000 : form?.tax_income * 0.3;
    // return (
    //   tempIncome -
    //   form?.fund_provident_rmf_value -
    //   SSFMax(form) -
    //   form?.fund_provident_ssf_value -
    // );

    return sevenValueMax?.RMF_sum;
  };

  const getPercentBarRMF = useMemo(
    () =>
      getPercent(
        form.tax_plan_rmf + form?.fund_provident_rmf_value,
        RMFMax(form)
      ),
    [form?.tax_plan_rmf, RMFMax(form)]
  );

  const getPercentBarESG = useMemo(() => {
    if (form?.tax_income * 0.3 >= SSFMax(form)) {
      return getPercent(form.tax_fund_esg + form?.fund_esg, SSFMax(form));
    }

    if (form?.tax_income * 0.3 < SSFMax(form)) {
      return getPercent(
        form.tax_fund_esg + form?.fund_esg,
        form?.tax_income * 0.3
      );
    }
  }, [form?.tax_fund_esg, form?.tax_income]);

  const getPercentBarSSF = useMemo(
    () =>
      getPercent(
        form.tax_plan_ssf + form?.fund_provident_ssf_value,
        SSFMax(form)
      ),
    [form?.tax_plan_ssf, SSFMax(form)]
  );

  const ESGRemain = (form) => {
    let tempRemain =
      form?.tax_income * 0.3 >= 300000 ? 300000 : form?.tax_income * 0.3;

    if (userData?.is_premium) {
      tempRemain =
        IncomeTab4Calculation(taxData) * 0.3 >= 300000
          ? 300000
          : IncomeTab4Calculation(taxData) * 0.3;
    }

    return tempRemain - form?.fund_esg < 0 ? 0 : tempRemain - form?.fund_esg;
  };

  // ประกันชีวิต
  const onInsuranceLifeChange = (e) => {
    let value = numberWithoutComma(e.target.value);
    let newForm = { ...form, tax_plan_insurance_life: value };

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_insurance_life: false,
      });
      return setForm({ ...form, tax_plan_insurance_life: "" });
    } else {
      setError({
        ...error,
        tax_plan_insurance_life: false,
      });
    }

    if (value > InsuranceLifeRemain(newForm)) {
      setForm({ ...form, tax_plan_insurance_life: value });
      setFormAfter({ ...formAfter, tax_plan_insurance_life: value });
      setTaxData({ ...form, tax_plan_insurance_life: value });

      setError({
        ...error,
        tax_plan_insurance_life: true,
      });
      return;
    } else {
      setForm({ ...form, tax_plan_insurance_life: value });
      setFormAfter({ ...formAfter, tax_plan_insurance_life: value });
      setTaxData({ ...form, tax_plan_insurance_life: value });

      setError({
        ...error,
        tax_plan_insurance_life: false,
      });
      return;
    }
  };

  // ประกันสุขภาพ
  const onInsuranceHelthChange = (e) => {
    let value = numberWithoutComma(e.target.value);
    let newForm = { ...form, tax_plan_insurance_health: value };

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_insurance_health: false,
      });
      return setForm({ ...form, tax_plan_insurance_health: "" });
    } else {
      setError({
        ...error,
        tax_plan_insurance_health: false,
      });
    }

    if (value > InsuranceHealthRemain(newForm)) {
      setForm({ ...form, tax_plan_insurance_health: value });
      setFormAfter({ ...formAfter, tax_plan_insurance_health: value });
      setTaxData({ ...form, tax_plan_insurance_life: value });

      setError({
        ...error,
        tax_plan_insurance_health: true,
      });
    } else {
      setForm({ ...form, tax_plan_insurance_health: value });
      setFormAfter({ ...formAfter, tax_plan_insurance_health: value });
      setTaxData({ ...form, tax_plan_insurance_life: value });

      setError({
        ...error,
        tax_plan_insurance_health: false,
      });
    }
  };

  const onInsuranceAnnuity = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_insurance_annuity: false,
      });
      return setForm({ ...form, tax_plan_insurance_annuity: "" });
    } else {
      setError({
        ...error,
        tax_plan_insurance_annuity: false,
      });
    }

    if (value > InsuranceAnnuityRemain(form)) {
      setForm({ ...form, tax_plan_insurance_annuity: value });
      setFormAfter({ ...formAfter, tax_plan_insurance_annuity: value });
      setTaxData({ ...form, tax_plan_insurance_annuity: value });

      return setError({
        ...error,
        tax_plan_insurance_annuity: true,
      });
    } else {
      setForm({ ...form, tax_plan_insurance_annuity: value });
      setFormAfter({ ...formAfter, tax_plan_insurance_annuity: value });
      setTaxData({ ...form, tax_plan_insurance_annuity: value });

      return;
    }

    // setForm({ ...form, tax_plan_insurance_annuity: value });
    // setFormAfter({ ...formAfter, tax_plan_insurance_annuity: value });
  };

  // console.log("error",error)

  const onChangeRMF = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_rmf: false,
      });
      return setForm({ ...form, tax_plan_rmf: "" });
    } else {
      setError({
        ...error,
        tax_plan_rmf: false,
      });
    }

    if (value + Number(form?.fund_provident_rmf_value) > RMFMax(form)) {
      // if (value > RMFMax(form)) {
      setForm({ ...form, tax_plan_rmf: value });
      setFormAfter({ ...formAfter, tax_plan_rmf: value });
      setTaxData({...form, tax_plan_rmf: value})

      setError({
        ...error,
        tax_plan_rmf: true,
      });
      return;
    } else {
      setForm({ ...form, tax_plan_rmf: value });
      setFormAfter({ ...formAfter, tax_plan_rmf: value });
      setTaxData({...form, tax_plan_rmf: value})

      setError({
        ...error,
        tax_plan_rmf: false,
      });
      return;
    }
  };

  const onChangeESG = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_fund_esg: false,
      });
      return setForm({ ...form, tax_fund_esg: "" });
    } else {
      setError({
        ...error,
        tax_fund_esg: false,
      });
    }

    if (form?.tax_income * 0.3 > 300000) {
      if (value + Number(form?.fund_esg) > 300000) {
        setForm({ ...form, tax_fund_esg: value });
        setFormAfter({ ...formAfter, tax_fund_esg: value });
        setTaxData({ ...form, tax_fund_esg: value });

        setError({
          ...error,
          tax_fund_esg: true,
        });
        return;
      } else {
        setForm({ ...form, tax_fund_esg: value });
        setFormAfter({ ...formAfter, tax_fund_esg: value });
        setTaxData({ ...form, tax_fund_esg: value });

        return;
      }
    }

    if (form?.tax_income * 0.3 < 300000) {
      if (value + Number(form?.fund_esg) > form?.tax_income * 0.3) {
        setForm({ ...form, tax_fund_esg: value });
        setFormAfter({ ...formAfter, tax_fund_esg: value });
        setError({
          ...error,
          tax_fund_esg: true,
        });
        return;
      } else {
        setForm({ ...form, tax_fund_esg: value });
        setFormAfter({ ...formAfter, tax_fund_esg: value });
        return;
      }
    }

    setForm({ ...form, tax_fund_esg: value });
    setFormAfter({ ...formAfter, tax_fund_esg: value });
  };

  const onChangeSSF = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_ssf: false,
      });
      return setForm({ ...form, tax_plan_ssf: "" });
    } else {
      setError({
        ...error,
        tax_plan_ssf: false,
      });
    }

    if (value + Number(form?.fund_provident_ssf_value) > SSFMax(form)) {
      setForm({ ...form, tax_plan_ssf: value });
      setFormAfter({ ...formAfter, tax_plan_ssf: value });
      setTaxData({...form, tax_plan_ssf: value})

      setError({
        ...error,
        tax_plan_ssf: true,
      });

      return;
    } else {
      setForm({ ...form, tax_plan_ssf: value });
      setFormAfter({ ...formAfter, tax_plan_ssf: value });
      setTaxData({...form, tax_plan_ssf: value})

      setError({
        ...error,
        tax_plan_ssf: false,
      });
    }
  };

  const onChangeDonate = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_education_donate: false,
      });
      return setForm({ ...form, tax_plan_education_donate: "" });
    } else {
      setError({
        ...error,
        tax_plan_education_donate: false,
      });
    }

    if (value * 2 > (donateCap - Number(form?.education_donate || 0)) * 0.1) {
      setForm({ ...form, tax_plan_education_donate: value });
      setFormAfter({ ...formAfter, tax_plan_education_donate: value });
      return setError({
        ...error,
        tax_plan_education_donate: true,
      });
    } else {
      setError({
        ...error,
        tax_plan_education_donate: false,
      });
      setForm({ ...form, tax_plan_education_donate: value });
      setFormAfter({ ...formAfter, tax_plan_education_donate: value });
    }
  };

  const onChangeDonateEtc = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_other_donate: false,
      });
      return setForm({ ...form, tax_plan_other_donate: "" });
    } else {
      setError({
        ...error,
        tax_plan_other_donate: false,
      });
    }

    if (value > OtherDonateRemain(form)) {
      setForm({ ...form, tax_plan_other_donate: value });
      setFormAfter({ ...formAfter, tax_plan_other_donate: value });
      setError({
        ...error,
        tax_plan_other_donate: true,
      });
      return;
    } else {
      setForm({ ...form, tax_plan_other_donate: value });
      setFormAfter({ ...formAfter, tax_plan_other_donate: value });
      setError({
        ...error,
        tax_plan_other_donate: false,
      });
      return;
    }
  };

  const onChangeGorBorKhor = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_fund_GorBorKhor: false,
      });
      return setForm({ ...form, tax_fund_GorBorKhor: "" });
    } else {
      setError({
        ...error,
        tax_fund_GorBorKhor: false,
      });
    }

    if (value + Number(form?.fund_GorBorKhor_value) > 200000)
      return setError({
        ...error,
        tax_fund_GorBorKhor: true,
      });

    setForm({ ...form, tax_fund_GorBorKhor: value });
    setFormAfter({ ...formAfter, tax_fund_GorBorKhor: value });
  };

  const onChangePrivateSchoolTeacher = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_fund_PrivateSchoolTeacher: false,
      });
      return setForm({ ...form, tax_fund_PrivateSchoolTeacher: "" });
    } else {
      setError({
        ...error,
        tax_fund_PrivateSchoolTeacher: false,
      });
    }

    if (value + Number(form?.fund_PrivateSchoolTeacher_value) > 200000)
      return setError({
        ...error,
        tax_fund_PrivateSchoolTeacher: true,
      });

    setForm({ ...form, tax_fund_PrivateSchoolTeacher: value });
    setFormAfter({ ...formAfter, tax_fund_PrivateSchoolTeacher: value });
  };

  const checkPercent = () => {
    let result = 0;
    result =
      ((getCaltax() - Number(displaySumAfterTaxPaid())) / getCaltax()) * 100;

    if (isNaN(result)) {
      return 0;
    }

    return Number(result)?.toFixed(2);
  };

  const checkSum7values = () => {
    let sum = 0;

    sum =
      Number(form?.fund_nsf || 0) +
      Number(form?.tax_plan_insurance_annuity || 0) +
      Number(form?.insurance_annuity || 0) +
      Number(form?.tax_fund_provident || 0) +
      Number(form?.fund_provident || 0) +
      Number(form?.tax_fund_nsf || 0) +
      Number(form?.tax_plan_rmf || 0) +
      Number(form?.fund_provident_rmf_value || 0) +
      Number(form?.tax_plan_ssf || 0) +
      Number(form?.fund_provident_ssf_value || 0) +
      Number(form?.fund_GorBorKhor_value || 0) +
      Number(form?.fund_PrivateSchoolTeacher_value || 0);

    return Number(sum);
  };

  const checkSumFillValue = () => {
    let sum = 0;

    sum =
      Number(form?.tax_plan_insurance_annuity) +
      Number(form?.tax_fund_esg) +
      Number(form?.tax_plan_rmf) +
      Number(form?.tax_plan_ssf);

    return Number(sum);
  };

  useEffect(() => {
    const sumValue = checkSum7values();

    if (sumValue > 500000) {
      setError((prev) => ({ ...prev, sum_Fund: true }));
    } else {
      setError((prev) => ({ ...prev, sum_Fund: false }));
    }
  }, [
    form?.tax_plan_insurance_annuity,
    form?.insurance_annuity,
    form?.tax_fund_provident,
    form?.fund_provident,
    form?.tax_fund_nsf,
    form?.tax_plan_rmf,
    form?.fund_provident_rmf_value,
    form?.tax_plan_ssf,
    form?.fund_provident_ssf_value,
  ]);

  useEffect(() => {
    if (checkSumFillValue() > 500000) {
      setError({ ...error, sum_after_Fund: true });
    } else {
      setError({ ...error, sum_after_Fund: false });
    }
  }, [
    form?.tax_plan_insurance_annuity,
    form?.tax_fund_esg,
    form?.tax_plan_rmf,
    form?.tax_plan_ssf,
  ]);

  //----------------------------------------------------------------------------//

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setForm({
        ...form,
        deduct_summary_af: displaySumDeduction(),
      });
    }
    return () => {
      ignore = true;
    };
  }, [
    form?.deduct_summary,
    form?.tax_plan_insurance_life,
    form?.tax_plan_insurance_health,
    form?.tax_plan_insurance_annuity,
    form?.tax_fund_provident,
    form?.tax_fund_nsf,
    form?.tax_plan_rmf,
    form?.tax_plan_ssf,
    form?.tax_plan_political_donate,
    form?.tax_fund_esg,
  ]);

  // mark
  useEffect(() => {
    const newForm = {
      ...form,
      after_tax_paid: displaySumAfterTaxPaid(),
      final_tax: userData?.is_premium
        ? displaySumAfterTaxPaid() - TabThreeWithHoldingTax(taxData)
        : displaySumAfterTaxPaid() - form.tax_withholding,
      net_income: getNetIncome(),
    };
    setForm(newForm);
    setTaxData(newForm);
  }, [form.tax_withholding, displaySumAfterTaxPaid()]);

  useEffect(() => {
    let ignore = false;
    const targetElement = document.getElementById("first_reload");

    if (targetElement && !ignore) {
      const targetOffset =
        targetElement.getBoundingClientRect().top + window.scrollY + -190;

      window.scrollTo({
        top: targetOffset,
        behavior: "smooth",
      });
    }

    return () => {
      ignore = true;
    };
  }, []);

  const onSetForm = (form) => {
    setForm(form);
  };

  return (
    <div id="first_reload">
      <div className="my-9">
        {/*------------------ ก่อนวางแผน -------------------*/}
        <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5 mb-4`}>
          <div className=" flex justify-between mb-[6px]">
            <div className=" text-red-500 text-sm font-semibold">
              ก่อนวางแผน
            </div>
            <div className={STYLE_NUMBER_PLAN_BAR}>
              {numeral(getCaltax())?.format("0,0")} บาท
            </div>
          </div>
          <Progress value={getPercentBar} color="red" className="bg-white" />
          <div className="text-right text-xs text-maingray mt-2.5 -mb-2.5">
            * ยังไม่ได้คำนวณ ภาษีหัก ณ ที่จ่าย
          </div>
        </div>

        {/*------------------ หลังวางแผน -------------------*/}
        {/* displaySumAfterTaxPaid */}
        {/* displaySumBudget() === 0 */}
        {displaySumBudget() === 0 ? (
          ""
        ) : (
          <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5 mb-4`}>
            <div className=" flex justify-between mb-[6px]">
              <div className=" text-maingreen text-sm font-semibold">
                หลังวางแผน
              </div>
              <div className={STYLE_NUMBER_PLAN_BAR}>
                {numeral(displaySumAfterTaxPaid())?.format("0,0")} บาท
              </div>
            </div>
            <Progress
              value={getPercentBarAfter}
              color="green"
              className="bg-white"
            />
            <div className="text-right text-xs text-maingray mt-2.5 -mb-2.5">
              * ยังไม่ได้คำนวณ ภาษีหัก ณ ที่จ่าย
            </div>
          </div>
        )}

        {/*------------------ ภาษีหัก ณ ที่จ่าย -------------------*/}
        <div className={`${STYLE_CARD_BG} px-5 mb-4`}>
          <div className=" flex justify-between ">
            <div className=" text-mainblue text-sm font-semibold">
              ภาษีหัก ณ ที่จ่าย
            </div>
            <div className={STYLE_NUMBER_PLAN_BAR}>
              {userData?.is_premium
                ? numeral(TabThreeWithHoldingTax(taxData))?.format("0,0")
                : numeral(form?.tax_withholding)?.format("0,0")}{" "}
              บาท
            </div>
          </div>
        </div>

        {/*----------- Card แสดงผลของยอดสรุปภาษีทั้งหมด ----------*/}
        <CardConclusionTax
          form={form}
          TabThreeWithHoldingTax={TabThreeWithHoldingTax(taxData)}
          userData={userData}
        />

        {/*--------------------- วางแผนภาษี --------------------*/}
        <React.Fragment>
          <div className=" my-[14px] text-center text-maincyan font-semibold text-[28px]  font-IBMPlexSansThai">
            มาวางแผนภาษีกันเถอะ !
          </div>
          <CardMain
            icon={true}
            title="วางแผนภาษี"
            onClick={() => {
              setOpenTaxPlanInfo(true);
            }}
            panel={
              <div className="mt-3">
                <div className=" flex flex-col justify-center items-center">
                  <div className="text-xl font-semibold text-maincyan text-center">
                    {numeral(displaySumBudget())?.format("0,0") + " "}
                    {displaySumBudget() ? "บาท" : ""}
                  </div>
                  <div className=" text-sm text-maingray font-medium">
                    งบประมาณที่ใช้
                  </div>
                </div>
                <div className=" mt-5 border-t border-gray55">
                  <div className="text-xs text-maingray text-center mt-1">
                    รายละเอียด
                  </div>
                </div>

                {/*---------- ประกันชีวิต ----------*/}
                <Accordion
                  open={open === 1}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 1 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 1 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(1);
                    }}
                  >
                    {open === 1 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>ประกันชีวิต</div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>ประกันชีวิต</div>
                        <span
                          className={cn(" text-maincyan font-medium ", {
                            "text-red-500":
                              error?.tax_plan_insurance_life ||
                              InsuranceLifeRemain(form) <
                                form?.tax_plan_insurance_life,
                          })}
                        >
                          {form?.tax_plan_insurance_life
                            ? numeral(form?.tax_plan_insurance_life)?.format(
                                "0,0"
                              )
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className="  ">
                      {(error?.tax_plan_insurance_life ||
                        InsuranceLifeRemain(form) <
                          form?.tax_plan_insurance_life) && (
                        <div className=" text-center text-red-500 font-medium mb-1 text-xs">
                          เกินสิทธิ โปรดระบุตามสิทธิคงเหลือ
                        </div>
                      )}
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>
                        <NumericFormat
                          maxLength={7}
                          value={form?.tax_plan_insurance_life}
                          placeholder="0"
                          onChange={onInsuranceLifeChange}
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_plan_insurance_life ||
                            InsuranceLifeRemain(form) <
                              form?.tax_plan_insurance_life
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />
                        <div>บาท</div>
                      </div>

                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(cloneInsurance?.insurance_life)?.format(
                            "0,0"
                          )}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(
                            InsuranceLifeRemain(form) < 0
                              ? 0
                              : InsuranceLifeRemain(form)
                          )?.format("0,0")}
                        </span>
                        บาท{" "}
                      </div>

                      <div className={STYLE_DOK_JAN}>
                        *เบี้ยประกันชีวิตเมื่อรวมกับเบี้ยประกันสุขภาพแล้วต้องไม่เกิน
                        100,000 บาท{" "}
                      </div>
                      <div className={STYLE_DOK_JAN}>
                        ** หากลดหย่อนแล้วเกิน 100,000 บาท
                        ระบบจะให้สิทธิลดหย่อนเบี้ยนประกันชีวิตก่อน
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- ประกันสุขภาพ ----------*/}
                <Accordion
                  open={open === 2}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 2 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 2 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(2);
                    }}
                  >
                    {open === 2 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>ประกันสุขภาพ</div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>ประกันสุขภาพ</div>
                        <span
                          className={cn(" text-maincyan font-medium  ", {
                            "text-red-500":
                              error?.tax_plan_insurance_health ||
                              InsuranceHealthRemain(form) <
                                form?.tax_plan_insurance_health,
                          })}
                        >
                          {form?.tax_plan_insurance_health
                            ? numeral(form?.tax_plan_insurance_health)?.format(
                                "0,0"
                              )
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className="  ">
                      {(error?.tax_plan_insurance_health ||
                        InsuranceHealthRemain(form) <
                          form?.tax_plan_insurance_health) && (
                        <div className=" text-center text-red-500 font-medium mb-1 text-xs">
                          เกินสิทธิ โปรดระบุตามสิทธิคงเหลือ
                        </div>
                      )}
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>
                        <NumericFormat
                          maxLength={6}
                          value={form?.tax_plan_insurance_health}
                          onChange={onInsuranceHelthChange}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_plan_insurance_health ||
                            InsuranceHealthRemain(form) <
                              form?.tax_plan_insurance_health
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />
                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(InsuranceHealthBoughtAlready(form))?.format(
                            "0,0"
                          )}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(
                            InsuranceHealthRemain(form) < 0
                              ? 0
                              : InsuranceHealthRemain(form)
                          )?.format("0,0")}
                        </span>
                        บาท{" "}
                      </div>
                      <div className={STYLE_DOK_JAN}>
                        *เบี้ยประกันสุขภาพเมื่อรวมกับเบี้ยประกันชีวิตแล้วต้องไม่เกิน
                        100,000 บาท
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- ประกันชีวิตแบบบำนาญ ----------*/}
                <Accordion
                  open={open === 3}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 3 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 3 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(3);
                    }}
                  >
                    {open === 3 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>
                            ประกันชีวิตแบบบำนาญ
                          </div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>
                          ประกันชีวิตแบบบำนาญ
                        </div>
                        <span
                          className={cn(" text-maincyan font-medium  ", {
                            "text-red-500":
                              error?.tax_plan_insurance_annuity ||
                              error?.sum_Fund,
                          })}
                        >
                          {form?.tax_plan_insurance_annuity
                            ? numeral(form?.tax_plan_insurance_annuity)?.format(
                                "0,0"
                              )
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className="">
                      <div className="flex justify-center mb-2">
                        {error?.sum_Fund ||
                          (InsuranceAnnuityRemain(form) < 0 && (
                            <ErrorPrivilege
                              errortext={`เกินสิทธิ โปรดระบุตามคำแนะนำ `}
                            />
                          ))}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>
                        <NumericFormat
                          maxLength={7}
                          value={form?.tax_plan_insurance_annuity}
                          onChange={onInsuranceAnnuity}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_plan_insurance_annuity ||
                            InsuranceAnnuityRemain(form) < 0
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />
                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(cloneInsurance?.insurance_annuity)?.format(
                            "0,0"
                          )}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {InsuranceAnnuityRemain(form) < 0
                            ? 0
                            : numeral(InsuranceAnnuityRemain(form))?.format(
                                "0,0"
                              )}
                        </span>
                        บาท{" "}
                      </div>
                      <div>
                        <div className={STYLE_DOK_JAN}>
                          * กองทุนสำรองเลี้ยงชีพ, เงินสะสมกองทุนออมแห่งชาติ,
                          ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF),
                          กองทุนรวมเพื่อการออม(SSF),
                          กองทุนสงเคราะห์ครูโรงเรียนเอกชน,
                          กองทุนบำเหน็จบำนาญข้าราชการ เมื่อรวมกันแล้วต้องไม่เกิน
                          500,000 บาท
                        </div>
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG) ----------*/}
                <Accordion
                  open={open === 6}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 6 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 6 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(6);
                    }}
                  >
                    {open === 6 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>
                            ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน
                            (Thailand ESG)
                          </div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>
                          ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน
                          (Thailand ESG)
                        </div>
                        <span
                          className={cn(" text-maincyan font-medium  ", {
                            "text-red-500":
                              error?.tax_fund_esg || error?.sum_Fund,
                          })}
                        >
                          {form?.tax_fund_esg
                            ? numeral(form?.tax_fund_esg)?.format("0,0")
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className=" px-10">
                      <div className="flex justify-center mb-2">
                        {error?.tax_fund_esg &&
                          form?.tax_income * 0.3 >= 300000 &&
                          !error?.sum_Fund && (
                            <ErrorPrivilege
                              errortext={`เกินสิทธิ โปรดระบุตามคำแนะนำ`}
                            />
                          )}
                        {error?.tax_fund_esg &&
                          form?.tax_income * 0.3 < 300000 &&
                          !error?.sum_Fund && (
                            <ErrorPrivilege
                              errortext={`เกินสิทธิ โปรดระบุตามคำแนะนำ`}
                            />
                          )}
                        {error?.sum_Fund && (
                          <ErrorPrivilege
                            errortext={`เกินสิทธิ โปรดระบุตามคำแนะนำ`}
                          />
                        )}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>

                        <NumericFormat
                          maxLength={7}
                          value={form?.tax_fund_esg}
                          onChange={onChangeESG}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_fund_esg
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />

                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.fund_esg
                            ? numeral(form?.fund_esg)?.format("0,0")
                            : 0}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(ESGRemain(form)).format("0,0")}
                        </span>
                        บาท{" "}
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- กองทุนรวมเพื่อการเลี้ยงชีพ (RMF) ----------*/}
                <Accordion
                  open={open === 7}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 7 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 7 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(7);
                    }}
                  >
                    {open === 7 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>
                            กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)
                          </div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>
                          กองทุนรวมเพื่อการเลี้ยงชีพ (RMF){" "}
                        </div>
                        <span
                          className={cn(" text-maincyan font-medium  ", {
                            "text-red-500":
                              error?.tax_plan_rmf || error?.sum_Fund,
                          })}
                        >
                          {form?.tax_plan_rmf
                            ? numeral(form?.tax_plan_rmf)?.format("0,0")
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className=" px-10">
                      <div className="flex justify-center mb-2">
                        {error?.tax_plan_rmf && (
                          <ErrorPrivilege
                            errortext={`เกินสิทธิ โปรดระบุตามคำแนะนำ`}
                          />
                        )}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>
                        <NumericFormat
                          maxLength={7}
                          value={form?.tax_plan_rmf}
                          onChange={onChangeRMF}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_plan_rmf
                              ? // || (RemainRMF() < 0)
                                STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />
                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.fund_provident_rmf_value
                            ? numeral(form?.fund_provident_rmf_value)?.format(
                                "0,0"
                              )
                            : 0}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(RMFMax(form) < 0 ? 0 : RMFMax(form))?.format(
                            "0,0"
                          )}
                        </span>
                        บาท{" "}
                      </div>
                    </div>
                    <div>
                      <div className={STYLE_DOK_JAN}>
                        * กองทุนสำรองเลี้ยงชีพ, เงินสะสมกองทุนออมแห่งชาติ,
                        ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF),
                        กองทุนรวมเพื่อการออม(SSF),
                        กองทุนสงเคราะห์ครูโรงเรียนเอกชน,
                        กองทุนบำเหน็จบำนาญข้าราชการ เมื่อรวมกันแล้วต้องไม่เกิน
                        500,000 บาท
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- กองทุนรวมเพื่อการออม (SSF) ----------*/}
                <Accordion
                  open={open === 8}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 8 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 8 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(8);
                    }}
                  >
                    {open === 8 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>
                            กองทุนรวมเพื่อการออม (SSF)
                          </div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>
                          กองทุนรวมเพื่อการออม (SSF){" "}
                        </div>
                        <span
                          className={cn(" text-maincyan font-medium  ", {
                            "text-red-500":
                              error?.tax_plan_ssf || error?.sum_Fund,
                          })}
                        >
                          {form?.tax_plan_ssf
                            ? numeral(form?.tax_plan_ssf)?.format("0,0")
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className=" px-10">
                      <div className="flex justify-center mb-2">
                        {error?.tax_plan_ssf && (
                          <ErrorPrivilege
                            errortext={`เกินสิทธิ โปรดระบุตามคำแนะนำ`}
                          />
                        )}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>

                        <NumericFormat
                          maxLength={7}
                          value={form?.tax_plan_ssf}
                          onChange={onChangeSSF}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_plan_ssf
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />

                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.fund_provident_ssf_value
                            ? numeral(form?.fund_provident_ssf_value)?.format(
                                "0,0"
                              )
                            : 0}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(SSFMax(form) < 0 ? 0 : SSFMax(form))?.format(
                            "0,0"
                          )}
                        </span>
                        บาท{" "}
                      </div>
                    </div>
                    <div>
                      <div className={STYLE_DOK_JAN}>
                        * กองทุนสำรองเลี้ยงชีพ, เงินสะสมกองทุนออมแห่งชาติ,
                        ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF),
                        กองทุนรวมเพื่อการออม(SSF),
                        กองทุนสงเคราะห์ครูโรงเรียนเอกชน,
                        กองทุนบำเหน็จบำนาญข้าราชการ เมื่อรวมกันแล้วต้องไม่เกิน
                        500,000 บาท
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- เงินบริจาคสนับสนุนการศึกษา / สถานพยาบาล / สภากาชาดไทย / อื่นๆ ----------*/}
                <Accordion
                  open={open === 9}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 9 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 9 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(9);
                    }}
                  >
                    {open === 9 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>
                            เงินบริจาคสนับสนุนการศึกษา / สถานพยาบาล /
                            สภากาชาดไทย / อื่นๆ
                          </div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>
                          เงินบริจาคสนับสนุนการศึกษา / สถานพยาบาล / สภากาชาดไทย
                          / อื่นๆ{" "}
                        </div>
                        <span
                          className={cn(" text-maincyan font-medium  ", {
                            "text-red-500": error?.tax_plan_education_donate,
                          })}
                        >
                          {form?.tax_plan_education_donate
                            ? numeral(form?.tax_plan_education_donate)?.format(
                                "0,0"
                              )
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className=" px-10">
                      <div className="flex justify-center mb-2">
                        {error?.tax_plan_education_donate && (
                          // ||
                          //   SupportEducationRemain(form) <= 0
                          <ErrorPrivilege
                            errortext={`เกินสิทธิ โปรดระบุตามคำแนะนำ`}
                          />
                        )}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>บริจาคเพิ่ม</div>
                        <NumericFormat
                          maxLength={7}
                          value={form?.tax_plan_education_donate}
                          onChange={onChangeDonate}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_plan_education_donate
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />
                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ได้รับสิทธิ์ 2 เท่า
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.tax_plan_education_donate
                            ? numeral(
                                form?.tax_plan_education_donate * 2
                              )?.format("0,0")
                            : 0}
                        </span>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.education_donate
                            ? numeral(form?.education_donate)?.format("0,0")
                            : 0}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(
                            SupportEducationRemain(form) < 0
                              ? 0
                              : SupportEducationRemain(form)
                          )?.format("0,0")}
                        </span>
                        บาท{" "}
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- เงินบริจาค ----------*/}
                <Accordion
                  open={open === 10}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 10 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 10 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(10);
                    }}
                  >
                    {open === 10 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>เงินบริจาค</div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>เงินบริจาค</div>
                        <span
                          className={cn(" text-maincyan font-medium  ", {
                            "text-red-500": error?.tax_plan_other_donate,
                          })}
                        >
                          {form?.tax_plan_other_donate
                            ? numeral(form?.tax_plan_other_donate)?.format(
                                "0,0"
                              )
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className=" px-10">
                      <div className="flex justify-center mb-2">
                        {error?.tax_plan_other_donate && (
                          // ||
                          //   OtherDonateRemain(form) <= 0
                          <ErrorPrivilege
                            errortext={`เกินสิทธิ โปรดระบุตามคำแนะนำ`}
                          />
                        )}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>

                        <NumericFormat
                          maxLength={7}
                          value={form?.tax_plan_other_donate}
                          onChange={onChangeDonateEtc}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_plan_other_donate
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />

                        <div>บาท</div>
                      </div>

                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.other_donate
                            ? numeral(form?.other_donate)?.format("0,0")
                            : 0}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(
                            OtherDonateRemain(form) < 0
                              ? 0
                              : OtherDonateRemain(form)
                          )?.format("0,0")}
                        </span>
                        บาท{" "}
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>
              </div>
            }
          />
        </React.Fragment>
      </div>
    </div>
  );
};
export default ViewStepThreeTaxPlan;
