import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn, toFloat2 } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  key_property_invest_category,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";
import { DonutChartInvestMain } from "../../../components/chart/DonutChartMain";
import LineChartSavingMain from "../../saving_plan/components/LineChartSaving.Main";
import { useSavingStore } from "../../../_store/savingStore";

function ExpenseFuturePDF({ page, datalist = [] }) {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);

  console.log("datalist", datalist);

  //   const futureItems = useMemo(() => {
  //     const newFutureItems = [];
  //     let mergeItems = [
  //       {
  //         key: "บ้าน",
  //         label: "ซื้อบ้าน",
  //         icon: "house.svg",
  //       },
  //       {
  //         key: "รถยนต์",
  //         label: "ซื้อรถยนต์",
  //         icon: "car.svg",
  //       },
  //     ];

  //     if (futurePlanData?.expenese_home_items?.length > 0) {
  //       mergeItems[0] = futurePlanData?.expenese_home_items[0];
  //       mergeItems[0].icon = "house.svg";
  //     }
  //     if (futurePlanData?.expenese_car_items?.length > 0) {
  //       mergeItems[0] = futurePlanData?.expenese_car_items[0];
  //       mergeItems[0].icon = "car.svg";
  //     }

  //     if (mergeItems.length > 0) {
  //       for (const mergeItem of mergeItems) {
  //         newFutureItems.push({
  //           label: mergeItem.label,
  //           icon: mergeItem.icon,
  //           ...mergeItem,
  //           options: [
  //             {
  //               label: "คาดการณ์ไว้อีก",
  //               value: mergeItem.year ? `${mergeItem.year} ปี` : "ปี",
  //             },
  //             {
  //               label: "เงินดาวน์",
  //               value: mergeItem.down
  //                 ? `${numeral(mergeItem.down).format("0,0")} บาท`
  //                 : "บาท",
  //             },
  //             {
  //               label: `ออมเงิน/ลงทุน เพื่อเงินดาวน์${mergeItem.key} ได้ดอกเบี้ย (ต่อปี)`,
  //               value: mergeItem.invest_down_interest
  //                 ? `${mergeItem.invest_down_interest} ปี`
  //                 : "ปี",
  //             },
  //             {
  //               label: "ต้องออมเงินลงทุน ปีละ",
  //               value: mergeItem.sum_invest_five
  //                 ? `${numeral(mergeItem.sum_invest_five).format("0,0")} บาท`
  //                 : "บาท",
  //             },
  //             {
  //               label: "มูลค่ารถยนต์ (บาท)",
  //               value: mergeItem.amount
  //                 ? `${numeral(mergeItem.amount).format("0,0")} บาท`
  //                 : "บาท",
  //             },
  //             {
  //               label: `ดอกเบี้ย${mergeItem.key} (บาท)`,
  //               value: mergeItem.interest
  //                 ? `${numeral(mergeItem.interest).format("0,0")} บาท`
  //                 : "บาท",
  //             },
  //             {
  //               label: "ระยะเวลาที่ต้องการผ่อน",
  //               value: mergeItem.period ? `${mergeItem.period} ปี` : "บาท",
  //             },
  //           ],
  //         });
  //       }
  //     }
  //     return newFutureItems;
  //   }, [futurePlanData]);

  const futureItems = [];

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="แผนอนาคต"
        icon="/images/personal.svg"
        classNameIcon="w-[24px] h-[27px]"
      />
      {/* แผนอนาคต */}
      <PdfCard className={"flex flex-col space-y-3"} title={"รายจ่ายในอนาคต"}>
        {datalist.map((item, index) => {
          return (
            <PdfCardWhite
              key={index}
              className={cn("flex flex-col space-y-2 px-3")}
            >
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12, "text-left")}>
                {item?.title}
              </div>
              <PdfLine />
              <div className="flex items-center space-x-2">
                <div>
                  <img src={`/images/${item.icon}`} />
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                  {item?.label}
                </div>
              </div>
              <PdfCardGray className={cn("px-2")}>
                <div className="flex justify-between space-y-1">
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {"คาดการณ์ไว้อีก"}
                  </div>
                  <div
                    className={cn(
                      STYLE_PDF_TEXT_MEDIUM_BLUE_11,
                      "flex space-x-2"
                    )}
                  >
                    {`${item?.interest_year} ปี`}
                  </div>
                </div>
                <div className="flex justify-between space-y-1">
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {"เงินดาวน์"}
                  </div>
                  <div
                    className={cn(
                      STYLE_PDF_TEXT_MEDIUM_BLUE_11,
                      "flex space-x-2"
                    )}
                  >
                    {`${numeral(item?.down).format('0,0')} บาท`}
                  </div>
                </div>
                <div className="flex justify-between space-y-1">
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {"ออมเงิน/ลงทุน เพื่อเงินดาวน์รถยนต์ ได้ดอกเบี้ย (ต่อปี)"}
                  </div>
                  <div
                    className={cn(
                      STYLE_PDF_TEXT_MEDIUM_BLUE_11,
                      "flex space-x-2"
                    )}
                  >
                    {`${numeral(item?.sum_invest_five).format('0,0')} บาท`}
                  </div>
                </div>
                <div className="flex justify-between space-y-1">
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {"มูลค่าบ้าน (บาท)"}
                  </div>
                  <div
                    className={cn(
                      STYLE_PDF_TEXT_MEDIUM_BLUE_11,
                      "flex space-x-2"
                    )}
                  >
                    {`${numeral(item?.value).format('0,0')} บาท`}
                  </div>
                </div>
                <div className="flex justify-between space-y-1">
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {"ดอกเบี้ยบ้าน (บาท)"}
                  </div>
                  <div
                    className={cn(
                      STYLE_PDF_TEXT_MEDIUM_BLUE_11,
                      "flex space-x-2"
                    )}
                  >
                    {`${numeral(item?.pay_per_year).format('0,0')} บาท`}
                  </div>
                </div>
                <div className="flex justify-between space-y-1">
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {"ระยะเวลาที่ต้องการผ่อน"}
                  </div>
                  <div
                    className={cn(
                      STYLE_PDF_TEXT_MEDIUM_BLUE_11,
                      "flex space-x-2"
                    )}
                  >
                    {`${numeral(item?.period_year).format('0,0')} ปี`}
                  </div>
                </div>
              </PdfCardGray>
              {/* <div className={cn("mt-2 flex justify-between")}>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                  ต้องชำระงวดละ
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                  {numeral(item?.sum_amount_per_period).format("0,0")} บาท
                </div>
              </div> */}
            </PdfCardWhite>
          );
        })}
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default ExpenseFuturePDF;
