import { HeadWithIcon } from "../../../components/header/Header";
import {
  STYLE_PADDING_MAIN,
  STYLE_TEXT_BLUE,
  STYLE_TEXT_CYAN,
} from "../../../utils/useStyle";
import { CardDebt } from "../styles/Card";
import { ViewTableDebt } from "./VIDebtTable";
import { VIDrawerDebtDetails } from "./VIDrawerDebtDetails";
import { useBalanceStore } from "../../../_store/balanceStore";
import { useMemo, useState } from "react";
import {
  OPTIONS_TYPE_CAR,
  OPTIONS_TYPE_PLACE,
  options_debt_long,
} from "../../balance/functions";
import { MTButton } from "../../../components/button/MTButton";
import { useNavigate } from "react-router-dom";
import { BarChartGroupDebtRandomColor } from "../styles/ChartDebt";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  calculateLoanDetails,
  calculateLoanTermPeriod,
  calDebtCreditCard,
  calTotalCreditorFloatInterest,
  generateAmortizationSchedule,
  round,
  toFloat2,
} from "../../../utils/useFunctions";
import _ from "lodash";
import { options_debt } from "../../balance/styles/Card";
import { calPeriodShortTerm } from "../../balance/component/VIDrawerDebt.Main";

export const calAmountInterest = (amount, interest, days) => {
  return toFloat2(((amount * interest) / 100) * (days / 365));
};

const ViewDebtPlan = ({ openInfo, setOpenInfo }) => {
  // ข้อมูลงบดุล
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }

  const mergeItems = useMemo(() => {
    let items = [];

    if (balanceData?.debt_long_list?.length > 0) {
      items = [...balanceData?.debt_long_list];
    }

    if (balanceData?.debt_short_list?.length > 0) {
      items = [...items, ...balanceData?.debt_short_list];
    }

    return items;
  }, []);


  return (
    <div className={`${STYLE_PADDING_MAIN} space-y-5 mb-10`}>
      {/*---------------------------------------- ตาราง ----------------------------------------*/}
      <ViewTableDebt
        debtShortList={balanceData?.debt_short_list}
        debtLongList={balanceData?.debt_long_list}
      />

      <HeadWithIcon title={"สถานการณ์ปัจจุบัน"} img={"../images/stg_1.png"} />
      {/*---------------------------------------- หนี้สินระยะสั้น ----------------------------------------*/}
      <>
        <div className=" text-mainblue font-semibold">หนี้สินระยะสั้น</div>
        {balanceData?.debt_short_list?.length > 0 ? (
          <>
            {/*---- บัตรเครดิต ----*/}
            {balanceData?.debt_short_list?.map((item, index) => {
              if (item?.options_debt_short === "debt_creditcard")
                return (
                  <div key={index} className="">
                    {item?.credit_cards?.map((item2, index) => {
                      // เจ้าหนี้ได้เงินทั้งสิน
                      const totalCreditor = () => {
                        let result = 0;
                        result +=
                          item2?.start_amount +
                          item2?.start_amount * (item2?.interest / 100);
                        return result;
                      };
                      // เป็นดอกเบี้ยเงินกู้
                      const totalInterestLoan = () => {
                        let result = 0;
                        result += totalCreditor() - item2?.start_amount;
                        return result;
                      };

                      const getValueZeroIsNaN = (value) => {
                        if (_.isNaN(value)) return 0;
                        return value || 0;
                      };

                      const creditor = calDebtCreditCard(item2);

                      // console.log("creditor", creditor)

                      return (
                        <div key={index} className="mb-4">
                          <CardDebt
                            title={`บัตรเครดิต`}
                            name={item2?.creditor_name}
                            total_start_overdue={item2?.unpaid_amount}
                            total_overdue={item2?.unpaid_amount}
                            onClick={() => {
                              setOpenInfo(!openInfo);
                            }}
                            options_debt_short="creditcard"
                            isCreditCard
                            interest_loan_peryear={item2?.interest}
                            total_payment={item2?.pay_permonth}
                            period={Math.ceil(
                              item2?.unpaid_amount / item2?.pay_permonth
                            )}
                            periodCustom={creditor?.creditor_items?.length}
                            total_creditor={creditor.total_creditor}
                            total_interest_loan={
                              creditor.total_creditor_interest
                            }
                            type_payment_ori={item2?.type_payment}
                            total_loan={item2?.unpaid_amount}
                            type_payment={
                              item2?.type_payment === "pay_minimum"
                                ? "จ่ายขั้นต่ำ"
                                : item2?.type_payment === "pay_full"
                                ? "จ่ายเต็มจำนวน"
                                : "จ่ายแบบระบุจำนวน"
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- เงินกู้ระยะสั้น ----*/}
            {balanceData?.debt_short_list?.map((item, index) => {
              if (item?.options_debt_short === "debt_shortterm")
                return (
                  <div key={index} className="">
                    {item?.short_terms?.map((item2, index) => {

                      const interest_min = toFloat2(
                        (item2.unpaid_amount * item2.interest) / 100
                      );

                      const loanDetails = generateAmortizationSchedule(
                        item2.unpaid_amount,
                        item2.interest,
                        item2.pay_permonth
                      );

                      const totalInterest = loanDetails.reduce((acc, item) => {
                        return acc + item?.interestPayment;
                      }, 0);
                      const totalCreditor = round(
                        item2?.unpaid_amount + totalInterest,
                        0
                      );

                      return (
                        <div key={index} className="mb-4">
                          <CardDebt
                            title={`หนี้กู้ระยะสั้น`}
                            name={item2?.creditor_name}
                            total_start_overdue={item2?.unpaid_amount}
                            total_overdue={item2?.unpaid_amount}
                            onClick={() => {
                              setOpenInfo(!openInfo);
                            }}
                            interest_loan_peryear={item2?.interest}
                            total_payment={item2?.pay_permonth}
                            period={item2?.period || 0}
                            total_creditor={totalCreditor}
                            total_interest_loan={round(totalInterest, 0)}
                            total_loan={item2?.unpaid_amount}
                            type_payment={
                              item2?.type_payment === "pay_minimum"
                                ? "จ่ายขั้นต่ำ"
                                : item2?.type_payment === "pay_full"
                                ? "จ่ายเต็มจำนวน"
                                : "จ่ายแบบระบุจำนวน"
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                );
            })}

            {/*---- หนี้นอกระบบ ----*/}
            {balanceData?.debt_short_list?.map((item, index) => {
              if (item?.options_debt_short === "debt_informal_shortterm")
                return (
                  <div key={index} className="">
                    {item?.informal_short_terms?.map((item2, index) => {
                      // เจ้าหนี้ได้เงินทั้งสิน
                      const totalCreditor = () => {
                        // let result = 0;
                        // result +=
                        //   item2?.unpaid_amount +
                        //   item2?.unpaid_amount * (item2?.interest / 100);
                        // return result
                        return item2?.start_amount + totalInterestLoan();
                      };

                      // เป็นดอกเบี้ยเงินกู้
                      const totalInterestLoan = () => {
                        // let result = 0;
                        // result += totalCreditor() - item2?.unpaid_amount;
                        // return result;
                        const interest_period =
                          item2?.unpaid_amount * (item2.interest / 100);
                        let interest = 0;
                        let period = 1;
                        if (item2.compounding_type === "daily") {
                          period = 365;
                        } else if (item2.compounding_type === "weekly") {
                          period = 52;
                        } else if (item2.compounding_type === "monthly") {
                          period = 12;
                        }

                        interest = interest_period * period;
                        return interest;
                      };

                      const getCompoundingType = () => {
                        if (item2.compounding_type === "daily") {
                          return "รายวัน";
                        } else if (item2.compounding_type === "weekly") {
                          return "รายสัปดาห์";
                        } else if (item2.compounding_type === "monthly") {
                          return "รายเดือน";
                        }
                        return "รายปี";
                      };

                      return (
                        <div key={index} className="mb-4">
                          <CardDebt
                            title={`หนี้นอกระบบ (รายวัน)`}
                            name={item2?.creditor_name}
                            total_start_overdue={item2?.unpaid_amount}
                            total_overdue={item2?.start_amount}
                            onClick={() => {
                              setOpenInfo(!openInfo);
                            }}
                            number_of_days={item2?.number_of_days}
                            compounding_type={getCompoundingType()}
                            interest_loan_peryear={item2?.interest}
                            total_payment={item2?.pay_permonth}
                            period={item2?.period || 0}
                            total_creditor={
                              item2.pay_permonth * item2?.number_of_days
                            }
                            total_interest_loan={
                              item2.pay_permonth * item2?.number_of_days -
                              item2?.start_amount
                            }
                            total_loan={item2?.start_amount}
                            type_payment={""}
                            pay_permonth={item2?.pay_permonth}
                            options_debt_short={item?.options_debt_short}
                            year={item2?.year}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- เงินกู้ระยะสั้นอื่น ๆ ----*/}
            {balanceData?.debt_short_list?.map((item, index) => {
              if (item?.options_debt_short === "debt_shortterm_other")
                return (
                  <div key={index} className="">
                    {item?.short_term_others?.map((item2, index) => {
                      // เจ้าหนี้ได้เงินทั้งสิน
                      // const totalCreditor = () => {
                      //   let result = 0;
                      //   result +=
                      //     item2?.start_amount +
                      //     item2?.start_amount * (item2?.interest / 100);
                      //   return result;
                      // };
                      // เป็นดอกเบี้ยเงินกู้
                      // const totalInterestLoan = () => {
                      //   let result = 0;
                      //   result += totalCreditor() - item2?.start_amount;
                      //   return result;
                      // };

                      const creditor = calTotalCreditorFloatInterest(item2);

                      // 6533
                      const totalInterest = creditor.reduce((acc, item) => {
                        return acc + item.interest;
                      }, 0);

                      const totalCreditorInterest = creditor.reduce(
                        (acc, item, index) => {
                          if (index > 11) {
                            return acc;
                          }
                          return acc + item.interest;
                        },
                        0
                      );

                      return (
                        <div key={index} className="mb-4">
                          <CardDebt
                            title={`หนี้กู้ระยะสั้น (ดอกลอย)`}
                            name={item2?.creditor_name}
                            options_debt_short="debt_shortterm_other"
                            total_start_overdue={item2?.unpaid_amount}
                            total_overdue={item2?.unpaid_amount}
                            onClick={() => {
                              setOpenInfo(!openInfo);
                            }}
                            pay_permonth={item2?.pay_permonth}
                            interest_loan_peryear={item2?.interest}
                            total_payment={item2?.pay_permonth}
                            period={item2?.period || 0}
                            total_creditor={
                              item2?.unpaid_amount + totalCreditorInterest
                            }
                            total_interest_loan={totalCreditorInterest}
                            total_loan={item2?.unpaid_amount}
                            total_creditor2={
                              item2?.unpaid_amount + totalInterest
                            }
                            total_interest_loan2={totalInterest}
                            total_loan2={item2?.unpaid_amount}
                            type_payment={""}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
            })}
          </>
        ) : (
          <div className="text-center">ไม่มีรายการ</div>
        )}
      </>

      {/*---------------------------------------- หนี้สินระยะยาว ----------------------------------------*/}
      <>
        <div className=" text-mainblue font-semibold">หนี้สินระยะยาว</div>
        {balanceData?.debt_long_list?.length > 0 ? (
          <>
            {/*---- สินเชื่อรถยนต์ / จักรยานยนต์ ----*/}
            {balanceData?.debt_long_list?.map((item, index) => {
              if (item?.options_debt_long === "debt_car")
                return (
                  <div key={index} className="">
                    {item?.debt_long_cars?.map((item2, index) => {
                      const findTypeCar = OPTIONS_TYPE_CAR?.filter(
                        (thisType) => {
                          if (thisType.value === item2?.type_car)
                            return thisType;
                        }
                      );
                      const typeCar = findTypeCar?.[0]?.label;
                      // เจ้าหนี้ได้เงินทั้งสิน
                      const totalCreditor = () => {
                        let result = 0;
                        result +=
                          item2?.unpaid_amount +
                          item2?.unpaid_amount * (item2?.interest / 100);
                        return result;
                      };
                      // เป็นดอกเบี้ยเงินกู้
                      const totalInterestLoan = () => {
                        let result = 0;
                        result += totalCreditor() - item2?.unpaid_amount;
                        return result;
                      };

                      const getTypePayment = () => {
                        const findOptionPayment = options_debt.find(
                          (debtItem) => {
                            return debtItem.value === item2?.status;
                          }
                        );
                        return findOptionPayment?.label;
                      };

                      let total_creditor = item2?.pay_permonth * item2?.period;
                      let total_interest_loan =
                        total_creditor - item2?.unpaid_amount;

                      return (
                        <div key={index} className="mb-4">
                          <CardDebt
                            title={
                              typeCar
                                ? `${typeCar} ${item2?.title_car}`
                                : item2?.title_car
                            }
                            total_start_overdue={item2?.unpaid_amount}
                            total_overdue={item2?.unpaid_amount}
                            onClick={() => {
                              setOpenInfo(!openInfo);
                            }}
                            // periodCustom={`${item2?.period} ปี`}
                            interest_loan_peryear={item2?.interest}
                            total_payment={item2?.pay_permonth}
                            period={item2?.period || 0}
                            total_creditor={total_creditor}
                            total_interest_loan={total_interest_loan}
                            total_loan={item2?.unpaid_amount}
                            type_payment={getTypePayment()}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- สินเชื่อที่อยู่อาศัย ----*/}
            {balanceData?.debt_long_list?.map((item, index) => {
              if (item?.options_debt_long === "debt_place")
                return (
                  <div key={index} className="">
                    {item?.debt_long_places?.map((item2, index) => {
                      const findTypePlace = OPTIONS_TYPE_PLACE?.filter(
                        (thisType) => {
                          if (thisType.value === item2?.type_place)
                            return thisType;
                        }
                      );
                      const typePlace = item2?.type_place_other
                        ? item2?.type_place_other
                        : findTypePlace?.[0]?.label;
                      // เจ้าหนี้ได้เงินทั้งสิน
                      const totalCreditor = () => {
                        let result = 0;
                        result +=
                          item2?.start_amount +
                          item2?.start_amount * (item2?.interest / 100);
                        return result;
                      };
                      // เป็นดอกเบี้ยเงินกู้
                      const totalInterestLoan = () => {
                        let result = 0;
                        result += totalCreditor() - item2?.start_amount;
                        return result;
                      };

                      const getTypePayment = () => {
                        const findOptionPayment = options_debt.find(
                          (debtItem) => {
                            return debtItem.value === item2?.status;
                          }
                        );
                        return findOptionPayment?.label;
                      };

                      let total_creditor =
                        item2?.pay_permonth * item2?.period * 12;
                      let total_interest_loan =
                        total_creditor - item2?.unpaid_amount;

                      return (
                        <div key={index} className="mb-4">
                          <CardDebt
                            title={
                              typePlace
                                ? `${typePlace} ${item2?.address_place}`
                                : item2?.address_place
                            }
                            total_start_overdue={item2?.unpaid_amount}
                            total_overdue={item2?.unpaid_amount}
                            onClick={() => {
                              setOpenInfo(!openInfo);
                            }}
                            typeDebt="place"
                            interest_loan_peryear={item2?.interest}
                            total_payment={item2?.pay_permonth}
                            period={item2?.period || 0}
                            total_creditor={total_creditor}
                            total_interest_loan={total_interest_loan}
                            total_loan={item2?.unpaid_amount}
                            type_payment={getTypePayment()}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
            })}
            {/*---- หนี้สินระยะยาวอื่น ๆ ----*/}
            {balanceData?.debt_long_list?.map((item, index) => {
              if (item?.options_debt_long === "debt_long_other")
                return (
                  <div key={index} className="">
                    {item?.debt_long_others?.map((item2, index) => {
                      // เจ้าหนี้ได้เงินทั้งสิน
                      const totalCreditor = () => {
                        let result = 0;
                        result +=
                          item2?.start_amount +
                          item2?.start_amount * (item2?.interest / 100);
                        return result;
                      };
                      // เป็นดอกเบี้ยเงินกู้
                      const totalInterestLoan = () => {
                        let result = 0;
                        result += totalCreditor() - item2?.start_amount;
                        return result;
                      };
                      return (
                        <div key={index} className="mb-4">
                          <CardDebt
                            title={item2?.title}
                            total_start_overdue={item2?.unpaid_amount}
                            total_overdue={item2?.unpaid_amount}
                            onClick={() => {
                              setOpenInfo(!openInfo);
                            }}
                            typeDebt="long_other"
                            interest_loan_peryear={item2?.interest}
                            total_payment={item2?.pay_permonth}
                            period={item2?.period || 0}
                            total_creditor={totalCreditor()}
                            total_interest_loan={totalInterestLoan()}
                            total_loan={item2?.start_amount}
                            type_payment={
                              item2?.status === "normal"
                                ? "ปกติ"
                                : item2?.status
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                );
            })}
          </>
        ) : (
          <div className="text-center">ไม่มีรายการ</div>
        )}
      </>
      <div className="flex flex-col items-center w-full">
        <div className=" mt-5">
          <div className=" flex justify-center items-center text-mainblue  font-semibold text-xl text-center ">
            <ImageMain src="../images/taxicon3.png" className=" w-8 h-8" />
            ไม่ต้องเครียด
          </div>
          <div className={` text-mainblue  text-base text-center mt-1`}>
            คุณสามารถจัดการหนี้สินได้ด้วยการจัดการของเรา
          </div>
          <div className={`${STYLE_TEXT_CYAN}  text-center text-2xl mt-2`}>
            มาวางแผนหนี้สินกันเถอะ !
          </div>
        </div>
      </div>

      <MTButton
        text={"วางแผนหนี้สิน"}
        disabled={
          !balanceData?.debt_short_list?.length &&
          !balanceData?.debt_long_list?.length
        }
        color="bg-maincyan"
        width="w-full h-12"
        onClick={() => {
          if (
            !balanceData?.debt_short_list?.length &&
            !balanceData?.debt_long_list?.length
          ) {
            return;
          }
          scrollToTop()
          navigate("/debtplan/form");
        }}
      />

      {!mergeItems?.length && (
        <div className="flex flex-col text-center text-[13px] font-medium">
          “กรุณากรอข้อมูลใน งบดุล {">"} หนี้สิน {">"}{" "}
          หนี้สินระยะสั้น/หนี้สินระยะยาว
          เพื่อให้ระบบมีข้อมูลในการวิเคราะห์การวางแผนหนี้สินของคุณ”
        </div>
      )}
    </div>
  );
};
export default ViewDebtPlan;
