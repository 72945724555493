import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import { ImageMain } from "../../../components/img/Img.Main";
import { CardFutureCheckBox } from "../styles/Card";

export const FutureStepOne = () => {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const setFuturePlanData = useFuturePlanStore(
    (state) => state.setFuturePlanData
  );

  const templateHomeCar = {
    year: "",
    down: "",
    invest_down_interest: "",
    invest_down_interest_amount: "",
    sum_invest_five: "",
    diff_amount: "",
    amount: "",
    interest: "",
    interest_amount: "",
    amount_all: "",
    period: "",
    sum_amount_per_period: "",
  };

  const templateNormal = {
    title: "",
    year: "",
    amount: "",
  };

  const onChangeCheckBox = (key) => (e) => {
    let final_template = {};
    let localFuturePlanData = futurePlanData;

    if (localFuturePlanData[key]?.length > 0) {
      setFuturePlanData({
        ...futurePlanData,
        [key]: !e?.target?.checked ? [] : localFuturePlanData[key],
      });
      return;
    } else if (["expenese_home_items", "expenese_car_items"].includes(key)) {
      final_template = templateHomeCar;
      if (key === "expenese_home_items") {
        final_template.interest = 6;
        final_template.period = 25
      } else {

      }
    } else {
      final_template = templateNormal;
    }

    setFuturePlanData({
      ...futurePlanData,
      [key]: e?.target?.checked
        ? [
            {
              ...final_template,
            },
          ]
        : [],
    });
  };


  

  return (
    <div className=" flex flex-col justify-center items-center gap-2.5">
      <ImageMain src="/images/future_money.png" className="w-[60px] h-[60px]" />
      <div className=" text-black text-xl font-semibold">วางแผนอนาคต</div>
      <div className=" bg-gray50 h-full  w-full rounded-[10px] p-4 space-y-5 ">
        <div className=" text-black text-center font-semibold text-base ">
          ในอนาคตคุณต้องการทำสิ่งเหล่านี้หรือไม่
        </div>
        {/* ----- รายจ่าย ----- */}
        <div className=" space-y-2">
          <div className="text-maincyan font-medium">รายจ่าย</div>
          <CardFutureCheckBox
            icon={`../images/house_welcome.png`}
            label={"ซื้อบ้าน"}
            checked={futurePlanData?.expenese_home_items?.length > 0}
            onChange={onChangeCheckBox("expenese_home_items")}
          />
          <CardFutureCheckBox
            icon={`../images/car_insuarance_all.png`}
            label={"ซื้อรถยนต์"}
            checked={futurePlanData?.expenese_car_items?.length > 0}
            onChange={onChangeCheckBox("expenese_car_items")}
          />
          <CardFutureCheckBox
            icon={`../images/baby.png`}
            label={"วางแผนมีลูก"}
            checked={futurePlanData?.expenese_children_items?.length > 0}
            onChange={onChangeCheckBox("expenese_children_items")}
          />
          <CardFutureCheckBox
            icon={`../images/car_tour.png`}
            label={"ท่องเที่ยว"}
            checked={futurePlanData?.expenese_travel_items?.length > 0}
            onChange={onChangeCheckBox("expenese_travel_items")}
          />
          <CardFutureCheckBox
            icon={`../images/buy.png`}
            label={"ซื้อของสะสม"}
            checked={futurePlanData?.expenese_collection_items?.length > 0}
            onChange={onChangeCheckBox("expenese_collection_items")}
          />
          <CardFutureCheckBox
            icon={`../images/smile_open.png`}
            label={"อื่น ๆ"}
            checked={futurePlanData?.expenese_other_items?.length > 0}
            onChange={onChangeCheckBox("expenese_other_items")}
          />
           <CardFutureCheckBox
            icon={`../images/edu_1.png`}
            // public/images/edu_1.png
            // /images/education_icon.svg
            label={"นำแผนการศึกษาบุตรปัจจุบันมาคำนวณด้วย"}
            checked={futurePlanData?.expenese_children_education?.length > 0}
            onChange={onChangeCheckBox("expenese_children_education")}
          />
        </div>

        {/* ----- รายรับ ----- */}
        <div className=" space-y-2">
          <div className="text-maincyan font-medium">รายรับ</div>
          <CardFutureCheckBox
            icon={`../images/two_hearts.png`}
            label={"รับมรดก"}
            checked={futurePlanData?.income_legacy_items?.length > 0}
            onChange={onChangeCheckBox("income_legacy_items")}
          />
          <CardFutureCheckBox
            icon={`../images/saving_expenses.png`}
            label={"ขายธุรกิจ"}
            checked={futurePlanData?.income_business_items?.length > 0}
            onChange={onChangeCheckBox("income_business_items")}
          />
          <CardFutureCheckBox
            icon={`../images/invest_insuarance_all.png`}
            label={"รายรับจากอสังหาริมทรัพย์ (ขาย เช่า เซ้ง)"}
            checked={futurePlanData?.income_property_items?.length > 0}
            onChange={onChangeCheckBox("income_property_items")}
          />
          <CardFutureCheckBox
            icon={`../images/taxicon3.png`}
            label={"อื่น ๆ"}
            checked={futurePlanData?.income_other_items?.length > 0}
            onChange={onChangeCheckBox("income_other_items")}
          />
        </div>
      </div>
    </div>
  );
};
