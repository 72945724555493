import { useMemo } from "react";
import { useTaxPlanContext } from "../../pages/tax_plan/Tax_Plan.Main";
import _ from "lodash";
import { E } from "react-flaticons";

const TaxHeirachy = (localForm, onSetForm = () => {}, tab = "one") => {
  const form = _.cloneDeep(localForm);
  const { deduction, setDeduction } = useTaxPlanContext();
  const PERCENT_15 = 0.15;

  const tempform = { ...form };

  const deductionTitleFind = (key_name) => {
    return deduction?.find((item) => {
      return item.title === key_name;
    });
  };

  let tempDataList = [];
  let tempDataField = {};

  if (deductionTitleFind("ผู้มีเงินได้")) {
    tempDataField = {
      title: deductionTitleFind("ผู้มีเงินได้")?.title,
      value: "",
      value2: deductionTitleFind("ผู้มีเงินได้")?.amount,
    };
    tempDataList.push(tempDataField);
  }

  if (deductionTitleFind("แม่")) {
    tempDataField = {
      title: deductionTitleFind("แม่")?.title,
      value: deductionTitleFind("แม่")?.amount,
      value2: deductionTitleFind("แม่")?.amount,
    };
    tempDataList.push(tempDataField);
  }

  if (deductionTitleFind("พ่อ")) {
    tempDataField = {
      title: deductionTitleFind("พ่อ")?.title,
      value: deductionTitleFind("พ่อ")?.amount,
      value2: deductionTitleFind("พ่อ")?.amount,
    };
    tempDataList.push(tempDataField);
  }

  if (deductionTitleFind("ลูก")) {
    tempDataField = {
      title: deductionTitleFind("ลูก")?.title,
      value: deductionTitleFind("ลูก")?.amount,
      value2: deductionTitleFind("ลูก")?.amount,
    };
    tempDataList.push(tempDataField);
  }

  // "เลี้ยงดูคนพิการ"
  if (deductionTitleFind("เลี้ยงดูคนพิการ")) {
    tempDataField = {
      title: deductionTitleFind("เลี้ยงดูคนพิการ")?.title,
      value: deductionTitleFind("เลี้ยงดูคนพิการ")?.amount,
      value2: deductionTitleFind("เลี้ยงดูคนพิการ")?.amount,
    };
    tempDataList.push(tempDataField);
  }

  // "เลี้ยงดูคนทุพพลภาพ"
  if (deductionTitleFind("เลี้ยงดูคนทุพพลภาพ")) {
    tempDataField = {
      title: deductionTitleFind("เลี้ยงดูคนทุพพลภาพ")?.title,
      value: deductionTitleFind("เลี้ยงดูคนทุพพลภาพ")?.amount,
      value2: deductionTitleFind("เลี้ยงดูคนทุพพลภาพ")?.amount,
    };
    tempDataList.push(tempDataField);
  }

  // 'เงินสะสมกองทุนสำรองเลี้ยงชีพ

  if (deductionTitleFind("คู่สมรส")) {
    tempDataField = {
      title: deductionTitleFind("คู่สมรส")?.title,
      value: deductionTitleFind("คู่สมรส")?.amount,
      value2: deductionTitleFind("คู่สมรส")?.amount,
    };
    tempDataList.push(tempDataField);
  }

  if (
    deductionTitleFind("เบี้ยประกันสุขภาพบิดามารดาของผู้มีเงินได้และคู่สมรส")
  ) {
    tempDataField = {
      title: deductionTitleFind(
        "เบี้ยประกันสุขภาพบิดามารดาของผู้มีเงินได้และคู่สมรส"
      )?.title,
      value: deductionTitleFind(
        "เบี้ยประกันสุขภาพบิดามารดาของผู้มีเงินได้และคู่สมรส"
      )?.amount,
      value2: deductionTitleFind(
        "เบี้ยประกันสุขภาพบิดามารดาของผู้มีเงินได้และคู่สมรส"
      )?.amount,
    };

    if (
      deductionTitleFind("เบี้ยประกันสุขภาพบิดามารดาของผู้มีเงินได้และคู่สมรส")
        ?.amount >= 15000
    ) {
      tempDataField = {
        title: deductionTitleFind(
          "เบี้ยประกันสุขภาพบิดามารดาของผู้มีเงินได้และคู่สมรส"
        )?.title,
        value: deductionTitleFind(
          "เบี้ยประกันสุขภาพบิดามารดาของผู้มีเงินได้และคู่สมรส"
        )?.amount,
        value2: 15000,
      };
    }

    tempDataList.push(tempDataField);
  }

  if (
    deductionTitleFind("ประกันชีวิต") ||
    deductionTitleFind("ประกันสุขภาพ" ) ||
    form?.tax_plan_insurance_health ||
    form?.tax_plan_insurance_life
  ) {
    let LifeInsure = _.get(deductionTitleFind("ประกันชีวิต"), "amount", 0);
    let HealthInsure = _.get(deductionTitleFind("ประกันสุขภาพ"), "amount", 0);

    if (tab === "four") {
      LifeInsure =
        _.get(deductionTitleFind("ประกันชีวิต"), "amount", 0) +
        _.get(form, "tax_plan_insurance_life", 0);
      HealthInsure =
        _.get(deductionTitleFind("ประกันสุขภาพ"), "amount", 0) +
        _.get(form, "tax_plan_insurance_health", 0);
    }


    if (
      _.get(deductionTitleFind("ประกันชีวิต"), "amount", 0) +
        _.get(deductionTitleFind("ประกันสุขภาพ"), "amount", 0) +
        _.get(form, "tax_plan_insurance_health", 0) +
        _.get(form, "tax_plan_insurance_life", 0) >=
      100000
    ) {
      let LifeInsure2 = LifeInsure
      let HealthInsure2 = HealthInsure

      if (HealthInsure >= (100000 - LifeInsure)) {
        // HealthInsure = (100000 - LifeInsure) >= 25000 ? 25000 : 100000 - LifeInsure;
        HealthInsure2 = (100000 - LifeInsure) >= 25000 ? 25000 : 100000 - LifeInsure;
      }


      if (LifeInsure >= 100000) {
        LifeInsure2 = 100000;
        HealthInsure2 = 0;
      }


      tempDataField = {
        title: "ประกันชีวิต",
        value: LifeInsure,
        value2: LifeInsure2,
        sort: 3,
      };

      tempDataList.push(tempDataField);
      tempDataField = {
        title: "ประกันสุขภาพ",
        value: HealthInsure,
        value2: HealthInsure2,
        sort: 3,
      };
      tempDataList.push(tempDataField);
    } else {

      if (LifeInsure > 0) {
        tempDataField = {
          title: "ประกันชีวิต",
          value: LifeInsure,
          value2: LifeInsure >= 100000 ? 100000 : LifeInsure,
          sort: 3,
        };
        tempDataList.push(tempDataField);
      }

      if (HealthInsure > 0) {
        tempDataField = {
          title: "ประกันสุขภาพ",
          value: HealthInsure,
          value2: HealthInsure >= 25000 ? 25000 : HealthInsure,
          sort: 3,
        };
        tempDataList.push(tempDataField);
      }
    }
  }

  if (
    deductionTitleFind(
      "ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG)"
    ) ||
    form?.tax_fund_esg
  ) {
    let ESGValue = _.get(
      deductionTitleFind(
        "ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG)"
      ),
      "amount",
      0
    );

    if (tab === "four") {
      ESGValue =
        _.get(
          deductionTitleFind(
            "ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG)"
          ),
          "amount",
          0
        ) + _.get(form, "tax_fund_esg", 0);
    }

    if (ESGValue >= 300000) {
      tempDataField = {
        title: "ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG)",
        value: ESGValue,
        value2: 300000,
      };
      tempDataList.push(tempDataField);
    } else {
      tempDataField = {
        title: "ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG)",
        value: ESGValue,
        value2:
          ESGValue > form?.tax_income * 0.3 ? form?.tax_income * 0.3 : ESGValue,
      };
      tempDataList.push(tempDataField);
    }
  }

  if (
    deductionTitleFind("ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพ") ||
    deductionTitleFind("ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)") ||
    deductionTitleFind("กองทุน กบข.") ||
    deductionTitleFind("กองทุนสงเคราะห์ครูโรงเรียนเอกชน") ||
    deductionTitleFind("เงินสะสมกองทุนสำรองเลี้ยงชีพ") ||
    deductionTitleFind("เงินสะสมกองทุนการออมแห่งชาติ (กอช)  ") ||
    deductionTitleFind("ประกันชีวิตแบบบำนาญ") ||
    form?.tax_plan_insurance_annuity ||
    form?.tax_plan_rmf ||
    form?.tax_plan_ssf
  ) {
    let totalSevenMore = 0;
    let tempMAx = 500000;

    // console.log("tempMAx",tempMAx)
    if (deductionTitleFind("เงินสะสมกองทุนสำรองเลี้ยงชีพ")) {
      const amountTitleFind = deductionTitleFind(
        "เงินสะสมกองทุนสำรองเลี้ยงชีพ"
      ).amount;
      const max = form?.tax_income * PERCENT_15;
      totalSevenMore += amountTitleFind > max ? max : amountTitleFind;

      tempDataField = {
        title: "เงินสะสมกองทุนสำรองเลี้ยงชีพ",
        value: deductionTitleFind("เงินสะสมกองทุนสำรองเลี้ยงชีพ")?.amount,
        value2: 0,
        sort: 1,
      };

      const amountOfPercent = form?.tax_income * PERCENT_15;

      if (amountTitleFind >= form?.tax_income * PERCENT_15) {
        tempDataField.value2 = form?.tax_income * PERCENT_15;
      } else {
        tempDataField.value2 = amountTitleFind;
      }

      if (
        amountTitleFind >= 500000 &&
        form?.tax_income * PERCENT_15 >= 500000
      ) {
        tempMAx = tempMAx - 500000 <= 0 ? 0 : tempMAx - 500000;
        tempDataField = {
          title: "เงินสะสมกองทุนสำรองเลี้ยงชีพ",
          value: deductionTitleFind("เงินสะสมกองทุนสำรองเลี้ยงชีพ")?.amount,
          value2: tempMAx > 500000 ? 500000 : tempMAx,
          sort: 0,
        };
        tempDataList.push(tempDataField);
      }

      if (tempMAx - tempDataField.value2 <= 0) {
        tempDataField.value2 = tempMAx;
      }

      tempMAx = tempMAx - tempDataField.value2;
      tempDataList.push(tempDataField);
    }

    // console.log("tempMAx1",tempMAx)
    // console.log("totalSevenMore 1", totalSevenMore);
    if (deductionTitleFind("เงินสะสมกองทุนการออมแห่งชาติ (กอช)  ")) {
      const amountTitleFind = deductionTitleFind(
        "เงินสะสมกองทุนการออมแห่งชาติ (กอช)  "
      ).amount;
      totalSevenMore += amountTitleFind > 30000 ? 30000 : amountTitleFind;

      tempDataField = {
        title: "เงินสะสมกองทุนการออมแห่งชาติ (กอช)  ",
        value: amountTitleFind,
        value2: 0,
        sort: 2,
      };

      if (
        amountTitleFind >= 30000 &&
        _.get(deductionTitleFind("กองทุน กบข."), "amount", 0) <= 0
      ) {
        // console.log("before กอช", tempMAx);
        tempMAx = tempMAx - 30000 <= 0 ? 0 : tempMAx - 30000;

        // console.log("after กอช", tempMAx);
        // 500,000 - 370,000 = 130,000

        tempDataField = {
          title: "เงินสะสมกองทุนการออมแห่งชาติ (กอช)  ",
          value: amountTitleFind,
          value2: tempMAx > 30000 ? 30000 : tempMAx,
          sort: 2,
        };
        tempDataList.push(tempDataField);
      } else {
        tempDataField = {
          title: "เงินสะสมกองทุนการออมแห่งชาติ (กอช)  ",
          value: amountTitleFind,
          value2: amountTitleFind,
          sort: 2,
        };

        if (tempMAx - tempDataField.value2 <= 0) {
          tempDataField.value2 = tempMAx;
        }

        if (tempDataField.value2 > 30000) {
          tempDataField.value2 = 30000;
        }

        tempMAx = tempMAx - tempDataField.value2;
        tempDataList.push(tempDataField);
      }

      if (
        totalSevenMore > 500000 &&
        _.get(deductionTitleFind("ประกันชีวิตแบบบำนาญ"), "amount", 0) <= 0
      ) {
        const diffAmount = totalSevenMore - 500000;
        const remain = tempDataField.value2 - diffAmount;
        tempDataField.value2 = remain;
        if (remain <= 0) {
          tempDataField.value2 = 0;
        }
      }
    }

    
    // console.log("tempMAx2",tempMAx)
    // console.log("totalSevenMore 2", totalSevenMore);
    if (
      deductionTitleFind("ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)") ||
      form?.tax_plan_ssf
    ) {
      let SSFValue = _.get(
        deductionTitleFind("ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)"),
        "amount",
        0
      );

      if (tab === "four") {
        SSFValue =
          _.get(
            deductionTitleFind("ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)"),
            "amount",
            0
          ) + _.get(form, "tax_plan_ssf", 0);
      }

      tempDataField = {
        title: "ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)",
        value: SSFValue,
        value2: 0,
        sort: 6,
      };


      if (SSFValue >= 200000 && form?.tax_income * 0.3 >= 200000) {
        totalSevenMore += 200000;
        tempDataField = {
          title: "ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)",
          value: SSFValue,
          value2: tempMAx > 200000 ? 200000 : tempMAx,
          sort: 6,
        };

        tempDataList.push(tempDataField);
      } else {
        totalSevenMore += SSFValue;
        const ssfAmount = SSFValue;

        tempDataField = {
          title: "ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)",
          value: ssfAmount,
          value2: ssfAmount,
          sort: 6,
        };

        if (ssfAmount >= form?.tax_income * 0.3) {
          tempDataField.value2 = form?.tax_income * 0.3;
        } else {
          tempDataField.value2 = ssfAmount;
        }

        if (tempMAx - tempDataField.value2 <= 0) {
          tempDataField.value2 = tempMAx;
        }

        tempMAx = tempMAx - tempDataField.value2;
        tempDataList.push(tempDataField);
      }
    }

    // console.log("tempMAx3",tempMAx)
    // console.log("totalSevenMore 3", totalSevenMore);
    if (deductionTitleFind("กองทุน กบข.")) {
      tempDataField = {
        title: "กองทุน กบข.",
        value: deductionTitleFind("กองทุน กบข.")?.amount,
        value2: 0,
        sort: 1,
      };
      if (deductionTitleFind("กองทุน กบข.")?.amount >= 500000) {
        totalSevenMore += 500000;
        // let newtempMAx = tempMAx - 500000 <= 0 ? 0 : tempMAx - 500000;

        tempDataField = {
          title: "กองทุน กบข.",
          value: deductionTitleFind("กองทุน กบข.")?.amount,
          // value2: newtempMAx > 500000 ? 500000 : newtempMAx,
          value2: 500000,
          sort: 1,
        };
      } else {
        totalSevenMore += deductionTitleFind("กองทุน กบข.")?.amount;
        tempDataField = {
          title: "กองทุน กบข.",
          value: deductionTitleFind("กองทุน กบข.")?.amount,
          value2:  deductionTitleFind("กองทุน กบข.")?.amount,
          sort: 1,
        };
      }

      if (tempMAx - tempDataField.value2 <= 0) {
        tempDataField = {
          title: "กองทุน กบข.",
          value: deductionTitleFind("กองทุน กบข.")?.amount,
          value2: tempMAx,
        };
      }


      tempMAx = tempMAx - tempDataField.value2;
      tempDataList.push(tempDataField);
    }

    // console.log("tempMAx4",tempMAx)
    // console.log("totalSevenMore 4", totalSevenMore);
    if (deductionTitleFind("กองทุนสงเคราะห์ครูโรงเรียนเอกชน")) {
      const amountTitleFind = deductionTitleFind(
        "กองทุนสงเคราะห์ครูโรงเรียนเอกชน"
      ).amount;

      tempDataField = {
        title: "กองทุนสงเคราะห์ครูโรงเรียนเอกชน",
        value: amountTitleFind,
        value2: 0,
        sort: 2,
      };

      if (amountTitleFind >= 500000) {
        totalSevenMore += 500000;
        // tempMAx = tempMAx - 500000 <= 0 ? 0 : tempMAx - 500000;
        tempDataField = {
          title: "กองทุนสงเคราะห์ครูโรงเรียนเอกชน",
          value: amountTitleFind,
          // value2: tempMAx > 500000 ? 500000 : tempMAx,
          value2: 500000,
          sort: 2,
        };
        tempDataList.push(tempDataField);
      } else {
        totalSevenMore += amountTitleFind;
        tempDataField = {
          title: "กองทุนสงเคราะห์ครูโรงเรียนเอกชน",
          value: amountTitleFind,
          value2: amountTitleFind,
          sort: 2,
        };

        if (tempMAx - tempDataField.value2 <= 0) {
          tempDataField.value2 = tempMAx;
        }

        tempMAx = tempMAx - tempDataField.value2;
        tempDataList.push(tempDataField);
      }
    }

    // console.log("tempMAx5",tempMAx)
    // console.log("totalSevenMore 5", totalSevenMore);
    if (
      deductionTitleFind("ประกันชีวิตแบบบำนาญ") ||
      form?.tax_plan_insurance_annuity
    ) {
      let amountTitleFind = _.get(
        deductionTitleFind("ประกันชีวิตแบบบำนาญ"),
        "amount",
        0
      );
      let amountTitleLife = _.get(
        deductionTitleFind("ประกันชีวิต"),
        "amount",
        0
      );

      if (tab === "four") {
        amountTitleFind += _.get(form, "tax_plan_insurance_annuity", 0);
        amountTitleLife += _.get(form, "tax_plan_insurance_life", 0);
      }

      tempDataField = {
        title: "ประกันชีวิตแบบบำนาญ",
        value: amountTitleFind,
        value2: 0,
        sort: 4,
      };

      if (amountTitleFind >= 200000 && form?.tax_income * 0.15 >= 200000) {
        console.log("1")
        tempMAx = tempMAx - 200000;
        tempDataField = {
          title: "ประกันชีวิตแบบบำนาญ",
          value: amountTitleFind,
          // value2: tempMAx > 200000 ? 200000 : tempMAx,
          value2: 200000,
          sort: 4,
        };
        tempDataList.push(tempDataField);
      } else {
        // console.log("2")

        tempDataField = {
          title: "ประกันชีวิตแบบบำนาญ",
          value: amountTitleFind,
          value2:
            (form?.tax_income * 0.15) >= 200000
              ? 200000
              : form?.tax_income * 0.15,
          sort: 4,
        };

        if (amountTitleFind >= form?.tax_income * 0.15) {
        console.log("3")
          tempDataField.value2 = form?.tax_income * 0.15;
        } else {
        // console.log("4")
          tempDataField.value2 = amountTitleFind;
        }

        if (tempMAx - tempDataField.value2 <= 0) {
        // console.log("5")
          tempDataField.value2 = tempMAx;
        }
        // console.log("6")
        tempMAx = tempMAx - tempDataField.value2;
        tempDataList.push(tempDataField);
      }

      if (amountTitleFind > 0 && amountTitleLife < 100000) {
        // console.log("7")
        let foundLife = tempDataList.find(
          (item) => item.title === "ประกันชีวิต"
        );
        // console.log("8")
        let foundHealth = tempDataList.find(
          (item) => item.title === "ประกันสุขภาพ"
        );
        // console.log("9")
        let LifeValue = Number(_.get(foundLife,"value2",0))
        let HealthValue = Number(_.get(foundHealth,"value2",0)||0)

        let diffAnualty = amountTitleFind > (100000 - (LifeValue + HealthValue) ) ? (amountTitleFind-100000 - LifeValue - HealthValue) :amountTitleFind

        if (foundLife) {
        
        if((Number(LifeValue||0)+Number(HealthValue||0)+Number(diffAnualty||0))>=100000){
        
          foundLife.value2 = Number(LifeValue) + amountTitleFind-Number(diffAnualty);
          tempDataField.value2 = Number(diffAnualty);
        }else{
          
          foundLife.value2 = Number(LifeValue) + Number(diffAnualty);
          tempDataField.value2 = amountTitleFind-Number(diffAnualty);
        }
          
        } else {
          // console.log("11")
          if (foundHealth) {
          // console.log("12")

            let getLifeValue = 0
            let LifeValueMax = 100000 - HealthValue
  
            if ( amountTitleFind > LifeValueMax) {
          // console.log("13")
              getLifeValue = LifeValueMax
            }
            else{
          // console.log("14")
              getLifeValue = amountTitleFind
            }
  

            tempDataField.value2 = LifeValueMax > amountTitleFind ? 0 : amountTitleFind - LifeValueMax
            foundHealth.value2 = HealthValue
            tempDataList.push({
              title: "ประกันชีวิต",
              value: amountTitleLife ? 0 : amountTitleLife,
              value2: getLifeValue ,
              sort: 3,
            });
          
          }else{
          // console.log("15")

          tempDataField.value2 = amountTitleFind - 100000  < 0 ? 0:amountTitleFind - 100000
          tempDataList.push({
            title: "ประกันชีวิต",
            value: amountTitleLife ? 0 : amountTitleLife,
            value2: amountTitleFind > 100000 ? 100000 : amountTitleFind,
            sort: 3,
          });
        }
        }



      } else {
        // console.log("16")

        totalSevenMore += amountTitleFind;
      }

      if (
        totalSevenMore > 500000 &&
        _.get(
          deductionTitleFind("ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพ"),
          "amount",
          0
        ) <= 0
      ) {
        // console.log("17")

        // 350,000 - 170,000 = 180,000
        const diffAmount = totalSevenMore - 500000;
        const remain = tempDataField.value2 - diffAmount;
        tempDataField.value2 = remain;
        if (remain <= 0) {
          tempDataField.value2 = 0;
        }
        // console.log("18")
        // กอช
        if (remain <= 0) {
          const foundKoc = tempDataList.find(
            (item) => item.title === "เงินสะสมกองทุนการออมแห่งชาติ (กอช)  "
          );
          // console.log("19")
          const remainKoc = foundKoc?.value2 - Math.abs(remain);
          if (foundKoc) {
          // console.log("20")
            foundKoc.value2 = remainKoc <= 0 ? 0 : remainKoc;
          }
        }
      }

      // console.log("---------------------")
    }

    // console.log("tempMAx6",tempMAx)
    // console.log("totalSevenMore 6", totalSevenMore);
    if (
      deductionTitleFind("ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพ") ||
      form?.tax_plan_rmf
    ) {
      let rmfAmount = _.get(
        deductionTitleFind("ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพ"),
        "amount",
        0
      );
      if (tab === "four") {
        rmfAmount += _.get(form, "tax_plan_rmf", 0);
      }
      tempDataField = {
        title: "ค่าซื้อหน่วยลงทุนเพื่อการเลี้ยงชีพ (RMF)",
        value: rmfAmount,
        value2: 0,
        sort: 5.1,
      };

      const max = form?.tax_income * 0.3;
      totalSevenMore += tempDataField?.value > max ? max : tempDataField?.value;

      if (
        deductionTitleFind("ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพ")
          ?.amount >= 500000 &&
        form?.tax_income * 0.3 >= 500000
      ) {
        tempMAx = tempMAx - 500000 <= 0 ? 0 : tempMAx - 500000;

        tempDataField = {
          title: "ค่าซื้อหน่วยลงทุนเพื่อการเลี้ยงชีพ (RMF)",
          value: deductionTitleFind(
            "ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพ"
          )?.amount,
          value2: tempMAx > 500000 ? 500000 : tempMAx,
          sort: 5,
        };
        tempDataList.push(tempDataField);
      } else {
        tempDataField = {
          title: "ค่าซื้อหน่วยลงทุนเพื่อการเลี้ยงชีพ (RMF)",
          value: rmfAmount,
          value2: rmfAmount,
          sort: 5,
        };

        if (rmfAmount >= form?.tax_income * 0.3) {
          tempDataField.value2 = form?.tax_income * 0.3;
        } else {
          tempDataField.value2 = rmfAmount;
        }

        if (tempMAx - tempDataField.value2 <= 0) {
          tempDataField.value2 = tempMAx;
        }

        tempMAx = tempMAx - tempDataField.value2;
        tempDataList.push(tempDataField);
      }

      if (totalSevenMore > 500000) {
        // 1,030,000 - 500,000 = 530,000
        // 500,000 - 530,000 = -30,000

        // const diffAmount = 500000 - tempMAx
        // const remain = rmfAmount - diffAmount;
        const diffAmount = totalSevenMore - 500000;
        const remain = rmfAmount - diffAmount;
        tempDataField.value2 = remain;
        if (remain <= 0) {
          tempDataField.value2 = 0;
          // ประกันชีวิตแบบบำนาญ
          const foundPensionInsurance = tempDataList.find(
            (item) => item.title === "ประกันชีวิตแบบบำนาญ"
          );
          const remainPension =
            foundPensionInsurance?.value2 - Math.abs(remain);
          if (foundPensionInsurance) {
            foundPensionInsurance.value2 =
              remainPension <= 0 ? 0 : remainPension;
          }

          // กอช
          if (remainPension <= 0) {
            const foundKoc = tempDataList.find(
              (item) => item.title === "เงินสะสมกองทุนการออมแห่งชาติ (กอช)  "
            );
            const remainKoc = foundKoc?.value2 - Math.abs(remainPension);
            if (foundKoc) {
              foundKoc.value2 = remainKoc <= 0 ? 0 : remainKoc;
            }
          }
        }
      }
    }

    // ย้อนกลับถ้าเกิน 500,000 และมี กบขกับโรงเรียนเอกชน
    const foundProvident = tempDataList.find(
      (item) => item.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ"
    );
    const foundSSF = tempDataList.find(
      (item) => item.title === "ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)"
    );
    const foundGorBorKor = tempDataList.find(
      (item) => item.title === "กองทุน กบข."
    );
    const foundPrivateSchool = tempDataList.find(
      (item) => item.title === "กองทุนสงเคราะห์ครูโรงเรียนเอกชน"
    );
    const foundKor = tempDataList.find(
      (item) => item.title === "เงินสะสมกองทุนการออมแห่งชาติ (กอช)  "
    );
    const foundPension = tempDataList.find(
      (item) => item.title === "ประกันชีวิตแบบบำนาญ"
    );
    const foundRMF = tempDataList.find(
      (item) => item.title === "ค่าซื้อหน่วยลงทุนเพื่อการเลี้ยงชีพ (RMF)"
    );
    let remainSevenMore =
      _.get(foundProvident, "value2", 0) +
      _.get(foundSSF, "value2", 0) +
      _.get(foundGorBorKor, "value2", 0) +
      _.get(foundPrivateSchool, "value2", 0) +
      _.get(foundKor, "value2", 0) +
      _.get(foundPension, "value2", 0) +
      _.get(foundRMF, "value2", 0);
    if (remainSevenMore > 500000) {
      if (foundPrivateSchool) {
        remainSevenMore -= foundPrivateSchool.value2;
        const remainPrivateSchool = foundPrivateSchool.value2 - remainSevenMore;
        foundPrivateSchool.value2 =
          remainPrivateSchool <= 0 ? 0 : remainPrivateSchool;
      }

      if (foundGorBorKor && remainSevenMore > 500000) {
        remainSevenMore -= 500000;
        const remainGorBorKor = foundGorBorKor.value2 - remainSevenMore;
        foundGorBorKor.value2 = remainGorBorKor <= 0 ? 0 : remainGorBorKor;
      }
    }
  }

  if (deductionTitleFind("เงินค่าชดเชยที่ได้รับตามกฎหมายแรงงาน")) {
    if (
      deductionTitleFind("เงินค่าชดเชยที่ได้รับตามกฎหมายแรงงาน")?.amount >=
      30000
    ) {
      tempDataField = {
        title: "เงินค่าชดเชยที่ได้รับตามกฎหมายแรงงาน",
        value: deductionTitleFind("เงินค่าชดเชยที่ได้รับตามกฎหมายแรงงาน")
          ?.amount,
        value2: 30000,
      };
      tempDataList.push(tempDataField);
    } else {
      tempDataField = {
        title: "เงินค่าชดเชยที่ได้รับตามกฎหมายแรงงาน",
        value: deductionTitleFind("เงินค่าชดเชยที่ได้รับตามกฎหมายแรงงาน")
          ?.amount,
        value2: deductionTitleFind("เงินค่าชดเชยที่ได้รับตามกฎหมายแรงงาน")
          ?.amount,
      };
      tempDataList.push(tempDataField);
    }
  }

  if (
    deductionTitleFind(
      "ดอกเบี้ยเงินกู้ยืมเพื่อ ซื้อ เช่าซื้อ หรือสร้างอาคารอยู่อาศัย"
    )
  ) {
    if (
      deductionTitleFind(
        "ดอกเบี้ยเงินกู้ยืมเพื่อ ซื้อ เช่าซื้อ หรือสร้างอาคารอยู่อาศัย"
      )?.amount >= 100000
    ) {
      tempDataField = {
        title: "ดอกเบี้ยเงินกู้ยืมเพื่อ ซื้อ เช่าซื้อ หรือสร้างอาคารอยู่อาศัย",
        value: deductionTitleFind(
          "ดอกเบี้ยเงินกู้ยืมเพื่อ ซื้อ เช่าซื้อ หรือสร้างอาคารอยู่อาศัย"
        )?.amount,
        value2: 100000,
      };
      tempDataList.push(tempDataField);
    } else {
      tempDataField = {
        title: "ดอกเบี้ยเงินกู้ยืมเพื่อ ซื้อ เช่าซื้อ หรือสร้างอาคารอยู่อาศัย",
        value: deductionTitleFind(
          "ดอกเบี้ยเงินกู้ยืมเพื่อ ซื้อ เช่าซื้อ หรือสร้างอาคารอยู่อาศัย"
        )?.amount,
        value2: deductionTitleFind(
          "ดอกเบี้ยเงินกู้ยืมเพื่อ ซื้อ เช่าซื้อ หรือสร้างอาคารอยู่อาศัย"
        )?.amount,
      };
      tempDataList.push(tempDataField);
    }
  }

  if (deductionTitleFind("เงินสมทบกองทุนประกันสังคม")) {
    if (deductionTitleFind("เงินสมทบกองทุนประกันสังคม")?.amount >= 9000) {
      tempDataField = {
        title: "เงินสมทบกองทุนประกันสังคม",
        value: deductionTitleFind("เงินสมทบกองทุนประกันสังคม")?.amount,
        value2: 9000,
      };
      tempDataList.push(tempDataField);
    } else {
      tempDataField = {
        title: "เงินสมทบกองทุนประกันสังคม",
        value: deductionTitleFind("เงินสมทบกองทุนประกันสังคม")?.amount,
        value2: deductionTitleFind("เงินสมทบกองทุนประกันสังคม")?.amount,
      };
      tempDataList.push(tempDataField);
    }
  }

  if (deductionTitleFind("ค่าฝากครรภ์และค่าคลอดบุตร")) {
    if (deductionTitleFind("ค่าฝากครรภ์และค่าคลอดบุตร")?.amount >= 60000) {
      tempDataField = {
        title: "ค่าฝากครรภ์และค่าคลอดบุตร",
        value: deductionTitleFind("ค่าฝากครรภ์และค่าคลอดบุตร")?.amount,
        value2: 60000,
      };
      tempDataList.push(tempDataField);
    } else {
      tempDataField = {
        title: "ค่าฝากครรภ์และค่าคลอดบุตร",
        value: deductionTitleFind("ค่าฝากครรภ์และค่าคลอดบุตร")?.amount,
        value2: deductionTitleFind("ค่าฝากครรภ์และค่าคลอดบุตร")?.amount,
      };
      tempDataList.push(tempDataField);
    }
  }

  if (deductionTitleFind("สิทธิลดหย่อนพิเศษจากนโยบายรัฐบาล")) {
    tempDataField = {
      title: "สิทธิลดหย่อนพิเศษจากนโยบายรัฐบาล",
      value: deductionTitleFind("สิทธิลดหย่อนพิเศษจากนโยบายรัฐบาล")?.amount,
      value2: deductionTitleFind("สิทธิลดหย่อนพิเศษจากนโยบายรัฐบาล")?.amount,
    };
    tempDataList.push(tempDataField);
  }

  if (deductionTitleFind("เงินที่บริจาคแก่พรรคการเมือง")) {
    if (deductionTitleFind("เงินที่บริจาคแก่พรรคการเมือง")?.amount >= 10000) {
      tempDataField = {
        title: "เงินที่บริจาคแก่พรรคการเมือง",
        value: deductionTitleFind("เงินที่บริจาคแก่พรรคการเมือง")?.amount,
        value2: 10000,
      };
      tempDataList.push(tempDataField);
    } else {
      tempDataField = {
        title: "เงินที่บริจาคแก่พรรคการเมือง",
        value: deductionTitleFind("เงินที่บริจาคแก่พรรคการเมือง")?.amount,
        value2: deductionTitleFind("เงินที่บริจาคแก่พรรคการเมือง")?.amount,
      };
      tempDataList.push(tempDataField);
    }
  }

  let donateIncome = () => {
    let tempDonateIncome =
      form?.tax_income -
      form?.deduction_expenses -
      tempDataList?.reduce((sum, item) => {
        return sum + Number(item?.value2 || 0);
      }, 0);

    if (tempDonateIncome <= 0) {
      return 0;
    }

    return tempDonateIncome;
  };

  tempform.donateIncome_limit = donateIncome();

  if (
    deductionTitleFind(
      "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ"
    ) ||
    form?.tax_plan_education_donate
  ) {
    let DonateEduValue = _.get(
      deductionTitleFind(
        "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ"
      ),
      "amount",
      0
    );

    if (tab === "four") {
      DonateEduValue = DonateEduValue + (_.get(form, "tax_plan_education_donate", 0) * 2);
    }

    if (
      DonateEduValue >=
      donateIncome() * 0.1
    ) {
      tempDataField = {
        title: "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ",
        value:
        DonateEduValue / 2,
        value2: donateIncome() * 0.1,
      };
    } else {
      tempDataField = {
        title: "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ",
        value:
        DonateEduValue / 2,
        value2: DonateEduValue,
      };

      if (donateIncome() <= 0) {
        tempDataField = {
          title: "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ",
          value:
          DonateEduValue / 2,
          value2: 0,
        };
      }
    }
    tempDataList.push(tempDataField);
  }

  let otherDonate = () => {
    let tempDonateIncome =
      form?.tax_income -
      form?.deduction_expenses -
      tempDataList?.reduce((sum, item) => {
        return sum + Number(item?.value2 || 0);
      }, 0);

    if (tempDonateIncome <= 0) {
      return 0;
    }

    return tempDonateIncome;
  };

  tempform.otherDonate_limit = otherDonate();

  // "เงินบริจาคอื่น ๆ"
  if (deductionTitleFind("เงินบริจาคอื่น ๆ")|| form?.tax_plan_other_donate) {
    let DonateOtherValue = _.get(
      deductionTitleFind(
        "เงินบริจาคอื่น ๆ"
      ),
      "amount",
      0
    );

    if (tab === "four") {
      DonateOtherValue = DonateOtherValue + (_.get(form, "tax_plan_other_donate", 0) );
    }
    if (DonateOtherValue >= otherDonate() * 0.1) {
      tempDataField = {
        title: "เงินบริจาคอื่น ๆ",
        value: DonateOtherValue,
        value2: otherDonate() * 0.1,
      };
      tempDataList.push(tempDataField);
    } else {
      tempDataField = {
        title: "เงินบริจาคอื่น ๆ",
        value: DonateOtherValue,
        value2: DonateOtherValue,
      };

      if (otherDonate() <= 0) {
        tempDataField = {
          title: "เงินบริจาคอื่น ๆ",
          value: DonateOtherValue,
          value2: 0,
        };
      }

      tempDataList.push(tempDataField);
    }
  }

  onSetForm(tempform);

  return tempDataList;
};


export const ExceptionLists = [
  "กองทุน กบข.",
  "กองทุนสงเคราะห์ครูโรงเรียนเอกชน",
  "เงินค่าชดเชยที่ได้รับตามกฎหมายแรงงาน",
];

export const IncomeAfterCal = (
  tax_income,
  deduction_expenses,
  sumDeduction
) => {
  let taxAdjust = Number(tax_income || 0);
  let expenseAdjust = Number(deduction_expenses || 0);
  let sumDeductionAdjust = Number(sumDeduction || 0);

  let sumIncomeCal = taxAdjust - expenseAdjust - sumDeductionAdjust;
  if (sumIncomeCal < 0) {
    return 0;
  }
  return sumIncomeCal;
};

export const ExceptionSum = (form) => {
  const totalException = TaxHeirachy(form)
    .filter((item) => {
      if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ" && item?.value) {
        return item;
      }
      if (ExceptionLists.includes(item?.title) && item?.value) {
        return item;
      }
    })
    .reduce((sum, item) => {
      if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
        if (item?.value2 >= 10000) {
          return sum + Number(item?.value2) - 10000;
        } else {
          return sum + Number(item?.value2);
        }
      }
      return sum + Number(item?.value2);
    }, 0);

  return totalException;
};

export const DeductionSum = (form) => {
  const totalTaxDeduct = TaxHeirachy(form,()=>{},"four")
    .filter((item) => {
      if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
        return item;
      }
      if (!ExceptionLists.includes(item?.title)) {
        return item;
      }
    })
    .reduce((sum, item) => {
      if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
        if (item?.value2 >= 10000) {
          return sum + 10000;
        } else {
          return sum + Number(item?.value2);
        }
      }
      return sum + Number(item?.value2);
    }, 0);
  return totalTaxDeduct;
};

export default TaxHeirachy;
